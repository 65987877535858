


import axios from "axios"
import { add_sms_Provider } from "../../Redux/addsmsProvider/addsmsProvider"
import { get_All_sms_Provider } from "../../Redux/addsmsProvider/getsmsProvider"
import { store } from "../../Redux/Store"
import { HEADERS } from "../urlAndPath"

//addSmsProvider

export const addSmsProvider =(body,setGetdata,getdata,setCountry,setProvider,setApiPath,setUserId,setPassword)=>{
    axios.post("admin/addSmsProvider",body,HEADERS)
    .then((res)=>{


        if(res.status === 200){
            store.dispatch(add_sms_Provider({addsmsProvider:res.data}));
        }
        console.log(res)
        setCountry(null);
        setProvider(null);
        setApiPath(null);
        setUserId(null);
        setPassword(null);
        setGetdata(!getdata);
        
    }).catch((err)=>{
        console.log("Error Occured....")
    })

}


//getSmsProvider

export const getSmsProvider=(setIsLoading)=>{
    axios.get("admin/getSmsProviders")
    .then((res)=>{
        // console.log("getTheData",res)
        if(res.status === 200){
            store.dispatch(get_All_sms_Provider({getSmsProvider:res.data}))
            setIsLoading(false);
        }




    }).catch((err)=>{
        console.log("errorr occured....");
    })


}