import { get_department_slice } from "../../../Redux/Staff/departmentSlice";
import { store } from "../../../Redux/Store";
import { HEADERS, STAFFBASEURL } from "../../urlAndPath"



// get departmet list api call
export const getDepartmentAPICall=async()=>{
    await STAFFBASEURL.get("employee/getAllDepartments",HEADERS)
        .then((res)=>{
            if(res.status===200){
                store.dispatch(get_department_slice({departmentData:res.data}))
            }
        })
        .catch((err)=>{
            console.log(err.response);
        })
}


// add department api call
export const departmentAPICall= async (
    body,
    updateListner,
    setAddDept,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    setDepartmentName

    )=>{
   await STAFFBASEURL.post('employee/addDepartment',body,HEADERS)
   .then((res)=>{
     if(res.status===200){
        updateListner()
        setAddDept(false)
        setSnackMsg("Succcess")
        setSuccessSnackbar(true)
        setDepartmentName()
     }
   })
   .catch((err)=>{
      console.log(err.response);
      if(err.response===undefined){
        setSnackMsg("Network Connection error!!");
      }
      if(err.response.status===404){
        setSnackMsg("No Data")
      }
      if(err.response.status===409){
        setSnackMsg("Already Exist")
      }
      if(err.ersponse.status===401){
        setSnackMsg("Unauthorized")
      }
      if(err.response.status===403){
        setSnackMsg("Forbidden")
      }
      setErrorSnackbar(true)
   })
 
}

// edit department api call
export const editDepartmentAPICall= async(
    body,
    updateListner,
    setEditDept,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    setDepartmentName
)=>{
    await STAFFBASEURL.put("employee/editDepartment",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            updateListner()
            setEditDept(false)
            setSnackMsg("Succcess")
            setSuccessSnackbar(true)
            setDepartmentName()
        }
    })
    .catch((err)=>{
        console.log(err.response);
      if(err.response===undefined){
        setSnackMsg("Network Connection error!!");
      }
      if(err.response.status===404){
        setSnackMsg("No Data")
      }
      if(err.response.status===409){
        setSnackMsg("Already Exist")
      }
      if(err.ersponse.status===401){
        setSnackMsg("Unauthorized")
      }
      if(err.response.status===403){
        setSnackMsg("Forbidden")
      }
      setErrorSnackbar(true)
   })
    
}

