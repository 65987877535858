import { createSlice } from "@reduxjs/toolkit";

const getAllsmsProviderSlice = createSlice({
    name:'getAllsmsProviderSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_All_sms_Provider:(state,action)=>{
            state.value = action.payload.getSmsProvider;
            // console.log(action.payload)
        }
    }
});

export const {get_All_sms_Provider} = getAllsmsProviderSlice.actions;
export default getAllsmsProviderSlice.reducer;