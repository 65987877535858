import React from 'react'
import TopContainer from '../CustomComponent/TopContainer'
import "../../../Css/Staff/attendance.css"
import totalstaff from '../../../Assets/Icons/total_staff.svg'
import { IconButton } from '@mui/material'

export const Attendance = () => {
  return (
    <>
      <TopContainer label="Staff > Attendance"/>
        <div className='global-container'>
          <div className='global-heading-containe'>
            <div className='total-staff-container'>
               <div className='total-staff-left-container'>
                 <h4>Total Staff</h4>
                 <p>500</p>
               </div>
               <div className='total-staff-right-container'>
               <img src={totalstaff} alt="" />
               </div>
            </div>
            <div className='global-single-input attendance-month-input'>
              <p>Select month</p>
              <input type="month" style={{width:"77%"}}/>
            
            </div>
          </div>
          <div className='global-container-table'>
            <table>
               <thead>
                 <tr>
                   <th>No</th>
                   <th>Employee ID</th>
                   <th>Name</th>
                   <th>In</th>
                   <th>Out</th>
                   <th>Total Work Hours</th>
                   <th>Break In</th>
                   <th>Break Out</th>
                   <th>Total Break Hours</th>
                   <th>Date</th>
                   <th>Leave</th>
                   <th>View</th>
                   <th>Status</th>
                 </tr>
               </thead>
               <tbody>
                <tr>
                 <td></td>
                 <td></td>
                 <td></td>
                 <td></td>
                 <td></td>
                 <td></td>
                 <td></td>
                 <td></td>
                 <td></td>
                 <td></td>
                 <td></td>
                 <td>
                      <IconButton>
                          <i class="bi bi-eye visibility-icon"></i>
                        </IconButton>
                    
                 </td>
                 <td>
                   <span className='attendance-active-status'>Active</span>
                 </td>
                </tr>
               </tbody>
            </table>
          </div>
        </div>
    </>
  )
}
