import { Dialog } from "@mui/material";
import React from "react";
import "../../../Css/Custom css/refundReportView.css";
const RefundReportView = (props) => {
  const { open, onClose } = props;
  return (
    <>
      <Dialog maxWidth="lg"  open={open} >
        <div className="refund-report-view-parent">

          <div className="refund-report-view-head-container">
            <h2>View Details</h2>
          </div>
          <div className="refund-report-view-body-container">
            <div className="refund-report-view-body-container-section" >
                 <div className="global-single-input refund-report-view-input" style={{width:'90%'}}>
                    <p>Transaction ID</p>
                    <input type="text" />    
                 </div>
                 <div className="global-single-input refund-report-view-input" style={{width:'90%'}}>
                    <p>Name</p>
                    <input  type="text" />
                 </div>
                 <div className="global-single-input refund-report-view-input" style={{width:'90%'}}>
                    <p>Account Details</p>
                    <input  type="text" />
                 </div>
                 <div className="global-single-input refund-report-view-input" style={{width:'90%'}}>
                    <p>Transfer Amount</p>
                    <input  type="text" />
                 </div>
                 
            </div>
            <div className="refund-report-view-body-container-section" >
                 <div className="global-single-input refund-report-view-input" style={{width:'90%'}}>
                    <p>Customer ID</p>
                    <input type="text" />    
                 </div>
                 <div className="global-single-input refund-report-view-input" style={{width:'90%'}}>
                    <p>Contact</p>
                    <input  type="text" />
                 </div>
                 <div className="global-single-input refund-report-view-input" style={{width:'90%'}}>
                    <p>Transfer Details</p>
                    <input  type="text" />
                 </div>
                 <div className="global-single-input refund-report-view-input" style={{width:'90%'}}>
                    <p>Reason</p>
                    <input  type="text" />
                 </div>
                 
            </div>
            

        
           </div>
           
           <div className="refund-report-view-container-button">
                    <div className="refund-report-view-container-button-ctn">
                        <button onClick={onClose} className="cancel-btn">Cancel</button>
                    </div>
           </div>
           </div>
      </Dialog>
    </>
  );
};

export default RefundReportView;
