import { createSlice } from "@reduxjs/toolkit";

export const homeDashboardSlice=createSlice({
    name:'homeDashboardSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_homeDashboard:(state,action)=>{
            state.value=action.payload.homeDashboardData
        }
    }
})
export const {get_homeDashboard} =homeDashboardSlice.actions

export default homeDashboardSlice.reducer