import React, { useEffect, useState } from "react";
import "../../../Css/Report Forward/reportForwardMenu.css";
import { set_Selected_State } from "../../../Redux/Global States/ReportForwardMenuStateSlice";
import { store } from "../../../Redux/Store";
const ReportForwardMenu = (props) => {
  const activeMenuLoc = localStorage.getItem("REPORT_FORWARD_ACTIVE_TAB");
  const [activeMenu, setActiveMenu] = useState(activeMenuLoc!==null ? activeMenuLoc:"socialPlatformli");
  localStorage.setItem("REPORT_FORWARD_ACTIVE_TAB", activeMenu);

  useEffect(() => {
    if (activeMenuLoc !== null) {
      setActiveMenu(activeMenu);
    }
  }, []);

  useEffect(() => {
      store.dispatch(set_Selected_State({selectedState:activeMenu}))
  }, [activeMenu])
  


  return (
    <div className="forward-menu-parent">
      <ul>
        <li
          id="socialPlatformli"
          className={
            activeMenu === "socialPlatformli" ? "active-menu" : undefined
          }
          onClick={() => setActiveMenu("socialPlatformli")}
        >
          <div className="checkbox-container">
            <div className="checkbox-single-grp">
              <input type="checkbox" name="platform" id="smsPlatform" />
              <label htmlFor="smsPlatform">SMS</label>
            </div>
            <div className="checkbox-single-grp">
              <input type="checkbox" name="platform" id="kooppaPlatform" />
              <label htmlFor="kooppaPlatform">KOOPPA</label>
            </div>
            <div className="checkbox-single-grp">
              <input type="checkbox" name="platform" id="whatsappPlatform" />
              <label htmlFor="whatsappPlatform">WHATSAPP</label>
            </div>
          </div>
        </li>
        <li
          id="emailli"
          className={activeMenu === "emailli" ? "active-menu" : undefined}
          onClick={() => setActiveMenu("emailli")}
        >
          Email Address
        </li>
        <li
          id="reportsli"
          className={activeMenu === "reportsli" ? "active-menu" : undefined}
          onClick={() => setActiveMenu("reportsli")}
        >
          Reports
        </li>
      </ul>
    </div>
  );
};

export default ReportForwardMenu;
