import { IconButton, MenuItem, Select, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../../Css/Staff/HRM/Manageemployee.css";


export const ManageEmployee = () => {
  const navigate = useNavigate();
  console.log(navigate)
  const allDepartmentList = [];

  //states
  const [searchEmployeeList, setSearchEmployeeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectDpt, setSelectDpt] = useState("0");


  //employeeSingleView
  const employeeSingleView = () => {
    navigate("/dashboard/staff/hrm/manageEmployee/viewEmployee")
  };

  //convertDateFormat
  const convertDateFormat = () => {};

  //getSearchKeyword
  const getSearchKeyword = () => {};

  return (
    <div className="global-page-parent-container1">
      <p style={{ marginLeft: "1%" }} className="breadcrumb">
        Staff &gt; HRM &gt; Manage Employee{" "}
      </p>
      <div className="manage-employee-container global-container">
        <div
          className="manage-employee-top-container"
          style={{ alignItems: "center" }}
        >
          <h3>Employee List</h3>
          <div
            style={{
              width: "60%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Select
              sx={{
                "& .MuiSvgIcon-root": {
                  color: "#fff",
                },
              }}
              value={selectDpt}
              onChange={(e) => setSelectDpt(e.target.value)}
              style={{ width: "30%" }}
            >
              <MenuItem value="0">All Department</MenuItem>
              {allDepartmentList?.map((item, i) => (
                <MenuItem key={i} value={item?._id}>
                  {item?.departmentName}
                </MenuItem>
              ))}
            </Select>
            <div
              className="view-order-search-container view-order-admin-search-container"
              style={{ width: "60%" }}
            >
              <input
                type="text"
                placeholder="Search Employee name,employee id,mobile.."
                value={searchKeyword}
                onChange={getSearchKeyword}
              />
              <IconButton>
                <i className="bi bi-search search-icon-order"></i>
              </IconButton>
            </div>
          </div>
        </div>
        <div className="manage-employee-table global-container-table">
          <table className="global-table ">
            <thead>
              <tr>
                <th>No</th>
                <th>Employee ID</th>
                <th>Branch</th>
                <th>Name</th>
                <th>Image</th>
                <th>Phone Number</th>
                <th>Joining Date</th>
                <th>Designation</th>
                <th>Department</th>
                <th>Default Doc</th>
                <th>Expiry Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={15}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={15}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={15}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={15}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={15}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={15}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={15}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={15}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={15}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={15}>
                    {/* <Loader /> */}
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={40}
                    />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td>A</td>
                  <td>B</td>
                  <td>C</td>
                  <td>D</td>
                  <td>E</td>
                  <td>F</td>
                  <td>G</td>
                  <td>H</td>
                  <td>I</td>
                  <td>J</td>
                  <td>K</td>
                  <td>L</td>
                  <td>
                    <IconButton onClick={employeeSingleView}>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                    <IconButton onClick={""}>
                      <i class="bi bi-pencil-square edit-icon"></i>
                    </IconButton>
                    <IconButton onClick={""}>
                      <i class="bi bi-trash3 delete-icon"></i>
                    </IconButton>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>

      {/* <ErrorSnackbar
      open={errorSnackbar}
      handleClose={closeErrorSnackbar}
      message={snackMsg}
      /> */}
    </div>
  );
};
