import { IconButton } from "@mui/material";
import React from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
const SearchInput = () => {
  return (
    <div className="search-input-parent-container">
      <input type="text" placeholder="Search..." />
      <IconButton
        sx={{
          flex: "20",
          borderRadius: "0",
          margin: "4px 4px 4px 0",
          backgroundColor: "#eee",
        }}
      >
        <SearchOutlinedIcon />
      </IconButton>
    </div>
  );
};

export default SearchInput;
