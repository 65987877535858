let date= new Date()
const currentDay = `0${new Date().getDate()}`.slice(-2);
const currentMonth = `0${new Date().getMonth() + 1}`.slice(-2);
const currentYear = new Date().getFullYear();
const currentHour= date.getHours()
const currentMinutes=String(date.getMinutes()).padStart(2, '0')
const currentSeconds=date.getSeconds()
    
export const today= `${currentYear}-${currentMonth}-${currentDay}`;
export const currentTime=`${currentHour}:${currentMinutes}`

export const getCurrentTime = () => {
    let dateFn = new Date();
    let currentTime = "00:00:00";
    const currentHour = dateFn.getHours();
    const currentMinutes = String(dateFn.getMinutes()).padStart(2, "0");
    const currentSeconds = String(dateFn.getSeconds()).padStart(2, "0");
    currentTime = `${currentHour}:${currentMinutes}:${currentSeconds}`;
    return currentTime;
  };
