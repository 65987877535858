import { createSlice } from "@reduxjs/toolkit";

export const taxSettingsSlice = createSlice({
  name: "taxSettingsSlice",
  initialState: {
    value: undefined,
    TaxSingleView:null,
    taxFiltered:undefined
  },
  reducers: {
    get_tax_settings_list: (state, action) => {
      state.value = action.payload.taxSettingsData;
    },
    Tax_single_View:(state,action) =>{
      state.TaxSingleView = action.payload.singleViewOfTax;
    },
    tax_filtered_list:(state,action) =>{
      state.taxFiltered = action.payload.filteredListTax;
    }
  },
});

export const { get_tax_settings_list,Tax_single_View,tax_filtered_list} = taxSettingsSlice.actions;

export default taxSettingsSlice.reducer;