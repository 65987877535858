import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { store } from '../../../Redux/Store'
import { customer_register_initial_form } from './customerRegistrationSlice'
import { get_my_plans_singleView } from '../../../Redux/RegisterdList/registerdUserSlice'

export const CustomerRegistrationPopup = () => {
    const navigateTo=useNavigate()
    const popupFormInitial={
      email:"",
      mobile:"",
      companyName:""
    }
    const [popForm,setPopupForm]=useState(popupFormInitial)

    const getPopupForm=(key)=>(e)=>{
       const {value}=e.target;
       setPopupForm({...popForm,[key]:value})
    }
    const clickNextBtn=()=>{
      store.dispatch(customer_register_initial_form({initialFormData:popForm}))
      navigateTo("/dashboard/customerRegistration/form")
  }
  useEffect(()=>{
    store.dispatch(get_my_plans_singleView({singleViewData:undefined}))
    localStorage.removeItem("packageId")
  },[])
  
  return (
    <div className='global-container' style={{height:"88vh"}}>
       <div className='customer-reg-popup' >
          <p style={{textAlign:"left",fontSize:"1rem",fontWeight:600}}>Customer Registration</p>
          <div className='register-form-input' style={{alignItems:"flex-start"}}>
            <p>Email</p>
            <input 
              type="text"
              value={popForm?.email}
              onChange={getPopupForm("email")}
              />
          </div>
          <div className='register-form-input' style={{alignItems:"flex-start"}}>
            <p>Mobile</p>
            <input 
              type="text"
              value={popForm?.mobile}
              onChange={getPopupForm("mobile")}
              />
          </div>
          <div className='register-form-input' style={{alignItems:"flex-start"}}>
            <p>Company Name</p>
            <input 
            type="text"
            value={popForm?.companyName}
            onChange={getPopupForm("companyName")}
            />
          </div>
          <button onClick={clickNextBtn} className='global-button-contained-blue' style={{width:"97%"}}>Next</button>

       </div>
    </div>
  )
}
