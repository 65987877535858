import React from "react";
import "../../../Css/HomeDashboard/card.css";

export const Card = (props) => {
  const { backgroundColor, url, heading, price, color } = props;

  return (
    <div className="card-container">
      <div className="card-sub-container">
        <div
          className="card-icon-container"
          style={{ backgroundColor: backgroundColor }}
        >
          <img className="user-image" src={url} />
        </div>
        <div className="card-content">
          <p style={{ color: color }} className="card-content-head">
            {heading}
          </p>
          <p style={{ color: color }} className="card-content-price">
            {price}
          </p>
        </div>
      </div>
    </div>
  );
};
