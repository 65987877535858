import { Autocomplete, Dialog, IconButton, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import uploadImage from '../../../../../Assets/Images/upload-image.png'
import countryStateList from '../../../../../Assets/JSON/countryState.json'
import { addEmployeeAPICall } from '../../../../../API/Staff/HRM/addEmployee'
export const PersonalInfo = (props) => {
  const {navigateToNextBtn} = props
  const [uploadedImage,setUploadedImage]=useState()
  const [imageUrl,setImageUrl]=useState()
  const [accountLogin,setAccountLogin]=useState(false)
  const [accountLoginPassword,setAccountLoginPassword]=useState(false)

  const genderList=[
    {name:'Female'},
    {name:'Male'},
    {name:"Other"}
  ]
  const meritalStatus=[
    {name:"Single"},
    {name:"Married"},
  ]
  const bloodGroup=[
    {group:'A +ve'},
    {group:'A -ve'},
    {group:'B +ve'},
    {group:'B -ve'},
    {group:'AB +ve'},
    {group:'AB -ve'},
    {group:'O +ve'},
    {group:'O -ve'},

  ]

  const personalInfoInitialState ={
     staffName:'',
     gender:null,
     fatherName:'',
     maritalStatus:null,
     contactNumber:'',
     bloodGroup:null,
     emergencyNumber:'',
     address:'',
     email:'',
     dob:'',
     conutry:null,
     state:null,
     userName:'',
     password:'',
     confirmPassword:''

  }
  const [personalInfoForm,setPersonalInfoForm]=useState(personalInfoInitialState)
  const [stateList,setStateList]=useState([])
  const [passwordError,setPasswordError]=useState()
  // const [staffImage,setStaffImage]=useState()




  // onChange function
  const getPersonalInfo=(key)=>(e,newValue)=>{
    const {value}=e.target;
    if(key === "staffName"){
      setPersonalInfoForm({...personalInfoForm,staffName:value})
    }
    if(key === "gender"){
      setPersonalInfoForm({...personalInfoForm,gender:newValue?.name})
    }
    if(key === "fatherName"){
      setPersonalInfoForm({...personalInfoForm,fatherName:value})
    }
    if(key === "meritalStatus"){
      setPersonalInfoForm({...personalInfoForm,maritalStatus:newValue?.name})
    }
    if(key ==="contactNumber"){
      setPersonalInfoForm({...personalInfoForm,contactNumber:value})
    }
    if(key ==="bloodGroup"){
      setPersonalInfoForm({...personalInfoForm,bloodGroup:newValue?.group})
    }
    if(key === "emergencyNumber"){
      setPersonalInfoForm({...personalInfoForm,emergencyNumber:value})
    }
    if(key === "address"){
      setPersonalInfoForm({...personalInfoForm,address:value})
    }
   if(key ==="email"){
    setPersonalInfoForm({...personalInfoForm,email:value})
   }
   if(key==="dob"){
    setPersonalInfoForm({...personalInfoForm,dob:value})
   }
   if(key === "country"){
    setPersonalInfoForm({...personalInfoForm,conutry:newValue?.country})
   }
   if(key === "state"){
    setPersonalInfoForm({...personalInfoForm,state:newValue})
   }
   if(key === "userName"){
    setPersonalInfoForm({...personalInfoForm,userName:value})
   }
   if(key === "password"){
    setPersonalInfoForm({...personalInfoForm,password:value})
   }
   if(key === "confirmPassword"){
    if(personalInfoForm?.password !== value){
     
      setPersonalInfoForm({...personalInfoForm,confirmPassword:value})
      setPasswordError("Password do not match")
    }
    else{
      setPersonalInfoForm({...personalInfoForm,confirmPassword:value})
      setPasswordError("")
    }
   
   }

  }

 

    /// extract state from whole country list
    useEffect(()=>{
      let stateList =[]
      stateList = countryStateList?.filter((obj)=>{
         if(obj?.country === personalInfoForm?.conutry){
           return stateList
          }
        })
      setStateList(stateList[0])
    },[personalInfoForm?.conutry])
   
   



  
  
useEffect(()=>{
    let image = undefined;
    if(typeof uploadedImage ==="string"){
      image = uploadedImage
    }
    else if(uploadedImage !== undefined && uploadedImage !== null){
      image=URL.createObjectURL(uploadedImage)
    }
    setImageUrl(image)
},[uploadedImage])



  
  const imageUploadFn=(e)=>{
    setUploadedImage(e.target.files[0])
  }
  const removeImage=()=>{
    setImageUrl(undefined)
  }


  // account login fn
  const openAccountLogin=()=>{
    setAccountLogin(true)
  }
  const closeAccountLogin=()=>{
    setAccountLogin(false)
    setAccountLoginPassword(true)
  }
  const cancelAccountLogin=()=>{
    setAccountLogin(false)
    setPersonalInfoForm({...personalInfoForm,userName:''})
  }
  // password dialog open fn
  const cancelPasswordDialog=()=>{
    setAccountLoginPassword(false)
    setAccountLogin(true)
    setPersonalInfoForm({
      ...personalInfoForm,
      password:'',
      confirmPassword:''
    })
  }


  // body to api
  const formData = new FormData();
  formData.append("staff_name",personalInfoForm?.staffName)
  formData.append("email",personalInfoForm?.email)
  formData.append("contactnumber",personalInfoForm?.contactNumber)
  formData.append("gender",personalInfoForm?.gender)
  formData.append("address",personalInfoForm?.address)
  formData.append("emp",uploadedImage)
  formData.append("fathersName",personalInfoForm?.fatherName)
  formData.append("maritialStatus",personalInfoForm?.maritalStatus==="Single"?true:personalInfoForm?.maritalStatus === "Married" ? false:null)
  formData.append("bloodGroup",personalInfoForm?.bloodGroup)
  formData.append("emergencyContactNumber",personalInfoForm?.emergencyNumber)
  formData.append("country",personalInfoForm?.conutry)
  formData.append("state",personalInfoForm?.state)
  formData.append("username",personalInfoForm?.userName)
  formData.append("password",personalInfoForm?.password)
  formData.append("dob",personalInfoForm?.dob)
  formData.append("branchId","LEECLT1")

  // next button function
  const addEmployeeNextBtnFn=()=>{
    addEmployeeAPICall(
      formData,
      navigateToNextBtn
      )
    
  }

  console.log(personalInfoForm?.staffName);
  return (
    <div className='add-employee-profile-info-container'>
      <div className='profile-info-sub-container'>
        <div className='global-single-input add-employee-input'>
            <p>Staff Name</p>
            <input 
             type="text" 
             placeholder='Enter Statff Name'
             value={personalInfoForm?.staffName}
             onChange={getPersonalInfo("staffName")}
             />
        </div>
        <div className='global-single-input add-employee-input auto-complete'>
            <p>Gender</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={genderList || ['']}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} placeholder='Select Gender'/>}
              onChange={getPersonalInfo("gender")}
            />
        </div>
        <div className='global-single-input add-employee-input'>
            <p>Father's Name</p>
            <input 
              type="text" 
              placeholder="Enter Father's name"
              value={personalInfoForm?.fatherName}
              onChange={getPersonalInfo("fatherName")}
              />
        </div>
        <div className='global-single-input add-employee-input auto-complete'>
            <p>Merital Status</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={meritalStatus||['']}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} placeholder='Select Merital Status'/>}
              onChange={getPersonalInfo("meritalStatus")}
            />
        </div>
        <div className='global-single-input add-employee-input'>
            <p>Contact Number</p>
            <input 
              type="text" 
              placeholder='Enter Contact Number'
              value={personalInfoForm?.contactNumber}
              onChange={getPersonalInfo("contactNumber")}
            />
        </div>
        <div className='global-single-input add-employee-input auto-complete'>
            <p>Blood Group</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={bloodGroup||['']}
              getOptionLabel={(option)=>option?.group}
              renderInput={(params)=><TextField {...params} placeholder='Select Blood Group'/>}
              onChange={getPersonalInfo("bloodGroup")}
            />
        </div>
        <div className='global-single-input add-employee-input'>
            <p>Emergency Contact Number</p>
            <input 
              type="text" 
              placeholder='Enter Emergency Number'
              value={personalInfoForm?.emergencyNumber}
              onChange={getPersonalInfo("emergencyNumber")}
              
              />
        </div>
        <div className='global-single-input add-employee-input'>
            <p>Address</p>
            <input 
              type="text" 
              placeholder='Enter Address'
              value={personalInfoForm?.address}
              onChange={getPersonalInfo("address")}
            />
        </div>
        <div className='global-single-input add-employee-input'>
            <p>Email</p>
            <input 
               type="text" 
               placeholder='abc@gmail.com'
               value={personalInfoForm?.email}
               onChange={getPersonalInfo("email")}
               />
        </div>
        <div className='global-single-input add-employee-input'>
            <p>DOB</p>
            <input 
              type="date" 
              placeholder=''
              onChange={getPersonalInfo("dob")}
            />
        </div>
        <div className='global-single-input add-employee-input auto-complete'>
            <p>Country</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={countryStateList||['']}
              getOptionLabel={(option)=>option?.country}
              renderInput={(params)=><TextField {...params} placeholder='Select Country'/>}
              onChange={getPersonalInfo("country")}
            />
        </div>
        <div className='global-single-input add-employee-input auto-complete'>
            <p>State</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={stateList?.states||['']}
              getOptionLabel={(option)=>option}
              renderInput={(params)=><TextField {...params} placeholder='Select State'/>}
              onChange={getPersonalInfo("state")}
            />
        </div>
        <div className='global-single-input add-employee-input'>
            <p>Upload Image</p>
            {
                    imageUrl === undefined &&
                  <div className='image-upload-file-conatiner'>
                  <input type="file" id="upload-image" onChange={imageUploadFn} />
                  <label htmlFor="upload-image">
                    <img src={uploadImage} alt="" />
                  </label>
                  </div>
                  }
                  {
                    imageUrl !== undefined &&
                    <div className='image-preview'>
                        <IconButton className='image-close-icon' onClick={removeImage}>
                           <i class="bi bi-x"></i>
                        </IconButton>
                        <img src={imageUrl} alt="" />
                    </div>
                    
                  }
        </div>
        <div className='global-single-input add-employee-input'>
            <button onClick={openAccountLogin} className='personal-info-account-login'>Account Login</button>
        </div>
      </div>
      <div className='profile-info-button-container'>
         <button className='global-button-contained-black-outlined'>Cancel</button>
         <button onClick={addEmployeeNextBtnFn} className='global-button-contained-blue'>Next</button>
      </div>

      {/* account login dialog */}
      <Dialog open={accountLogin} maxWidth="lg">
         <div className='add-leave-type-popup-container'>
            <div className='add-leave-type-popup-sub-container' style={{padding:"4% 8%"}}>
               <h3 style={{margin:"2% 0"}}>Account Login</h3>
               <hr className='global-br' style={{margin:"2% 0"}}/>
               <div className='account-login-top-container'>
                  <h3>Create Username</h3>
                  <p>Username will be your mobile number</p>
               </div>
               <div className='global-single-input account-login-input'>
                  <p>Mobile</p>
                  <input 
                    type="text" 
                    placeholder='Enter Mobile number' 
                    value={personalInfoForm?.userName}
                    onChange={getPersonalInfo("userName")}
                    />
               </div>
               <div className='account-login-button'>
                  <button onClick={cancelAccountLogin} className='global-button-contained-black-outlined'>Cancel</button>
                  <button onClick={closeAccountLogin}   className='global-button-contained-blue'>Next</button>
               </div>
            </div>
         </div>
      </Dialog>

      {/* account login password dialog */}
      <Dialog open={accountLoginPassword} maxWidth="lg">
        <div className='add-leave-type-popup-container'>
           <div className='add-leave-type-popup-sub-container' style={{padding:"4% 8%"}}>
               <h3 style={{margin:"2% 0"}}>Account Login</h3>
               <hr className='global-br' style={{margin:"2% 0"}}/>
               <div className='account-login-top-container'>
                <h3>Add Password</h3>
            </div>
            <div className='global-single-input account-login-password-input'>
                  <p>Password</p>
                  <input 
                    type="text" 
                    placeholder='Enter Password' 
                    value={personalInfoForm?.password}
                    onChange={getPersonalInfo("password")}
                    />
            </div>
            <div className='global-single-input account-login-password-input'>
                  <p>Confirm Password</p>
                  <input 
                    type="text" 
                    placeholder='re enter password' 
                    value={personalInfoForm?.confirmPassword}
                    onChange={getPersonalInfo("confirmPassword")}
                    />
                    <p className='validation-alert'>{passwordError}</p>
            </div>
            <div className='account-login-button' style={{margin:"4% 0"}}>
                  <button 
                    onClick={cancelPasswordDialog} 
                    className='global-button-contained-black-outlined'
                    >
                      Cancel</button>
                  <button 
                    disabled={personalInfoForm?.password !== personalInfoForm?.confirmPassword}  
                    onClick={()=>setAccountLoginPassword(false)}  
                    className={personalInfoForm?.password !== personalInfoForm?.confirmPassword?'global-button-contained-blue disabled':'global-button-contained-blue'}
                  >Submit</button>
               </div>
           </div>
          
        </div>
      </Dialog>
    </div>
  )
}
