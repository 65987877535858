import { Dialog, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

export const ContentPopup = (props) => {
    const {open,onClose,onDataUpdate,data}=props
    const [iconType,setIconType]=useState("1")
    const [content,setContent]=useState("")
    const [contentArray,setContentArray]=useState([])
    const changeIconType=(e)=>{
      setIconType(e.target.value)
    }
    const changeContent=(e)=>{
      setContent(e.target.value)
    }
    const addButtonFn=()=>{
      setContentArray([...contentArray,{icon:iconType,content:content}])
      onDataUpdate([...contentArray,{icon:iconType,content:content}]);
    }
    const deleteContent=(index)=>{
      console.log(index);
      // contentArray?.splice(index,1)
      // setContentArray([...contentArray])
      // onDataUpdate([...contentArray]);
      const updatedContentArray = contentArray.filter((_, i) => i !== index);
      setContentArray(updatedContentArray);
      onDataUpdate(updatedContentArray);
    }

    useEffect(() => {
      setContentArray(data);
    }, [data]);

    console.log(contentArray);

    
  return (
    <Dialog open={open} maxWidth="lg" onClose={onClose}>
    <div className="package-parent">
               <div className="package-head-container">
                  <h2>Add Content For Purchase Menu</h2>
               </div>
                <div className='content-icon-container'>
                      <div className='content-icon-single'>
                         <input 
                           type="radio" 
                           name="content-type"
                           id="content-type1"
                           value="1"
                           onChange={changeIconType}
                           defaultChecked
                           />
                          <label htmlFor="content-type1">
                            <div className="type-1-content" >
                              <i class="bi bi-check2" ></i>
                            </div>
                          </label>
                          
                      </div>
                      <div className='content-icon-single'>
                         <input 
                            type="radio"  
                            name="content-type"
                            id="content-type2"
                            value="2"
                            onChange={changeIconType}
                          
                            />
                            <label htmlFor="content-type2">
                              <div className="type-2-content">
                                 <i class="bi bi-check2"></i>
                               </div>
                            </label>
                        
                      </div>
                      <div className='content-icon-single'>
                         <input 
                         type="radio"  
                         name="content-type"
                         id="content-type3"
                         value="3"
                         onChange={changeIconType}
                         />
                         <label htmlFor="content-type3">
                           <i class="bi bi-check-circle check-circle"></i>
                         </label>
                       
                      </div>
                      <div className='content-icon-single'>
                         <input 
                           type="radio"  
                           name="content-type" 
                           id="content-type4"
                           value="4"
                           onChange={changeIconType}
                           />
                           <label htmlFor="content-type4">
                              <i class="bi bi-star-fill star-fill"></i>
                           </label>
                         
                        
                      </div>
                      <div className='content-icon-single'>
                         <input 
                           type="radio"  
                           name="content-type" 
                           id="content-type5"
                           value="5"
                           onChange={changeIconType}
                           />
                           <label htmlFor="content-type5">
                              <i class="bi bi-check2 check2"></i>
                           </label>
                        
                      </div>
                </div>
               <div className="package-body-container">
               
               <div className="package-body-container-left" >
                
                  <div className="global-single-input auto-complete" style={{width:'100%'}}>
                    <p>Content</p>
                     <input 
                      type="text"
                      value={content}
                      onChange={changeContent}
                      />
                   </div>
             
              </div>
            <div className="package-body-container-right">
                    <button onClick={addButtonFn}>Add</button>
            </div>
               </div>
               <div className="package-container-table">
           <table>
                  <thead>
                      <tr>
                          <th>Content</th>
                          <th>Action</th>
                          </tr>
                  </thead>
                  <tbody>
                  {
                     contentArray?.map((r,i)=>(
                        <tr>
                        <td>
                           {r?.icon==="1"?
                               <div className="type-1-content" >
                                 <i class="bi bi-check2" ></i>
                               </div> 
                           :r?.icon==="2"?
                               <div className="type-2-content">
                                <i class="bi bi-check2"></i>
                               </div>
                           :r?.icon==="3"?
                                <i class="bi bi-check-circle check-circle"></i>
                           :r?.icon==="4"?
                               <i class="bi bi-star-fill star-fill"></i>
                           :r?.icon==="5"?
                                <i class="bi bi-check2 check2"></i>
                           :undefined
                           }&nbsp;
                           {r?.content}
                        </td>
                        <td>
                          <IconButton 
                       
                             onClick={()=>deleteContent(i)}
                          >
                             <DeleteOutlineOutlinedIcon sx={{ color: "#fd0301" }}/>
                          </IconButton></td>
                       </tr>
                     ))
                  }
                     
                    
                    
                     
                  </tbody>
              </table>
           </div>
          
   </div>
   </Dialog>
  )
}
