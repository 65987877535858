import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import TopContainer from '../CustomComponent/TopContainer'
import SuccessSnackbar from '../../SingleCompoents/SnackBars/SuccessSnackbar'

export const NotificationSettings = () => {
  return (
    <>
    <TopContainer label="Notification Settings" />
    <div className="global-container device-settings-container">
      <p>Notification Settings</p>
      <hr />
      <div className="device-settings-form">
        <div className="global-single-input device-settings-input auto-complete">
          <p>Name/ID</p>
          <Autocomplete
            sx={{width:"100%"}}
            options={[""]}
            getOptionLabel={(option)=>option}
            renderInput={(params)=><TextField {...params} />}
           

          />
       
            <p className="Country-validation-alert"></p>
       
        </div>
      
       
      
      </div>
      <div className="device-settings-cancel-submit-btn-container">
        <button className="global-button-contained-black-outlined"
         
        >
          Cancel
        </button>
        <button
          className="global-button-contained-blue"
         
        >
          Submit
        </button>
      </div>
    </div>
 
    <SuccessSnackbar
    //   open={successSnackbaropen}
    //   handleClose={closeSuccessSnackbar}
    //   message={snackMsg}
    />
  </>
  )
}
