import React from 'react'
import TopContainer from '../CustomComponent/TopContainer'
import '../../../Css/CustomerSupport/customerSupport.css'
import { IconButton } from '@mui/material'
import VisibilityOutlined from '@mui/icons-material/VisibilityOutlined'
import { DeleteOutlined } from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
export const CustomerSupport = () => {
  return (
    <>
       <TopContainer label="Customer Support" />
       <div className='global-container customer-support-container'>
       <h4>Customer Support</h4>
        <div className="global-container-table">
          <table>
              <thead>
               <tr>
                 <th>Ticket No</th>
                 <th>Country</th>
                 <th>Type of ERP</th>
                 <th>Company</th>
                 <th>Contact</th>
                 <th>Email</th>
                 <th>Date</th>
                 <th>Contact By</th>
                 <th>Status</th>
                 <th>View</th>
                 <th>Delete</th>
               </tr>
              </thead>
              <tbody>
                  <tr>
                    <td>ABCDEF</td>
                    <td>India</td>
                    <td>Restaurant</td>
                    <td>Leeyet</td>
                    <td>1234567890</td>
                    <td>leeyet@gmail.com</td>
                    <td>06-08-2022</td>
                    <td>call</td>
                    <td>
                        <div className='customer-support-status'>
                        <p>Completed</p>
                        <KeyboardArrowDownIcon sx={{color:"#fff"}}/>
                        </div>
                    </td>
                    <td>
                        <IconButton sx={{color:"#a2bb65"}}>
                            <VisibilityOutlined/>
                        </IconButton>
                    </td>
                    <td>
                    <IconButton sx={{color:"#e05263"}}>
                            <DeleteOutlined/>
                        </IconButton>
                    </td>
                  </tr>
              </tbody>
            
          </table>
        </div>
       </div>
    </>
  )
}
