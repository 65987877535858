/** @format */

import axios from "axios";
import { store } from "../../Redux/Store";
import { HEADERS } from "../urlAndPath";
import { get_All_sms_Package, get_all_whatsapp_package } from "../../Redux/smsPackage/getSmsPackageSlice";

//addSmsPackage...

export const addsmsPackageAPIcall = (
  body,
  setSuccessSnackOpen,
  setSnackMsg,
  setsmsPackageCountry,
  setPurchaseRate,
  setPurchaseQty,
  setSellingRate,
  setSellingQty,
  setStartDate,
  setEndDate
) => {
  console.log(body);

  axios
    .post("admin/addSmsPackage", body, HEADERS)
    .then((res) => {
      console.log("response", res);
      setSuccessSnackOpen(true);
      setSnackMsg("Successfully... 👍 ");
      setsmsPackageCountry("");
      setPurchaseRate("");
      setPurchaseQty("");
      setSellingRate("");
      setSellingQty("");
      setStartDate("");
      setEndDate("");

    })
    .catch((err) => {
      console.log("Something wrong....");
    });
};

// view sms packahe 
export const viewAllSmsPackageAPICall=()=>{
  axios.get("admin/getSmsPackages",HEADERS)
  .then((res)=>{
    if(res.status===200){
      console.log('get data');
      store.dispatch(get_All_sms_Package({getSmsPackage:res.data}))
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}

// add whatsapp package
export const addWhatsappPackageAPICall = (body,clearFn) => {
  axios.post("admin/addwhatsappPackage",body,HEADERS)
  .then((res)=>{
     if(res.status===200){
         clearFn()
         viewAllWhatsappPackageAPICall()
     }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}
export const viewAllWhatsappPackageAPICall=()=>{
  axios.get("admin/getwhatsappPackages",HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_all_whatsapp_package({getWhatsappPackage:res.data}))
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}
