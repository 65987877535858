import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "../../Css/Dashboard/dashboard.css";

import Login from "../Login/Login";
import { CustomerSupport } from "../Pages/CustomerSupport/CustomerSupport";
import { DeviceSettings } from "../Pages/DeviceSettings/DeviceSettings";
import FloorPlanApproval from "../Pages/Floor Plan Approval/FloorPlanApproval";
import { HomeDashboard } from "../Pages/HomeDashboard/HomeDashboard";
import RecentPurchaseView from "../Pages/HomeDashboard/RecentPurchaseView";
import TotalRevenue from "../Pages/HomeDashboard/TotalRevenue";
import TotalRenewal from "../Pages/HomeDashboard/TotalRenewal";
import TopClients from "../Pages/HomeDashboard/TopClients";
import AddOffer from "../Pages/Offers/AddOffer";
import OffersListed from "../Pages/Offers/OffersListed";
import PackageAndPlans from "../Pages/Package and Plans/PackageAndPlans";
import { Refund } from "../Pages/Refund/Refund";
import RegisteredUsers from "../Pages/Registered Users/RegisteredUsers";
import RegisteredUserSingle from "../Pages/Registered Users/RegisteredUserSingle";
import ReportForward from "../Pages/Report forward/ReportForward";
import DailyReport from "../Pages/Reports/DailyReport";
import RefundReport from "../Pages/Reports/RefundReport";
import { SmsPackage } from "../Pages/Sms/SmsPackage";
import { SmsProvider } from "../Pages/Sms/SmsProvider";
import UserPackage from "../Pages/Sms/UserPackage";
import LeftMenu from "./LeftMenu/LeftMenu";
import TopBar from "./TopBar/TopBar";
import { RolesAndPermission } from "../Pages/RolesAndPermission/RolesAndPermission";
import { CustomerList } from "../Pages/Marketing/CustomerList";
import { AddCustomer } from "../Pages/Marketing/AddCustomer";
import { StaffDashboad } from "../Pages/Staff/StaffDashboad";
import { Department } from "../Pages/Staff/HRM/Department";
import { Designation } from "../Pages/Staff/HRM/Designation";
import { ManageEmployee } from "../Pages/Staff/HRM/ManageEmployee";
import { AddEmployee } from "../Pages/Staff/HRM/AddEmployee";
import { AddDocument } from "../Pages/Staff/HRM/AddDocument";
import { Attendance } from "../Pages/Staff/Attendance";
import { LeaveApplication } from "../Pages/Staff/Leave/LeaveApplication";
import { Holiday } from "../Pages/Staff/Leave/Holiday";
import { AddLeaveType } from "../Pages/Staff/Leave/AddLeaveType";
import { AddPayroll } from "../Pages/Staff/Payroll/AddPayroll";
import { PayrollItem } from "../Pages/Staff/Payroll/PayrollItem";
import { Payslip } from "../Pages/Staff/Payroll/Payslip";
import { AddNewLoan } from "../Pages/Staff/Loan/AddNewLoan";
import { LoanReport } from "../Pages/Staff/Loan/LoanReport";
import { LoanApproval } from "../Pages/Staff/Loan/LoanApproval";
import { EmployeeSalary } from "../Pages/Staff/Payroll/EmployeeSalary";
import { ViewEmployee } from "../Pages/Staff/HRM/ViewEmployee";
import { Query } from "../Pages/Marketing/Enquiry/Query";
import { RequestDemo } from "../Pages/Marketing/Enquiry/RequestDemo";
import { CustomerRegistration } from "../Pages/Registered Users/CustomerRegistration";
import { CustomerRegistrationForm } from "../Pages/Registered Users/customerRegistrationForm";
import { TrialPeriod } from "../Pages/TrialPeriod/TrialPeriod";
import { NotificationSettings } from "../Pages/NotificationSettings/NotificationSettings";
import { CompanyProfile } from "../Pages/CompanyProfile/CompanyProfile";
import { Catelog } from "../Pages/Catelog/Catelog";
import { SubCategory } from "../Pages/Catelog/SubCategory";
import { Parts } from "../Pages/Catelog/Parts";
import { CustomerRegistrationPopup } from "../Pages/Registered Users/CustomerRegistrationPopup";
import { CustomerRegistartionCart } from "../Pages/Registered Users/CustomerRegistartionCart";
import { WhatsappPackage } from "../Pages/Sms/WhatsappPackage";
import { Tax } from "../Pages/Tax/Tax";


const Dashboard = () => {
  const [toggle, setToggle] = useState(false);

  //Topbar toggle button onclick fn
  const topbarToggleBtn = () => {
    setToggle(!toggle);
    console.log(`Topbar Toggle button Clicked`);
  };



  useEffect(() => {
    if (toggle) {
      document.getElementById("dashboardLeftMenuContainer").style.flex = "4";
      document.getElementById("dashboardRightContainer").style.flex = "94";
      document.getElementById("leftMenu").classList.add("left-menu-collapsed");
    } else {
      document.getElementById("dashboardLeftMenuContainer").style.flex = "18";
      document.getElementById("dashboardRightContainer").style.flex = "82";
      document
        .getElementById("leftMenu")
        .classList.remove("left-menu-collapsed");
    }
  }, [toggle]);

  return (
    <div className="dashboard-parent-container">
      <div
        className="dashboard-left-menu-container"
        id="dashboardLeftMenuContainer"
      >
        <LeftMenu />
      </div>
      <div className="dashboard-right-division" id="dashboardRightContainer">
        <TopBar onClick={topbarToggleBtn} expanded={!toggle} />
        <div className="dashboard-pages-container">
          <Routes>
            <Route path="/homeDashboard" element={<HomeDashboard />} />
            <Route path="/registeredCustomerList" element={<RegisteredUsers />} />
            <Route path="/customerRegistration" element={<CustomerRegistrationPopup />} />
            <Route path="/customerRegistration/plan" element={<CustomerRegistration/>}/>
            <Route path="/customerRegistration/form" element={<CustomerRegistrationForm/>} />
            <Route path="/customerRegistration/cart" element={<CustomerRegistartionCart/>} />
            
{/* registerd use Single view */}

            <Route
              path="/registeredCustomerList/registeredUserSingle"
              element={<RegisteredUserSingle />}
            />


            <Route path="/packagesAndplans" element={<PackageAndPlans />} />
            <Route path="/trialPeriod" element={<TrialPeriod />} />
            <Route path="/floorPlanApprove" element={<FloorPlanApproval />} />
            <Route path="/addOffers" element={<AddOffer />} />
            <Route path="/offersListed" element={<OffersListed />} />

            {/* marketing */}
            <Route path="/customerList" element={<CustomerList />} />
            <Route path="/addCustomer" element={<AddCustomer />} />
            <Route path="/query" element={<Query />} />
            <Route path="/requestDemo" element={<RequestDemo />} />


            <Route path="/tax" element={<Tax />} />
            <Route path="/dailyreport" element={<DailyReport />} />
            <Route path="/refundreport" element={<RefundReport />} />
            <Route path="/reportForward" element={<ReportForward />} />

            <Route path="/smsprovider" element={<SmsProvider />} />
            <Route path="/smspackage" element={<SmsPackage />} />
            <Route path="/whatsapppackage" element={<WhatsappPackage/>}/>
            <Route path="/userpackage" element={<UserPackage />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/customerSupport" element={<CustomerSupport />} />
            <Route path="/deviceSettings" element={<DeviceSettings />} />
            <Route path="/notificationSettings" element={<NotificationSettings />} />
            <Route path="/companyProfile" element={<CompanyProfile />} />
            <Route
              path="/rolesAndPermission"
              element={<RolesAndPermission />}
            />
            <Route
              path="/recentpurchaseview"
              element={<RecentPurchaseView />}
            />
            <Route path="/totalrevenue" element={<TotalRevenue />} />
            <Route path="/totalrenewal" element={<TotalRenewal />} />
            <Route path="/topclients" element={<TopClients />} />

            {/* staff section */}
            <Route path="/dashboard" element={<StaffDashboad />} />
            <Route path="/department" element={<Department />} />
            <Route path="/designation" element={<Designation />} />
            <Route path="/manageEmployee" element={<ManageEmployee />} />

            <Route
              path="staff/hrm/manageEmployee/viewEmployee"
              element={<ViewEmployee/>}
            />

            <Route path="/addEmployee" element={<AddEmployee />} />
            <Route path="/addDocument" element={<AddDocument />} />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/leaveApplication" element={<LeaveApplication />} />
            <Route path="/holiday" element={<Holiday />} />
            <Route path="/addLeaveType" element={<AddLeaveType />} />
            <Route path="/addPayroll" element={<AddPayroll />} />
            <Route path="/employeeSalary" element={<EmployeeSalary />} />
            <Route path="/payrollItem" element={<PayrollItem />} />
            <Route path="/payslip" element={<Payslip />} />
            <Route path="/addNewLoan" element={<AddNewLoan />} />
            <Route path="/LoanReport" element={<LoanReport />} />
            <Route path="/loanApproval" element={<LoanApproval />} />
            <Route path="/catalog" element={<Catelog />} />
            <Route path="/catalog/subCategory" element={<SubCategory />} />
            <Route path="/catalog/subCategory/parts" element={<Parts/>} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
