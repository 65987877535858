import { createSlice } from "@reduxjs/toolkit";

export const departmentSlice=createSlice({
    name:"departmentSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_department_slice:(state,action)=>{
            state.value=action.payload.departmentData
        }
    }
})
export const {get_department_slice}=departmentSlice.actions
export default departmentSlice.reducer