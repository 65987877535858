import { createSlice } from '@reduxjs/toolkit'
export const reportForwardMenuStateSlice=createSlice(
    {
        name:'reportForwardMenuState',
        initialState:{
            value:"socialPlatformli"
        },
        reducers:{
            set_Selected_State:(state,action)=>{
                state.value=action.payload.selectedState
            }
        }
    }
)
export const {set_Selected_State} = reportForwardMenuStateSlice.actions
export default reportForwardMenuStateSlice.reducer