import { generate_package_id, get_module_duration, get_package_duration, get_package_list, get_plan_list, get_single_package_list, get_sub_module, get_sub_module_byId } from "../../Redux/AddPackage/generatePackageIdSlice"
import { store } from "../../Redux/Store"
import { HEADERS, HEADERS_FORMDATA, HEADER_QUERY } from "../urlAndPath"
import axios from "axios"

export const generatePackageIdAPICall=()=>{
    axios.get("package/generatePackageId",HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(generate_package_id({generatePackageId:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

// view all package list
export const viewAllPackageList=(body)=>{
    axios.post("package/getAllPackages",body,HEADERS)
    .then((res)=>{
        if(res.status === 200){
            store.dispatch(get_package_list({packageListData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

//package duration
//view package duration list
export const viewPackageDuration= async ()=>{
    await axios.get("package/getPackageDuration",HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_package_duration({packageDuration:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

//add package duration 
export const addPackageDuration= async (
    body,
    updateListner,
    setDuration,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg
    )=>{
    await axios.post("package/addPackageDuration",body,HEADERS)
    .then((res)=>{
      if(res.status===200){
        updateListner();
        setDuration('')
        setSnackMsg('Duration Added')
        setSuccessSnackbar(true)
      }
    })
    .catch((err)=>{
        if(err.response===undefined){
            setSnackMsg("Network Connection error!!");
          }
          if(err.response.status===404){
            setSnackMsg("No Data")
          }
          if(err.response.status===409){
            setSnackMsg("Already Exist")
          }
          if(err.ersponse.status===401){
            setSnackMsg("Unauthorized")
          }
          if(err.response.status===403){
            setSnackMsg("Forbidden")
          }
          setErrorSnackbar(true)
        console.log(err.response);
    })
}

// delete package duration
export const deletePackageDurationAPICall=async(
    body,
    update,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg
    )=>{
    await axios.delete("package/deletePackageDuration",{
        headers:HEADER_QUERY,
        data:body
    })
    .then((res)=>{
        if(res.status===200){
            update()
            setSnackMsg('deleted')
            setSuccessSnackbar(true)
        }
    })
    .catch((err)=>{
        if(err.response===undefined){
            setSnackMsg("Network Connection error!!");
          }
          if(err.response.status===404){
            setSnackMsg("No Data")
          }
          if(err.response.status===409){
            setSnackMsg("Already Exist")
          }
          if(err.ersponse.status===401){
            setSnackMsg("Unauthorized")
          }
          if(err.response.status===403){
            setSnackMsg("Forbidden")
          }
          setErrorSnackbar(true)
        console.log(err.response);
    })
}

//module duration
//add module package duration
export const addModulePackageDurationAPICall=async(
    body,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    update,
    clearState
    )=>{
    await axios.post("package/addModule",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            update()
            setSnackMsg('added')
            setSuccessSnackbar(true)
            clearState()
        }
    })
    .catch((err)=>{
        if(err.response===undefined){
            setSnackMsg("Network Connection error!!");
          }
          if(err.response.status===404){
            setSnackMsg("No Data")
          }
          if(err.response.status===409){
            setSnackMsg("Already Exist")
          }
          if(err.ersponse.status===401){
            setSnackMsg("Unauthorized")
          }
          if(err.response.status===403){
            setSnackMsg("Forbidden")
          }
          setErrorSnackbar(true)
        console.log(err.response);
    })
}

// get module duration
export const getModuleDurationAPICall=async()=>{
  await axios.get("package/getModules",HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_module_duration({moduleDuration:res.data}))
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}

// delete module duration
export const deleteModuleDurationAPICall=async(
  body,
  setSuccessSnackbar,
  setErrorSnackbar,
  setSnackMsg,
  update,
  )=>{
  await axios.delete("package/deletemodule",{
    headers:HEADER_QUERY,
    data:body
  })
  .then((res)=>{
    if(res.status===200){
            update()
            setSnackMsg('deleted')
            setSuccessSnackbar(true)
    }
  })
  .catch((err)=>{
    if(err.response===undefined){
      setSnackMsg("Network Connection error!!");
    }
    if(err.response.status===404){
      setSnackMsg("No Data")
    }
    if(err.response.status===409){
      setSnackMsg("Already Exist")
    }
    if(err.ersponse.status===401){
      setSnackMsg("Unauthorized")
    }
    if(err.response.status===403){
      setSnackMsg("Forbidden")
    }
    setErrorSnackbar(true)
    console.log(err.response);
  })
}

// sub modules
// add sub module 
export const addSubModuleAPICall=async(
  body,
  update,
  setSuccessSnackbar,
  setErrorSnackbar,
  setSnackMsg,
  clearState

  )=>{
  await axios.post("package/addSubModule",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      console.log('added');
      update()
      setSnackMsg('added')
      setSuccessSnackbar(true)
      clearState()
    }
  })
  .catch((err)=>{
    if(err.response===undefined){
      setSnackMsg("Network Connection error!!");
    }
    if(err.response.status===404){
      setSnackMsg("No Data")
    }
    if(err.response.status===409){
      setSnackMsg("Already Exist")
    }
    if(err.ersponse.status===401){
      setSnackMsg("Unauthorized")
    }
    if(err.response.status===403){
      setSnackMsg("Forbidden")
    }
    setErrorSnackbar(true)
    console.log(err.response);
  })
}
// get sub module
export const getAllSubModuleAPICall=async()=>{
  await axios.get("package/getSubModules",HEADERS)
  .then((res)=>{
    if(res.status=200){
      console.log('get all');
      store.dispatch(get_sub_module({subModuleList:res.data}))
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}
// delete sub module
export const deleteSubModuleAPICall=async(
  body,
  setSuccessSnackbar,
  setErrorSnackbar,
  setSnackMsg,
  update,
  )=>{
  await axios.delete("package/deleteSubModule",{
    headers:HEADER_QUERY,
    data:body
  })
  .then((res)=>{
    if(res.status===200){
      update()
      setSnackMsg('deleted')
      setSuccessSnackbar(true)

    }
  })
  .catch((err)=>{
    if(err.response===undefined){
      setSnackMsg("Network Connection error!!");
    }
    if(err.response.status===404){
      setSnackMsg("No Data")
    }
    if(err.response.status===409){
      setSnackMsg("Already Exist")
    }
    if(err.ersponse.status===401){
      setSnackMsg("Unauthorized")
    }
    if(err.response.status===403){
      setSnackMsg("Forbidden")
    }
    setErrorSnackbar(true)
    console.log(err.response);
  })
}
export const getAllSubModuleById=async(body)=>{
  await axios.post("package/viewSubModules",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_sub_module_byId({subModuleListById:res.data}))
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}
//  add package api call
export const addPackageAPICall=async(
  body,
  clearState,
  updateListner,
  setSnackBarStates,
  snackBarStates
)=>{
  await axios.post("package/addPackage",body,HEADERS_FORMDATA)
  .then((res)=>{
     if(res.status===200){
        console.log('added');
        clearState()
        viewAllPackageList()
        updateListner()
        setSnackBarStates({...snackBarStates,success:true,snackMsg:"Package Added"})
     }
  })
  .catch((err)=>{
    console.log(err.response);
    setSnackBarStates({...snackBarStates,error:true,snackMsg:err.response.data})
  })
}

export const currentLoactionAPI=(setCountry)=>{
   axios.get("http://ip-api.com/json")
   .then((res)=>{
    setCountry(res?.data?.country)
   })
   .catch((err)=>{
    console.log(err);
   })
}

/// add plan 
export const addPlanAPICall=(body)=>{
  axios.post("package/addPlanType",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      console.log('added');
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}

export const viewPlanAPICall=(body)=>{
  axios.post("package/viewPlanType",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(get_plan_list({planData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_plan_list({planData:undefined}))
  })
  
}


export const deletePackageAPICall=(_id,updateListener,setSnackBarStates,snackBarStates)=>{
  axios.delete("package/deletePackage",{
    headers: HEADER_QUERY,
    data: {
      packageId:_id,
    },
  })
  .then((res)=>{
     if(res.status===200){
      setSnackBarStates({...snackBarStates,success:true,snackMsg:"Package Deleted"})
      updateListener()
     }
  })
  .catch((err)=>{
    console.log(err.response);
     setSnackBarStates({...snackBarStates,error:true,snackMsg:err.response.data})
  })
}

export const editPackageAPICall=(body,clearState,updateListner,setSnackBarStates,snackBarStates)=>{
  axios.put("package/editPackage",body,HEADERS_FORMDATA)
  .then((res)=>{
    if(res.status===200){
       console.log("edited");
       clearState()
       generatePackageIdAPICall()
       store.dispatch(get_single_package_list({singalePackageData:undefined}))
       updateListner()
       setSnackBarStates({...snackBarStates,success:true,snackMsg:"Package Edited"})
    }
  })
  .catch((err)=>{
    console.log(err.response);
    setSnackBarStates({...snackBarStates,error:true,snackMsg:err.response.data})
  })
}

export const singlePackageAPICall=(body)=>{
    axios.post("package/getSinglePackage",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
          store.dispatch(get_single_package_list({singalePackageData:res.data}))
        }
    })
    .catch((err)=>{
          console.log(err.response);
    })
}
