import React, { useState } from 'react'
import TopContainer from '../../CustomComponent/TopContainer'
import { Dialog, IconButton } from '@mui/material'

export const AddLeaveType = () => {
    const [addLeaveType,setAddLeaveType]=useState(false)

    const openAddLeaveType=()=>{
       setAddLeaveType(true)
    }
  return (
    <>
      <TopContainer label="Staff > Leave > Add Leave Type"/>
      <div className='global-container'>
          <div className='global-heading-containe'>
                <h3 style={{margin:"2% 0 0 2%"}}>Add Leave Type</h3>
                <button onClick={openAddLeaveType} className='global-button-contained-blue'>Add Leave Type</button>
            </div>
            <div className='global-container-table'>
              <table>
                <thead>
                  <tr>
                     <th>No</th>
                     <th>Leave Type</th>
                     <th>Total Leave Days</th>
                     <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                       <IconButton>
                          <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton>
                        <IconButton>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
      </div>

      {/* add leave Type */}
      <Dialog open={addLeaveType} maxWidth="lg">
        <div className='add-leave-type-popup-container'>
           <div className='add-leave-type-popup-sub-container'>
             <h3 style={{margin:"2% 0"}}>Add Leave Type</h3>
             <hr className='global-br' style={{margin:"2% 0"}}/>
             <div className='global-single-input leave-type-input'>
              <p>Leave Type</p>
              <input type="text" />
             </div>
             <div className='global-single-input leave-type-input'>
              <p>No Of Leave Days</p>
              <input type="text" />
             </div>
             <div className='button-container'>
                <button onClick={()=>setAddLeaveType(false)} className='global-button-contained-black-outlined' style={{width:"20%",height:"35px"}}>Cancel</button>
                <button className='global-button-contained-blue' style={{width:"20%",height:"35px"}}>Submit</button>
             </div>
           </div>
        </div>
      </Dialog>
    </>
  )
}
