import { Divider } from '@mui/material'
import React, { useState } from 'react'
import { addTrialPeriodAPICall } from './TrialPeriodAPI'
import SuccessSnackbar from '../../SingleCompoents/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../SingleCompoents/SnackBars/ErrorSnackbar'

export const TrialPeriod = () => {
  const [type,setType]=useState("false")
  const [duration,setDuration]=useState(0)
  const [snackBarStates,setSnackBarStates]=useState({
    success:false,
    error:false,
    snackMsg:"",
  })
  const handleCloseSuccess=()=>()=>{
    setSnackBarStates({...snackBarStates,error:false,success:false})
}

  const submitBtnFunction=()=>{
    addTrialPeriodAPICall({
      
        duration:Number(duration),
        type: type==="true"?true: false

    
    },setSnackBarStates,snackBarStates)
  }


  return (
    <div className='global-container'>
       <div>
          <div style={{display:"flex",padding:"1%"}}>
            <div style={{width:"67px",display:"flex"}}>
                <input 
                   type="radio" 
                   id='trialPeriodMonth' 
                   name="trialPeriod" 
                   style={{accentColor:"#000",marginRight:"4px",cursor:"pointer"}}
                   value={"false"}
                   checked={type==="false"}
                   onChange={(e)=>setType(e.target.value)}
                   defaultChecked
                   />
                <label htmlFor="trialPeriodMonth" style={{cursor:"pointer"}}>Month</label>
            </div>
            <div style={{width:"60px",display:"flex",marginLeft:"8px"}}>
                <input 
                   type="radio" 
                   id='trialPeriodDays' 
                   name="trialPeriod" 
                   style={{accentColor:"#000",marginRight:"4px",cursor:"pointer"}}
                   checked={type==="true"}
                   onChange={(e)=>setType(e.target.value)}
                   value={"true"}
                   />
                <label htmlFor="trialPeriodDays" style={{cursor:"pointer"}}>Days</label>
            </div>
          </div>
          <Divider/>
       </div>
       <div style={{display:"flex",alignItems:"center"}}>
         <div className="global-single-input auto-complete trial-period-input">
            <p>Duration</p>
            <input type="number"
               value={duration} 
               onChange={(e)=>setDuration(e.target.value)}
            />
         </div>
         <button onClick={submitBtnFunction} style={{margin:"3% 1% 1% 1%"}} className='global-button-contained-blue'>Submit</button>
       </div>

       <SuccessSnackbar
            open={snackBarStates.success}
            handleClose={handleCloseSuccess()}
            message={snackBarStates.snackMsg}
         />
        <ErrorSnackbar
         open={snackBarStates.error}
          handleClose={handleCloseSuccess()}
         message={snackBarStates.snackMsg}
        />
    </div>
  )
}
