import { DeleteOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";
import "../../../Css/Report Forward/reportForward.css";
const AddMobile = () => {
  return (
    <div className="report-parent-container">
      <div className="report-menu-input-container">
        <p>Add Mobile</p>
        <div className="input-wrapper">
          <div className="mobile-no-container">
            <input type="text" className="country-code" defaultValue="+91" />
            <input type="text" placeholder="_ _ _ _   _ _ _  _ _ _"/>
          </div>
          <button className="add-btn">+</button>
        </div>
      </div>
      <div className="display-container">
        <div className="data-row">
          <p>+91 123456789</p>
          <p>
            <IconButton>
              <DeleteOutlined  sx={{color:'#fd0301'}}/>
            </IconButton>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddMobile;
