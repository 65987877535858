import React, { useState } from "react";
import "../../../../Css/Staff/Payroll/employeeSalary.css"
import TopContainer from "../../CustomComponent/TopContainer"
import { Autocomplete, Dialog, IconButton, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PaySlip, PayslipView } from "../../CustomComponent/PayslipView";
export const EmployeeSalary=()=>{
    const [paySlipView,setPayslipView]=useState(false)
    
    const navigateTo=useNavigate()

    const clickAddnewPayroll=()=>{
        navigateTo("/dashboard/addPayroll")
    }

    return(
        <>
        <TopContainer label="Payroll > Employee Salary"/>
        <div className="global-container">
            <div className="global-top-container">
                <div className="global-top-container-head-section">
                    <h3>Employee Salary</h3>
                </div>
                <div className="global-top-container-filter-section">
                    <div className="global-single-input auto-complete">
                        <p>Name/ID</p>
                        <Autocomplete
                         options={["Nihad","Kallugal"]}
                         getOptionLabel={(option)=>option}
                         renderInput={(params)=>(<TextField {...params} placeholder="Select .."/>
                         )}
                        />
                    </div>
                    <div className="global-single-input auto-complete">
                        <p>Designation</p>
                        <Autocomplete
                         options={["Nihad","Kallugal"]}
                         getOptionLabel={(option)=>option}
                         renderInput={(params)=>(<TextField {...params} placeholder="Select .."/>
                         )}
                        />
                    </div>
                    <div className="global-single-input">
                        <p>From</p>
                        <input type="date" />
                    </div>
                    <div className="global-single-input">
                        <p>To</p>
                        <input type="date" />
                    </div>
                </div>
                <button className="global-button-contained-blue">
                    Submit
                </button>
            </div>

            <div className="global-container-table">
                <div className="global-top-container-head-section">
                    <h3>Employee Salary</h3>
                    <div className="button-container-head">
                        <button 
                        onClick={clickAddnewPayroll}
                        className="global-button-contained-blue">Add New Payroll</button>
                        <button className="global-button-contained-blue btn-yellow">Download Monthly Report</button>
                    </div>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Employee ID</th>
                            <th>Mobile</th>
                            <th>Joining Date</th>
                            <th>Designation</th>
                            <th>Status</th>
                            <th>Payslip</th>
                            <th>Edit</th>
                            <th>Download</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                       <tr>
                        <td>1</td>
                        <td><img src="" alt="" /></td>
                        <td>munushi</td>
                        <td>EMP23</td>
                        <td>9495864512</td>
                        <td>12/10/2021</td>
                        <td>Designer</td>
                        <td>divorced</td>
                        <td><IconButton onClick={()=>setPayslipView(true)}>
                               <i class="bi bi-eye visibility-icon"></i>
                           </IconButton></td>
                        <td><IconButton>
                            <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton></td>
                        <td><IconButton>
                            <i class="bi bi-arrow-down-circle download-icon"></i>
                        </IconButton></td>
                        <td><IconButton>
                            <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton></td>
                       </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <Dialog maxWidth="lg" open={paySlipView} onClose={()=>setPayslipView(false)}>
            <>
                <PayslipView exitDialog={()=>setPayslipView(false)}/>
            </>
        </Dialog>
        </>
    )
}