import React from 'react'
import '../../Css/Login/loginRightForm.css'
import image from '../../Assets/Images/Image 1.png'
const RightLogin = () => {
  return (
    <div className='login-right-main-container'>
       <div className='login-right-top-container'></div>
       <div className='login-right-bottom-container'>
         <div className='right-bottom-image-container'>
         <img className="right-bottom-image" src={image} /> 
         </div>
       
       </div>
    </div>
  )
}

export default RightLogin