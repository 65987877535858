export const filterObjFromList=(key,list,keyToMatch,listToFilter)=>{
    // console.log(key,list,keyToMatch,listToFilter);
    if(Array.isArray(list)){
      if (listToFilter && listToFilter[keyToMatch]) {
        let filteredArray = list?.filter((obj) => obj[key] === listToFilter[keyToMatch]);
        return filteredArray !== undefined ? (filteredArray?.length !== 0 ? filteredArray[0] : null) : null;
        
      } else {
        return null;
      }
    }
}