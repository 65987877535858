import { get_all_designation } from "../../../Redux/Staff/designationSlice"
import { store } from "../../../Redux/Store"
import { HEADERS, STAFFBASEURL } from "../../urlAndPath"


//  view designation api call
export const viewDesigantionAPI= async()=>{
    STAFFBASEURL.get("employee/getAllDesignations",HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_all_designation({designationData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

// add designation api call
export const designationAPICall= async (
    body,
    updateListner,
    setAddDesigntion,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    clearState
    )=>{
    STAFFBASEURL.post("employee/addDesignation",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            updateListner()
            setAddDesigntion(false)
            setSnackMsg("Succcess")
            setSuccessSnackbar(true)
            clearState()
        }
    })
    .catch((err)=>{
        console.log(err.response);
        if(err.response===undefined){
            setSnackMsg("Network Connection error!!");
          }
          if(err.response.status===404){
            setSnackMsg("No Data")
          }
          if(err.response.status===409){
            setSnackMsg("Already Exist")
          }
          if(err.ersponse.status===401){
            setSnackMsg("Unauthorized")
          }
          if(err.response.status===403){
            setSnackMsg("Forbidden")
          }
          setErrorSnackbar(true)
    })
}

// edit designation api call
export const editDesignationAPICall = async(
    body,
    updateListner,
    setEditDesignation,
    setSuccessSnackbar,
    setErrorSnackbar,
    setSnackMsg,
    )=>{
    await STAFFBASEURL.put("employee/editDesignation",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            updateListner()
            setEditDesignation(false)
            setSnackMsg("Succcess")
            setSuccessSnackbar(true)
        }
    })
    .catch((err)=>{
        console.log(err.response);
        if(err.response===undefined){
            setSnackMsg("Network Connection error!!");
          }
          if(err.response.status===404){
            setSnackMsg("No Data")
          }
          if(err.response.status===409){
            setSnackMsg("Already Exist")
          }
          if(err.ersponse.status===401){
            setSnackMsg("Unauthorized")
          }
          if(err.response.status===403){
            setSnackMsg("Forbidden")
          }
          setErrorSnackbar(true)
    })
}