/** @format */

import React, { useEffect } from "react";
import TopContainer from "../CustomComponent/TopContainer";
import "../../../Css/Offers/addOffer.css";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import CustomImageUpload from "../CustomComponent/CustomImageUpload";
import { useState } from "react";
import {
  addOfferApiCall,
  generateTransactionIDApiCall,
} from "../../../API/Offers/OffersApi";
import { useSelector } from "react-redux";
import CountryListWithCode from "../../../Assets/JSON/CountryLIstWithCode.json";
import ErpList from "../../../Assets/JSON/ErpList.json";
import ErpTypeOfOffersList from "../../../Assets/JSON/ErpTypeOfOffersList.json";
import AddOfferPercentage from "../../../Assets/JSON/AddOfferPercentage.json";
import SuccessSnackbar from "../../SingleCompoents/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../SingleCompoents/SnackBars/ErrorSnackbar";

const AddOffer = () => {
  const transactionId = useSelector((state) => state.transactionIdSlice.value);
  console.log("Transaction ID ISSSS--->", transactionId);

  //transaction generate ID state
  // const [transactionid,setTransactionid] = useState({
  //   prefix:"#",
  //   transId:"##"

  // });
  // console.log("!!!!!!!",transactionid)

  //validation Alerts
  const [countryAlert, setcountryAlert] = useState(false);
  const [typeofErpAlert, settypeOfErpAlert] = useState(false);
  const [offerTypeAlert, setOfferTypeAlert] = useState(false);
  const [percentageAlert, setPercentageAlert] = useState(false);
  const [startDateAlert, setStartdateAlert] = useState(false);
  const [endDateAlert, setEndDateAlert] = useState(false);
  const [conditionAlert, setConditionAlert] = useState(false);
  const [ImaUrl, setImgUrl] = useState();

  // success snackbar
  const [successSnackbaropen, setSuccessSnackbaropen] = useState(false);

  //erro snackbar
  const [errosnackBar, setErrorSnackbar] = useState(false);

  //msg
  const [snackMsg, setSnackMsg] = useState("Oops!!");
  const [errorSnackMsg, setErrorsnackMsg] = useState("Error ...");

  // closeSuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackbaropen(false);
  };

  //closeErrorSnackbar
  const closeErrorSnackbar = () => {
    setErrorSnackbar(false);
  };

  const PersonalInformation = {
    country: null,
    typeOferp: null,
    offerType: null,
    addPercentage: null,
    saleStartDate: "",
    saleEndDate: "",
    conditions: "",
  };

  const [addoffers, setaddOffers] = useState(PersonalInformation);
  console.log(addoffers);

  //Modify state
  const [isModify, setIsModify] = useState(false);

  useEffect(() => {
    generateTransactionIDApiCall();
    // setTransactionid(transactionId)
  }, [isModify]);

  //getImage

  const getImage = (e) => {
    console.log("GetImage--->");
    setImgUrl(e.target.files[0]);
  };

  //add offer Submit_Btn
  const addofferSubmitBtn = () => {
    const {
      country,
      typeOferp,
      offerType,
      addPercentage,
      saleStartDate,
      saleEndDate,
      conditions,
    } = addoffers;

    if (country === null) {
      setcountryAlert(true);
    } else if (typeOferp === null) {
      settypeOfErpAlert(true);
    } else if (offerType === null) {
      setOfferTypeAlert(true);
    } else if (addPercentage === null) {
      setPercentageAlert(true);
    } else if (saleStartDate === "") {
      setStartdateAlert(true);
    } else if (saleEndDate === "") {
      setEndDateAlert(true);
    } else if (conditions === "") {
      setConditionAlert(true);
    } else {
      const formData = new FormData();
      formData.append("country", addoffers?.country?.name);
      formData.append("typeOferp", addoffers?.typeOferp?.name);
      formData.append("offerType", addoffers?.offerType?.name);
      formData.append("addPercentage", addoffers?.addPercentage?.offer);
      formData.append("saleStartDate", addoffers?.saleStartDate);
      formData.append("saleEndDate", addoffers?.saleEndDate);
      formData.append("conditions", addoffers?.conditions);
      formData.append("image", ImaUrl);
      addOfferApiCall(
        formData,
        setSuccessSnackbaropen,
        setSnackMsg,
        clearState,
        setErrorSnackbar,
        setErrorsnackMsg
      );
    }
  };

  // clear State
  const clearState = () => {
    console.log("clear the state");
    setaddOffers({
      country: null,
      typeOferp: null,
      offerType: null,
      addPercentage: null,
      saleStartDate: "",
      saleEndDate: "",
      conditions: "",
    });
    setImgUrl();
  };

  //getPersonalInfoForm

  const getPersonalInfoForm = (e, newValue, key) => {
    const value = newValue || e.target.value || "";
    console.log(value);

    if (key === "country") {
      setaddOffers({ ...addoffers, country: newValue });
      setcountryAlert(false);
      if (value === "") {
        setcountryAlert(true);
      }
    }

    if (key === "TypeOfErp") {
      setaddOffers({ ...addoffers, typeOferp: newValue });
      settypeOfErpAlert(false);
      if (value === "") {
        settypeOfErpAlert(true);
      }
    }

    if (key === "offerType") {
      setaddOffers({ ...addoffers, offerType: newValue });
      setOfferTypeAlert(false);
      if (value === "") {
        setOfferTypeAlert(true);
      }
    }

    if (key === "addPercentage") {
      setaddOffers({ ...addoffers, addPercentage: newValue });
      setPercentageAlert(false);
      if (value === "") {
        setPercentageAlert(true);
      }
    }
    if (key === "startDate") {
      setaddOffers({ ...addoffers, saleStartDate: value });
      setStartdateAlert(false);
      if (value === "") {
        setStartdateAlert(true);
      }
    }
    
    if (key === "EndDate") {
      setaddOffers({ ...addoffers, saleEndDate: value });
      setEndDateAlert(false);
      if (value === "") {
        setEndDateAlert(true);
      }
    }

    if (key === "condition") {
      setaddOffers({ ...addoffers, conditions: value });
      setConditionAlert(false);
      if (value === "") {
        setConditionAlert(true);
      }
    }
  };

  return (
    <>
      <TopContainer label="Offers" />
      <div className="add-offer-container">
        <h3>Add Offer</h3>
        <hr />
        <div className="add-offer-form">
          <div className="global-single-input add-offer-single-input">
            <p>transaction ID</p>
            {/* <Select>
              <MenuItem>ABCD123</MenuItem>
            </Select> */}
            <input
              type="text"
              disabled
              value={transactionId?.prefix + transactionId?.transId}
            />
          </div>

          {/* Country */}
          <div className="global-single-input add-offer-single-input auto-complete">
            <p>Country</p>

            <Autocomplete
              sx={{
                width: "100%",
              }}
              options={CountryListWithCode || [""]}
              getOptionLabel={(option) => `${option?.name}`}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Country" />
              )}
              value={addoffers?.country}
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "country")
              }
            />
            {countryAlert && (
              <p className="validation-alert">Select a Country</p>
            )}
          </div>
          {/* Type of Erp */}
          <div className="global-single-input add-offer-single-input auto-complete">
            <p>Type of ERP</p>
            {/* <Select
              onChange={(e) => setTypeofErp(e.target.value)}
              value={TypeOfErp}
            >
              <MenuItem value="Erp -Restaurant 1">Erp -Restaurant 1</MenuItem>
              <MenuItem value="Erp -Restaurant 2">Erp -Restaurant 2</MenuItem>
              <MenuItem value="Erp -Restaurant 3">Erp -Restaurant 3</MenuItem>
              <MenuItem value="Erp -Restaurant 4">Erp -Restaurant 4</MenuItem>
            </Select> */}

            <Autocomplete
              sx={{ width: "100%" }}
              options={ErpList || [""]}
              getOptionLabel={(option) => `${option?.name}`}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Erp" />
              )}
              value={addoffers?.typeOferp}
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "TypeOfErp")
              }
            />
            {typeofErpAlert && (
              <p className="validation-alert">Select a typeOfErp</p>
            )}
          </div>
        </div>
        <hr />
        <div className="add-offer-form">
          <div className="global-single-input add-offer-single-input auto-complete">
            <p>Offer Type</p>
            {/* <Select
              onChange={(e) => setOfferType(e.target.value)}
              value={offerType}
            >
              <MenuItem value="Buy one get 1">Buy one get 1</MenuItem>
              <MenuItem value="Buy one get 2">Buy one get 2</MenuItem>
              <MenuItem value="Buy one get 3">Buy one get 3</MenuItem>
              <MenuItem value="Buy one get 4">Buy one get 4</MenuItem>
            </Select> */}

            <Autocomplete
              sx={{ width: "100%" }}
              options={ErpTypeOfOffersList || [""]}
              getOptionLabel={(option) => `${option?.name}`}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Offer" />
              )}
              value={addoffers?.offerType}
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "offerType")
              }
            />
            {offerTypeAlert && (
              <p className="validation-alert">Select offerType</p>
            )}
          </div>
          <div className="global-single-input add-offer-single-input auto-complete">
            <p>Add Percentage(%)</p>
            {/* <Select
              onChange={(e) => setPercentage(e.target.value)}
              value={percentage}
            >
              <MenuItem value="25">25</MenuItem>
              <MenuItem value="50">50</MenuItem>
              <MenuItem value="75">75</MenuItem>
              <MenuItem value="99">99</MenuItem>
              <MenuItem value="100">100</MenuItem>
            </Select> */}

            <Autocomplete
              sx={{ width: "100%" }}
              options={AddOfferPercentage || [""]}
              getOptionLabel={(option) => `${option?.offer}`}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Offer" />
              )}
              value={addoffers?.addPercentage}
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "addPercentage")
              }
            />
            {percentageAlert && (
              <p className="validation-alert">Add Percentage</p>
            )}
          </div>
          <div className="global-single-input add-offer-single-input">
            <p>Sale Start Date</p>
            <input
              type="date"
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "startDate")
              }
              value={addoffers?.saleStartDate}
            />
            {startDateAlert && (
              <p className="validation-alert">Select Start Date</p>
            )}
          </div>
          <div className="global-single-input add-offer-single-input">
            <p>Sale End Date</p>
            <input
              type="date"
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "EndDate")
              }
              value={addoffers?.saleEndDate}
            />
            {endDateAlert && (
              <p className="validation-alert">Select End Date</p>
            )}
          </div>
          <div className="global-single-input add-offer-single-input">
            <p>Conditions</p>
            {/* <Select
              onChange={(e) => setConditions(e.target.value)}
              value={conditions}
            >
              <MenuItem value="Condition 1">Condition 1</MenuItem>
              <MenuItem value="Condition 2">Condition 2</MenuItem>
              <MenuItem value="Condition 3">Condition 3</MenuItem>
              <MenuItem value="Condition 4">Condition 4</MenuItem>
              <MenuItem value="Condition 5">Condition 5</MenuItem>
            </Select> */}
            <input
              type="text"
              placeholder="Enter condition"
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "condition")
              }
              value={addoffers?.conditions}
            />
            {conditionAlert && (
              <p className="validation-alert">Select End Date</p>
            )}
          </div>

          <div className="global-single-input add-offer-single-input">
            <p>Image</p>
            <CustomImageUpload
              setImgUrl={setImgUrl}
              uploadImageUrl={ImaUrl}
              onChange={getImage}
            />
          </div>
        </div>
        <div className="add-offer-cancel-submit-container">
          <button className="global-button-contained-black-outlined"
          onClick={clearState}
          >
            Cancel
          </button>
          <button
            className="global-button-contained-blue"
            onClick={addofferSubmitBtn}
          >
            Submit
          </button>
        </div>

        <SuccessSnackbar
          open={successSnackbaropen}
          handleClose={closeSuccessSnackbar}
          message={snackMsg}
        />
        <ErrorSnackbar
          open={errosnackBar}
          handleClose={closeErrorSnackbar}
          message={errorSnackMsg}
        />
      </div>
    </>
  );
};

export default AddOffer;
