import { Dialog, IconButton, Skeleton, dialogActionsClasses } from "@mui/material";
import React, { useEffect, useState } from "react";

import "../../../../Css/Staff/HRM/Department.css";
import { departmentAPICall, editDepartmentAPICall, getDepartmentAPICall } from "../../../../API/Staff/HRM/departmentAPI";
import { useSelector } from "react-redux";
import SuccessSnackbar from "../../../SingleCompoents/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../SingleCompoents/SnackBars/ErrorSnackbar";
export const Department = () => {
  const allDepartmentList = useSelector((state)=>state.departmentSlice.value)
  
  const [addDept,setAddDept]=useState(false)
  const [editDept,setEditDept]=useState(false)
  const [isModify,setIsModify]=useState(false)
  const [departmentName,setDepartmentName]=useState()
  const [successSnackbar,setSuccessSnackbar]=useState(false)
  const [errorSnackbar,setErrorSnackbar]=useState(false)
  const [snackMsg,setSnackMsg]=useState("Oops!!!")
  const [editedId,setEditedId]=useState()


  // upadateListner
  const updateListner=()=>{
    setIsModify(!isModify)
  }
  // api call
  useEffect(()=>{
    getDepartmentAPICall()
  },[isModify])

  const openAddDeptDialog=()=>{
    setAddDept(true)
  }
  const getDepartmentName=(e)=>{
    setDepartmentName(e.target.value)
  }
 const bodyToApi={
  departmentName
 }
  const departmentSubmitBtn=()=>{
    if(departmentName === undefined){
      alert("Enter Department Name")
    }
    else{
      departmentAPICall(
        bodyToApi,
        updateListner,
        setAddDept,
        setSuccessSnackbar,
        setErrorSnackbar,
        setSnackMsg,
        setDepartmentName
        )
    }
    
  }

  // open edit dialog
  const openEditDialog=(data)=>{
    setEditDept(true)
    setDepartmentName(data?.departmentName)
    setEditedId(data?._id)
  }

  // edit submit button function
 const editSubmitBtnFn=()=>{
  if(departmentName === undefined ||departmentName === '' ){
    alert("Enter Department Name")
  }
  else{
    editDepartmentAPICall(
      { 
        id:editedId,
        departmentName:departmentName
      },
      updateListner,
      setEditDept,
      setSuccessSnackbar,
      setErrorSnackbar,
      setSnackMsg,
      setDepartmentName
      )
  }
  
 }
 
  
  return (
    <>
      <div className="global-page-parent-container1">
        <p style={{marginLeft:"1%"}} className="breadcrumb">Staff &gt; HRM &gt; Department </p>
        <div className="department-container global-container">
          <div className="department-top-container">
            <h3>Department</h3>
            <button onClick={openAddDeptDialog}>Add Department</button>
          </div>
          <div className="global-container-table">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Department Name</th>
                  <th>Edit</th>
                  {/* <th>Delete</th> */}
                </tr>
              </thead>
            
                <tbody>
                  {allDepartmentList?.length > 0 ? (
                    allDepartmentList?.map((r, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{r?.departmentName?.toUpperCase()}</td>
                        <td>
                          <IconButton
                           onClick={()=>openEditDialog(r)}
                          >
                            <i class="bi bi-pencil-square edit-icon"></i>
                          </IconButton>
                        </td>
                        {/* <td>
                      <IconButton disabled className="disabled-icon-button">
                        <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>
                    </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3}>No Departments</td>
                    </tr>
                  )}
                </tbody>
             
            </table>
          </div>
        </div>

        {/* add department dialog */}
        <Dialog
          open={addDept}
          maxWidth="lg"
        >
          <div className="add-department-dialog-container">
            <div className="add-department-dialog-sub-container">
            <h3>Add Department</h3>
            <hr className="global-hr" />
            <div className="global-single-input department-input">
              <p>Department Name</p>
              <input
                type="text"
                placeholder="Enter new department name..."
                value={departmentName}
                onChange={getDepartmentName}
              />
            </div>
            <div className="department-button-container">
              <button
                className="global-button-contained-black-outlined"
                onClick={()=>setAddDept(false)}
              >
                Cancel
              </button>
              <button 
              className="global-button-contained-blue"
              onClick={departmentSubmitBtn}
              >
                Submit
              </button>
            </div>
            </div>
           
          </div>
        </Dialog>
        {/* Edit department dialog */}
        <Dialog open={editDept} maxWidth="lg"> 
          <div className="add-department-dialog-container">
             <div className="add-department-dialog-sub-container">
            <h3>Edit Department</h3>
            <hr className="global-hr" />
            <div className="global-single-input department-input">
              <p>Department Name</p>
              <input
                type="text"
                placeholder="Enter department name..."
                value={departmentName}
                onChange={getDepartmentName}
              
              />
            </div>
            <div className="department-button-container">
              <button
             onClick={()=>setEditDept(false)}
             className="global-button-contained-black-outlined"
              >
                Cancel
              </button>
              <button
                className="global-button-contained-blue"
                 onClick={editSubmitBtnFn}
              >
                Submit
              </button>
            </div>
            </div>
          </div>
        </Dialog>
      </div>

     <SuccessSnackbar
       open={successSnackbar}
       handleClose={()=>setSuccessSnackbar(false)}
       message={snackMsg}
     />
     <ErrorSnackbar
       open={errorSnackbar}
       handleClose={()=>setErrorSnackbar(false)}
       message={snackMsg}
     />
      
    </>
  );
};
