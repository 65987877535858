import React from 'react'
import "../Registered Users/invoice.css"
import logo from "../../../Assets/Images/eyet-logo-b copy.png"

export const Invoice = (props) => {
   const {data} = props
  return (
    <div className='admin-invoice-container'>
       <div className='admin-padding-invoice-container'>
         
              <div className='justify-space-between'>
               <img src={logo} alt="" style={{width:"100px"}} />
               <p style={{fontSize:"1.2rem",fontWeight:600}}>INVOICE</p>
              </div>
              <div className='justify-space-between'>
               <p style={{fontSize:"0.8rem",fontWeight:600}}>Order ID:{data?.orderId}</p>
               <p style={{fontSize:"0.8rem",fontWeight:600}}>Invoice No {data?.invoiceNo}</p>
              </div>
         
           <hr />
           <div className='justify-space-between'>
               <div className='address-eng-container'>
                  <h5>Invoice To</h5>
                  <p>{data?.invoiceTo?.companyName}</p>
                  <p>{data?.invoiceTo?.address}</p>
                  <h5>{data?.invoiceDate}</h5>
               </div>
               <div className='address-arabic-container'>
                 <h5>فاتورة إلى</h5>
                 <p>{data?.invoiceTo?.nativeCompanyName}</p>
                 <p>{data?.invoiceTo?.nativeAddress}</p>
               </div>
           </div>

           <div className='invoice-table-container'>
               <table>
                  <thead>
                     <tr>
                        <th>ERP</th>
                        <th>Package</th>
                        <th>Rate</th>
                     </tr>
                  </thead>
                  <tbody>
                      <tr>
                         <td>
                           <p>{data?.package?.name}</p>
                         </td>
                         <td>{data?.package?.type}</td>
                         <td>{data?.package?.rate}</td>
                        
                      </tr>
                    
                  </tbody>
               </table>
           </div>
           <div className='invoice-table-container'>
               <table>
                  <thead>
                     <tr>
                        <th>Outlet</th>
                        <th>Rate</th>
                        <th>Amount</th>
                     </tr>
                  </thead>
                  <tbody>
                      <tr>
                         <td>
                           <p>{data?.outlet?.no}</p>
                         </td>
                         <td>{data?.outlet?.rate}</td>
                         <td>{data?.outlet?.total}</td>
                        
                      </tr>
                    
                  </tbody>
               </table>
           </div>
           <div className='invoice-table-container sms-whatsapp-package'>
               <table>
                  <thead>
                     <tr>
                        <th>PACKAGE</th>
                        <th>QTY</th>
                        <th>RATE</th>
                        <th>AMOUNT</th>
                     </tr>
                  </thead>
                  <tbody>
                      <tr>
                         <td>SMS PACKAGE</td>
                         <td>{data?.sms?.qty}</td>
                         <td>{data?.sms?.rate}</td>
                         <td>{data?.sms?.amount}</td>
                        
                      </tr>
                      <tr>
                         <td>WHATSAPP PACKAGE</td>
                         <td>{data?.whatsapp?.qty}</td>
                         <td>{data?.whatsapp?.rate}</td>
                         <td>{data?.whatsapp?.amount}</td>
                        
                      </tr>
                    
                  </tbody>
               </table>
           </div>
           <div className='backup-price'>
            <p>BACKUP PRICE</p>
            <p>{data?.backupPrice}</p>
           </div>

           <div className='invoice-sub-total-container'>
              <p>SUBTOTAL : {data?.subtotal}</p>
              {
               data?.tax?.map((r)=>(
                 <p>{r?.taxName?.toUpperCase()} : {r?.taxamount}</p>
               ))
              }
              
              <p>PAID : {data?.paid}</p>
              <p>BALANCE : {data?.balance}</p>
              <p 
              style={{
               backgroundColor:" rgb(0, 0, 0)",
               color: "rgb(255, 255, 255)",
               width: "31%",
               height: "10px",
               display: "flex",
               justifyContent:"center",
               alignItems: "center",
               padding:"2%",
               fontSize:"0.65rem"
               }}>TOTAL AMOUNT : {data?.totalAmount} </p>
           </div>
           <div className='invoice-autorized-signatory'>
              <p>Autorized Signatory</p>
           </div>
           <hr />
           <div className='invoice-terms-and-conditions'>
              <div className='invoice-terms-and-conditions-eng'>
                 <h5>Terms And Comditions</h5>
                 <p>The ERP account provided to you is non-transferable and is intended for your use only. Sharing, selling, or transferring the account to another individual or entity is strictly prohibited. This is to ensure the security and integrity of the data stored within the ERP system and to prevent unauthorized access to sensitive information. Failure to renew the ERP subscription by the expiration date will result in the termination of access to the software and services.</p>
              </div>
              <div className='invoice-terms-and-conditions-arabic'>
                 <h5 style={{direction:"rtl"}}>الشروط والأحكام</h5>
                 <p style={{direction:"rtl"}}>
                 حساب ERP المقدم لك غير قابل للتحويل وهو مخصص لاستخدامك فقط. يُمنع منعًا باتًا مشاركة الحساب أو بيعه أو نقله إلى فرد أو كيان آخر. وذلك لضمان أمان وسلامة البيانات المخزنة داخل نظام تخطيط موارد المؤسسات (ERP) ولمنع الوصول غير المصرح به إلى المعلومات الحساسة. سيؤدي عدم تجديد اشتراك ERP بحلول تاريخ انتهاء الصلاحية إلى إنهاء الوصول إلى البرامج والخدمات.
                  </p>
              </div>
           </div>
           <div className='invoice-thank-you'>
             <p>THANK YOU</p>
           </div>
       </div>
    </div>
  )
}
