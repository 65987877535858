import React from "react";
import TopContainer from "../CustomComponent/TopContainer";
import '../../../Css/Reports/dailyreport.css'
const DailyReport =()=>{


    return(<>
        <TopContainer label="Daily Report"/>
        <div className="global-container daily-report-parent-container">
        <h3>Daily Report</h3>
        <hr />
        <div className="daily-report-form-container">
          <div className="daily-report-form-child">
            <div className="global-single-input fullwidth-input">
              <p>Transaction ID</p>
              <input type="text" />
            </div>
            <div className="global-single-input fullwidth-input">
              <p>Name </p>
              <input type="text" />
            </div>
            <div className="global-single-input fullwidth-input">
              <p>Contact</p>
              <input type="text" />
            </div>
            <div className="global-single-input fullwidth-input">
              <p>Start Date</p>
              <input type="text" />
            </div>
            <div className="global-single-input fullwidth-input">
              <p>End Date</p>
              <input type="text" />
            </div>
            <div className="daily-report-submit-container">        
             <button className="daily-report-button-contained">Submit</button>
             </div>
          </div>          
        </div>       
      </div>


      <div className="global-container daily-report-list-table-container">
            <h3>Daily Report</h3>
            <div className="global-container-table">
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Date</th>
                <th>Transaction ID</th>
                <th>Cutsomer ID</th>
                <th>Name</th>
                <th>Contact</th>
                <th>Amount</th>
                <th>Package</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>01</td>
                <td>20/20/2020</td>
                <td>PKSB9302</td>
                <td>CUS098</td>
                <td>Nihad</td>
                <td>9345934566</td>
                <td>500.00</td>
                <td>ERP</td>
                <td>12/11/2014</td>
                <td>19/10/2016</td>
              </tr>
              <tr>
                <td>01</td>
                <td>20/20/2020</td>
                <td>PKSB9302</td>
                <td>CUS098</td>
                <td>Nihad</td>
                <td>9345934566</td>
                <td>500.00</td>
                <td>ERP</td>
                <td>12/11/2014</td>
                <td>19/10/2016</td>
              </tr>
              <tr>
                <td>01</td>
                <td>20/20/2020</td>
                <td>PKSB9302</td>
                <td>CUS098</td>
                <td>Nihad</td>
                <td>9345934566</td>
                <td>500.00</td>
                <td>ERP</td>
                <td>12/11/2014</td>
                <td>19/10/2016</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> 
        
    
    </>);
};

export default DailyReport;