/** @format */

import React, { useState } from "react";
import TopContainer from "../CustomComponent/TopContainer";
import "../../../Css/Offers/offersListed.css";
import { Dialog, IconButton, Skeleton } from "@mui/material";
import { DeleteOutlined, VisibilityOutlined } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import Image from "../../../Assets/Images/man.jpg";
import NoImage from "../../../../src/Assets/Images/no-image.png"
import { useEffect } from "react";
import { getAllOffers, getSingleOfferApiCall } from "../../../API/Offers/OffersApi";
import { useSelector } from "react-redux";
const OffersListed = () => {
  //getSingleOffer
  const SingleOfferview = useSelector((state)=>{
    return state.SingleOfferSlice.value;
  });
  console.log("-->",SingleOfferview)

  // get ALL offersList

  const getAllOffersList = useSelector((state) => {
    return state.offersListSlice.value;
  });

  const [OfferListedSingleViewOpen, setOfferListedSingleViewOpen] =
    useState(false);

  //Loading State
  const [isloading, setisLoading] = useState(true);

  //Open Single view Dialog
  const clickVisibilityIconBtn = (id) => {
    setOfferListedSingleViewOpen(true);
  
    if(id !==undefined){
      getSingleOfferApiCall({
        transId:id
    });

    }
  };



  const closeViewDialog = () => {
    setOfferListedSingleViewOpen(false);
  };

  //getAlloffers APICALL
  useEffect(() => {
    getAllOffers(setisLoading);
  }, []);

  return (
    <>
      <TopContainer label="Offers > Offer Listed" />
      <div className="global-container">
        <div className="offers-listed-topbar-container">
          <h3>Offer Listed</h3>
          <button className="global-button-contained-blue">Add Offer</button>
        </div>
        <div className="global-container-table offers-listed-table">
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Transaction ID</th>
                <th>ERP Type</th>
                <th>Image</th>
                <th>Offer Type</th>
                <th>Edit</th>
                <th>View</th>
                <th>Remove</th>
              </tr>
            </thead>
            {isloading ? (
              <tbody>
                <tr>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {getAllOffersList?.map((r, i) => (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{r?.transId}</td>
                    <td>{r?.typeOfErp}</td>
                    <td>
                      <img src={r?.imgUrl} alt="" />
                    </td>
                    <td>{r?.offerType}</td>
                    <td>
                      <IconButton>
                        {" "}
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          color="#8373f1"
                          size="sm"
                        />
                      </IconButton>
                    </td>
                    <td>
                      <IconButton onClick={()=>clickVisibilityIconBtn(r?._id)}>
                        <VisibilityOutlined sx={{ color: "#48aa54" }} />
                      </IconButton>
                    </td>
                    <td>
                      <IconButton>
                        <DeleteOutlined sx={{ color: "#f80000" }} />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>

      {/* Single View Dailog  */}
      <Dialog open={OfferListedSingleViewOpen} maxWidth="lg">
        <div className="offers-listed-dialog-parent">
          <div className="offers-listed-dialog-wrapper">
            <h3>View Offer</h3>
            <hr />
            <div className="offer-listed-form-container">
              <div className="global-single-input offers-listed-view-input">
                <p>Transaction ID</p>
                <input type="text" value={SingleOfferview?.transId} disabled/>
              </div>
              <div className="global-single-input offers-listed-view-input">
                <p>Country</p>
                <input type="text" value={SingleOfferview?.country} disabled/>
              </div>
              <div className="global-single-input offers-listed-view-input">
                <p>Type of ERP</p>
                <input type="text" value={SingleOfferview?.typeOfErp} disabled/>
              </div>
            </div>
            <hr />
            <div className="offer-listed-form-container">
              <div className="global-single-input offers-listed-view-input">
                <p>Offer Type</p>
                <input type="text" disabled value={SingleOfferview?.offerType}/>
              </div>
              <div className="global-single-input offers-listed-view-input">
                <p>Percentage(%)</p>
                <input type="text" disabled value={SingleOfferview?.addPercentage}/>
              </div>
              <div className="global-single-input offers-listed-view-input">
                <p>Sale Start Date</p>
                <input type="date" value={SingleOfferview?.saleStartDate} disabled/>
              </div>
              <div className="global-single-input offers-listed-view-input">
                <p>Sale End Date</p>
                <input type="date" value={SingleOfferview?.saleEndDate} disabled/>
              </div>
              <div className="global-single-input offers-listed-view-input">
                <p>Conditions</p>
                <input type="text" value={SingleOfferview?.conditions} disabled/>
              </div>
              <div className="global-single-input offers-listed-view-input">
                <p>Image</p>
                <img src={SingleOfferview?.imgUrl || NoImage} alt="image" />
              </div>
            </div>
            <div className="offers-listed-button-container">
              <button
                className="global-button-contained-black cancel-btn"
                onClick={closeViewDialog}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default OffersListed;
