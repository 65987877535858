import { createSlice } from "@reduxjs/toolkit";

const TaxListSlice = createSlice({
    name:"TaxListSlice",
    initialState:{
        value:undefined,
        singleTax:undefined
    },
    reducers:{
        get_All_Tax:(state,action)=>{
            state.value = action.payload.TaxList;
        },
        get_single_tax:(state,action)=>{
            state.singleTax=action.payload.singleTaxData;
        }
    }
});
export const {get_All_Tax,get_single_tax} = TaxListSlice.actions;
export default TaxListSlice.reducer;