import { createSlice } from "@reduxjs/toolkit";

const deviceSettingsSlice=createSlice({
    name:"deviceSettingsSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        add_device_Settings:(state,action)=>{
            console.log("*****",action)
            state.value =action.payload.addDeviceSettings;
        }
    }
});
export const {add_device_Settings} = deviceSettingsSlice.actions;
export default deviceSettingsSlice.reducer;