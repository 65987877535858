/** @format */

import {
  Checkbox,
  IconButton,
  Skeleton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../../Css/Registered Users/registeredUsers.css";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteConfirmationDialog from "../CustomComponent/DeleteConfirmationDialog";
import TopContainer from "../CustomComponent/TopContainer";
import { useNavigate } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { getAllCustomers } from "../../../API/RegisterdUsers/RegisterdUsersApi";
import { useSelector } from "react-redux";
import {
  deleteSingleCustomerApiCall
} from "../../../API/RegisterdUsers/Deletecustomer";
import { deleteAlertMessage } from "../../../Js/generalFunnctionnns";
import moduleIcon from "../../../Assets/Icons/Group 14456.svg"
import { AddSubModule } from "../CustomComponent/AddSubModule";
import { store } from "../../../Redux/Store";
import { customer_register_initial_form } from "./customerRegistrationSlice";
import { get_my_plans_singleView } from "../../../Redux/RegisterdList/registerdUserSlice";

const RegisteredUsers = () => {
  //getAllcustomersList
  const getAllCustomersList = useSelector((state) => {
    return state.registerdUsers.value;
  });

  const navigate = useNavigate();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openInactiveUser, setOpenInactiveUser] = useState(true);
  const [isLoading, setisLoading] = useState(true);
  const [addSubModule,setAddSubModule]=useState(false)

  //Delete Icon Button Fn
  const clickDeleteIconBtn = (id) => {
    // setOpenDeleteDialog(true);
    console.log(id);

    //Delete the single customer
  
    
  };


//deleteSupplier

  const deleteSupplier = (id) =>{
    console.log(id)
    deleteSingleCustomerApiCall({
      customerId: id,
    });



  }

  //////

 




  //Close Dialog
  const closeConfirmDialog = () => {
    setOpenDeleteDialog(false);
  };

  // Visibility IconButton Onclick
  const clickVisibilityIcon = (_id) => {
    console.log(_id);
    localStorage.setItem("singleViewId",_id)
    navigate(`/dashboard/registeredCustomerList/registeredUserSingle`);
  };
  const purchaseBtnFn=(id)=>{
     store.dispatch(customer_register_initial_form({initialFormData:{cusId:id,existCustomer:true}}))
     localStorage.removeItem("packageId")
     store.dispatch(get_my_plans_singleView({singleViewData:undefined}))
     navigate("/dashboard/customerRegistration/cart")

  }

  useEffect(() => {
    // Get all customers
    getAllCustomers(setisLoading);
  }, []);


  return (
    <>

      <TopContainer label="Registerd Users" />

    

    
        <div className="global-container reg-user-container">
          <div className="global-container-table">
            <table>
              <thead>
                <tr>
                  <th>Customer ID</th>
                  <th>Customer Name</th>
                  <th>Contact</th>
                  <th>Email</th>
                  <th>Package</th>
                  <th>Mobile Package</th>
                  <th>Status</th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={10}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {getAllCustomersList?.list?.map((r, i) => (
                    <tr    style={{cursor:"pointer"}}>
                      <td onClick={() => clickVisibilityIcon(r?._id)}>{r?.cusId}</td>
                      <td onClick={() => clickVisibilityIcon(r?._id)}>{r?.companyName}</td>
                      <td onClick={() => clickVisibilityIcon(r?._id)}>{r?.mobile}</td>
                      <td onClick={() => clickVisibilityIcon(r?._id)}>{r?.email}</td>
                      <td style={{width:"15%"}} onClick={() => clickVisibilityIcon(r?._id)}>
                        {r?.packs?.map((obj)=>(<p style={{margin:"4px 0"}}>{obj?.packageName}</p>))
                        }</td>
                      <td ></td>
                      <td >
                        {
                          r?.status==="Purchase"?
                           <p onClick={()=>purchaseBtnFn(r?._id)} className="status-purchase">Purchase</p>
                          :r?.status==="Active"?
                          <p className="status-active">Active</p>
                          :undefined
                        }
                        {/* <p className="status-active">Active</p> */}
                        
                      </td>

                     
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      
      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onClose={closeConfirmDialog}
      />
       <AddSubModule 
       open={addSubModule} 
       onClose={()=>setAddSubModule(false)}/>
    </>
  );
};

export default RegisteredUsers;
