import React from "react";

import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { today } from "../../../Functions/Date";
export const DatePicker = () => {
  return (
    <div className="custom-date-parent">
      <input value={today} type="date" className="time-picker-input" />
      <CalendarMonthOutlinedIcon
        sx={{
          color: "#2e58c9",
          position: "absolute",
          top: 6,
          left: 6,
          fontSize: "1.2rem",
          cursor:'pointer'
        }}
      />
    </div>
  );
};
