import React from "react";
import TopContainer from "../CustomComponent/TopContainer";
import '../../../Css/Reports/dailyreport.css'
import { IconButton } from "@mui/material";
import { VisibilityOutlined } from "@mui/icons-material";
import RefundReportView from "../CustomComponent/RefundReportView";
import {useState} from "react"
const RefundReport =()=>{
    const [openModule, setOpenModule] = useState(false);
    const [openPackage, setOpenPackage] = useState(false);
    const clickModuleDuration = () => {
        setOpenModule(true);
    };
    const closeConfirmDialog=()=>{
        setOpenModule(false)
        setOpenPackage(false)
    
        }   

    return(<>
        <TopContainer label="Refund Report"/>   
      <div className="global-container daily-report-list-table-container">
            <h3>Refund Report</h3>
            <div className="global-container-table">
          <table>
            <thead>
              <tr>               
                <th>Transaction ID</th>
                <th>Cutsomer ID</th>
                <th>Name</th>
                <th>Contact</th>
                <th>Account Details</th>
                <th>Transfer Details</th>
                <th>Amount</th>
                <th>Refund Status</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PKSB9302</td>
                <td>CUS098</td>
                <td>Nihad</td>
                <td>9345934566</td>
                <td>01</td>
                <td>20/20/2020</td>
                <td>500.00</td>
                <td><div className="refund-status-ctn">
                        <p>Completed</p>
                    </div></td>
                <td><IconButton onClick={clickModuleDuration}>
                    <VisibilityOutlined sx={{ color: "#48aa54" }} />
                  </IconButton></td>
              </tr>
              <tr>
                <td>PKSB9302</td>
                <td>CUS098</td>
                <td>Nihad</td>
                <td>9345934566</td>
                <td>01</td>
                <td>20/20/2020</td>
                <td>500.00</td>
                <td><div className="refund-status-ctn">
                        <p>Completed</p>
                    </div></td>
                <td><IconButton >
                    <VisibilityOutlined sx={{ color: "#48aa54" }} />
                  </IconButton></td>
              </tr>
              <tr>
                <td>PKSB9302</td>
                <td>CUS098</td>
                <td>Nihad</td>
                <td>9345934566</td>
                <td>01</td>
                <td>20/20/2020</td>
                <td>500.00</td>
                <td><div className="refund-status-ctn">
                        <p>Completed</p>
                    </div></td>
                <td><IconButton >
                    <VisibilityOutlined sx={{ color: "#48aa54" }} />
                  </IconButton></td>
              </tr>
              <tr>
                <td>PKSB9302</td>
                <td>CUS098</td>
                <td>Nihad</td>
                <td>9345934566</td>
                <td>01</td>
                <td>20/20/2020</td>
                <td>500.00</td>
                <td><div className="refund-status-ctn">
                        <p>Completed</p>
                    </div></td>
                <td><IconButton >
                    <VisibilityOutlined sx={{ color: "#48aa54" }} />
                  </IconButton></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> 
        <RefundReportView open={openModule} onClose={closeConfirmDialog}/>
        
    </>);
};

export default RefundReport;