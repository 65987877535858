import axios from "axios";
import { HEADERS } from "../../../API/urlAndPath"
import { store } from "../../../Redux/Store";
import { filter_package_list } from "./customerRegistrationSlice";
import { get_my_plans_singleView } from "../../../Redux/RegisterdList/registerdUserSlice";

export const addCustomerManual=(body,successAlertMessage,setState,state)=>{
    axios.post("admin/addcustomermanual",body,HEADERS)
    .then((res)=>{
          if(res.status===200){
            successAlertMessage(true)
            setTimeout(()=>{
              successAlertMessage(false)
            },[2000])
          
            //  successAlertMessage("Customer Registered Successfully")

          }
    })
    .catch((err)=>{
        if (err.response.status === 500) {
            setState({ ...state, message: "Internal Server error !", error: true })
          } else {
            setState({ ...state, message: err?.response?.data, error: true })
          }
    })
}

// filter erp package type
export const filterErpPackage=(body)=>{
  axios.post("package/filtererppackage",body,HEADERS)
  .then((res)=>{
     if(res.status===200){
        store.dispatch(filter_package_list({filterPackageData:res.data}))
     }
  })
  .catch((err)=>{
        store.dispatch(filter_package_list({filterPackageData:undefined}))

  })
}

// purchase manual
export const puchaseManualAPICall=(body,setLoadingForm,setState,state)=>{
  setLoadingForm(true)
   axios.post("admin/addpurchasemanual",body,HEADERS)
   .then((res)=>{
      if(res.status===200){
        setLoadingForm(false)
        setState({...state,message:"Purchased Successfully",success:true})
      }
   })
   .catch((err)=>{
    setLoadingForm(false)
    console.log(err?.response?.data);
    if (err.response.status === 500) {
      setState({ ...state, message:"Internal Server error !", error: true })
    } else {
      setState({ ...state, message:err?.response?.data,error: true })
    }
    console.log(err.response);
   })
}

export const freeTrialAPICall=(body,setLoadingForm,setState,state)=>{
  setLoadingForm(true)
   axios.post("admin/add7daymanual",body,HEADERS)
   .then((res)=>{
      if(res.status===200){
        setLoadingForm(false)
        setState({...state,message:"Success",success:true})
      }
   })
   .catch((err)=>{
    setLoadingForm(false)
    console.log(err?.response?.data);
    if (err.response.status === 500) {
      setState({ ...state, message:"Internal Server error !", error: true })
    } else {
      setState({ ...state, message:err?.response?.data,error: true })
    }
    console.log(err.response);
   })

}

export const viewPlansSingleAPICall=(body)=>{
  axios.post("package/viewoutletSingleForcart",body,HEADERS)
  .then((res)=>{
      if(res.status===200){
         store.dispatch(get_my_plans_singleView({singleViewData:res.data}))
      }
  })
  .catch((err)=>{
      console.log(err.response);
  })
}

export const  addOutletAPICall=(
  body,
  setState,
  state,
  setCheckedDataList
  // setOpenSuccessSnackbar,
  // setOpenErrorSnackbar,
  // setSnackMsg 
)=>{
axios.post("admin/renewbranchManual",body,HEADERS)
.then((res)=>{
  if(res.status===200){
      // setSnackMsg(res.data)
      // setOpenSuccessSnackbar(true)
       setState({...state,message:"Added",success:true})
       localStorage.removeItem("packageId")
       store.dispatch(get_my_plans_singleView({singleViewData:undefined}))
       setCheckedDataList([])
  }
})
.catch((err)=>{
  if (err.response.status === 500) {
    setState({ ...state, message:"Internal Server error !", error: true })
  } else {
    setState({ ...state, message:err?.response?.data,error: true })
  }
  // setSnackMsg(err.response.data)
  // setOpenErrorSnackbar(true)
})
}
