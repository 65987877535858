import axios from "axios"
import { HEADERS } from "../../../API/urlAndPath"

export const addTrialPeriodAPICall=(body,setSnackBarStates,snackBarStates)=>{
    axios.post("package/addPackageDuration",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            console.log("Trial Period Added")
            setSnackBarStates({...snackBarStates,success:true,snackMsg:"Trial Period Added"})
        }
    })
    .catch((err)=>{
        console.log(err.response)
        setSnackBarStates({...snackBarStates,error:true,snackMsg:err.response.data})
    })
}