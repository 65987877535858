import React from 'react'
import TopContainer from '../../CustomComponent/TopContainer'
import { IconButton } from '@mui/material'

export const LoanReport = () => {
  return (
    <>
       <TopContainer label="Loan > Loan Report"/>
       <div className='global-container'>
           <div className='global-heading-containe'>
                <h3>Loan</h3>
            </div>
            <div className='global-container-table'>
               <table>
                  <thead>
                    <tr>
                        <th>Loan ID</th>
                        <th>Employee ID</th>
                        <th>Name</th>
                        <th>Image</th>
                        <th>Mobile</th>
                        <th>Loan Amount</th>
                        <th>Interest</th>
                        <th>Actual Amount</th>
                        <th>View</th>
                        <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                        <td>zayy</td>
                        <td>zayy</td>
                        <td>zayy</td>
                        <td>zayy</td>
                        <td>zayy</td>
                        <td>zayy</td>
                        <td>zayy</td>
                        <td>zayy</td>
                        <td>
                           <IconButton>
                               <i class="bi bi-eye visibility-icon"></i>
                           </IconButton>
                        </td>
                        <td>
                           <IconButton>
                               <i class="bi bi-arrow-down-circle download-icon"></i>
                           </IconButton>
                        </td>
                    </tr>
                  </tbody>
               </table>
            </div>
       </div>
    </>
  )
}
