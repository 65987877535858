import { IconButton, Tooltip } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import TopContainer from "../CustomComponent/TopContainer";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
 export const TotalRenewal = () => {
    const navigate=useNavigate()
  // Visibility IconButton Onclick
  const clickVisibilityIcon=()=>{
    navigate('/dashboard/registeredUserSingle')
  }
  return (
    <>
      <TopContainer label='Dashboard > Total Renewal'/>
      <div className="global-container reg-user-container">
        <div className="global-container-table">
          <table>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Customer Name</th>
                <th>Contact</th>
                <th>Date</th>
                <th>Document</th>
                <th>Package</th>
                <th>Amount</th>
                <th>Action</th>
                <th>Status</th>
               
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PKDGS12345</td>
                <td>VKR</td>
                <td>+91 123456789</td>
                <td>17-10-2022</td>
                <td>ID Proof</td>
                <td>ERP </td>
                <td>124.5</td>
               
                <td>
                  <Tooltip title="View">
                    <IconButton onClick={clickVisibilityIcon}>
                      <VisibilityOutlinedIcon sx={{ color: "#83a62f" }} />
                    </IconButton>
                  </Tooltip>
                </td>
                <td className="recent-purchase-status"><p className="renewal">Renewal</p></td>
               
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>PKDGS12345</td>
                <td>VKR</td>
                <td>+91 123456789</td>
                <td>17-10-2022</td>
                <td>ID Proof</td>
                <td>ERP </td>
                <td>124.5</td>
               
                <td>
                  <Tooltip title="View">
                    <IconButton onClick={clickVisibilityIcon}>
                      <VisibilityOutlinedIcon sx={{ color: "#83a62f" }} />
                    </IconButton>
                  </Tooltip>
                </td>
                <td className="recent-purchase-status"><p className="renewal">Renewal</p></td>
               
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>PKDGS12345</td>
                <td>VKR</td>
                <td>+91 123456789</td>
                <td>17-10-2022</td>
                <td>ID Proof</td>
                <td>ERP </td>
                <td>124.5</td>
               
                <td>
                <Tooltip title="View">
                    <IconButton onClick={clickVisibilityIcon}>
                      <VisibilityOutlinedIcon sx={{ color: "#83a62f" }} />
                    </IconButton>
                  </Tooltip>
                </td>
                <td className="recent-purchase-status"><p className="renewal">Renewal</p></td>
               
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>PKDGS12345</td>
                <td>VKR</td>
                <td>+91 123456789</td>
                <td>17-10-2022</td>
                <td>ID Proof</td>
                <td>ERP </td>
                <td>124.5</td>
               
                <td>
                  <Tooltip title="Delete">
                    <IconButton>
                      <DeleteOutlineOutlinedIcon sx={{ color: "#fd0301" }} />
                    </IconButton>
                  </Tooltip>
                </td>
                <td className="recent-purchase-status"><p className="renewal">Renewal</p></td>
               
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </>
  );
};

export default TotalRenewal;
