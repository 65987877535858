import { Autocomplete, Dialog, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {useSelector } from "react-redux";
import CountryListWithCode from "../../../Assets/JSON/CountryLIstWithCode.json"
import { addCustomerManual } from './CustomerRegistrationAPICall';
import { successAlertMessage } from '../../../Js/generalFunnctionnns';
import ErrorSnackbar from '../../SingleCompoents/SnackBars/ErrorSnackbar';
import { viewAllPackageList } from '../../../API/AddPackage/AddPackageAPI';
import { store } from '../../../Redux/Store';
import { customer_register_initial_form } from './customerRegistrationSlice';

export const CustomerRegistrationForm = () => {
   const navigateTo=useNavigate()
  const popupData=useSelector((state)=>state.customerRegistrationSlice.initialForm)
 // package list
 const packageList=useSelector((state)=>state.generatePackageIdSlice?.value1)
 
  
    const formValuesInitial={
        firstName:"",
        lastName:"",
        companyType:null,
        country:null,
        phone:'',
        websiteName:"",
        userName:"",
        password:"",
        isEdit: false,
    }
    const [formValues,setFormValues]=useState(formValuesInitial)
    const [errorState,setErrorState]=useState({})
    const [snackBarStates, setSnackBarStates] = useState({
      success: false,
      error: false,
      message: "",
      alert: false
    });
    const closeSnackbar = () => {
      setSnackBarStates({
        ...snackBarStates,
        success: false,
        error: false,
        alert: false,
        message: "",
      });
    };
    const [customerRegSuccessAlert,setCustomerRegSuccessAlert]=useState(false)
    const getFormValues=(key)=>(e,newValue)=>{
      const {value}=e.target;
      setFormValues({...formValues,[key]:value===0?newValue:value})
    }
    const bodyToSubmitBody={
      mobile:popupData?.mobile,
      companyName:popupData?.companyName,
      email:popupData?.email,
      info:{
        firstName:formValues?.firstName,
        lastName:formValues?.lastName,
        companyType:"",
        country:formValues?.country?.name,
        mobile:formValues?.phone,
        website:formValues?.websiteName
      },
      username:formValues?.userName,
      password:formValues?.password
    }
    const submitBtnFunction=()=>{
      addCustomerManual(
        bodyToSubmitBody,
        setCustomerRegSuccessAlert,
        setSnackBarStates,
         snackBarStates
         )
        //  setCustomerRegSuccessAlert(true)
      // console.log(bodyToSubmitBody);
    }

    const purchaseBtnFn=()=>{
      if(formValues?.userName===""){
        setErrorState({...errorState,userName:"required*"})
      }
      else if(formValues?.password===""){
        setErrorState({...errorState,password:"required*"})
      }
      else{
        store.dispatch(customer_register_initial_form({initialFormData:bodyToSubmitBody}))
        navigateTo("/dashboard/customerRegistration/cart")
      }
     
    }
    console.log(formValues?.userName);
    useEffect(()=>{
      viewAllPackageList()
    },[])

    // useEffect(() => {
    //   const handleBeforeUnload = (event) => {
    //     // Customize the message if needed
    //     event.preventDefault();
    //     event.returnValue = 'Are you sure you want to leave?';
    //     return 'Are you sure you want to leave?';
    //   };
  
    //   const handleUnload = () => {
    //     // Redirect to the desired page
    //     // window.location.href = '/dashboard/customerRegistration';
    //     navigateTo("/dashboard/customerRegistration")
    //   };
  
    //   window.addEventListener('beforeunload', handleBeforeUnload);
    //   window.addEventListener('unload', handleUnload);
  
    //   return () => {
    //     window.removeEventListener('beforeunload', handleBeforeUnload);
    //     window.removeEventListener('unload', handleUnload);
    //   };
    // }, [popupData]);

    useEffect(() => {
      const handleBeforeUnload = (event) => {
        if (popupData !== undefined) {
          event.preventDefault();
          event.returnValue = ''; // Some browsers require a return value for this event
          return 'Data may not be saved. Are you sure you want to leave?';
        }
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [popupData]);


  
  
  
 




 


    console.log(popupData);
  return (
    <div className='global-container'>
         {/* <p onClick={handleUnload}>onClick</p> */}
      
         <div style={{display:"flex",flexWrap:"wrap"}}>
         <div className="global-single-input auto-complete" style={{width:"30%"}}>
            <p>First Name</p>
            <input
             type="text"
             value={formValues?.firstName}
             onChange={getFormValues("firstName")}
            />
         </div>
         <div className="global-single-input auto-complete" style={{width:"30%"}}>
            <p>Last Name</p>
            <input
             type="text"
             value={formValues?.lastName}
             onChange={getFormValues("lastName")}
            />
         </div>
         <div className="global-single-input auto-complete" style={{width:"30%"}}>
            <p>Company Type</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={[""]}
              getOptionLabel={(option)=>option}
              renderInput={(params)=><TextField {...params}/>}
              value={formValues?.companyType}
              onChange={getFormValues("companyType")}
            />
          </div>
         <div className="global-single-input auto-complete" style={{width:"30%"}}>
            <p>Country</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={CountryListWithCode||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params}/>}
              value={formValues?.country}
              onChange={getFormValues("country")}
            />
          </div>
          <div className="global-single-input auto-complete" style={{width:"30%"}}>
            <p>Phone</p>
            <input
             type="text"
             value={formValues?.phone}
             onChange={getFormValues("phone")}
            />
         </div>
         <div className="global-single-input auto-complete" style={{width:"30%"}}>
            <p>Website Name</p>
            <input
             type="text"
             value={formValues?.websiteName}
             onChange={getFormValues("websiteName")}
            />
         </div>
         <div className="global-single-input auto-complete" style={{width:"30%"}}>
            <p>User Name</p>
            <input
             type="text"
             value={formValues?.userName}
             onChange={getFormValues("userName")}
            />
            <p style={{color:"red"}}>{errorState?.userName}</p>
         </div>
         <div className="global-single-input auto-complete" style={{width:"30%"}}>
            <p>Password</p>
            <input
             type="password"
             value={formValues?.password}
             onChange={getFormValues("password")}
            />
             <p style={{color:"red"}}>{errorState?.password}</p>
         </div>
         <div className="package-plans-cancel-submit-btn-container" style={{width:"100%",margin:"2% 0"}}>
          <button 
            onClick={purchaseBtnFn} 
            className="global-button-contained-black-outlined" style={{color:"#8373f1",borderColor:"#8373f1"}}>
            Purchase ERP
          </button>
          <button 
          className="global-button-contained-blue" 
          onClick={submitBtnFunction}
          >Submit</button>
        </div>
        
         </div>

         <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <Dialog open={customerRegSuccessAlert} maxWidth="lg" onClose={()=>setCustomerRegSuccessAlert(false)}>
          <div style={{width:"30vw",height:"22vh",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="#02a202" class="bi bi-check-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
            <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"/>
           </svg>
           <p style={{fontSize:"0.8rem",fontWeight:600,marginTop:"10%"}}>Customer Registered Successfully</p>
          </div>
      </Dialog>
      


        
    </div>
  )
}

