/** @format */

import { IconButton, Skeleton, Tooltip } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import TopContainer from "../CustomComponent/TopContainer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getRecentPurchases } from "../../../API/HomeDashboard/homeDashboardAPI";
import { useSelector } from "react-redux";
export const RecentPurchaseView = () => {
  const navigate = useNavigate();

  // Visibility IconButton Onclick
  const clickVisibilityIcon = () => {
    navigate("/dashboard/registeredUserSingle");
  };

  //RecentPurchaseData
  const RecentPurchasedata = useSelector((state) => {
    return state.RecentPurchaselist.value;
  });

  console.log(RecentPurchasedata);

  const [isLoading, setisLoading] = useState(true);

  // getRecentPurchases
  useEffect(() => {
    getRecentPurchases(setisLoading);
  }, []);

  return (
    <>
      <TopContainer label="Dashboard > Recent Purchase" />
      <div className="global-container reg-user-container">
        <div className="global-container-table">
          <table>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Customer Name</th>
                <th>Contact</th>
                <th>Date</th>
                <th>Document</th>
                <th>Package</th>
                <th>Amount</th>
                <th>Action</th>
                <th>Status</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {RecentPurchasedata?.map((r, i) => (
                  <tr key={i}>
                    <td>{r?.purchaseId}</td>
                    <td>{r?.customerName}</td>
                    <td>{r?.contact}</td>
                    <td>{r?.date}</td>
                    <td>ID Proof</td>
                    <td>{r?.package[0]}</td>
                    <td>{r?.amount}</td>

                    <td>
                      <Tooltip title="View">
                        <IconButton onClick={clickVisibilityIcon}>
                          <VisibilityOutlinedIcon sx={{ color: "#83a62f" }} />
                        </IconButton>
                      </Tooltip>
                    </td>
                    <td>
                      <p className="active">Active</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default RecentPurchaseView;
