import axios from "axios";
import { get_All_customers, get_Single_Customers, get_customer_invoice } from "../../Redux/RegisterdList/registerdUserSlice";
import { store } from "../../Redux/Store";
import { HEADERS } from "../urlAndPath";


// getAllCustomers Api

export const getAllCustomers=(setisLoading)=>{
    console.log("registerdUsers api")
    axios.post("customer/viewregisteredusers",{},HEADERS)
    .then((res)=>{

        if(res.status === 200){
            console.log(res)
            store.dispatch(get_All_customers({registerdUserData:res.data}))
            setisLoading(false);
            
        }
    }).catch((err)=>{
        console.log("Something wrongg.....");
    })

};


//getSingleCustomer


export const getSingleCustomer=(body,setLoadingForm)=>{
    setLoadingForm(true)
    axios.post("customer/viewcustomersingle",body,HEADERS)
    .then((res)=>{

        
        if(res.status === 200){
            // console.log(res)
            store.dispatch(get_Single_Customers({singleUserData:res.data}))
            setLoadingForm(false)
        }
        
    }).catch((err)=>{
        console.log("error occured...")
        setLoadingForm(false)
    })

}

// suspend user api call
export const suspendUserApiCall=(body)=>{
    axios.post("admin/suspenduseraccount",body,HEADERS)
    .then((res)=>{
         if(res.status===200){
           console.log('aa');
         }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

export const balancePaymentAPICall=(body,setLoadingForm,setState,state,setBalancePayment,updateListner)=>{
    const singleViewId=localStorage.getItem("singleViewId")
    axios.post("admin/clearbalance",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            console.log('seccess');
            
            setState({...state,message:"Payment Success",success:true})
            getSingleCustomer({_id:singleViewId},setLoadingForm)
            setBalancePayment(false)
            updateListner()
        }
    })
    .catch((err)=>{
        console.log(err.response);
        if (err.response.status === 500) {
            setState({ ...state, message:"Internal Server error !", error: true })
          } else {
            setState({ ...state, message:err?.response?.data,error: true })
          }
    })
}

// reset passowrd
export const resetPasswordAPICall=(body,setState,state,setPasswordReset)=>{
    axios.post("admin/updateUserPassword",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
           console.log('success');
           setState({...state,message:"Success",success:true})
           setPasswordReset(false)
        }
    })
    .catch((err)=>{
        console.log(err.response);
        if (err.response.status === 500) {
            setState({ ...state, message:"Internal Server error !", error: true })
          } else {
            setState({ ...state, message:err?.response?.data,error: true })
          }
    })
}

export const viewInvoiceOfCustomer=(body,setOpenInvoice,setState,state)=>{
    axios.post("admin/invoiceview",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            // console.log('aaa');
            store.dispatch(get_customer_invoice({customerInvoiceData:res.data}))
            setOpenInvoice(true)
        }
    })
    .catch((err)=>{
        if (err.response.status === 500) {
            setState({ ...state, message:"Internal Server error !", error: true })
          } else {
            setState({ ...state, message:err?.response?.data,error: true })
          }
    })
}