import React, { useState } from "react";
import TopContainer from "../../CustomComponent/TopContainer";
import { Autocomplete, Dialog, IconButton, MenuItem, Select, TextField } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";


export const Query=()=>{

    const [showMsg,setShowMsg]=useState(false)

    return(
        <>
        <TopContainer label="Marketing > Enquiry > Query"/>
        <div className="global-container">
        <div className="global-container-table" >
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Status</th>
                        <th>Message</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Shaakirr</td>
                        <td>kallungal123@gmail.com</td>
                        <td>9895610041</td>
                        <td>
                        <div className="global-single-input auto-complete-violet" style={{width:"90%"}}>
                           <Autocomplete
                               options={["Pending"]}
                               getOptionLabel={(option)=>option}
                               renderInput={(params)=>(<TextField {...params} placeholder=""/>)}
                             />
                          </div>
                        </td>
                        <td>
                            <IconButton onClick={()=>setShowMsg(true)}>
                                <i class="bi bi-eye visibility-icon"></i>
                            </IconButton>
                        </td>
                        <td>
                          <IconButton>
                            <EditOutlinedIcon />
                          </IconButton>
                        </td>
                    </tr>
                  
                </tbody>
            </table>
        </div>
        </div>

        <Dialog open={showMsg} maxWidth="lg" onClose={()=>setShowMsg(false)}>
            <div className="query-msg-dialog-container">
                <div className="query-msg-dialog-section">
                    <h3>Message</h3>
                    <hr />
                    <div className="query-message-field">
                        <p>'gstAmount' is assigned a value but never used no-unused-vars Line 18:20:  'setGstAmount' is assigned a value but never used       no-unused-vars
                            Line 19:10:  'gstPerc' is assigned a value but never used            no-unused-vars
                            Line 19:18:  'setGstperc' is assigned a value but never used  is assigned a value but never used no-unused-vars Line 18:20:  'setGstAmount' is assigned a value but never used       no-unused-vars
                            Line 19:10:  'gstPerc' is assigned a value but never used  
                        </p>
                    </div>
                    <button 
                    onClick={()=>setShowMsg(false)}
                    className="global-button-contained-blue">
                        Back
                    </button>
                </div>
            </div>
        </Dialog>
        </>
    )
}