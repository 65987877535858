import React, { useEffect, useState } from 'react'
import TopContainer from '../CustomComponent/TopContainer'
import { Dialog, Icon, IconButton, Skeleton } from '@mui/material'
import '../../../Css/Marketing/customerList.css'
import { deleteCustomersApiCall, viewCustomersList } from '../../../API/Marketing/CustomersList'
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2'
import { deleteAlertMessage } from '../../../Js/generalFunnctionnns'
import SuccessSnackbar from '../../SingleCompoents/SnackBars/SuccessSnackbar'
import ErrorSnackbar from '../../SingleCompoents/SnackBars/ErrorSnackbar'

export const CustomerList = () => {
  const {loading,error,data} = useSelector((state)=>state.MarketinSlice)
  console.log(data)

  const [singleView,setSingleView]=useState(false);
  const [modify,setModify] = useState(false);
  const [successSnackbaropen,setSuccessSnackbaropen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("oops!!");
  const [erroSnackOpen,setErrorSnackOpen] = useState(false)


  // const [isLoading,setisLoading] = useState(false);

  const closeErrorSnackbar=()=>{
    setErrorSnackOpen(false)
  }

  const closeSuccessSnackbar =()=>{
    setSuccessSnackbaropen(false);
  }
// delete Api call

  const deleteCustomer =(id)=>{

    deleteCustomersApiCall(id,updateListner,setSuccessSnackbaropen,setSnackMsg,setErrorSnackOpen)
  }

  // updateListener
  const updateListner=()=>{
    setModify(!modify)
    
  }


   const data1=[
    {"status":"Approved"},
    {"status":"Rejected"},
    {"status":"Registered"},
   ]



//useEffect
useEffect(()=>{
  viewCustomersList()
},[modify])


  return (
    <>
     <TopContainer label="Marketing > Customer List" />
     <div className='global-container'>
       <div className="offers-listed-topbar-container">
          <h3>Customer Listed</h3>
        </div>
        <div className="global-container-table  offers-listed-table">
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Date</th>
                <th>Customer Name</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Package</th>
                <th>Modules</th>
                <th>Company Name</th>
                <th>Status</th>
                <th>View</th>
                <th>Delete</th>
              </tr>
            </thead>
            {loading ?(
              <tbody>
                  <tr>
                    <td colSpan={11}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={11}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={11}>
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={"100%"}
                        height={30}
                      />
                    </td>
                  </tr>
                </tbody>
            ):(
              <tbody>
                {
                  data?.map((r,i)=>(
                    <tr>
                    <td>{i+1}</td>
                    <td>{r?.date}</td>
                    <td>{r?.customerName}</td>
                    <td>{r?.contact}</td>
                    <td>{r?.email}</td>
                    <td>{r?.package}</td>
                    <td>{r?.module}</td>
                    <td>{r?.companyName}</td>
                    <td><span
                      className={
                        r?.status === "Approved"?
                        'status-appproved'
                        :r?.status === "Rejected"?
                        'status-rejected'
                        :r?.status === 'Registered'?
                        'status-registered'
                        :undefined
                      }
                    >{r?.status}</span></td>
                    <td>
                        <IconButton onClick={()=>setSingleView(true)}>
                           <i class="bi bi-eye visibility-icon"></i>
                        </IconButton>
                    </td>
                    <td>
                       <IconButton onClick={()=>deleteAlertMessage(r._id,deleteCustomer)}>
                       <i class="bi bi-trash3 delete-icon"></i>
                       </IconButton>
                    </td>
                    </tr>
                  ))
                }
              </tbody>

            )}

              
          </table>
        </div>
     </div>

     {/* single view */}
     <Dialog open={singleView} maxWidth="lg">
        <div className='customer-list-single-view-container'>
           <div className='customer-list-single-view-sub-container'>
             <h3>View</h3>
             <hr />
             <div className='customer-list-single-view-input-container'>
              <div className='global-single-input' style={{width:"48%"}}>
                 <p>MeetUp Location</p>
                 <input type="text" />
              </div>
              <div className='global-single-input' style={{width:"48%"}}>
                 <p>Appoinment Date&Time</p>
                 <input type="text" />
              </div>
              <div className='global-single-input' style={{width:"48%"}}>
                 <p>Meet Count</p>
                 <input type="text" />
              </div>
             </div>
             <div style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"2% 0"}}>
             <button className='global-button-contained-black' onClick={()=>setSingleView(false)}>Close</button>
             </div>
             
           </div>
         
        </div>
     </Dialog>

     <SuccessSnackbar
        open={successSnackbaropen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />

      <ErrorSnackbar
        open={erroSnackOpen}
        handleClose={closeErrorSnackbar}
        message={snackMsg}
      />
    </>
  )
}
