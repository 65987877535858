import { Autocomplete, Dialog, IconButton, TextField } from '@mui/material'
import React, { useState } from 'react'
import "../../../../../Css/Staff/HRM/empLeave.css"
import CustomImageUpload from '../../../CustomComponent/CustomImageUpload'
export const EmpLeaveDetails = () => {

  const [viewSingleLeave,setViewSingleLeave]=useState(false)
  const [applyForLeave,setApplyForLeave]=useState(false)

  const clickApplyForLeave=()=>{
    setApplyForLeave(true)
  }
  return (
    <>
    <div className="global-container">
      <div className="global-container-table">
        <div className="global-top-container-head-section">
          <div className="emp-leave-detail-top-ctn">
              <div className="emp-leave-detail-card" style={{backgroundColor:"rgb(20, 145, 20)"}}>Paid Leave &nbsp;&nbsp;2/3</div>
              <div className="emp-leave-detail-card" style={{backgroundColor:"rgb(255, 0, 0)"}}>Sick Leave &nbsp;&nbsp;2/10</div>
              <div className="emp-leave-detail-card" style={{backgroundColor:"rgb(255, 87, 0)"}}>Unpaid Leave &nbsp;&nbsp;2/30</div>
          </div>
          <button 
          onClick={clickApplyForLeave}
          className="global-button-contained-blue emp-leave-apply-button">Apply For Leave</button>
        </div>
        <div className="global-top-container-head-section">
          <h3>Leave Summary</h3>
          <div className='dashboard-employee-leave-details-search-container'>
             <input type="text" placeholder='Search...'/>
             <button>Search</button>
         </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Leave Type</th>
              <th>Date From</th>
              <th>Date To</th>
              <th>Days</th>
              <th>Applied On</th>
              <th>Status</th>
              <th>View</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Sick</td>
              <td>02-06-2000</td>
              <td>02-07-2001</td>
              <td>395</td>
              <td>03-02-2022</td>
              <td><span className='status-span pending'>Pending</span></td>
              <td>
                <IconButton onClick={()=>setViewSingleLeave(true)}>
                  <i class="bi bi-eye visibility-icon"></i>
                </IconButton>
              </td>
              <td>
                <IconButton>
                    <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td>Sick</td>
              <td>02-06-2000</td>
              <td>02-07-2001</td>
              <td>395</td>
              <td>03-02-2022</td>
              <td><span className='status-span rejected'>Rejected</span></td>
              <td>
                <IconButton onClick={()=>setViewSingleLeave(true)}>
                  <i class="bi bi-eye visibility-icon"></i>
                </IconButton>
              </td>
              <td>
                <IconButton>
                    <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
            <tr>
              <td>1</td>
              <td>Sick</td>
              <td>02-06-2000</td>
              <td>02-07-2001</td>
              <td>395</td>
              <td>03-02-2022</td>
              <td><span className='status-span accepted'>Accepted</span></td>
              <td>
                <IconButton onClick={()=>setViewSingleLeave(true)}>
                  <i class="bi bi-eye visibility-icon"></i>
                </IconButton>
              </td>
              <td>
                <IconButton>
                    <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Dialog open={viewSingleLeave} onClose={()=>setViewSingleLeave(false)} maxWidth="lg">
        <div className="leave-application-view-container">
            <div className="leave-application-view-section">
            <div className="leave-application-view-container-top">
                <h3>Leave Application</h3>
            </div>
            <div className="leave-application-view-container-body">
                <p>I will be on leave from [start date] to [end date] due to personal reasons. I have arranged for a smooth transition of my responsibilities. Please let me know if there are any specific procedures or requirements for leave approval.
                    <br />
                    <br />
                    Thank you for your understanding.
                    <br />
                    Sincerely,
                    [Your Name]</p>
            </div>
            <div className="leave-application-view-container-button">
                <span className="status-span accepted">Accepted</span>                          
            </div>
          </div>
        </div>
    </Dialog>

    <Dialog open={applyForLeave} maxWidth="lg">
        <div className="leave-application-view-container">
          <div className="apply-leave-section">
            <div className="global-top-container-head-section">
              <h3>Apply For Leave</h3>
              <p className='apply-leave-top-para'>Selected Days : <span>0</span></p>
            </div>
            <hr />
            <div className="global-single-input auto-complete">
              <p>Select Leave Type</p>
              <Autocomplete
                options={["sick","casual"]}
                getOptionLabel={(option)=>option}
                renderInput={(params)=>(
                  <TextField {...params}/>
                )}
              />
            </div>
            <div className="global-single-input">
              <p>Leave Date From</p>
              <input type="date" />
            </div>
            <div className="global-single-input">
              <p>Leave Date To</p>
              <input type="date" />
            </div>
            <div className="global-single-input">
              <p>Reason</p>
              <textarea/>
            </div>
            <div className="global-single-input">
              <p>Document Upload</p>
              <CustomImageUpload/>
            </div>
            <div className="apply-leave-btn-ctn">
              <button 
              onClick={()=>setApplyForLeave(false)}
              className="global-button-contained-black-outlined">Cancel</button>
              <button className="global-button-contained-blue">Submit</button>
            </div>
          </div>
        </div>
    </Dialog>
    </>
  )
}
