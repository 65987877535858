import React, { useState } from 'react'
import TopContainer from '../../CustomComponent/TopContainer'
import { Autocomplete, TextField } from '@mui/material'
import "../../../../Css/Staff/Payroll/addPayroll.css"
export const AddPayroll = () => {

  const [addPayrolllPayment,setAddPayrollPayment]=useState(false)


  const clickPayment=()=>{
        setAddPayrollPayment(true)
    }
  return (
    <>
      <TopContainer label="Payroll > Add Payroll"/>

      <div className="staff-add-payroll-container">
                <div className="staff-add-payroll-top-ctn">
                    <h3>Payroll Voucher</h3>
                    <div className="staff-add-payroll-top-ctn-input-field">
                        <div className="global-single-input add-payroll-input">
                            <p>Voucher No</p>
                            <input type="text" disabled/>
                        </div>
                        <div className="global-single-input add-payroll-input">
                            <p>Date</p>
                            <input type="date" 
                            />
                        </div>
                        <div className="global-single-input add-payroll-input auto-complete">
                            <p>Employee Id</p>
                            <Autocomplete
                             sx={{width:"100%"}}
                             options={["empuran","ludif"]}
                             getOptionLabel={(option)=>option}
                             renderInput={(params)=>
                                <TextField {...params}/>
                                }
                            />
                        </div>
                        <div className="global-single-input add-payroll-input auto-complete">
                            <p>Employee</p>
                            <Autocomplete
                             sx={{width:"100%"}}
                             options={["allActiveEmpList","benu"]}
                             getOptionLabel={(option)=>option}
                             renderInput={(params)=>
                                <TextField {...params}/>
                                }/>
                        </div>
                    </div>
                    <div className="staff-add-payroll-top-ctn-btn-ctn">
                        <button className="global-button-contained-black-outlined">Clear</button>
                        <button className="global-button-contained-blue">Submit</button>
                    </div>
                </div>
                <div className="staff-add-payroll-body-ctn">
                <div className="staff-add-payroll-body-container-parent">  
                    <div className="staff-add-payroll-body-section">
                        <h4>Salary Information</h4>
                        <p className="add-payroll-body-earning-p">Earnings</p>
                        <div className="staff-add-payroll-body-section-input-field">
                            <div className="global-single-input">
                                <p>Basic Salary</p>
                                <input type="text" placeholder="0.00"
                                />
                            </div>
                            <div className="global-single-input">
                                <p>HRA Allowance</p>
                                <input type="text" placeholder="0.00"
                                />
                            </div>
                            <div className="global-single-input">
                                <p>DA Allowance</p>
                                <input type="text" placeholder="0.00"
                                />
                            </div>
                            <div className="global-single-input">
                                <p>Bonus</p>
                                <input type="text" placeholder="0.00"/>
                            </div>
                            <div className="global-single-input">
                                <p>Travel Allowance</p>
                                <input type="text" placeholder="0.00"/>
                            </div>
                            <div className="global-single-input">
                                <p>Education Allowance</p>
                                <input type="text" placeholder="0.00"/>
                            </div>
                            <div className="global-single-input">
                                <p>Over Time(H)</p>
                                <input type="text" placeholder="0.00"/>
                            </div>
                        </div>
                        <div className="staff-add-payroll-body-section-bottom-ctn">
                            <hr className="global-hr"/>
                            <div className="global-single-input auto-complete">
                                <p>Other Allowance</p>
                                <Autocomplete
                                 sx={{width:"100%"}}
                                 options={["allowanceList,lokes"]}
                                 getOptionLabel={(option)=>option}
                                 renderInput={(params)=>
                                    <TextField {...params}/>
                                 } />
                            </div>
                            {/* {
                             selectedAllowance!==null &&
                            <div className="payroll-body-section-bottom-ctn-extra">
                              <p>{selectedAllowance}</p>
                              <div className="global-single-input">
                                  <input type="text" placeholder="0.00" onChange={addPayrollOnChange("otherAllowance")} 
                                     value={addPayrollBody.otherAllowance}/>
                              </div>
                            </div>
                            } */}
                        </div>
                    </div>
                    <div className="staff-add-payroll-body-section-footer">
                        <p>Total Earnings</p>
                        <div className="global-single-input">
                            <input type="text" placeholder="0.00"
                            />
                        </div>
                    </div>
                   </div> 
                   <div className="staff-add-payroll-body-container-parent"> 
                    <div className="staff-add-payroll-body-section">
                        <h4>Salary Information</h4>
                        <p className="add-payroll-body-earning-p" style={{color:"red"}}>Deductions</p>
                        <div className="staff-add-payroll-body-section-input-field">  
                            <div className="staff-add-payroll-body-section-input-two">
                                <div className="global-single-input">
                                    <p>Loan</p>
                                    <input type="text"  placeholder="0.00"/>
                                </div>
                                <div className="global-single-input" style={{marginRight:0}}>
                                    <p>EMI (Monthly)</p>
                                    <input type="text" placeholder="0.00" />
                                </div>
                            </div>
                            <div className="global-single-input">
                                <p>EPF</p>
                                <input type="text"  placeholder="0.00"/>
                            </div>
                            <div className="global-single-input">
                                <p>ESI</p>
                                <input type="text" placeholder="0.00"/>
                            </div>
                            <div className="global-single-input">
                                <p>TDS</p>
                                <input type="text" placeholder="0.00"/>
                            </div>
                            <div className="global-single-input">
                                <p>Leave</p>
                                <input type="text" placeholder="0.00" />
                            </div>
                            <div className="global-single-input">
                                <p>Medical Claim</p>
                                <input type="text" placeholder="0.00"/>
                            </div>
                        </div>
                        <div className="staff-add-payroll-body-section-bottom-ctn">
                            <hr className="global-hr" />
                            <div className="global-single-input auto-complete">
                                <p>Other Deductions</p>
                                <Autocomplete
                                 sx={{width:"100%"}}
                                 options={["srk","dfs"]}
                                 getOptionLabel={(option)=>option}
                                 renderInput={(params)=>
                                    <TextField {...params}/>
                                 }
                                //  onChange={addPayrollOnChange("otherDeductionSelect")}
                                />
                            </div>
                            {/* {
                            selectedDeduction!==null &&
                            <div className="payroll-body-section-bottom-ctn-extra">
                                <p>{selectedDeduction}</p>
                                <div className="global-single-input">
                                    <input type="text" placeholder="0.00" value={addPayrollBody.otherDeduction}
                                        onChange={addPayrollOnChange("otherDeduction")}
                                    />
                                </div>
                            </div>
                            } */}
                        </div>
                    </div>
                        <div className="staff-add-payroll-body-section-footer">
                                <p>Total Deduction</p>
                                <div className="global-single-input">
                                    <input type="text" placeholder="0.00" disabled
                                    />
                                </div>
                        </div>
                   </div> 
                </div>
                <div className="staff-add-payroll-footer-ctn">
                    <div className="staff-add-payroll-footer-ctn-section">
                        <div className="staff-add-payroll-footer-ctn-left-section global-single-input">
                            <div className="staff-add-payroll-footer-ctn-right">
                                <h4>Total Payable Salary</h4>
                            </div>
                            <input type="text" disabled placeholder="0.00" />
                        </div>
                        <div className="staff-add-payroll-footer-ctn-left-section global-single-input">
                            <div className="staff-add-payroll-footer-ctn-right">
                                <p>Paid Amount</p>
                            </div>
                            <input type="text"  placeholder="0.00" 
                            />
                        </div>
                        <div className="staff-add-payroll-footer-ctn-left-section global-single-input">
                            <div className="staff-add-payroll-footer-ctn-right">
                                <p>Balance Amount</p>
                            </div>
                            <input type="text" disabled placeholder="0.00"
                            />
                        </div>
                    </div>
                </div>
                <div className="staff-add-payroll-button-ctn" style={{position:"relative"}}>
                    <button  className="global-button-contained-black-outlined">Clear</button>
                    <button className="global-button-contained-blue" style={{marginRight:0}} onClick={clickPayment}>Payment</button>
                </div>
            </div>   
    </>
  )
}
