import { IconButton, Tooltip } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import TopContainer from "../CustomComponent/TopContainer";

 export const UserPackage = () => {

  return (
    <>
      <TopContainer label='SMS > User List'/>
      <div className="global-container reg-user-container">
        <div className="global-container-table">
          <table>
            <thead>
              <tr>
                <th>Customer ID</th>
                <th>Customer Name</th>
                <th>Contact</th>
                <th>ERP Package</th>
                <th>Country</th>
                <th>SMS Provider</th>
                <th>Puchase Quantity</th>
                <th>Price</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PKDGS12345</td>
                <td>ajaaasss</td>
                <td>+91 9998889990</td>
                <td>Restaurant</td>
                <td>India</td>
                <td>Jio</td>
                <td>500</td>
                <td>1000</td>
                <td>11-07-22</td>
                <td>10-12-22</td>              
              </tr>
              <tr>
                <td>PKDGS12345</td>
                <td>ajaaasss</td>
                <td>+91 9998889990</td>
                <td>Restaurant</td>
                <td>India</td>
                <td>Jio</td>
                <td>500</td>
                <td>1000</td>
                <td>11-07-22</td>
                <td>10-12-22</td>              
              </tr>
              <tr>
                <td>PKDGS12345</td>
                <td>ajaaasss</td>
                <td>+91 9998889990</td>
                <td>Restaurant</td>
                <td>India</td>
                <td>Jio</td>
                <td>500</td>
                <td>1000</td>
                <td>11-07-22</td>
                <td>10-12-22</td>              
              </tr>
              <tr>
                <td>PKDGS12345</td>
                <td>ajaaasss</td>
                <td>+91 9998889990</td>
                <td>Restaurant</td>
                <td>India</td>
                <td>Jio</td>
                <td>500</td>
                <td>1000</td>
                <td>11-07-22</td>
                <td>10-12-22</td>              
              </tr>
              <tr>
                <td>PKDGS12345</td>
                <td>ajaaasss</td>
                <td>+91 9998889990</td>
                <td>Restaurant</td>
                <td>India</td>
                <td>Jio</td>
                <td>500</td>
                <td>1000</td>
                <td>11-07-22</td>
                <td>10-12-22</td>              
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
    </>
  );
};

export default UserPackage;

