/** @format */

import React, { useEffect, useState } from "react";
import "../../../Css/HomeDashboard/puchase.css";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import {
  IconButton,
  Skeleton,
  Dialog,
  Autocomplete,
  TextField,
} from "@mui/material";

import sideMenuList from "../../../../src/Assets/JSON//sidemenuList.json";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { QuickAccess } from "./QuickAccess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
// import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { convertDateFormat } from "../../../Js/Date";
import { top100Films } from "../Sms/dummydata";
import {
  addQuickAccessAPICall,
  viewAllQuickAccess,
} from "../../../API/HomeDashboard/homeDashboardAPI";
import { useSelector } from "react-redux";

export const Purchase = ({ dashBoardData, isLoading }) => {
  //view Quick Access

  const viewQuickAccess = useSelector((state) => state.quickAccessSlice.value);
  console.log("ViewQuickAccess----->", viewQuickAccess);

 

  //Update Listener States
  const [isModify, setIsModify] = useState(false);

  //openQuick Access State
  const [openQuickAccessDialog, setOpenQuickAccessDialog] = useState(false);

  //Quick Access States
  const [name1, setName1] = useState();
  const [name2, setName2] = useState();
  const [name3, setName3] = useState();

  const [section1, setSection1] = useState();
  const [section2, setSection2] = useState();
  const [section3, setSection3] = useState();
  // console.log(name1,name2,name3)

  const [quickAccess1, setQuickAccess1] = useState();
  const [quickAccess2, setQuickAccess2] = useState();
  const [quickAccess3, setQuickAccess3] = useState();
  console.log("%%%%%", name1, section1);

  const data = {
    labels: ["Active Customers", "Renewal"],
    datasets: [
      {
        data: [80, 30],
        backgroundColor: ["#1100ff", "#ff2400"],
        radius: 100,
        // borderWidth:30
      },
    ],
  };

  //Quick Access
  const addQuickAccess = () => {
    setOpenQuickAccessDialog(true);
  };

  // Update Listener
  const updateListener = () => {
    setIsModify(!isModify);
  };

  //view Quick access
  useEffect(() => {
    viewAllQuickAccess();
  }, [isModify]);

  useEffect(() => {
    if (viewQuickAccess !== undefined) {
      setQuickAccess1(viewQuickAccess?.access[0]?.url);
      setQuickAccess2(viewQuickAccess?.access[1]?.url);
      setQuickAccess3(viewQuickAccess?.access[2]?.url);
      console.count("NO:");
      setName1(viewQuickAccess?.access[0]?.shortCutName);
      setName2(viewQuickAccess?.access[1]?.shortCutName);
      setName3(viewQuickAccess?.access[2]?.shortCutName);
    }
  }, [viewQuickAccess]);

  useEffect(() => {
    sideMenuList.map((item, i) => {
      if (item.path === quickAccess1) {
        setName1(item?.name);
        setSection1(item?.section);
      }
      if (item.path === quickAccess2) {
        setName2(item?.name);
        setSection2(item?.section);
      }
      if (item.path === quickAccess3) {
        setName3(item?.name);
        setSection3(item?.section);
      }
    });
  }, [quickAccess1, quickAccess2, quickAccess3]);

  // QuickAccess Fun...

  const QuickAccessFun = (e, newValue) => {
    console.log(e);
    console.log(newValue);
    setQuickAccess1(newValue?.path);
  };

  //add Quick access api call body..
  const bodyToApi = {
    access: [
      {
        shortCutName: name1,
        url: quickAccess1,
      },
      {
        shortCutName: name2,
        url: quickAccess2,
      },
      {
        shortCutName: name3,
        url: quickAccess3,
      },
    ],
  };

  //addQuickAccessSubmitBtn
  const addQuickAccessSubmitBtn = () => {
    console.log("addQuickAccessSubmitBtn....");
    if (
      quickAccess1 !== undefined &&
      quickAccess2 !== undefined &&
      quickAccess3 !== undefined
    ) {
      addQuickAccessAPICall(bodyToApi, updateListener);
      setOpenQuickAccessDialog(false);
    } else {
      alert("Add All Quick Access...");
    }
  };

  return (
    <>
      <div className="middle-left-container">
        <div className="new-global-table-container">
          <table>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Name</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Package</th>
                <th>Status</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={10}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={"100%"}
                      height={30}
                    />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {dashBoardData?.recentPurchases?.map((r, i) => (
                  <tr key={i}>
                    <td>{r?.purchaseId}</td>
                    <td>{r?.customerName}</td>
                    <td>{r?.contact}</td>
                    <td>---</td>
                    <td>{r?.package}</td>
                    <td className="recent-purchase-status">
                      <p className="status-active">Active</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
      <div className="middle-right-container">
        <div className="middle-right-top-container">
          <p className="right-top-head">ERP Purchase Status</p>
          <div className="chart-container">
            <Doughnut
              data={data}
              options={{
                cutout: 100,
                aspectRatio: true,
                plugins: {
                  legend: {
                    display: true,
                    position: "bottom",
                    labels: {
                      boxWidth: 18,
                      boxHeight: 18,
                    },
                    maxHeight: 25,
                  },
                },
              }}
            ></Doughnut>
          </div>
        </div>
        <div className="middle-right-bottom-container">
          <div className="middle-right-bottom-first-container">
            <p>Quick Access</p>
            <div className="edit-icon-container" onClick={addQuickAccess}>
              <FontAwesomeIcon icon={faPenToSquare} color="#8373f1" size="s" />
            </div>
          </div>

          {viewQuickAccess?.access?.map((item, i) => (
            <QuickAccess
              backgroundColor={
                i === 0
                  ? "#d0ebef"
                  : i == 1
                  ? "#dbf0e2"
                  : i === 2
                  ? "#ffddc3"
                  : undefined
              }
              label={item?.shortCutName}
              path={item?.url}
              color="#003c7e"
            />
          ))}


        </div>
      </div>
      <Dialog
        open={openQuickAccessDialog}
        maxWidth="lg"
        className="QuickAccess-dialog-box"
        onKeyDown={(e) => e.key === "Escape" && setOpenQuickAccessDialog(false)}
      >
        <div className="quick-access-dialog-container">
          <h3>Add Quick Access</h3>
          <hr className="global-hr" />
          <div className="quick-access-dialog-select">
            <div className="quick-access-input quick-access-edit auto-complete">
              <Autocomplete
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#d0ebef",
                }}
                options={sideMenuList || [""]}
                getOptionLabel={(option) =>
                  `${option?.section} >> ${option?.name}`
                }
                defaultValue={{ section: section1, name: name1 }}
                renderInput={(params) => <TextField {...params} size="small" />}
                // onChange={(e,newValue)=>setQuickAccess1(newValue?.path)}
                onChange={(e, newValue) => QuickAccessFun(e, newValue)}
              />
            </div>
            <div className="quick-access-input quick-access-edit auto-complete">
              <Autocomplete
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#ffddc3",
                }}
                defaultValue={{ section: section2, name: name2 }}
                options={sideMenuList || [""]}
                getOptionLabel={(option) =>
                  `${option?.section} >> ${option?.name}`
                }
                renderInput={(params) => <TextField {...params} size="small" />}
                onChange={(e, newValue) => setQuickAccess2(newValue?.path)}
              />
            </div>
            <div className="quick-access-input quick-access-edit auto-complete">
              <Autocomplete
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#cfc3ee",
                }}
                defaultValue={{ section: section3, name: name3 }}
                options={sideMenuList || [""]}
                getOptionLabel={(option) =>
                  `${option?.section} >> ${option?.name}`
                }
                renderInput={(params) => <TextField {...params} size="small" />}
                onChange={(e, newValue) => setQuickAccess3(newValue?.path)}
              />
            </div>
          </div>
          <div className="quick-access-edit-button">
            <button
              onClick={() => setOpenQuickAccessDialog(false)}
              className="quick-access-edit-cancel-button"
            >
              Cancel
            </button>
            <button
              onClick={addQuickAccessSubmitBtn}
              className="quick-access-edit-submit-button"
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};
