

export const LogoutAdmin =()=>{
    console.log("log out the admin clicked....")
    if(window.confirm("Are u ready for log out ")){
        console.log("ok")
        localStorage.clear()
        window.location.replace("/")
    }

  }