import React, { useState } from "react";
import TopContainer from "../CustomComponent/TopContainer";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import pakages from "../../../Assets/JSON/ErpList1.json";
import ModuleList from "../../../Assets/JSON/Module_List.json";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { addCustomer } from "../../../API/Marketing/CustomersList";
import SuccessSnackbar from "../../SingleCompoents/SnackBars/SuccessSnackbar";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const AddCustomer = () => {
  //states
  const [DateAlert, setDateAlert] = useState(false);
  const [CustomerNameAlert, setCustomerNameAlert] = useState(false);
  const [contactAlert, setContactAlert] = useState(false);
  const [EmailAlert, setEmailAlert] = useState(false);
  const [packageAlert, setPackageAlert] = useState(false);
  const [ModuleAlert, setModuleAlert] = useState(false);
  const [companyNameAlert, setCompanyNameAlert] = useState(false);
  const [MeetUpLocationAlert, setMeetUpLocationAlert] = useState(false);
  const [AppoinemtDateandTimeAlert, setAppoinemtDateandTimeAlert] =
    useState(false);
  const [MeetUpCountAlert, setMeetUpCountAlert] = useState(false);
  const [StatusAlert, setStatusAlert] = useState(false);
  const [successSnackbaropen, setSuccessSnackbaropen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("Oops!!");

  const PersonalInformation = {
    Date: "",
    CustomerName: "",
    Contact: "",
    Email: "",
    Package: [],
    Module: [],
    CompanyName: "",
    MeetUpLocation: "",
    AppoinemtDateandTime: "",
    MeetUpCount: "",
    Status: null,
  };


  const [addCustomers, setaddCustomers] = useState(PersonalInformation);
  console.log(addCustomers.Package);

  //getPersonalInfoForm
  const getPersonalInfoForm = (e, newValue, key) => {
    const value = newValue || e.target.value || "";
    console.log(value);
    if (key === "Date") {
      setaddCustomers({ ...addCustomers, Date: value });
      setDateAlert(false);
      if (value === "") {
        setDateAlert(true);
      }
    }

    if (key === "CustomerName") {
      setaddCustomers({ ...addCustomers, CustomerName: value });
      setCustomerNameAlert(false);
      if (value === "") {
        setCustomerNameAlert(true);
      }
    }

    if (key === "contact") {
      setaddCustomers({ ...addCustomers, Contact: value });
      setContactAlert(false);
      if (value === "") {
        setContactAlert(true);
      }
    }

    if (key === "Email") {
      setaddCustomers({ ...addCustomers, Email: value });
      setEmailAlert(false);
      if (value === "") {
        setEmailAlert(true);
      }
    }

    if (key === "Package") {
      console.log("7777");
      setaddCustomers({ ...addCustomers, Package: value });
      setPackageAlert(false);
      if (value === "") {
        console.log("qqq");
        setPackageAlert(true);
      }
    }
    if (key === "Module") {
      setaddCustomers({ ...addCustomers, Module: value });
      setModuleAlert(false);
      if (value === "") {
        setPackageAlert(true);
      }
    }

    if (key === "CompanyName") {
      setaddCustomers({ ...addCustomers, CompanyName: value });
      setCompanyNameAlert(false);
      if (value === "") {
        setCompanyNameAlert(true);
      }
    }
    if (key === "MeetUpLocation") {
      setaddCustomers({ ...addCustomers, MeetUpLocation: value });
      setMeetUpLocationAlert(false);
      if (value === "") {
        setMeetUpLocationAlert(true);
      }
    }

    if (key === "AppoinemtDateandTime") {
      setaddCustomers({ ...addCustomers, AppoinemtDateandTime: value });
      setAppoinemtDateandTimeAlert(false);
      if (value === "") {
        setAppoinemtDateandTimeAlert(true);
      }
    }
    if (key === "MeetUpCount") {
      setaddCustomers({ ...addCustomers, MeetUpCount: value });
      setMeetUpCountAlert(false);
      if (value === "") {
        setMeetUpCountAlert(true);
      }
    }
    if (key === "Status") {
      setaddCustomers({ ...addCustomers, Status: value });
      setStatusAlert(false);
      if (value === "") {
        setStatusAlert(true);
      }
    }
  };

  const addCustomersSubmitBtn = () => {
    const {
      Date,
      CustomerName,
      Contact,
      Email,
      Package,
      Module,
      CompanyName,
      MeetUpLocation,
      AppoinemtDateandTime,
      MeetUpCount,
      Status,
    } = addCustomers;
    console.log(Date);
    if (Date === "") {
      setDateAlert(true);
    } else if (CustomerName === "") {
      setCustomerNameAlert(true);
    } else if (Contact === "") {
      setContactAlert(true);
    } else if (Email === "") {
      setEmailAlert(true);
    } else if (Package === null) {
      setPackageAlert(true);
    } else if (Module === null) {
      setModuleAlert(true);
    } else if (CompanyName === "") {
      setCompanyNameAlert(true);
    } else if (MeetUpLocation === "") {
      setMeetUpLocationAlert(true);
    } else if (AppoinemtDateandTime === "") {
      setAppoinemtDateandTimeAlert(true);
    } else if (MeetUpCount === "") {
      setMeetUpCountAlert(true);
    } else if (Status === null) {
      setStatusAlert(true);
    } else {
      addCustomer({
        customerName: CustomerName,
        contact:Contact,
        email: Email,
        package: Package,
        module: Module,
        companyName:CompanyName,
        meetUpLocation: MeetUpLocation,
        appointmentDate: AppoinemtDateandTime,
        meetCount: MeetUpCount,
        status: Status,
        date:Date,
      },setSuccessSnackbaropen,setSnackMsg,clearState,successSnackbaropen);
    }
  };

    // closeSuccessSnackbar
    const closeSuccessSnackbar = () => {
      setSuccessSnackbaropen(false);
    };

    // clear state
    const clearState =()=>{
      setaddCustomers(
        PersonalInformation
      )
    }

  return (
    <>
      <TopContainer label="Marketing > Add Customer" />
      <div className="global-container package-plans-parent">
        <h3>Add Customer</h3>
        <hr />
        <div className="add-package-form">
          <div className="global-single-input">
            <p>Date</p>
            <input
              type="date"
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "Date")
              }
              value={addCustomers?.Date}
            />
            {DateAlert && <p className="validation-alert">Select Date</p>}
          </div>
          <div className="global-single-input">
            <p>Customer Name</p>
            <input
              type="text"
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "CustomerName")
              }
              value={addCustomers?.CustomerName}
            />
            {CustomerNameAlert && (
              <p className="validation-alert">Enter Customer Name</p>
            )}
          </div>
          <div className="global-single-input global-single-input-no">
            <p>Contact</p>
            <input
              type="number"
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "contact")
              }
              value={addCustomers?.Contact}
            />
            {contactAlert && (
              <p className="validation-alert">Enter Contact No </p>
            )}
          </div>
          <div className="global-single-input">
            <p>Email</p>
            <input
              type="email"
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "Email")
              }
              value={addCustomers?.Email}
            />
            {EmailAlert && <p className="validation-alert">Enter Email</p>}
          </div>

          <div className="global-single-input auto-complete">
            <p>Package</p>
            <Autocomplete
              multiple
              sx={{ width: "100%" }}
              options={pakages || [""]}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Package" />
              )}
              
              value={addCustomers?.Package}
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "Package")
              }
            />
            {packageAlert && <p className="validation-alert">Select Package</p>}
          </div>

          <div className="global-single-input auto-complete">
            <p>Modules</p>
            {/* <input type="text" /> */}
            <Autocomplete
              multiple
              sx={{ width: "100%" }}
              options={ModuleList || [""]}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Module" />
              )}
              value={addCustomers?.Module}
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "Module")
              }
            />
            {ModuleAlert && <p className="validation-alert">Select Module</p>}
          </div>
          <div className="global-single-input">
            <p>Company Name</p>
            <input
              type="text"
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "CompanyName")
              }
              value={addCustomers?.CompanyName}
            />
            {companyNameAlert && (
              <p className="validation-alert">Enter Company Name</p>
            )}
          </div>
          <div className="global-single-input">
            <p>MeetUp Location</p>
            <input
              type="text"
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "MeetUpLocation")
              }
              value={addCustomers?.MeetUpLocation}
            />
            {MeetUpLocationAlert && (
              <p className="validation-alert">Enter Location</p>
            )}
          </div>
          <div className="global-single-input">
            <p>Appoinment Date&Time</p>
            <input
              type="Date"
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "AppoinemtDateandTime")
              }
              value={addCustomers?.AppoinemtDateandTime}
            />
            {AppoinemtDateandTimeAlert && (
              <p className="validation-alert">Enter Appoinment Dates & Time</p>
            )}
          </div>
          <div className="global-single-input global-single-input-no">
            <p>Meet Count</p>
            <input
              type="number"
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "MeetUpCount")
              }
              value={addCustomers?.MeetUpCount}
            />
            {MeetUpCountAlert && (
              <p className="validation-alert">Enter Meet Count</p>
            )}
          </div>
          <div className="global-single-input auto-complete">
            <p>Status</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={["Approve", "Pending", "Reject"] || [""]}
              getOptionLabel={(option) => option}
              renderInput={(params) => <TextField {...params} />}
              onChange={(e, newValue) =>
                getPersonalInfoForm(e, newValue, "Status")
              }
              value={addCustomers?.Status}
            />

            {StatusAlert && <p className="validation-alert">Select Status</p>}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button className="global-button-contained-black-outlined" onClick={clearState}>
            Cancel
          </button>
          <button
            className="global-button-contained-blue"
            onClick={addCustomersSubmitBtn}
          >
            Submit
          </button>
        </div>
      </div>
      <SuccessSnackbar
          open={successSnackbaropen}
          handleClose={closeSuccessSnackbar}
          message={snackMsg}
      />
    </>
  );
};
