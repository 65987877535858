import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import "../../../../../Css/Staff/HRM/empBankDetails.css"
export const EmpBankDetails = () => {
  return (
    <div className='emp-bank-detail-container'>
      <div className="emp-bank-detail-input-section">
        <div className="global-single-input">
          <p>Account holder</p>
          <input type="text" />
        </div>
        <div className="global-single-input">
          <p>Account Number</p>
          <input type="text" />
        </div>
        <div className="global-single-input auto-complete">
          <p>Bank Name</p>
          <Autocomplete
            options={["SBI","sibi","kallumav bank"]}
            getOptionLabel={(option)=>option}
            renderInput={(params)=>(
              <TextField {...params}/>
            )}
          />
        </div>
        <div className="global-single-input">
          <p>IFSC / IBAN</p>
          <input type="text" />
        </div>
        <div className="global-single-input">
          <p>Branch Location</p>
          <input type="text" />
        </div>
        <div className="global-single-input">
          <p>PAN / Income Tax Code</p>
          <input type="text" />
        </div>
      </div>
      <button className='global-button-contained-blue' style={{marginTop:"2%"}}>
        Update
      </button>
    </div>
  )
}
