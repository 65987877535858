import { createSlice } from "@reduxjs/toolkit";

export const manageEmployeeSlice=createSlice({
    name:"manageEmployeeSlice",
    initialState:{
        value:undefined,
        value1:undefined,
        value2:undefined,
        value3:undefined,
        value4:undefined
    },
    reducers:{
        get_inactive_employee:(state,action)=>{
            state.value=action.payload.inactiveEmployee
        },
        get_qrcode:(state,action)=>{
            state.value1=action.payload.qrcodeData
        },
        save_company_info:(state,action)=>{
            state.value2=action.payload.companyInfoData
        },
        view_all_branch:(state,action)=>{
           state.value3=action.payload.branchList
        },
        view_all_location:(state,action)=>{
            state.value4=action.payload.locationList
        }
        
    }

})
export const {get_inactive_employee,get_qrcode,save_company_info,view_all_branch,view_all_location} = manageEmployeeSlice.actions;
export default manageEmployeeSlice.reducer