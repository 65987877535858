import React, { useEffect, useState } from "react";
import '../../../Css/Custom css/customImageUpload.css'
import uploadImage from "../../../Assets/Images/upload-image.png";
import { IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";


const CustomImageUpload = (props) => {
  console.log(props.onChange)
  // console.log(props.uploadImageUrl);
  // console.log(typeof props.uploadImageUrl);

  const [imageURL, setImageURL] = useState();

  useEffect(() => {
    let imageUrl = undefined;
    if (typeof props.uploadImageUrl === "string") {
      imageUrl = props.uploadImageUrl;
    } else if (
      props.uploadImageUrl !== undefined &&
      props.uploadImageUrl !== null
    ) {
      imageUrl = URL.createObjectURL(props.uploadImageUrl);
    }
    setImageURL(imageUrl);
  }, [props.uploadImageUrl]);



  const clickCloseImage = () => {
    setImageURL(undefined);
    props.setImgUrl(null);
  };


  
  return (
    <div className="custom-image-parent-container">

      {imageURL === undefined && (
        <div className="file-picker-container">
          <input type="file" id="customImageUpload" onChange={props.onChange} />
          <label htmlFor="customImageUpload">
            <img src={uploadImage} alt="imageUpload Icon" />
          </label>
        </div>
      )}
      {imageURL !== undefined && (
        <div className="image-preview-container">
          <IconButton
            onClick={clickCloseImage}
            sx={{ border: "1px solid #fd0301", padding: "0" }}
            className="closeIcon-image"
            title="Remove Image"
          >
            <CloseOutlined sx={{ color: "#fd0301" }} />
          </IconButton>
          <img src={imageURL} alt="image Preview" />
        </div>
      )}
      
    </div>
  );
};

export default CustomImageUpload;
