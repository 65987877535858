import React from 'react'
import '../../../Css/Custom css/custom.css'

import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import { currentTime } from '../../../Functions/Date';
export const TimePicker = () => {
  return (
    <div className='custom-date-parent'>
       <input value={currentTime} type="time" className="time-picker-input" style={{marginRight:'16px'}}/> 
       <ScheduleOutlinedIcon
        sx={{
          color: "#2e58c9",
          position: "absolute",
          top: 6,
          left:6,
          fontSize: "1.2rem",
        }}
      />
    </div>
  )
}
