import React, { useState } from 'react'
import TopContainer from '../CustomComponent/TopContainer'
import "../CompanyProfile/companyProfile.css"
import { Autocomplete, TextField } from '@mui/material'
import uploadImage from "../../../Assets/Images/upload-image.png"

export const CompanyProfile = () => {
    const [selectedTab,setSelectedTab]=useState('profileInfo')
    const handleTabClick=(option)=>()=>{
        setSelectedTab(option===selectedTab?selectedTab:option)
    }
    console.log(selectedTab);
  return (
    <>
     <TopContainer label="Company Profile" />
     <div className="global-container">
        <div className='company-profile-top-container'>
            <div className='company-profile-top-left-container'>
              <div 
                className={`company-profile-single-tab ${selectedTab==="profileInfo"?"selected":""}`}
                onClick={handleTabClick("profileInfo")}
                >
               <p>Profile Info</p>
             </div>
            <div 
               className={`company-profile-single-tab ${selectedTab==="accountSettings"?"selected":""}`}
               onClick={handleTabClick("accountSettings")}
               >
              <p>Account Settings</p>
            </div>
            <div 
              className={`company-profile-single-tab ${selectedTab==="vatAndTax"?"selected":""}`}
              onClick={handleTabClick("vatAndTax")}
              >
              <p>VAT & TAX</p>
            </div>
         </div>
         <div className='company-profile-top-right-container'>
            <i class="bi bi-printer"></i>
         </div>
          
        </div>

        {
            selectedTab==="profileInfo"?
            <div className='company-profile-bottom-container'>
                
               <div className='company-info-input'>
             <p>Company Name</p>
             <input type="text" />
          </div>
          <div className='company-info-input' >
             <p style={{alignSelf:"end"}}>اسم الشركة</p>
             <input type="text" style={{textAlign:"right"}}/>
          </div>
          <div className='company-info-input'>
             <p>Company Email</p>
             <input type="text" />
          </div>
          <div className='company-info-input'>
             <p>Contact Address</p>
             <input type="text" />
          </div>
          <div className='company-info-input'>
             <p>Contact Person</p>
             <input type="text" />
          </div>
          <div className='company-info-input'>
             <p>Website</p>
             <input type="text" />
          </div>
          <div className='company-info-input logo-browse-input'>
             <p>Logo</p>
             <input type="text" />
             <div className="logo-browse-container">
               
               <label >
               <input type="file" />
                  Browse
               </label>
             </div>
          </div>
          <div className='company-info-input'>
             <p>Country</p>
             <Autocomplete
            sx={{width:"100%"}}
            options={[""]}
            getOptionLabel={(option)=>option}
            renderInput={(params)=><TextField {...params}   />}
           

          />
       
          </div>
          <div className='company-info-input'>
             <p>Currency</p>
             <Autocomplete
            sx={{width:"100%"}}
            options={[""]}
            getOptionLabel={(option)=>option}
            renderInput={(params)=><TextField {...params} />}
           

          />
          </div>
          <div className='company-info-input'>
             <p>Language</p>
             <Autocomplete
            sx={{width:"100%"}}
            options={[""]}
            getOptionLabel={(option)=>option}
            renderInput={(params)=><TextField {...params} />}
           

          />
          </div>
          <div className='company-info-input'>
             <p>Terms And Conditions</p>
             <textarea type="text" rows="4" cols="50"/>
          </div>
          <div className='company-info-input'>
             <p style={{alignSelf:"end"}}>الأحكام والشروط</p>
             <textarea type="text" rows="4" cols="50" style={{textAlign:"right"}}/>
          </div>
            
            </div> 
            :
            selectedTab==="accountSettings"?
              <div>
                 <p style={{textAlign:"start",fontSize:"0.9rem",fontWeight:600,margin:'1%'}}>Account Settings</p>
                 <div className='company-profile-bottom-container'>
                    <div className='company-info-input'>
                      <p>Account Holder Name</p>
                       <input type="text" />
                    </div>
                    <div className='company-info-input'>
                      <p>Account Type</p>
                       <input type="text" />
                    </div>
                    <div className='company-info-input'>
                      <p>Account Number</p>
                       <input type="text" />
                    </div>
                    <div className='company-info-input'>
                      <p>Re-Enter Account Number</p>
                       <input type="text" />
                    </div>
                    <div className='company-info-input'>
                      <p>IFSC/IBAN Code</p>
                       <input type="text" />
                    </div>
                </div>
                <hr  className='global-hr' style={{borderBottom:'1px solid rgb(211 208 208)',margin:'2% 0 1% 0'}}/>
                <div className='company-profile-bottom-container' style={{margin:"1% 0 0 0"}}>
                    <div className='company-info-input'>
                      <p>UPI Name</p>
                       <input type="text" />
                    </div>
                    <div className='company-info-input'>
                      <p>UPI ID</p>
                       <input type="text" />
                    </div>
                    <div className='global-single-input add-employee-input'>
                      <p>Qr Code Upload</p>
                 {/* { */}
                    {/* // imageUrl === undefined && */}
                    <div className='image-upload-file-conatiner'>
                    <input type="file" id="upload-image" />
                   <label htmlFor="upload-image">
                    <img src={uploadImage} alt="" />
                  </label>
                  </div>
                  {/* }
                  {
                    imageUrl !== undefined &&
                    <div className='image-preview'>
                        <IconButton className='image-close-icon' onClick={removeImage}>
                           <i class="bi bi-x"></i>
                        </IconButton>
                        <img src={imageUrl} alt="" />
                    </div>
                    
                  } */}
        </div>
                   
                </div>
              </div>
           
            :
             <div>
                <p style={{textAlign:"start",fontSize:"0.9rem",fontWeight:600,margin:'1%'}}>Enter Tax Details</p>
                <div className='company-profile-bottom-container'>
                    <div className='company-info-input'>
                      <p>Seller Registered Name Name</p>
                       <input type="text" />
                    </div>
                    <div className='company-info-input'>
                      <p>VAT Number</p>
                       <input type="text" />
                    </div>
                    <div className='company-info-input'>
                      <p>Income Tax Code</p>
                       <input type="text" />
                    </div>
                </div>
             </div>
          
        }
        
     </div>
    </>
  )
}
