import { createSlice } from "@reduxjs/toolkit";

const SingleOfferSlice = createSlice({
    name:'singleOfferSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_Single_offer_List:(state,action)=>{
            state.value = action.payload.singleOfferView
        }
    }
});

export const {get_Single_offer_List} = SingleOfferSlice.actions;
export default SingleOfferSlice.reducer;