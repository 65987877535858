import { createSlice } from "@reduxjs/toolkit";

const RecentPurchaseSlice = createSlice({
    name:'recentPurchaseSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        getRecentPurchase:(state,action)=>{
            state.value = action.payload.RecentPurchaseData
        }
    }
});

export const {getRecentPurchase} = RecentPurchaseSlice.actions;
export default RecentPurchaseSlice.reducer;