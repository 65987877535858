import { Autocomplete, TextField } from '@mui/material'
import React from 'react'
import CustomImageUpload from '../../../CustomComponent/CustomImageUpload'

export const EmpPersonalInfo = () => {
  return (
    <>
      <div className='emp-bank-detail-container'>
      <div className="emp-bank-detail-input-section">
        <div className="global-single-input">
          <p>Staff Name</p>
          <input type="text" />
        </div>
        <div className="global-single-input auto-complete">
          <p>Gender</p>
          <Autocomplete 
            options={["SALES","MANAGER"]}
            getOptionLabel={(option)=>option}
            renderInput={(params)=>(<TextField {...params}/>)}
          />
        </div>
        <div className="global-single-input auto-complete">
          <p>Father Name</p>
          <input type="text" />
        </div>
        <div className="global-single-input">
          <p>Marital Status</p>
          <Autocomplete
            options={["SALES","MANAGER","SHAKIR"]}
            getOptionLabel={(option)=>option}
            renderInput={(params)=>(
              <TextField {...params}/>
            )}
          />
        </div>
        <div className="global-single-input">
          <p>Contact Number</p>
          <input type="date" />
        </div>
        <div className="global-single-input">
          <p>Blood Group</p>
          <Autocomplete
            options={["SALES","MANAGER","SHAKIR"]}
            getOptionLabel={(option)=>option}
            renderInput={(params)=>(
              <TextField {...params}/>
            )}
          />
        </div>
        <div className="global-single-input">
          <p>Emergency Contact Number</p>
          <input type="text" />
        </div>
        <div className="global-single-input">
          <p>Address</p>
          <input type="text" />
        </div>
        <div className="global-single-input">
          <p>Email</p>
          <input type="text" />
        </div>
        <div className="global-single-input">
          <p>DOB</p>
          <input type="date" />
        </div>
        <div className="global-single-input auto-complete">
          <p>Country</p>
          <Autocomplete
            options={["SALES","MANAGER","SHAKIR"]}
            getOptionLabel={(option)=>option}
            renderInput={(params)=>(
              <TextField {...params}/>
            )}
          />
        </div>
        <div className="global-single-input auto-complete">
          <p>State</p>
          <Autocomplete
            options={["SALES","MANAGER","SHAKIR"]}
            getOptionLabel={(option)=>option}
            renderInput={(params)=>(
              <TextField {...params}/>
            )}
          />
        </div>
        <div className="global-single-input">
          <p>Image Upload</p>
          <CustomImageUpload/>
        </div>
      </div>
      <button className='global-button-contained-blue' style={{marginTop:"2%"}}>
        Update
      </button>
    </div>
    <hr style={{margin:"0 1%"}}/>
    <div className="emp-company-info-section">
      <h4>Account Login</h4>
      <div className="emp-company-info-section-body account-login-section">
        <div className="global-single-input">
          <p>Username</p>
          <input type="text" />
        </div>
        <div className="global-single-input">
          <p>Password</p>
          <input type="text" />
        </div>
      </div>
    </div>
    </>
  )
}
