import { createSlice } from "@reduxjs/toolkit";

export const generatePackageIdSlice=createSlice({
    name:"generatePackageIdSlice",
    initialState:{
        value:undefined,
        value1:undefined,
        value2:undefined,
        value3:undefined,
        value4:undefined,
        value5:undefined,
        plan:undefined,
        singlePackage:undefined

    },
    reducers:{
        generate_package_id:(state,action)=>{
            state.value=action.payload.generatePackageId
        },
        get_package_list:(state,action)=>{
           state.value1=action.payload.packageListData
        },
        get_package_duration:(state,action)=>{
            state.value2=action.payload.packageDuration
        },
        get_module_duration:(state,action)=>{
            state.value3=action.payload.moduleDuration
        },
        get_sub_module:(state,action)=>{
            state.value4=action.payload.subModuleList
        },
        get_sub_module_byId:(state,action)=>{
            state.value5=action.payload.subModuleListById
        },
        get_plan_list:(state,action)=>{
            state.plan=action.payload.planData
        },
        get_single_package_list:(state,action)=>{
            state.singlePackage=action.payload.singalePackageData
        }

    }
    
})
export const {
    generate_package_id,
    all_list,get_package_list,
    get_package_duration,
    get_module_duration,
    get_sub_module,
    get_sub_module_byId,
    get_plan_list,
    get_single_package_list
}=generatePackageIdSlice.actions;
export default generatePackageIdSlice.reducer