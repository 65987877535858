import {TEMP_TOKEN} from './token'
import axios from "axios"

// export const BASE_URL='http://192.168.1.30:3004/'

// local base url
  // axios.defaults.baseURL=process.env.REACT_APP_BASE_URL

// PRODUCTION BASE URL
axios.defaults.baseURL=process.env.REACT_APP_PRODUCTION_URL

// PRODUCTION BASE URL UAE
  //  axios.defaults.baseURL=process.env.REACT_APP_PRODUCTION_URL_UAE


// staff section base url
// export const STAFFBASEURL=axios.create({
//   baseURL:'http://192.168.1.24:3200/'
// })

// staff production baseUrl
export const STAFFBASEURL=axios.create({
  baseURL:'https://apitextile.eyeterp.com'
})
// uae production base url
// export const STAFFBASEURL=axios.create({
//   baseURL:'https://aeapitextile.eyeterp.ae'
// })



export const HEADERS={
    headers: {
    "Content-Type": "application/json",
  },
};


export const HEADERS_FORMDATA = {
  headers:{
    "Content-Type": "multipart/form-data",
  }
}

export const HEADER_QUERY = {
  "Content-Type": "application/json",
};