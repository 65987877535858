import  axios  from "axios"
import { HEADERS, HEADERS_FORMDATA, STAFFBASEURL } from "../../urlAndPath"
import { store } from "../../../Redux/Store";
import { get_inactive_employee,get_qrcode, view_all_branch, view_all_location } from "../../../Redux/Staff/manageEmployeeSlice";

// add employee api call
export const addEmployeeAPICall= async (formData,moveToNextTab)=>{
  await STAFFBASEURL.post("employee/addEmployee",formData,HEADERS_FORMDATA)
  .then((res)=>{
     if(res.status===200){
        console.log('add');
        moveToNextTab()
     }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}

// add employee api call ---- company info and bank details api call
export const bankAndCompanyInfoAPICall= async (body)=>{
  await STAFFBASEURL.put("employee/addcompanyInfo",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
      console.log('add');
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}

// view all employee in branch /// view all inactive employee in branch
export const viewAllInactiveEmployeeAPICall= async ()=>{
  await STAFFBASEURL.post("employee/viewallemployeesinbranch",{},HEADERS)
  .then((res)=>{
     if(res.status ===200){
       store.dispatch(get_inactive_employee({inactiveEmployee:res.data}))
        
     }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}

// generate qr code api call
export const generateQrCodeAPICall=async(body)=>{
    STAFFBASEURL.post("employee/clickQrcode",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_qrcode({qrcodeData:res.data}))
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

// get all branch api call
export const getAllBranchList= async ()=>{
  await axios.get("location/getBranches",HEADERS)
  .then((res)=>{
     if(res.status===200){
      store.dispatch(view_all_branch({branchList:res.data}))
     }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}
// get all location api call
export const getAllLocationList= async ()=>{
  await axios.get("location/getLocations",HEADERS)
  .then((res)=>{
    if(res.status===200){
      store.dispatch(view_all_location({locationList:res.data}))
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
} 





// add Location Api call

export const addlocationApicall =(body) =>{
  // console.log(body)
  axios.post("location/addLocation",body,HEADERS).then((res)=>{
    if(res.status === 200){
      console.log(res)
      
    }
  }).catch((err)=>{
    console.log("Error ")
  })

}
