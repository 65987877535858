import React, { useState } from 'react'
import TopContainer from '../../CustomComponent/TopContainer'
import { Dialog, IconButton } from '@mui/material'
import { PayslipView } from '../../CustomComponent/PayslipView'

export const Payslip = () => {

  const [paySlipView,setPayslipView]=useState(false)
  return (
    <>
      <TopContainer label="Payroll > Payslip"/>
      <div className="global-container">
        <div className="global-top-container">
          <h3>Payslip</h3>
        </div>
        <div className="global-container-table">
          <table>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Employee ID</th>
                        <th>Mobile</th>
                        <th>Joining Date</th>
                        <th>Designation</th>
                        <th>Status</th>
                        <th>Payslip</th>
                        <th>Download</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>1</td>
                    <td><img src="" alt="" /></td>
                    <td>munushi</td>
                    <td>EMP23</td>
                    <td>9495864512</td>
                    <td>12/10/2021</td>
                    <td>Designer</td>
                    <td>divorced</td>
                    <td><IconButton onClick={()=>setPayslipView(true)}>
                            <i class="bi bi-eye visibility-icon"></i>
                        </IconButton></td>
                    <td><IconButton>
                        <i class="bi bi-arrow-down-circle download-icon"></i>
                    </IconButton></td>
                    </tr>
                </tbody>
            </table>
        </div>
      </div>
      <Dialog maxWidth="lg" open={paySlipView} onClose={()=>setPayslipView(false)}>
          <>
              <PayslipView exitDialog={()=>setPayslipView(false)}/>
          </>
      </Dialog>
    </>
  )
}
