import React, { useEffect, useState } from 'react'
import '../../Css/Login/loginLeftForm.css'
import Logo from '../../Assets/Images/Logo.png'

import { loginApi } from '../../API/Login/LoginApi'
import { useNavigate } from 'react-router-dom'
import ErrorSnackbar from '../SingleCompoents/SnackBars/ErrorSnackbar'


const LeftLoginForm = () => {
  const navigate = useNavigate()

  const isAuth = localStorage.getItem('isAuth')
  console.log(isAuth)



  const [username,setUsername] = useState("");
  const [password,setPassword] = useState("");

  // success useState

  const [isSuccess,setisSuccess] = useState(false);

  // ErrorSnack useState

  const [ErrorSnack,setErrorSnack] = useState(false);

  //Error Msg useState

  const [ErrorMsg,setErrorMsg] = useState("Error!!!");

  const closeErrorSnackbar =()=>{
    setErrorSnack(false);

  }




  useEffect(()=>{
    if(isAuth){
      navigate('/dashboard/homeDashboard')
    }
  },[isAuth])





  const LoginBtn=()=>{
    const userCredential = {
      username,
      password
    }

    console.log(userCredential)
    loginApi(userCredential,setisSuccess,setErrorSnack,setErrorMsg);


    console.log("yyy")
  }



  // const adminLogin = {
  //   username:
  // }




  return (
    <div className="left-main-container">
       <div className="left-logo">
         <img className='logo-image' src={Logo}></img>
       </div>
       <div className="left-container-main-form">
         <div className="left-container-head">
           <h1>Login</h1>
           <p>Lorem Ipsum is simply dummy text.</p>
         </div>
         <div className='left-container-sub-form'>
           <div className='left-container-sub-form-input'>
            <p>User Name</p>
            <input type="text" placeholder='type mobile or username'
              onChange={(e)=>setUsername(e.target.value)}
            />
           </div>
           <div className='left-container-sub-form-input'>
            <p>Password</p>
            <input type="password" placeholder='add password'
              onChange={(e)=>setPassword(e.target.value)}
            />
           </div>
         </div>
         <div className='forgot-password'>
           <p>Forgot Password?</p>
         </div>
         <div className="login-button">
           <button onClick={LoginBtn}>Login</button>
         </div>
      
       </div>
       <ErrorSnackbar open={ErrorSnack}
          handleClose={closeErrorSnackbar}
          message={ErrorMsg}
          
       />
    </div>
  )
}

export default LeftLoginForm