/** @format */

import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Link } from "react-router-dom";
// css  from purchase.css
export const QuickAccess = (props) => {
  const { backgroundColor, label, color,path } = props;

  console.log(label,path)
  return (
    <div
      className="quickAccess-container"
      style={{ backgroundColor: backgroundColor }}
    >
      <Link
        to={path}
        style={{ textDecoration: "none", width: "100%" }}
      >
        <div className="quickAccess-content">
          <p style={{ color: color }}>{label}</p>
          <KeyboardArrowRightIcon sx={{ color: color, cursor: "pointer" }} />
        </div>
      </Link>
    </div>
  );
};
