import React from "react";

const Reports = () => {
  const reports = [
    "Purchase Report",
    "Stock Report",
    "Sales Report",
    "Daily Report",
    "Daily Cash/Card Summery",
    "Profit and Sale",
    "Transfer Report",
    "Stock Adjustment Report",
    "Expense Report",
    "Payments Report",
  ];
  return (
    <div className="report-parent-container">
      <div className="report-radio-group">
        <div>
          <input type="radio" name="duration" id="dailyRadio" />
          <label htmlFor="dailyRadio">Daily</label>
        </div>
        <div>
          <input type="radio" name="duration" id="weeklyRadio" />
          <label htmlFor="weeklyRadio">Weekly</label>
        </div>
        <div>
          <input type="radio" name="duration" id="monthlyRadio" />
          <label htmlFor="monthlyRadio">Monthly</label>
        </div>
      </div>
      <div className="report-checkbox-group">
        {reports.map((report, i) => (
          <div key={i} className='reports'>
            <input type="checkbox" name="duration" id={`${report}Checkbox`} />
            <label htmlFor={`${report}Checkbox`}>{report}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reports;
