import React, { useEffect, useState } from "react";
import TopContainer from "../../CustomComponent/TopContainer";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import "../../../../Css/Marketing/Enquiry/enquiry.css"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useSelector } from "react-redux";
import { getCustomerDemo } from "../../../../API/Marketing/demoRequestAPI";

export const RequestDemo=()=>{
    const demoList=useSelector((state)=>state.demoRequestSlice.list)
    const ErpTypes=[
        {name:"ERP-Autoparts"},
        {name:"ERP-Restaurant"},
        {name:"ERP-Textile"},
        {name:"ERP-Logistics"},
        {name:"ERP-Hotel"},
        {name:"ERP-Education"},
        {name:"ERP-Aviation"},
        {name:"ERP-Hospital"},
        {name:"ERP-Supermarket"}
      ]
      const [erpType,setErpType]=useState(null)

    useEffect(()=>{
        getCustomerDemo({erpType:erpType?.name})
    },[erpType])
    console.log(demoList);
    return(
    <>
     <TopContainer label="Marketing > Enquiry > Demo Request"/>
     <div className="global-container">
        <div className="global-single-input auto-complete auto-complete-violet">
            <Autocomplete
             options={ErpTypes||[]}
             getOptionLabel={(option)=>option?.name}
             renderInput={(params)=>(<TextField {...params} placeholder="ERP Type"/>)}
             value={erpType}
             onChange={(e,newValue)=>setErpType(newValue)}
            />
        </div>
        <div className="global-container-table" style={{padding:"2% 1%"}}>
            <table>
                <thead>
                    <tr>
                        <th>Req.Date</th>
                        <th>Email Address</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Company Name</th>
                        <th>Phone</th>
                        <th>Time</th>
                        <th>Date</th>
                        <th>ZIP Code</th>
                        <th>Status</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        demoList?.map((r,i)=>(
                            <tr>
                            <td>12-12-23</td>
                            <td>{r?.email}</td>
                            <td>{r?.firstName}</td>
                            <td>{r?.lastName}</td>
                            <td>{r?.erpType}</td>
                            <td>{r?.mobile}</td>
                            <td>{r?.scheduleTime}</td>
                            <td >{r?.scheduleDate}</td>
                            <td>{r?.zipcode}</td>
                            <td>
                            <div className="global-single-input auto-complete-violet" style={{width:"90%"}}>
                               <Autocomplete
                                   options={["Pending"]}
                                   getOptionLabel={(option)=>option}
                                   renderInput={(params)=>(<TextField {...params} placeholder=""/>)}
                                 />
                              </div>
                            </td>
                            <td>
                            <IconButton>
                                <EditOutlinedIcon />
                              </IconButton>
                            </td>
                        </tr>
                       
                        ))
                    }
                
                </tbody>
            </table>
        </div>
     </div>
    </>
    )
}