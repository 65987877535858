import { Autocomplete, Dialog, IconButton, MenuItem, Select, Skeleton, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDepartmentAPICall } from "../../../../API/Staff/HRM/departmentAPI";
import { designationAPICall, editDesignationAPICall, viewDesigantionAPI } from "../../../../API/Staff/HRM/designationAPI";
import SuccessSnackbar from "../../../SingleCompoents/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../SingleCompoents/SnackBars/ErrorSnackbar";

export const Designation = () => {
  const allDepartmentList = useSelector((state)=>state.departmentSlice.value)
  const allDesignationList = useSelector((state)=>state.designationSlice.value)

  const [addDesignation,setAddDesigntion]=useState(false)
  const [editDesignation,setEditDesignation]=useState(false)
  const [isModify,setIsModify]=useState(false)
  const [editedId,setEditedId]=useState(false)
  const [successSnackbar,setSuccessSnackbar]=useState(false)
  const [errorSnackbar,setErrorSnackbar]=useState(false)
  const [snackMsg,setSnackMsg]=useState("Oops!!!")

  const updateListner=()=>{
    setIsModify(!isModify)
  }

  const designationInitialForm={
    departmentName:null,
    designation:null,
    details:null
  }
  const [designationForm,setDeignationForm]=useState(designationInitialForm)
  // api call
useEffect(()=>{
  getDepartmentAPICall()
  viewDesigantionAPI()
},[isModify])

  const openDesignation=()=>{
    setAddDesigntion(true)
  }

  // onChange Functions
const getDesignation=(e)=>{
   setDeignationForm({...designationForm,designation:e.target.value})
}
const getDesignationDetails=(e)=>{
  setDeignationForm({...designationForm,details:e.target.value})
}

const bodyToApi={
  position:designationForm?.designation,
  details:designationForm?.details,
  departmentId:designationForm?.departmentName,

}
const designationSubmitFn=()=>{
  if(designationForm?.departmentName===null){
    alert("Select Department")
  }
  else if(designationForm?.designation === null || designationForm?.designation === ""){
    alert("Enter Designation")
  }

  else{
    designationAPICall(
      bodyToApi,
      updateListner,
      setAddDesigntion,
      setSuccessSnackbar,
      setErrorSnackbar,
      setSnackMsg,
      clearState
      )
  }
  
}


const clearState=()=>{
  setDeignationForm(designationInitialForm)
}
// designation edit 
const openEditDesignation=(data)=>{
   console.log(data);
   setEditDesignation(true)
   setEditedId(data?._id)
   // extract depatment name from whole list
   let departmentName=allDepartmentList?.filter((obj)=>obj?.departmentName===data?.departmentName)
   setDeignationForm({...designationForm,
    departmentName:departmentName[0],
    designation:data?.position,
    details:data?.details
  })
}



const editDesignationSubmitBtn=()=>{
  if(designationForm?.departmentName===null){
    alert("Select Department")
  }
  else if(designationForm?.designation === null || designationForm?.designation === ""){
    alert("Enter Designation")
  }
  else{
    editDesignationAPICall(
      {
        position:designationForm?.designation,
        details:designationForm?.details,
        id:editedId,
        departmentId:designationForm?.departmentName?._id
      },
      updateListner,
      setEditDesignation,
      setSuccessSnackbar,
      setErrorSnackbar,
      setSnackMsg,
    )
  }
  
}


  return (
    <div className="global-page-parent-container1">
      <p style={{marginLeft:"1%"}} className="breadcrumb">Staff &gt; HRM &gt; Designation </p>
      <div className="department-container global-container">
        <div className="department-top-container">
          <h3>Designation</h3>
          <button
            className="designation-button"
            onClick={openDesignation}
          >
            Add Designation
          </button>
        </div>
        <div className="global-container-table">
          <table className="global-table">
            <thead>
              <tr>
                <th>No</th>
                <th>Department</th>
                <th>Designation</th>
                <th>Details</th>
                <th>Edit</th>
                {/* <th>Delete</th> */}
              </tr>
            </thead>
         
              <tbody>
                {allDesignationList?.length > 0 ? (
                  allDesignationList
                    ?.slice(0)
                    ?.reverse()
                    ?.map((r, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{r?.departmentName?.toUpperCase()}</td>
                        <td>{r?.position?.toUpperCase()}</td>
                        <td>
                          {r?.details !== ""
                            ? r?.details?.toUpperCase()
                            : "-NIL-"}
                        </td>
                        <td>
                          <IconButton
                           onClick={()=>openEditDesignation(r)}
                          >
                            <i class="bi bi-pencil-square edit-icon"></i>
                          </IconButton>
                        </td>
                        {/* <td>
                      <IconButton disabled className="disabled-icon-button">
                        <i class="bi bi-trash3 delete-icon"></i>
                      </IconButton>
                    </td> */}
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={5}>No data</td>
                  </tr>
                )}
              </tbody>
           
          </table>
        </div>
      </div>

      {/* add designation dialog */}
      <Dialog
        open={addDesignation}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setAddDesigntion(false)}
      >
        <div className="add-department-dialog-container">
           <div className="add-department-dialog-sub-container">
          <h3>Add Designation</h3>
          <hr className="global-hr" />
          <div className="global-single-input auto-complete" style={{width:"97%"}}>
            <p>Department</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={allDepartmentList||[""]}
              getOptionLabel={(option)=>option?.departmentName}
              renderInput={(params)=><TextField {...params}/>}
              onChange={(e,newValue)=>setDeignationForm({...designationForm,departmentName:newValue?._id})}
            />
          </div>
          <div className="global-single-input" style={{width:"98%"}}>
            <p>Designation</p>
            <input
              type="text"
              placeholder="Enter new designation name..."
              value={designationForm?.designation}
              onChange={getDesignation}
            />
           
          </div>
          <div className="global-single-input department-input">
            <p>Details</p>
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              placeholder="Enter details of new designation... "
              value={designationForm?.details}
              onChange={getDesignationDetails}
             
            />
          </div>

           <div className="department-button-container">
            <button
             onClick={()=>setAddDesigntion(false)}
             className="global-button-contained-black-outlined"
            >
              Cancel
            </button>
            <button  
            className="global-button-contained-blue"
            onClick={designationSubmitFn}
            >
              Submit
            </button>
          </div>
          </div>
        </div>
      </Dialog>
      {/* edit designation dialog */}
      <Dialog
        open={editDesignation}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setEditDesignation(false)}
      >
        <div className="add-department-dialog-container">
          <div className="add-department-dialog-sub-container">
          <h3>Edit Designation</h3>
          <hr className="global-hr" />
          <div className="global-single-input auto-complete" style={{width:"97%"}}>
            <p>Department</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={allDepartmentList||[""]}
              getOptionLabel={(option)=>option?.departmentName}
              renderInput={(params)=><TextField {...params}/>}
              onChange={(e,newValue)=>setDeignationForm({...designationForm,departmentName:newValue})}
              value={designationForm?.departmentName}
            />
          </div>
          <div className="global-single-input" style={{width:"98%"}}>
            <p>Designation</p>
            <input
              type="text"
              placeholder="Enter new designation name..."
              value={designationForm?.designation}
              onChange={getDesignation}
            />
          </div>
          <div className="global-single-input department-input">
            <p>Details</p>
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              placeholder="Enter details of new designation... "
              value={designationForm?.details}
              onChange={getDesignationDetails}
            />
          </div>

          <div className="department-button-container">
            <button
              onClick={() => setEditDesignation(false)}
              className="global-button-contained-black-outlined"
            >
              Cancel
            </button>
            <button 
              className="global-button-contained-blue"
              onClick={editDesignationSubmitBtn}
              >
              Submit
            </button>
          </div>
          </div>
        </div>
      </Dialog>

      <SuccessSnackbar
       open={successSnackbar}
       handleClose={()=>setSuccessSnackbar(false)}
       message={snackMsg}
     />
     <ErrorSnackbar
       open={errorSnackbar}
       handleClose={()=>setErrorSnackbar(false)}
       message={snackMsg}
     />
    </div>
  );
};
