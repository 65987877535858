import { Dialog, IconButton } from '@mui/material'
import React, { useState } from 'react'
import TopContainer from '../../CustomComponent/TopContainer'

export const Holiday = () => {
   const [addHoliday,setAddHoliday]=useState(false)

   const openAddHoliday=()=>{
    setAddHoliday(true)
   }
  return (
    <>
        <TopContainer label="Staff > Leave > Holiday"/>
        <div className='global-container'>
           <div className='global-heading-containe'>
                <h3 style={{margin:"2% 0 0 2%"}}>Holiday</h3>
                <button onClick={openAddHoliday} className='global-button-contained-blue'>Add Holiday</button>
            </div>
            <div className='global-container-table'>
              <table>
                <thead>
                  <tr>
                     <th>No</th>
                     <th>Holiday Name</th>
                     <th>From</th>
                     <th>To</th>
                     <th>No Of Days</th>
                     <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                       <IconButton>
                          <i class="bi bi-pencil-square edit-icon"></i>
                        </IconButton>
                        <IconButton>
                          <i class="bi bi-trash3 delete-icon"></i>
                        </IconButton>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
        </div>

        {/* add holiday dialog */}
        <Dialog open={addHoliday} maxWidth="lg">
           <div className='add-leave-type-popup-container'>
             <div className='add-leave-type-popup-sub-container'>
               <h3 style={{margin:"2% 0"}}>Holiday</h3>
               <hr className='global-br' style={{margin:"2% 0"}}/>
               <div className='global-single-input leave-type-input'>
              <p>Holiday Name</p>
              <input type="text" />
             </div>
             <div className='global-single-input leave-type-input'>
              <p>From</p>
              <input type="date" />
             </div>
             <div className='global-single-input leave-type-input'>
              <p>To</p>
              <input type="date" />
             </div>
             <div className='global-single-input leave-type-input'>
              <p>Number Of Days</p>
              <input type="text" />
             </div>
             <div className='button-container'>
                <button onClick={()=>setAddHoliday(false)} className='global-button-contained-black-outlined' style={{width:"20%",height:"35px"}}>Cancel</button>
                <button className='global-button-contained-blue' style={{width:"20%",height:"35px"}}>Submit</button>
             </div>
             </div>
            
           </div>
        </Dialog>
    </>
  )
}
