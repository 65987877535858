import React from 'react'
import LeftLoginForm from './LeftLoginForm'
import RightLogin from './RightLogin'
import '../../Css/Login/login.css'

const Login = () => {

  
  return (
    <div className='login-container'>
      <div className='login-left-container'>
         <LeftLoginForm/>
      </div>
      <div className='login-right-container'>
        <RightLogin/>
      </div>
    </div>
  )
}

export default Login