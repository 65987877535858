import axios from "axios"
import { HEADERS, HEADERS_FORMDATA } from "../../../API/urlAndPath"
import { get_catalog_category,
     get_catalog_parts,
      get_catalog_sub_category,
      get_catalog_brand,
      get_catalog_model,
      get_catalog_manuf_year
     } from "./catalogCategorySlice"
import { store } from "../../../Redux/Store"

// view category
export const viewCatalogCategoriesAPICall=(body,setLoadingForm)=>{
    setLoadingForm(true)
    axios.post("catalogue/viewCatogoryList",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_catalog_category({catalogCategoryData:res.data}))
            setLoadingForm(false)
        }
    })
    .catch((err)=>{
        store.dispatch(get_catalog_category({catalogCategoryData:undefined}))
        setLoadingForm(false)
    })
}

// add category
export const addCatalogCategoryAPICall=(body,updateListner,clearFn)=>{
    axios.post("catalogue/addCatogory",body,HEADERS_FORMDATA)
    .then((res)=>{
        if(res.status===200){
            updateListner()
            clearFn()
        }
    })
    .catch((err)=>{
        console.log('err');
    })
}


// view sub category
export const viewSubCategoryAPICall=(body,setLoadingForm)=>{
    setLoadingForm(true)
    axios.post("catalogue/viewSubCatogoryListOfaCat",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_catalog_sub_category({catalogSubCategoryData:res.data}))
            setLoadingForm(false)
        }
    })
    .catch((err)=>{
        store.dispatch(get_catalog_sub_category({catalogSubCategoryData:undefined}))
        setLoadingForm(false)
    })
}
//add sub category
export const addSubCategoryAPICall=(body,setLoadingForm,updateListner,clearstate)=>{
    setLoadingForm(true)
  axios.post("catalogue/addSubCatogory",body,HEADERS_FORMDATA)
  .then((res)=>{
    if(res.status===200){
        setLoadingForm(false)
        updateListner()
        clearstate()
    }
  })
  .catch((err)=>{
    setLoadingForm(false)
  })
}

// view parts
export const viewCatalogPartsAPICall=(body)=>{
    axios.post("catalogue/filterCatalogue",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_catalog_parts({partsData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_catalog_parts({partsData:undefined}))

    })
}
// add parts
export const addCatalogParts=(body,updateListner,clearState)=>{
   axios.post("catalogue/addCatalogue",body,HEADERS_FORMDATA)
   .then((res)=>{
    if(res.status===200){
        updateListner()
        clearState()
    }
   })
   .catch((err)=>{
    console.log(err.response);
   })
}

// view brand api call
export const viewBrandAPICall=()=>{
    axios.post("catalogue/viewBrandList",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_catalog_brand({brandData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_catalog_brand({brandData:undefined}))

    })
}
// add brand
export const addBrancdAPICall=(body,updateListner)=>{
    axios.post("catalogue/addBrand",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            console.log('add');
            updateListner()
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

// view model
export const viewModelAPICall=()=>{
  axios.post("catalogue/viewBrandModelList",{},HEADERS)
  .then((res)=>{
    if(res.status===200){
        store.dispatch(get_catalog_model({modelData:res.data}))
    }
  })
  .catch((err)=>{
    store.dispatch(get_catalog_model({modelData:undefined}))
    
  })
}
// add model 
export const addModelAPICall=(body,updateListner)=>{
    axios.post("catalogue/addBrandModel",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            updateListner()
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

// view manufacture year api call
export const viewManufYearAPICall=()=>{
    axios.post("catalogue/viewManufYearList",{},HEADERS)
    .then((res)=>{
        if(res.status===200){
            store.dispatch(get_catalog_manuf_year({manufYearData:res.data}))
        }
    })
    .catch((err)=>{
        store.dispatch(get_catalog_manuf_year({manufYearData:undefined}))
        
    })
}

// add manuf year
export const addManufYearAPICall=(body,updateListner)=>{
    axios.post("catalogue/addManufYear",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
            updateListner()
        }
    })
    .catch((err)=>{
        console.log(err.response);
    })
}

// export and import function of parts 
export const downloadExcelFile = (response) => {
    try {
      const fileBuffer = response.fileBuffer;
      const arrayBuffer = new Uint8Array(fileBuffer.data).buffer;
  
      const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = response.filename;
  
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      console.log("downloaded.........");
    } catch (error) {
      console.error('Error downloading Excel file:', error);
    }
  };


export const exportPartsAPICall=(body)=>{
  axios.post("catalogue/exportCatalogue",body,HEADERS)
  .then((res)=>{
    if(res.status===200){
        console.log('export success');
        downloadExcelFile(res.data)
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}
export const importPartsAPICall=(body,setImportPopup,setSnackBarStates, snackBarStates,setArray)=>{
    axios.post("catalogue/importCatalogue",body,HEADERS_FORMDATA)
    .then((res)=>{
        if(res.status===200){
            console.log('uploaded');
            store.dispatch(get_catalog_parts({partsData:res.data}))
            setSnackBarStates({...snackBarStates,message:"File imported successfully",success:true})
            setImportPopup(false)
            setArray([])
        }
    })
    .catch((err)=>{
        console.log(err.response);
        setSnackBarStates({...snackBarStates,message:err?.response?.data,error:true})
    })
}