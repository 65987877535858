/** @format */

import React, { useEffect, useState } from "react";
import TopContainer from "../CustomComponent/TopContainer";
import "../../../Css/DeviceSettings/deviceSettings.css";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";

import SuccessSnackbar from "../../SingleCompoents/SnackBars/SuccessSnackbar";
import { addDeviceSettingsApi, viewAllDeviceList } from "../../../API/Device_settings/AddDeviceSettingsApi";
import CountryListWithCode from '../../../Assets/JSON/CountryLIstWithCode.json'


export const DeviceSettings = () => {

  const deviceList=[{name:"POS1"},{name:"POS2"},{name:"POS3"}]
  const brandList=[{name:"ezeTap1"},{name:"ezeTap2"},{name:"ezeTap3"}]



  const [country, setCountry] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const [brand, setBrand] = useState(null);
  const [apiPath, setApiPath] = useState("");
  const [allDeviceList,setAllDeviceList]=useState([])
  const [isModify,setIsModify]=useState(false)

  //Alert

  const [CountryAlert, setCountryAlert] = useState(false);
  const [DeviceTypeAlert, setDeviceTypeAlert] = useState(false);
  const [brandAlert, setBrandAlert] = useState(false);
  const [ApipathAlert, setApiPathAlert] = useState(false);


  //Snackbar states

  const [successSnackbaropen,setSuccessSnackbaropen] = useState(false);

  // snackbar msg

  const [snackMsg, setSnackMsg] = useState("oops!!");

  const updateListner=()=>{
    setIsModify(!isModify)
  }

  //Device stngs Submit Btn

  const DeviceStingsbtnsubmit = () => {
    if (country && deviceType && brand && apiPath) {
      //addDeviceSettingsApi

      addDeviceSettingsApi({
        country: country?.name,
        deviceType: deviceType?.name,
        brand: brand?.name,
        apiPath: apiPath,
      },
      setSuccessSnackbaropen,
      setSnackMsg,
      setCountry,
      setDeviceType,
      setBrand,
      setApiPath,
      updateListner
      );
    }
    if (country === "") {
      setCountryAlert(true);
      setDeviceTypeAlert(false);
      setBrandAlert(false);
      setApiPathAlert(false);
    } else if (deviceType === "") {
      setCountryAlert(false);
      setDeviceTypeAlert(true);
      setBrandAlert(false);
      setApiPathAlert(false);
    } else if (brand === "") {
      setCountryAlert(false);
      setDeviceTypeAlert(false);
      setBrandAlert(true);
      setApiPathAlert(false);
    }
    else if(apiPath === ""){
      setCountryAlert(false);
      setDeviceTypeAlert(false);
      setBrandAlert(false);
      setApiPathAlert(true);
    }
    else{
      setCountryAlert(false);
      setDeviceTypeAlert(false);
      setBrandAlert(false);
      setApiPathAlert(false);
    }
  };


  // closeSuccessSnackbar

  const closeSuccessSnackbar =()=>{
    setSuccessSnackbaropen(false);
  }

  // Cancel btn ..
  const deviceSettingsCancelBtn=()=>{
    setCountry("")
    setDeviceType("");
    setBrand("");
    setApiPath("");
  }

  // view all device list
useEffect(()=>{
  viewAllDeviceList(setAllDeviceList)
},[isModify])



  return (
    <>
      <TopContainer label="Device Settings" />
      <div className="global-container device-settings-container">
        <p>Device Settings</p>
        <hr />
        <div className="device-settings-form">
          <div className="global-single-input device-settings-input auto-complete">
            <p>Country</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={CountryListWithCode||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} placeholder="Select Country"/>}
              value={country}
              onChange={(e,newValue)=>setCountry(newValue)}

            />
            {CountryAlert && (
              <p className="Country-validation-alert">Select a Country</p>
            )}
          </div>
          <div className="global-single-input device-settings-input auto-complete">
            <p>Device Type</p>
          
              <Autocomplete
              sx={{width:"100%"}}
              options={deviceList||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} placeholder="Select Device Type"/>}
              value={deviceType}
              onChange={(e,newValue)=>setDeviceType(newValue)}

            />
            {DeviceTypeAlert && (
              <p className="Country-validation-alert">Select Device Type</p>
            )}
          </div>
          <div className="global-single-input device-settings-input auto-complete">
            <p>Brand</p>
                <Autocomplete
              sx={{width:"100%"}}
              options={brandList||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} placeholder="Select Brand"/>}
              value={brand}
              onChange={(e,newValue)=>setBrand(newValue)}

            />
            {brandAlert && (
              <p className="Country-validation-alert">Select Brand</p>
            )}
          </div>
          <div className="global-single-input device-settings-input">
            <p>API Path</p>
            <input
              type="text"
              placeholder="Api Path"
              value={apiPath}
              onChange={(e) => setApiPath(e.target.value)}
            />
            {ApipathAlert && (
              <p className="Country-validation-alert">Enter Api path</p>

            )}
          </div>
        </div>
        <div className="device-settings-cancel-submit-btn-container">
          <button className="global-button-contained-black-outlined"
            onClick={deviceSettingsCancelBtn}
          >
            Cancel
          </button>
          <button
            className="global-button-contained-blue"
            onClick={DeviceStingsbtnsubmit}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="provider-list-container">
      <p>Device Setting List</p>
      <div className="global-container-table">
        <table>
           <thead>
             <tr>
               <th>No</th>
               <th>Device Type</th>
               <th>Brand</th>
               <th>Path</th>
             </tr>
           </thead>
           <tbody>
            {
              allDeviceList?.map((r,i)=>(
              <tr>
                <td>{i+1}</td>
                <td>{r?.deviceType}</td>
                <td>{r?.brand}</td>
                <td>{r?.apiPath}</td>
              </tr>
              ))
            }
            
           </tbody>
        </table>
      </div>
      </div>
      <SuccessSnackbar
        open={successSnackbaropen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
    </>
  );
};
