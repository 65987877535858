import { IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import TopContainer from "../CustomComponent/TopContainer";
import TopClientsView from "../CustomComponent/TopClientsView";
 export const TotalRevenue = () => {
  
  const [openClientView, setOpenClientView] = useState(false);
  const clickClientView = () => {
    setOpenClientView(true);
  };
  const closeConfirmDialog=()=>{
    setOpenClientView(false)
    }
 
 
  return (
    <>
    <div>
      <TopContainer label='Top Clients'/>
      <div className="global-container reg-user-container">
        <div className="global-container-table">
        <table>
            <thead>
                <tr>
                <th>Customer ID</th>
                <th>Customer Name</th>
                <th>Contact</th>
                <th>Package</th>
                <th>Action</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>CUS9887</td>
                    <td>Nihad</td>
                    <td>960703050</td>
                    <td>ERP Accounting</td>
                    <td>
                    <Tooltip title="View">
                    <IconButton onClick={clickClientView} >
                      <VisibilityOutlinedIcon sx={{ color: "#83a62f" }} />
                    </IconButton>
                  </Tooltip>
                    </td>
                </tr>

                <tr>
                    <td>CUS9887</td>
                    <td>Nihad</td>
                    <td>960703050</td>
                    <td>ERP Accounting</td>
                    <td>
                    <Tooltip title="View">
                    <IconButton >
                      <VisibilityOutlinedIcon sx={{ color: "#83a62f" }} />
                    </IconButton>
                  </Tooltip>
                    </td>
                </tr>
                <tr>
                    <td>CUS9887</td>
                    <td>Nihad</td>
                    <td>960703050</td>
                    <td>ERP Accounting</td>
                    <td>
                    <Tooltip title="View">
                    <IconButton >
                      <VisibilityOutlinedIcon sx={{ color: "#83a62f" }} />
                    </IconButton>
                  </Tooltip>
                    </td>
                </tr>
                <tr>
                    <td>CUS9887</td>
                    <td>Nihad</td>
                    <td>960703050</td>
                    <td>ERP Accounting</td>
                    <td>
                    <Tooltip title="View">
                    <IconButton >
                      <VisibilityOutlinedIcon sx={{ color: "#83a62f" }} />
                    </IconButton>
                  </Tooltip>
                    </td>
                </tr>
                <tr>
                    <td>CUS9887</td>
                    <td>Nihad</td>
                    <td>960703050</td>
                    <td>ERP Accounting</td>
                    <td>
                    <Tooltip title="View">
                    <IconButton >
                      <VisibilityOutlinedIcon sx={{ color: "#83a62f" }} />
                    </IconButton>
                  </Tooltip>
                    </td>
                </tr>
                <tr>
                    <td>CUS9887</td>
                    <td>Nihad</td>
                    <td>960703050</td>
                    <td>ERP Accounting</td>
                    <td>
                    <Tooltip title="View">
                    <IconButton >
                      <VisibilityOutlinedIcon sx={{ color: "#83a62f" }} />
                    </IconButton>
                  </Tooltip>
                    </td>
                </tr>
            </tbody>
        </table>

        
        </div>
        </div>
        </div>
        <TopClientsView open={openClientView} onClose={closeConfirmDialog} />
    </>
  );
};

export default TotalRevenue;
