import React, { useEffect, useState } from 'react'
import TopContainer from '../CustomComponent/TopContainer'
import { Autocomplete, TextField } from '@mui/material'
import CountryListWithCode from "../../../Assets/JSON/CountryLIstWithCode.json"
import { addWhatsappPackageAPICall, viewAllWhatsappPackageAPICall } from '../../../API/Add_sms_Package/smsPackage'
import { useSelector } from 'react-redux'
export const WhatsappPackage = () => {
    const whatsappPackage=useSelector((state)=>state.getAllsmsPackageSlice.whatsapp)
    const formValuesInitialState={
        country:null,
        purchaseRate:"",
        purchaseQty:"",
        sellingRate:"",
        sellingQty:"",
        startDate:"",
        endDate:""

    }
    const [formValues,setFormValues]=useState(formValuesInitialState)
    const changeFormValues = (key) => (e, newValue) => {
        const value = newValue !== undefined ? newValue : e.target.value;
        setFormValues({
          ...formValues,
          [key]: value
        });
      };
      const clearFn=()=>{
        setFormValues(formValuesInitialState)
      }
      const bodyToapi={
        country:formValues?.country?.name,
        purchaseRate: Number(formValues?.purchaseRate),
        purchaseQty: Number(formValues?.purchaseQty),
        sellingRate: Number(formValues?.sellingRate),
        sellingQty: Number(formValues?.sellingQty),
        startDate: formValues?.startDate,
        endDate:formValues?.endDate
      }
    
      const submitBtnFn=()=>{
        addWhatsappPackageAPICall(bodyToapi,clearFn)
      }

      useEffect(()=>{
        viewAllWhatsappPackageAPICall()
      },[])
  return (
    <>
      <TopContainer label="SMS > WhatsApp Package" />
      <div className="global-container device-settings-container">
        <p>Add WhatsApp Package</p>
        <hr />
        <div className="device-settings-form">
          <div className="global-single-input auto-complete device-settings-input">
            <p>Country</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={CountryListWithCode||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params}/>}
              value={formValues?.country}
              onChange={changeFormValues("country")}
            />
        
          </div>
          <div className="global-single-input device-settings-input ">
            <p>Purchase Rate</p>
            <input
              type="text"
              value={formValues?.purchaseRate}
              onChange={changeFormValues("purchaseRate")}
             
              placeholder="Enter purchase Rate..."
            />
          
          </div>
          <div className="global-single-input device-settings-input ">
            <p>Purchase Qty</p>
            <input
              type="text"
              value={formValues?.purchaseQty}
              onChange={changeFormValues("purchaseQty")}
              placeholder="Enter Qty.."
            />
         
          </div>
          <div className="global-single-input device-settings-input ">
            <p>Selling Rate</p>
            <input
              type="text"
              value={formValues?.sellingRate}
              onChange={changeFormValues("sellingRate")}
              placeholder="Enter Selling Rate.."
            />
       
          </div>
          <div className="global-single-input device-settings-input ">
            <p>Selling Qty</p>
            <input
              type="text"
              value={formValues?.sellingQty}
              onChange={changeFormValues("sellingQty")}
              placeholder="Enter Selling Qty"
            />
        
          </div>
          <div className="global-single-input device-settings-input ">
            <p>Start Date</p>
            <input
              type="date"
              value={formValues?.startDate}
              onChange={changeFormValues("startDate")}
            />
          
          </div>
          <div className="global-single-input device-settings-input ">
            <p>End Date</p>
            <input
              type="date"
              value={formValues?.endDate}
              onChange={changeFormValues("endDate")}
            />
           
          </div>
        </div>
        <div className="device-settings-cancel-submit-btn-container">
          <button className="global-button-contained-black-outlined" >
            Cancel
          </button>
          <button className="global-button-contained-blue"  onClick={submitBtnFn} >
            Submit
          </button>
        </div>
      </div>
      <div className="provider-list-container">
        <p>WhatsApp Package List</p>
        <div className="global-container-table">
          <table>
            <thead>
              <tr>
                <th>No.</th>
                <th>Country</th>
                <th>Purchase Rate</th>
                <th>Purchase Qty</th>
                <th>Selling Rate</th>
                <th>Selling Qty</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
             <tbody>
                {
                    whatsappPackage?.map((r,i)=>(
                        <tr>
                            <td>{i+1}</td>
                            <td>{r?.country}</td>
                            <td>{r?.purchaseRate}</td>
                            <td>{r?.purchaseQty}</td>
                            <td>{r?.sellingRate}</td>
                            <td>{r?.sellingQty}</td>
                            <td>{r?.startDate}</td>
                            <td>{r?.endDate}</td>
                        </tr>
                    ))
                }
             
             </tbody>
           

           
            
          
          </table>
        </div>
      </div>
    </>
  )
}
