import { createSlice } from "@reduxjs/toolkit";

export const designationSlice=createSlice({
    name:"designationSlice",
    initialState:{
        value:undefined
    },
    reducers:{
        get_all_designation:(state,action)=>{
            state.value=action.payload.designationData
        }
    }
})

export const {get_all_designation}=designationSlice.actions;
export default designationSlice.reducer