import { Dialog } from "@mui/material";
import React from "react";
import "../../../Css/Custom css/deleteConfirmationDialog.css";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
const DeleteConfirmationDialog = (props) => {
  const { open, onClose } = props;
  return (
    <>
      <Dialog maxWidth="lg" open={open} >
        <div className="delete-confirm-parent">
          <h2>Are you sure delete this customer</h2>
          <div className="question-icon-container">
            <QuestionMarkOutlinedIcon sx={{ color: "#fd0301" ,fontSize:'2rem' }} />
          </div>
          <div className="delete-dialog-confirm-delete-container">
            <button onClick={onClose} className='cancel'>Cancel</button>
            <button  className='submit'>Submit</button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DeleteConfirmationDialog;
