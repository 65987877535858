import React from "react";

import "../../../Css/Dashboard/topBar.css";
import { IconButton } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import SearchInput from "./SearchInput";
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { LogoutAdmin } from "../../../Js/Logout";
const TopBar = (props) => {
  const { onClick, expanded } = props;

  //LogoutAdmin



  return (
    <div className="topbar-parent-container">
      {expanded ? (
       <IconButton
       sx={{
         height: "40px",
         width: "40px",
         marginLeft: "1%",
         marginRight: "auto",
       }}
       id="topBarToggleButton"
       onClick={onClick}
     >
       <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "1.3rem", color: "#000" }} />
     </IconButton>
      ) : (
        <IconButton
          sx={{
            height: "40px",
            width: "40px",
            marginLeft: "1%",
            marginRight: "auto",
          }}
          id="topBarToggleButton"
          onClick={onClick}
        >
          <MenuOutlinedIcon sx={{ fontSize: "1.3rem", color: "#000" }} />
        </IconButton>
      )}

      <SearchInput />
      <IconButton sx={{ height: "40px", width: "40px", marginLeft: "1%" }}>
        <NotificationsNoneOutlinedIcon
          sx={{ fontSize: "2rem", color: "#000" }}
        />
      </IconButton>
      <IconButton sx={{ height: "40px", width: "40px", marginLeft: "1%" }}>
        <EmailOutlinedIcon sx={{ fontSize: "2rem", color: "#000" }} />
      </IconButton>
      <div className="logout-container" onClick={LogoutAdmin}>
        <PowerSettingsNewOutlinedIcon
          sx={{ margin: "0 2% 0 0", color: "#e60000", fontSize: "1.2rem"}}
        />
        <p>Logout</p>
      </div>
    </div>
  );
};

export default TopBar;
