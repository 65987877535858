import { Dialog } from "@mui/material";
import React from "react";
import { MenuItem, Select } from "@mui/material";
import  "../../../Css/Custom css/refundtransaction.css"
import { useState } from "react";
import { current } from "@reduxjs/toolkit";
const RefundTransaction =(props)=>{
    const{open,onClose}=props;
    const[paymentClicked,setPaymentClicked]=useState(false)
    const clickPayment =()=>{
        setPaymentClicked(current=>!current);
    } 
    const closePayment =()=>{
        setPaymentClicked(false)
    } 
        
    
    return(
        <>
        <Dialog maxWidth="lg" open={open}>
        <div className="refund-transaction">
        <div className="refund-transaction-parent">
        <div className="refund-transaction-head-container">
        <div className="refund-transaction-head-container-top">
        <h1>Refund</h1>
        </div>
        <div className="refund-transaction-head-container-bottom">
        <div className="global-single-input package-plans-input" style={{width:'31%'}}>
                <p>Transaction ID</p>
                <input  type="text" placeholder="PKLMS564789" disabled="disabled" style={{fontSize:'0.8rem'}} />
            </div>
        </div>
        </div>
        <div className="refund-transaction-body-container">
        <div className="refund-transaction-body-container-top" >
            <div className="global-single-input package-plans-input" style={{width:'35%'}}>
                <p>Customer ID</p>
                <input  type="text" placeholder="CUS2000" disabled="disabled" style={{fontSize:'0.7rem'}}/>
            </div>
            <div className="global-single-input package-plans-input" style={{width:'35%'}} >
                <p>Name</p>
                <input  type="text" placeholder="Nihad" disabled="disabled" style={{fontSize:'0.7rem'}}/>
            </div>
            <div className="global-single-input package-plans-input" style={{width:'35%'}}>
                <p>Contact</p>
                <input  type="text" placeholder="9495894641" disabled="disabled" style={{fontSize:'0.7rem'}} />
            </div>
        </div>
        <div className="refund-transaction-body-container-top" >
            <div className="global-single-input package-plans-input" style={{width:'35%'}}>
                <p>Account Details</p>
                <input  type="text" placeholder="Acccounts" disabled="disabled" style={{fontSize:'0.7rem'}}/>
            </div>
            <div className="global-single-input package-plans-input" style={{width:'35%'}}>
                <p>Transfer Details</p>
                <input  type="text" placeholder="RSFG848849" disabled="disabled" style={{fontSize:'0.7rem'}}/>
            </div>
            <div className="global-single-input package-plans-input" style={{width:'35%'}}>
                <p>Transfer Amount</p>
                <input  type="text" placeholder="2500.00" disabled="disabled" style={{fontSize:'0.7rem'}}/>
            </div>
        </div>
        <div className="refund-transaction-body-container-bottom" >
            <div className="global-single-input package-plans-input" style={{width:'31%'}}>
                <p>Reason</p>
                <input  type="text" placeholder="timepass" disabled="disabled" style={{fontSize:'0.7rem'}} />
            </div>
        </div>        
        </div>
        </div>     
        {paymentClicked?
        <div className="refund-transaction-body-payment-container">
       <div className="refund-transaction-body-payment-section">
            <div className="refund-transaction-body-payment-section-top">
                <p>Refund Amount</p>
                <input />
            </div>
            <div className="refund-transaction-body-payment-section-bottom">
            <div className="global-single-input package-plans-input" style={{width:'23%'}}>
                <p>Payment Method</p>
                <Select>
                    <MenuItem>Card</MenuItem>
                    <MenuItem>UPI</MenuItem>
                </Select> 
            </div>
            <div className="global-single-input package-plans-input" style={{width:'23%'}}>
                <p>Card Name</p>
                <Select>
                    <MenuItem>Carsddd</MenuItem>
                    <MenuItem>ukkl</MenuItem>
                </Select> 
            </div>
            <div className="global-single-input package-plans-input" style={{width:'23%'}}>
                <p>Card No</p>
                <input  type="text" style={{fontSize:'0.7rem'}}/>
            </div>
            <div className="global-single-input package-plans-input" style={{width:'23%'}}>
                <p>CVV</p>
                <input  type="text" style={{fontSize:'0.7rem'}}/>
            </div>
            </div>
       </div>
        <div className="refund-transaction-container-button">
                <div className="refund-transaction-container-button-ctn">
                    <button onClick={closePayment} className="cancel-btn">Cancel</button>
                </div>
                <div className="refund-transaction-container-button-ctn">
                <button className="submit-btn">Submit</button>
                </div>
        </div>
        </div>:<div className="refund-transaction-container-button">
                <div className="refund-transaction-container-button-ctn">
                    <button onClick={onClose} className="cancel-btn">Cancel</button>
                </div>
                <div className="refund-transaction-container-button-ctn">
                <button className="submit-btn" onClick={clickPayment}>Payment</button>
                </div>
        </div>}
        </div>
        </Dialog>
        </>
    );
};

export default RefundTransaction;


