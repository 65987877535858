import { createSlice } from "@reduxjs/toolkit";

export const demoRequestSlice=createSlice({
    name:"demoRequestSlice",
    initialState:{
       list:undefined
    },
    reducers:{
        get_demo_request:(state,action)=>{
            state.list=action.payload.demoRequestData
        },
    }
})
export const {get_demo_request}=demoRequestSlice.actions;
export default demoRequestSlice.reducer