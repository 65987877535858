import React, { useState } from "react";
import "../../../../Css/Staff/HRM/viewEmployee.css";
import "react-calendar/dist/Calendar.css";
import { EmployeeDashboard } from "./ViewEmployeeTabs/EmployeeDashboard";
import { EmpPersonalInfo } from "./ViewEmployeeTabs/EmpPersonalInfo";
import { EmpCompanyInfo } from "./ViewEmployeeTabs/EmpCompanyInfo";
import { EmpBankDetails } from "./ViewEmployeeTabs/EmpBankDetails";
import { EmpLoanDetails } from "./ViewEmployeeTabs/EmpLoanDetails";
import { EmpLeaveDetails } from "./ViewEmployeeTabs/EmpLeaveDetails";
import { EmpPayslip } from "./ViewEmployeeTabs/EmpPayslip";
import { EmpDocumentDetails } from "./ViewEmployeeTabs/EmpDocumentDetails";

export const ViewEmployee = () => {
  const singleEmpData = "";

  //states
  const [activeTab, setActiveTab] = useState("dashboard");

  //dashboardMenuClick
  const dashboardMenuClick = () => {
    document.getElementById("dashboardBtn").classList.add("tab-active");
    document.getElementById("personalInfoBtn").classList.remove("tab-active");
    document.getElementById("companyInfoBtn").classList.remove("tab-active");
    document.getElementById("bankDetailsBtn").classList.remove("tab-active");
    document.getElementById("loanDetailsBtn").classList.remove("tab-active");
    document.getElementById("leaveDetailsBtn").classList.remove("tab-active");
    document.getElementById("paySlipsBtn").classList.remove("tab-active");
    document.getElementById("documentBtn").classList.remove("tab-active");

    setActiveTab("dashboard");
  };

  const personalInfoMenuClick = () => {
    document.getElementById("dashboardBtn").classList.remove("tab-active");
    document.getElementById("personalInfoBtn").classList.add("tab-active");
    document.getElementById("companyInfoBtn").classList.remove("tab-active");
    document.getElementById("bankDetailsBtn").classList.remove("tab-active");
    document.getElementById("loanDetailsBtn").classList.remove("tab-active");
    document.getElementById("leaveDetailsBtn").classList.remove("tab-active");
    document.getElementById("paySlipsBtn").classList.remove("tab-active");
    document.getElementById("documentBtn").classList.remove("tab-active");
    setActiveTab("personalInfo");
  };

  const companyInfoMenuClick = () => {
    document.getElementById("dashboardBtn").classList.remove("tab-active");
    document.getElementById("personalInfoBtn").classList.remove("tab-active");
    document.getElementById("companyInfoBtn").classList.add("tab-active");
    document.getElementById("bankDetailsBtn").classList.remove("tab-active");
    document.getElementById("loanDetailsBtn").classList.remove("tab-active");
    document.getElementById("leaveDetailsBtn").classList.remove("tab-active");
    document.getElementById("paySlipsBtn").classList.remove("tab-active");
    document.getElementById("documentBtn").classList.remove("tab-active");
    setActiveTab("companyInfo");
  };

  const bankDetailsMenuClick = () => {
    document.getElementById("dashboardBtn").classList.remove("tab-active");
    document.getElementById("personalInfoBtn").classList.remove("tab-active");
    document.getElementById("companyInfoBtn").classList.remove("tab-active");
    document.getElementById("bankDetailsBtn").classList.add("tab-active");
    document.getElementById("loanDetailsBtn").classList.remove("tab-active");
    document.getElementById("leaveDetailsBtn").classList.remove("tab-active");
    document.getElementById("paySlipsBtn").classList.remove("tab-active");
    document.getElementById("documentBtn").classList.remove("tab-active");
    setActiveTab("bankDetails");
  };

  const loanDetailsMenuClick = () => {
    document.getElementById("dashboardBtn").classList.remove("tab-active");
    document.getElementById("personalInfoBtn").classList.remove("tab-active");
    document.getElementById("companyInfoBtn").classList.remove("tab-active");
    document.getElementById("bankDetailsBtn").classList.remove("tab-active");
    document.getElementById("loanDetailsBtn").classList.add("tab-active");
    document.getElementById("leaveDetailsBtn").classList.remove("tab-active");
    document.getElementById("paySlipsBtn").classList.remove("tab-active");
    document.getElementById("documentBtn").classList.remove("tab-active");
    setActiveTab("loanDetails");
  };

  const leaveDetailsMenuClick = () => {
    document.getElementById("dashboardBtn").classList.remove("tab-active");
    document.getElementById("personalInfoBtn").classList.remove("tab-active");
    document.getElementById("companyInfoBtn").classList.remove("tab-active");
    document.getElementById("bankDetailsBtn").classList.remove("tab-active");
    document.getElementById("loanDetailsBtn").classList.remove("tab-active");
    document.getElementById("leaveDetailsBtn").classList.add("tab-active");
    document.getElementById("paySlipsBtn").classList.remove("tab-active");
    document.getElementById("documentBtn").classList.remove("tab-active");
    setActiveTab("leaveDetails");
  };

  const paySlipMenuClick = () => {
    document.getElementById("dashboardBtn").classList.remove("tab-active");
    document.getElementById("personalInfoBtn").classList.remove("tab-active");
    document.getElementById("companyInfoBtn").classList.remove("tab-active");
    document.getElementById("bankDetailsBtn").classList.remove("tab-active");
    document.getElementById("loanDetailsBtn").classList.remove("tab-active");
    document.getElementById("leaveDetailsBtn").classList.remove("tab-active");
    document.getElementById("paySlipsBtn").classList.add("tab-active");
    document.getElementById("documentBtn").classList.remove("tab-active");
    setActiveTab("paySlip");
  };

  const documentDetailsClick = () => {
    document.getElementById("dashboardBtn").classList.remove("tab-active");
    document.getElementById("personalInfoBtn").classList.remove("tab-active");
    document.getElementById("companyInfoBtn").classList.remove("tab-active");
    document.getElementById("bankDetailsBtn").classList.remove("tab-active");
    document.getElementById("loanDetailsBtn").classList.remove("tab-active");
    document.getElementById("leaveDetailsBtn").classList.remove("tab-active");
    document.getElementById("paySlipsBtn").classList.remove("tab-active");
    document.getElementById("documentBtn").classList.add("tab-active");
    setActiveTab("document");
  };

  return (
    <div className="global-page-parent-container1">
      <p style={{ marginLeft: "1%" }} className="breadcrumb">
        Staff &gt; HRM &gt; Manage Employee &gt; View Employee{" "}
      </p>

      <div className="manage-employee-view-employee-top-container global-container">
        <div className="manage-employee-view-employee-top-left-container">
          <div className="view-employee-employee-image">
            <img src={singleEmpData?.imageUrl} alt="employee" />
          </div>
          <h4>{singleEmpData?.staff_name}</h4>
          <p>{singleEmpData?.designationName}</p>
        </div>
        <div className="manage-employee-view-employee-top-right-container">
          <div className="view-employee-top-right-sub-container">
            <h4>Attendance</h4>
            <div className="view-employee-top-right-attendence">
              <h5>January</h5>
              <p>
                26 <span>/31</span>
              </p>
            </div>
          </div>
          <div className="view-employee-top-right-sub-container">
            <h4>Leaves</h4>
            <div className="view-employee-top-right-attendence">
              <h5>2022</h5>
              <p style={{ color: "red" }}>
                0 <span>/50</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* tabs */}
      <div className="add-employee-container">
        <div className="add-employee-top-container">
          <div className="add-employee-top-sub-container">
            <div
              className="add-employee-single-tab tab-active"
              id="dashboardBtn"
              onClick={dashboardMenuClick}
            >
              <p>Dashboard</p>
            </div>
            <div
              className="add-employee-single-tab"
              id="personalInfoBtn"
              onClick={personalInfoMenuClick}
            >
              <p>Personal Info</p>
            </div>
            <div
              className="add-employee-single-tab"
              id="companyInfoBtn"
              onClick={companyInfoMenuClick}
            >
              <p>Company Info</p>
            </div>
            <div
              className="add-employee-single-tab "
              id="bankDetailsBtn"
              onClick={bankDetailsMenuClick}
            >
              <p>Bank Details</p>
            </div>
            <div
              className="add-employee-single-tab "
              id="loanDetailsBtn"
              onClick={loanDetailsMenuClick}
            >
              <p>Loan Details</p>
            </div>
            <div
              className="add-employee-single-tab"
              id="leaveDetailsBtn"
              onClick={leaveDetailsMenuClick}
            >
              <p>Leave Details</p>
            </div>
            <div
              className="add-employee-single-tab "
              id="paySlipsBtn"
              onClick={paySlipMenuClick}
            >
              <p>Payslip</p>
            </div>
            <div
              className="add-employee-single-tab "
              id="documentBtn"
              onClick={documentDetailsClick}
            >
              <p>Document Details</p>
            </div>
          </div>
        </div>
        <div className="add-employee-bottom-container">
          {activeTab === "dashboard" ? (
            <EmployeeDashboard /> 
          ) : activeTab === "personalInfo" ? (
            <EmpPersonalInfo />
          ) : activeTab === "companyInfo" ? (
            <EmpCompanyInfo />
          ) : activeTab === "bankDetails" ? (
            <EmpBankDetails />
          ) : activeTab === "loanDetails" ? (
            <EmpLoanDetails />
          ) : activeTab === "leaveDetails" ? (
            <EmpLeaveDetails />
          ) : activeTab === "paySlip" ? (
            <EmpPayslip />
          ) : activeTab === "document" ? (
            <EmpDocumentDetails />
          ) : undefined}
        </div>
      </div>
    </div>
  );
};
