import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { HEADERS } from "../../API/urlAndPath";

export const getDataInfo = createAsyncThunk('anyname',()=>{
    return axios.get('customer/getAllSalesCustomers',HEADERS)
    .then((res)=>{
        return res.data
    })
})



const marketingSlice = createSlice({
    name:"marketingSliceSection",
    initialState:{
        data:[],
        error:'',
        loading:false,
    },
    extraReducers:{
        [getDataInfo.pending]:(state,action)=>{
            console.log("Pending....")
            state.loading = true;

        },
        [getDataInfo.fulfilled] : (state,action) =>{
            console.log("full filled.....")
            state.loading =false;
            state.data = action.payload
        },
        [getDataInfo.rejected]:(state,action)=>{
            state.loading=false;
            state.error = "Some Error Occured...."
        },

    }
    
});

export default marketingSlice.reducer