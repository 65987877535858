/** @format */

import React from "react";
import TopContainer from "../CustomComponent/TopContainer";
import { Select, MenuItem, Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { IconButton } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import { useState } from "react";
import {
  addSmsProvider,
  getSmsProvider,
} from "../../../API/Sms_Provider/SmsProviderApi";
import { useEffect } from "react";
import { useSelector } from "react-redux";
// css from deviceSettings.css
export const SmsProvider = () => {
  const getAllsmsProviderSlice = useSelector((state) => {
    console.log("***************");
    return state.getAllsmsProvider.value;
  });

  console.log("<<<<<<<<----------->>>>>>>", getAllsmsProviderSlice);

  const [Country, setCountry] = useState(null);
  const [provider, setProvider] = useState(null);
  const [apiPath, setApiPath] = useState(null);
  const [userId, setUserId] = useState(null);
  const [password, setPassword] = useState(null);
  const [getdata, setGetdata] = useState(false);

  //Loading

  const [isLoading,setIsLoading] = useState(true);


  //Alerts hooks
  const [providerAlert, setproviderAlert] = useState(null);
  const [countryAlert, setCountryAlert] = useState(null);
  const [apiAlert, setApiAlert] = useState(null);
  const [userIdAlert, setUserIdAlert] = useState(null);
  const [passwordAlert, setPasswordAlert] = useState(null);

  const formdataSubmition = () => {
    if (Country == null) {
      setCountryAlert(true);
    } else if (provider == null) {
      setCountryAlert(false);
      setproviderAlert(true);
    } else if (apiPath == null) {
      setCountryAlert(false);
      setproviderAlert(false);
      setApiAlert(true);
    } else if (userId == null) {
      setUserIdAlert(true);
      setCountryAlert(false);
      setproviderAlert(false);
      setApiAlert(false);
    } else if (password == null) {
      setUserIdAlert(false);
      setCountryAlert(false);
      setproviderAlert(false);
      setApiAlert(false);
      setPasswordAlert(true);
    } else {
      setUserIdAlert(false);
      setCountryAlert(false);
      setproviderAlert(false);
      setApiAlert(false);
      setPasswordAlert(false);

      //addSmsProvider Api Call...
      addSmsProvider(
        {
          country: Country,
          provider: provider,
          apiPath: apiPath,
          userId: userId,
          password: password,
        },
        setGetdata,
        getdata,
        setCountry,
        setProvider,
        setApiPath,
        setUserId,
        setPassword
      );
    }
  };

  useEffect(() => {
    //getSMS Provider
    getSmsProvider(setIsLoading);
  }, [getdata]);

  return (
    <>
      <TopContainer label="SMS > SMS Provider" />
      <div className="global-container device-settings-container">
        <p>SMS Provider</p>
        <hr />
        <div className="device-settings-form">
          <div className="global-single-input device-settings-input ">
            <p>Country</p>
            <Select
              onChange={(e) => setCountry(e.target.value)}
              value={Country}
            >
              <MenuItem value={"india"}>india</MenuItem>
              <MenuItem value={"Germen"}>Germen</MenuItem>
              <MenuItem value={"Portugal"}>Portugal</MenuItem>
              <MenuItem value={"maxico"}>maxico</MenuItem>
            </Select>
            {countryAlert && (
              <p className="Country-validation-alert">Select a Country</p>
            )}
          </div>

          <div className="global-single-input device-settings-input ">
            <p>Provider</p>
            <Select
              onChange={(e) => setProvider(e.target.value)}
              value={provider}
            >
              <MenuItem value={"1stLine"}>1stLine</MenuItem>
              <MenuItem value={"2ndLine"}>2ndLine</MenuItem>
              <MenuItem value={"3rdLine"}>3rdLine</MenuItem>
              <MenuItem value={"4thLine"}>4thLine</MenuItem>
            </Select>
            {providerAlert && (
              <p className="Country-validation-alert">Select a Provider</p>
            )}
          </div>
          <div className="global-single-input device-settings-input ">
            <p>API Path</p>
            <input
              type="text"
              value={apiPath}
              onChange={(e) => setApiPath(e.target.value)}
            />
            {apiAlert && (
              <p className="Country-validation-alert">Enter api Path</p>
            )}
          </div>
          <div className="global-single-input device-settings-input ">
            <p>User ID</p>
            <input
              type="text"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
            {userIdAlert && (
              <p className="Country-validation-alert">Enter User ID</p>
            )}
          </div>
          <div className="global-single-input device-settings-input ">
            <p>Password</p>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordAlert && (
              <p className="Country-validation-alert">Enter Password</p>
            )}
          </div>
        </div>
        <div className="device-settings-cancel-submit-btn-container">
          <button className="global-button-contained-black-outlined">
            Cancel
          </button>
          <button
            className="global-button-contained-blue"
            onClick={formdataSubmition}
          >
            Submit
          </button>
        </div>
      </div>
      <div className="provider-list-container">
        <p>SMS Provider List</p>
        <div className="global-container-table">
          <table>
            <thead>
              <tr>
                <th>No.</th>
                <th>Country</th>
                <th>Provider</th>
                <th>Status</th>
                <th>Edit</th>
                <th>Remove</th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
              <tr>
                <td colSpan={10}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={"100%"}
                    height={30}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={10}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={"100%"}
                    height={30}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={10}>
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width={"100%"}
                    height={30}
                  />
                </td>
              </tr>
            </tbody>

            ):(

              <tbody>
              {getAllsmsProviderSlice?.map((r, i) => (
                <tr>
                  <td>{i+1}</td>
                  <td>{r?.country}</td>
                  <td>{r?.provider}</td>
                  <td>Active</td>
                  <td>
                    <IconButton>
                      {" "}
                      <FontAwesomeIcon
                        icon={faPenToSquare}
                        color="#8373f1"
                        size="sm"
                      />
                    </IconButton>
                  </td>
                  <td>
                    {" "}
                    <IconButton>
                      <DeleteOutlined sx={{ color: "#f80000" }} />
                    </IconButton>{" "}
                  </td>
                </tr>
              ))}
            </tbody>

            )}
            
            {/* <tbody>
              <tr>
                <td>01</td>
                <td>India</td>
                <td>Airtel</td>
                <td>Active</td>
                <td>
                  <IconButton>
                    {" "}
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      color="#8373f1"
                      size="sm"
                    />
                  </IconButton>
                </td>
                <td>
                  {" "}
                  <IconButton>
                    <DeleteOutlined sx={{ color: "#f80000" }} />
                  </IconButton>{" "}
                </td>
              </tr>
            </tbody> */}
          </table>
        </div>
      </div>
    </>
  );
};
