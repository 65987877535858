import { createSlice } from "@reduxjs/toolkit";
const quickAccessSlice = createSlice({
    name:'quickAccessSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        get_quickAccess:(state,action)=>{
            state.value = action.payload.quickAccessData
        }
    }
});

export const {get_quickAccess} = quickAccessSlice.actions;
export default quickAccessSlice.reducer;