import { IconButton } from "@mui/material";
import React from "react";

export const PayslipView=(props)=>{

    const{exitDialog}=props;
    return(<>
        <div className="payroll-payslip-container">
                    <div className="payroll-payslip-container-header">
                        <h3>Payslip</h3>
                        <div className="payroll-payslip-container-header-right">
                            <button className="btn btn-payslip-red"><IconButton><i class="bi bi-arrow-down-circle" style={{color:'red'}}></i></IconButton></button>
                            <button className="btn btn-payslip-blue"><IconButton><i class="bi bi-printer printer-icon" style={{color:'blue'}}></i></IconButton></button>
                            <button className="btn payroll-payslip-container-header-right-close" onClick={exitDialog}>X</button>
                        </div>
                    </div>
                    <div className="payroll-payslip-container-body">
                        <div className="payroll-payslip-container-body-section">
                                <div className="payroll-payslip-container-body-top">
                                    <div className="payroll-payslip-container-body-top-section">
                                        <p>Employee Name: Munusi</p>
                                        <p>Employee ID: EMP200</p>
                                    </div>
                                    <div className="payroll-payslip-container-body-top-section">
                                        <p>Total Leave: 100</p>
                                    </div>
                                </div>
                                <div className="payroll-payslip-container-body-table">
                                    <div className="payroll-payslip-container-body-table-container">
                                        <div className="payroll-payslip-container-body-table-section">
                                            <div className="payroll-payslip-container-body-table-section-top">
                                                <h3>Earnings</h3>
                                            </div>
                                            <div className="payroll-payslip-container-body-table-section-body">
                                                <div className="payroll-payslip-container-body-table-section-body-left">
                                                    <div className="payroll-payslip-container-body-table-section-body-left-top">
                                                        <h5>Pay Type</h5>
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-left-body">
                                                        <h5>Basic Salary</h5>
                                                        <h5>HRA allowance</h5>
                                                        <h5>DA Allowance</h5>
                                                        <h5>Bonus</h5>
                                                        <h5>Travel Allowance</h5>
                                                        <h5>Other Allowance</h5>
                                                        <h5>Over Time</h5>
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-left-total">
                                                        <h4>Total Earnings</h4>
                                                    </div>
                                                </div>
                                                <div className="payroll-payslip-container-body-table-section-body-right">
                                                    <div className="payroll-payslip-container-body-table-section-body-right-top">
                                                        <h5>Amount</h5>
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-right-body">
                                                        <>
                                                        <h5>200000</h5>
                                                        <h5>12000</h5>
                                                        <h5>2000</h5>
                                                        <h5>4500</h5>
                                                        <h5>300</h5>
                                                        <h5>450</h5>
                                                        <h5>1200</h5>
                                                        </>
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-right-total">
                                                        <h5>225000</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="payroll-payslip-container-body-table-section">
                                            <div className="payroll-payslip-container-body-table-section-top">
                                                <h3>Deduction</h3>
                                            </div>
                                            <div className="payroll-payslip-container-body-table-section-body">
                                                <div className="payroll-payslip-container-body-table-section-body-left">
                                                    <div className="payroll-payslip-container-body-table-section-body-left-top">
                                                        <h5>Pay Type</h5>
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-left-body">
                                                        <h5>Loan</h5>
                                                        <h5>EMI</h5>
                                                        <h5>EPF</h5>
                                                        <h5>ESI</h5>
                                                        <h5>TDS</h5>
                                                        <h5>Medical Claim</h5>
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-left-total">
                                                        <h4>Total Deductions</h4>
                                                    </div>
                                                </div>
                                                <div className="payroll-payslip-container-body-table-section-body-right">
                                                    <div className="payroll-payslip-container-body-table-section-body-right-top">
                                                        <h5>Amount</h5>
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-right-body">
                                                        <>
                                                        <h5>15000</h5>
                                                        <h5>1200</h5>
                                                        <h5>200</h5>
                                                        <h5>750</h5>
                                                        <h5>300</h5>
                                                        <h5>100</h5>
                                                        </>
                                                    </div>
                                                    <div className="payroll-payslip-container-body-table-section-body-right-total">
                                                    <h5>250</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                        </div>
                        <div className="payroll-payslip-container-body-total">
                            <div className="payroll-payslip-container-body-total-section">
                                <h3>Net Salary</h3>
                                <p>Inwords</p>
                            </div>
                            <div className="payroll-payslip-container-body-total-section" style={{width:"75%"}}>
                                <h3>{`Rs ${"25000"}`}</h3>
                                {/* <p>{numToWords(empPayslipSingleBody?.netSalary)?.toUpperCase()}</p> */}
                                <p>Two Lakh Fifty Thousand</p>
                            </div>
                        </div>
                        <div className="payroll-payslip-container-body-footer">
                            <h4>Company Address</h4>
                            <p>Lorem Ipsum is simply dummy text of the </p>
                            <p>printing and typesetting industry.</p>
                            <p>Lorem Ipsum</p>
                        </div>
                    </div>
                </div>
    
    </>)
}