import React, { useRef, useState } from "react";
import TopContainer from "../CustomComponent/TopContainer";
import "../../../Css/Registered Users/registeredUsers.css";
import "../../../Css/Registered Users/registeredUsersSingle.css";
import AvatarImg from "../../../Assets/Images/man.jpg";
import { Dialog, IconButton, Tooltip } from "@mui/material";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { balancePaymentAPICall, getSingleCustomer, resetPasswordAPICall, suspendUserApiCall, viewInvoiceOfCustomer } from "../../../API/RegisterdUsers/RegisterdUsersApi";
import { useSelector } from "react-redux";
import printImage from "../../../Assets/Images/invoice2.svg"
import { Invoice } from "./Invoice";
import ReactToPrint from "react-to-print";
import { PrintOutlined } from "@mui/icons-material";
import textileImg from "../../../Assets/Icons/sewing-machine_2_.svg"
import { LoadingForm } from "../../Custom Components/LoadingForm";
import invoice from "../../../Assets/Images/Group 16831.png"
import { currentTime, today } from "../../../Functions/Date";
import SuccessSnackbar from "../../SingleCompoents/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../SingleCompoents/SnackBars/ErrorSnackbar";

const RegisteredUserSingle = () => {
  let invoicePrint = useRef();
  let navigate = useNavigate();
  const singleViewId=localStorage.getItem("singleViewId")
  const getSingleCustomers = useSelector((state)=>state.registerdUsers.singleUserData)
  const invoiceData=useSelector((state)=>state.registerdUsers.customerInvoice)
  const options=[{type:"Textile"},{type:"Restaurant"}]
  const data=[
    {no:121,name:"aswathi",type:"textile",amt:200,validity:"1 year",pDate:"12-12-24",expDate:"12-12-24",noOfOutlet:2,remaining:2,status:"Active"}
  ]

  const params = useParams();
  const [isModify,setIsModify]=useState(false)
  const [addOutlet,setAddOutlet]=useState(false)
  const [openInvoice,setOpenInvoice]=useState(false)
  const [selectedOption, setSelectedOption] = useState();
  const [borderColor, setBorderColor] = useState(getBorderColor(options[0]?.packageName));
  const [isExpand,setIsExpand]=useState(null)
  const [filteredData,setFilteredData]=useState([])
  const [loadingForm,setLoadingForm]=useState(false)
  const [passwordReset,setPasswordReset]=useState(false)
  const [balancePayment,setBalancePayment]=useState(false)
  // const [transactionData,setTransactionData]=useState()
  const [balanceState,setBalanceState]=useState({balance:0,payamount:0,id:""})
  const [passwordState,setPasswordState]=useState({password:"",confirmPassword:""})
  const [snackBarStates, setSnackBarStates] = useState({
    success: false,
    error: false,
    message: "",
    alert: false,
  });
  const closeSnackbar = () => {
    setSnackBarStates({
      ...snackBarStates,
      success: false,
      error: false,
      alert: false,
      message: "",
    });
  };
 const updateListner=()=>{
  setIsModify(!isModify)
 }
  const {registeredUserSingle} = params; //Destructur
  useEffect(()=>{
    getSingleCustomer({_id:singleViewId},setLoadingForm)
},[isModify])

  useEffect(() => {
    setBorderColor(getBorderColor(selectedOption));
  }, [selectedOption]);

 
  const handleOptionClick = (option) => {
    setSelectedOption(option?.packageName);
};

function getBorderColor(type) {
  switch (type) {
    case 'ERP-Textile':
      return '#e15871';
    case 'ERP-Supermarket':
      return 'blue';
    default:
      return 'white'; // Default color if type is not recognized
  }
}

const clickDownArrow=(index)=>{
  setIsExpand(index);
}
const clickUpArrow=()=>{
  setIsExpand(null);
}

const clickBalanceButton=(data)=>{
  // setTransactionData(data)
  setBalancePayment(true)
  setBalanceState({...balanceState,
    balance:data?.balance,
    id:data?._id
  })
}
const clickBlanceSubmitFn=()=>{
  balancePaymentAPICall({
    
      paidAmount:Number(balanceState?.payamount),
      _id:balanceState?.id
  
  },setLoadingForm,setSnackBarStates,snackBarStates,setBalancePayment,updateListner)
}

const suspendUserFn=(data)=>{
   console.log(data);
   suspendUserApiCall(
    {
      cusId:singleViewId,
      date:`${today} ${currentTime}`,
      planId:data?.planId
     }
   )
   
}

const  passwordChange=(key)=>(e)=>{
  setPasswordState({...passwordState,
  [key]:e.target.value
  })
}

const passwordResetBtnFunction=()=>{
  if(passwordState?.password !== passwordState?.confirmPassword){
    alert("password and confirmation do not match.")
  }
  else{
    resetPasswordAPICall({
      password:passwordState?.confirmPassword,
      _id:singleViewId
    },setSnackBarStates,snackBarStates,setPasswordReset)
  }
  
}

const openInvoiceFn=(data)=>{
  // setOpenInvoice(true)
  viewInvoiceOfCustomer({_id:data?._id},setOpenInvoice,setSnackBarStates,snackBarStates)
}

const clickAddOutlet=()=>{
  console.log('aa');
  localStorage.setItem("packageId",filteredData[0]?._id)
  navigate("/dashboard/customerRegistration/cart");
}

useEffect(()=>{
  if(getSingleCustomers !== undefined){
    setSelectedOption(getSingleCustomers?.packageList[0]?.packageName)
  }
},[getSingleCustomers])

useEffect(()=>{
  let data= getSingleCustomers?.packageList?.filter((obj)=>{
       if(obj?.packageName===selectedOption){
          return obj
       }
  })
  setFilteredData(data)
},[selectedOption,getSingleCustomers])




  return (
    <>
      {/* <TopContainer  /> */}
      <div className="registered-user-header-container">
        <div className="reg-user-name-info-wrapper reg-child">
          <img src={AvatarImg} alt="Avatar Image" />
          <p className="user-name">{getSingleCustomers?.companyName}</p>
          <p className="user-type">{}</p>
          {/* <p className="status-active">Active</p> */}
        </div>
        <div className="reg-user-customer-info-wrapper reg-child">
          <div className="customer-info-child">
            <div className="key">Customer ID</div>
            <div className="value">: {getSingleCustomers?.cusId}</div>
          </div>
          <div className="customer-info-child">
            <div className="key">Customer Name</div>
            <div className="value">: {getSingleCustomers?.customerName}</div>
          </div>
      
        </div>
        <div className="reg-user-package-info-wrapper reg-child">
          <div className="package-info-sub">
            <div className="key">Contact</div>
            <div className="value">: {getSingleCustomers?.contact}</div>
          </div>
          <div className="package-info-sub">
            <div className="key">Email</div>
            <div className="value">: {getSingleCustomers?.email}</div>
          </div>
       
        </div>
      </div>

      {/* <div className="registered-user-single-table-container">
        <div style={{display:"flex",justifyContent:"space-between"}}>
           <h3 style={{margin:"0 2%"}}>Package List</h3>
           <button onClick={()=>setAddOutlet(true)} className="global-button-contained-blue-royal">Add Outlets</button>
        </div>
        <div className="global-container-table">
          <table>
            <thead>
              <tr>
                <th>Package</th>
                <th>Amount</th>
                <th>Active Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th>invoice</th>
                <th>View</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ERP Accounting</td>
                <td>1300</td>
                <td>05-05-2022</td>
                <td>10-05-022</td>
                <td>
                  <p className="active">Active</p>
                </td>
                <td>
                 <Tooltip title="Print Invoice">
                      <IconButton
                              onClick={()=>setOpenInvoice(true)}
                              sx={{ width: "37px", height: "40px" }}
                            >
                              <img
                                style={{ width: "100%" }}
                                src={printImage}
                                alt=""
                              />
                            </IconButton>
                          </Tooltip>
                </td>
                <td><IconButton><VisibilityOutlined sx={{ color: "#83a62f" }}/></IconButton></td>
                <td><IconButton> <DeleteOutlineOutlinedIcon sx={{ color: "#fd0301" }} /></IconButton></td>
              </tr>
            
            </tbody>
          </table>
        </div>
      </div> */}

      {/* <div className="registered-user-single-table-container">
        <div style={{display:"flex",justifyContent:"space-between"}}>
           <h3 style={{margin:"0 2%"}}>Added Outlets</h3>
           <h3 style={{margin:"0 2%",color:"red",fontSize:"0.8rem"}}>Total Outlets 03</h3>
        </div>
          
           <div className="global-container-table">
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Outlet Name</th>
                <th>Active Date</th>
                <th>End Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>01</td>
                <td>abc</td>
                <td>05-05-2022</td>
                <td>10-05-022</td>
                <td>
                  <p>Active</p>
                </td>
                <td>
                  <span style={{backgroundColor:"rgb(74 13 183)",color:"#fff",padding:"2% 8%",cursor:"pointer"}}>Renew</span>
                </td>
              </tr>
             
            </tbody>
          </table>
        </div>
      </div> */}

      <div className="single-user-bottom-container">
          <div className="single-user-first-container">
              {getSingleCustomers?.packageList?.map((obj)=>(
                  <div 
                    className={"single-user-top-single-card"} 
                    onClick={() => handleOptionClick(obj)}
                    style={{ borderColor: selectedOption === obj.packageName ? obj?.hexCode : 'white' }}
                    >
                        <div className="single-user-top-single-card-image" style={{backgroundColor:obj?.hexCode}}>
                           <img src={`data:name;base64,${obj?.logo?.data}`} alt="" />
                        </div>
                        <p>{obj?.packageName}</p>
                  </div>
              ))}
          </div>
          <div className="single-user-second-container">
            <p className="package-label">PACKAGE</p>
            <div className="new-global-table-container" style={{backgroundColor:"#eef1f9"}}>
              <table>
                 <thead>
                  <tr>
                     <th>Package No</th>
                     <th>Customer</th>
                     <th>Package Type</th>
                     <th>Amount</th>
                     <th>Validity</th>
                     <th>Purchase Date</th>
                     <th>Expiry Date</th>
                     <th>No Of Outlet</th>
                     <th>Remaining Outlet</th>
                     <th>Status</th>
                     <th><p style={{fontSize:"0.7rem",color:"#11cd11"}}>Active/<span style={{fontSize:"0.7rem",color:"red"}}>Suspend</span></p></th>
                     <th></th>
                     <th></th>
                  </tr>
                 </thead>
                 <tbody>
                  {
                   filteredData !== undefined && filteredData?.length !== 0 ? filteredData?.map((r,i)=>(
                      <>
                      <tr>
                      <td>{r?.packageId}</td>
                      <td>{r?.packageName}</td>
                      <td>{r?.packageType}</td>
                      <td>{r?.amount}</td>
                      <td>{r?.validity}</td>
                      <td>{r?.purchaseDate}</td>
                      <td>{r?.expiryDate}</td>
                      <td>{r?.noOfOutlets}</td>
                      <td>{r?.remainingOutlets}</td>
                      <td>
                        <p className={
                          r?.status==="Refund Completed"?
                          "status-pakg-refund"
                          :r?.status==="active"?
                          "status-active"
                          :r?.status==="expired"?
                          "status-pakg-expired"
                          :undefined
                      }
                        
                        >{r?.status?.toUpperCase()}</p>
                      </td>
                      <td>
                      <input
                        className="toggle-checkbox"
                        type={"checkbox"}
                         checked={r?.status==="active"?true:false}
                         onChange={()=>suspendUserFn(r)}
                         name="customerAll"
                          />
                      </td>
                      <td>
                        {
                          isExpand === null ?
                           <IconButton onClick={()=>clickDownArrow(i)}>
                              <i class="bi bi-arrow-down"></i>
                            </IconButton>
                          :
                             <IconButton onClick={()=>clickUpArrow()}>
                                <i class="bi bi-arrow-up"></i>
                              </IconButton>
                        }
                       
                      
                      </td>
                      <td>
                        <i onClick={()=>setPasswordReset(true)} class="bi bi-lock" style={{color:"#500299",fontSize:"1rem",backgroundColor:"#e9d7fa",padding:"4px"}}></i>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#500299" class="bi bi-lock" viewBox="0 0 16 16">
                           <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1"/>
                        </svg> */}
                      </td>
                    </tr>
                    {
                       isExpand === i && (
                        <tr> 
                        <td colSpan={14}>
                         <div  className="new-global-table-container" style={{backgroundColor:"#eef1f9",display:"flex",justifyContent:"center"}}>
                         <table style={{width:"90%"}}>
                           <thead>
                            <tr>
                               <th>TXN</th>
                               <th>TXN Date</th>
                               {/* <th>Customer</th> */}
                               <th>TXN Expiry Date</th>
                               {/* <th>TXN Validity</th> */}
                               <th>Status</th>
                               <th>Amount</th>
                               <th>Paid</th>
                               <th>Balance</th>
                               <th></th>
                            </tr>
                           </thead>
                           <tbody>
                            {
                                r?.transactions?.map((k,i)=>(
                                    <tr key={i}>
                                    <td>{k?.purchaseId}</td>
                                    <td>{k?.txnDate}</td>
                                    {/* <td>--</td> */}
                                    <td>{k?.txnExpDate}</td>
                                    {/* <td>--</td> */}
                                    <td><p style={{fontSize:"0.8rem",fontWeight:600,color:"#0ac30a"}}>{k?.status?.toUpperCase()}</p></td>
                                    <td>{k?.amount}</td>
                                    <td>{k?.paidAmount}</td>
                                    <td>
                                      {
                                        k?.balance>0?
                                        <p  onClick={()=>clickBalanceButton(k)} title="Pay" style={{backgroundColor:"blue",padding:"2px",borderRadius:"4px",color:"#fff"}}>{k?.balance}</p>
                                        :
                                        <p>{k?.balance}</p>
                                      }
                                    
                                    </td>
                                    <td ><img  onClick={()=>openInvoiceFn(k)} title="Invoice" src={invoice} alt="" style={{width:"35px",height:"35px"}} /></td>
                                  </tr>
                                ))
                            }
                          
                           </tbody>
                         </table>
                         </div>
                         </td>
                      </tr>
                       )
                    }
                  
                    </>
                    ))
                    :
                    <tr>
                        <td colSpan={11}>No Data</td>
                    </tr>
                  }
                
                 </tbody>
              </table>
            </div>
          </div>
          <div className="single-user-second-container">
            <div className="package-label">
               <p>Outlets</p>
               <p onClick={clickAddOutlet} style={{fontSize:"0.7rem",color:"blue",fontWeight:500,margin:"2px 0 0 16px",cursor:"pointer"}}>Add Outlet</p>
            </div>
             
             <div  className="new-global-table-container" style={{backgroundColor:"#eef1f9"}}>
                <table>
                   <thead>
                      <tr>
                        <th>Outlet Name</th>
                        <th>country</th>
                        <th>Package</th>
                        <th>Type</th>
                        <th>Outlet Purchase Date</th>
                        <th>Outlet Expiry Date</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                   </thead>
                   <tbody>
                    {
                        filteredData !== undefined && filteredData[0]?.outletList?.length !== 0 ?  filteredData[0]?.outletList?.map((r,i)=>(
                            <tr>
                            <td>{r?.outletName}</td>
                            <td>{r?.country}</td>
                            <td>{r?.packageName}</td>
                            <td>{r?.packageType}</td>
                            <td>{r?.purchaseDate}</td>
                            <td>{r?.expiryDate}</td>
                            <td >
                               <p 
                               className={
                                r?.status==="Refund Completed"?
                                "status-pakg-refund"
                                :r?.status==="active"?
                                "status-active"
                                :r?.status==="expired"?
                                "status-pakg-expired"
                                :undefined
                               }
                              
                               >{r?.status?.toUpperCase()}</p>
                            </td>
                            {
                                r?.status==="expired"&&
                                <td style={{background:"none",borderBottom:"none",display:"flex",justifyContent:"center"}}>
                                <div className='go-to-website' style={{backgroundColor:"blue"}}>
                                  <i class="bi bi-arrow-repeat"></i>
                                  <p>Renew</p> 
                                </div>
                              </td>
                            }
                         </tr>
                           
                        ))
                        :
                        <tr>
                            <td colSpan={8}>No Data</td>
                        </tr>
                    }
                    
                   </tbody>
                </table>
             </div>
          </div>
      </div>

      <Dialog open={addOutlet} maxWidth="lg">
        <div className='register-form-container' style={{width:"50vw"}}>
           <div style={{padding:"12px"}}>
              <h4>Add Outlet Qty</h4>
              <p style={{color:"red",fontSize:"0.8rem",fontWeight:500,margin:"4px 0"}}>*Purchase Outlets -2 </p>
              <hr />
              <div style={{display:"flex"}}>
                <div className="global-single-input auto-complete" style={{width:"20%"}}>
                  <p>Qty</p>
                   <input
                     type="text"
                    />
                </div>
                <div className="global-single-input auto-complete" style={{width:"30%"}}>
                  <p>Outlet Price</p>
                   <input
                     type="text"
                    />
                </div>
                <div className="global-single-input auto-complete" style={{width:"30%"}}>
                  <p>Discount(%)</p>
                   <input
                     type="text"
                    />
                </div>
                <div className="global-single-input auto-complete" style={{width:"30%"}}>
                  <p>Total Price</p>
                   <input
                     type="text"
                    />
                </div>
              
              </div>
              <div style={{display:"flex",justifyContent:"center",margin:"8px 2px"}}>
                  <button onClick={()=>setAddOutlet(false)} className="global-button-contained-black-outlined">Cancel</button>
                  <button className="global-button-contained-blue">Save</button>
                </div>
           </div>
        </div>
      </Dialog>

      {/* invoice */}
      <Dialog open={openInvoice} onClose={()=>setOpenInvoice(false)} maxWidth="lg">
        <div style={{position:"relative",width:"45vw"}}>
           <div ref={(el) => (invoicePrint = el)} >
             <Invoice data={invoiceData}/>
           </div>
        </div>
       
         <dyiv id="invoicePrintIcon" style={{position:"fixed",bottom:"25%",left:"47%"}}>
              <ReactToPrint
                trigger={() => (
                  <IconButton
                    className="invoice-print-btn"
                    sx={{
                      backgroundColor: "#d3e3ec",
                    }}
                    // onClick={invoiceDialogClose}
                  >
                    <PrintOutlined
                      className="print-icon"
                      sx={{ color: "#000" }}
                    />
                  </IconButton>
                )}
                content={() => invoicePrint}
              />
              {/* </Tooltip> */}
            </dyiv>
      </Dialog>

      {/* password reset */}
      <Dialog open={passwordReset} maxWidth="lg" onClose={()=>setPasswordReset(false)}>
         <div className='register-form-container' style={{width:"25vw"}}>
           <div style={{padding:"20px"}}>
              <div className="reset-top-container">
                  <div className="reset-top-container-icon">
                  <i class="bi bi-lock" style={{color:"#33b166",fontSize:"1rem",padding:"4px"}}></i>
                  </div>
                 <p>Reset Password</p>
              </div>
              <div className="reset-password-input">
                    <p>Password</p>
                    <input type="password" value={passwordState?.password} onChange={passwordChange("password")}/>
              </div>
              <div className="reset-password-input">
                    <p>Confirm Password</p>
                    <input type="password" value={passwordState?.confirmPassword} onChange={passwordChange("confirmPassword")}/>
              </div>
              <button onClick={passwordResetBtnFunction} className="reset-submit">SUBMIT</button>
           
           </div>
         </div>
      </Dialog>

      <Dialog open={balancePayment} maxWidth="lg" onClose={()=>setBalancePayment(false)}>
          <div className='register-form-container' style={{width:"25vw"}}>
           <div style={{padding:"20px"}}>
              
             
           <div className="reset-top-container">

                 <p>Pay Balance</p>
              </div>
              <div className="pay-balance-container">
                    <p>Balance Amount</p>
                    <input type="text"  value={balanceState?.balance} />
              </div>
              <div className="pay-balance-container">
                    <p>Pay Amount</p>
                    <input type="text" value={balanceState?.payamount}  onChange={(e)=>setBalanceState({...balanceState,payamount:e.target.value})}/>
              </div>
              <button onClick={clickBlanceSubmitFn} className="reset-submit">Pay Balance</button>
             
           
           </div>
          </div>
      </Dialog>
      <LoadingForm loading={loadingForm}/>
      <SuccessSnackbar
        open={snackBarStates.success}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
      <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
      />
    </>
  );
};

export default RegisteredUserSingle;
