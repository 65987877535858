import React from "react";
import { useSelector } from "react-redux";
import TopContainer from "../CustomComponent/TopContainer";
import "../../../Css/Report Forward/reportForward.css";
import ReportForwardMenu from "./ReportForwardMenu";
import AddMobile from "./AddMobile";
import AddEmail from "./AddEmail";
import Reports from "./Reports";
const ReportForward = () => {
  const activeMenuLoc = useSelector(
    (state) => state.reportForwardMenuState.value
  );
  return (
    <>
      <TopContainer label="Report Forward" />
      <div className="report-main-container">
        <ReportForwardMenu />
        {activeMenuLoc === "socialPlatformli" ? (
          <AddMobile />
        ) : activeMenuLoc === "emailli" ? (
          <AddEmail />
        ) : activeMenuLoc === "reportsli" ? (
          <Reports />
        ) : undefined}
      </div>
    </>
  );
};

export default ReportForward;
