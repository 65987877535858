

import axios from "axios";


export const TOKEN = localStorage.getItem("userToken");
console.log(TOKEN)

//creates axios authorization token from local storage 
axios.defaults.headers.common["Authorization"] = `Bearer ${
  TOKEN !== null && TOKEN
}`;

