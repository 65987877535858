import Swal from "sweetalert2";




export const deleteAlertMessage=(id,deleteFunction)=>{
  console.log(id)
    Swal.fire({
        position: "center",
        icon: "warning",
        title: "Are you sure want to delete this data",
    
        // text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonColor: "#f80000",
        confirmButtonText: "Delete",
        customClass: {
          cancelButton: "sweet-alert-cancel-button",
          confirmButton: "sweet-alert-submit-button",
          icon: "icon-color",
        },
      }).then((result)=>{
        console.log(result)
        if(result.isConfirmed){
          console.log("succussfullyyyy")
          deleteFunction(id)
        }
      })
  }

  export const successAlertMessage = (title) => () => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: title,
      showConfirmButton: false,
      showCancelButton: false,
      // timer: 2500,
      confirmButtonText: "Submit",
      customClass: {
        confirmButton: "success-alert-message-submit-btn",
        cancelButton: "success-alert-message-cancel-btn",
      },
    });
  };