import React from 'react'
import { DatePicker } from './DatePicker.'
import { TimePicker } from './TimePicker'
import '../../../Css/Custom css/topContainer.css'
const TopContainer = (props) => {
    const {label}=props
  return (
    <div className="top-container">
        <div className="breadcrumb">
          <p className="breadcrumb">{label}</p>
        </div>
        <div className="date-time-container">
          <TimePicker />
          <DatePicker />
        </div>
      </div>
  )
}

export default TopContainer