/** @format */

import React, { useEffect, useState } from "react";
import TopContainer from "../CustomComponent/TopContainer";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import { Purchase } from "../HomeDashboard/Purchase";
import { top100Films } from "./dummydata";
import { addsmsPackage, addsmsPackageAPIcall, viewAllSmsPackageAPICall } from "../../../API/Add_sms_Package/smsPackage";
import SuccessSnackbar from "../../SingleCompoents/SnackBars/SuccessSnackbar";
import { fa0 } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
export const SmsPackage = () => {
  const smsPackage=useSelector((state)=>state.getAllsmsPackageSlice.value)
  //Alerts
  const [CountryAlert, setCountryAlert] = useState(false);
  const [purchaseAlert, setPurchaseAlert] = useState(false);
  const [qtyAlert, setQtyAlert] = useState(false);
  const [sellingAlert, setSellingAlert] = useState(false);
  const [sellingQtyAlert, setSellingQtyAlert] = useState(false);
  const [startDateAlert, setStartDateAlert] = useState(false);
  const [endDateAlert, setendDateAlert] = useState(false);

  const [smsPackageCountry, setsmsPackageCountry] = useState("");

  //Snackbar States
  const [successSnackOpen, setSuccessSnackOpen] = useState(false);

  const [snackMsg, setSnackMsg] = useState("oops!!");

  const [purchaseRate, setPurchaseRate] = useState("");
  const [PurchaseQty, setPurchaseQty] = useState("");
  const [sellingRate, setSellingRate] = useState("");
  const [sellingQty, setSellingQty] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(()=>{
    viewAllSmsPackageAPICall()
  },[])

  // Close SuccessSnackbar
  const closeSuccessSnackbar = () => {
    setSuccessSnackOpen(false);
  };




  //Submit sms packagebtn..
  const submitbtn = () => {
    if (
      smsPackageCountry &&
      purchaseRate &&
      PurchaseQty &&
      sellingRate &&
      sellingQty &&
      startDate &&
      endDate
    ) {

      //addsmspackage Api Call
      addsmsPackageAPIcall(
        {
          country: smsPackageCountry,
          purchaseRate: purchaseRate,
          purchaseQty: PurchaseQty,
          sellingRate: sellingRate,
          sellingQty: sellingQty,
          startDate: startDate,
          endDate: endDate,
        },
        setSuccessSnackOpen,
        setSnackMsg,
        setsmsPackageCountry,
        setPurchaseRate,
        setPurchaseQty,
        setSellingRate,
        setSellingQty,
        setStartDate,
        setEndDate

      );
    }

    if (smsPackageCountry === "") {

      setCountryAlert(true);
      setPurchaseAlert(false);
      setQtyAlert(false);
      setSellingAlert(false);
      setSellingQtyAlert(false);
      setStartDateAlert(false);
      setendDateAlert(false);
    } else if (purchaseRate === "") {

      setCountryAlert(false);
      setPurchaseAlert(true);
      setQtyAlert(false);
      setSellingAlert(false);
      setSellingQtyAlert(false);
      setStartDateAlert(false);
      setendDateAlert(false);
    } else if (PurchaseQty === "") {

      setCountryAlert(false);
      setPurchaseAlert(false);
      setQtyAlert(true);
      setSellingAlert(false);
      setSellingQtyAlert(false);
      setStartDateAlert(false);
      setendDateAlert(false);
    } else if (sellingRate === "") {


      setCountryAlert(false);
      setPurchaseAlert(false);
      setQtyAlert(false);
      setSellingAlert(true);
      setSellingQtyAlert(false);
      setStartDateAlert(false);
      setendDateAlert(false);


    } else if (sellingQty === "") {
      setCountryAlert(false);
      setPurchaseAlert(false);
      setQtyAlert(false);
      setSellingAlert(false);
      setSellingQtyAlert(true);
      setStartDateAlert(false);
      setendDateAlert(false);
    } else if (startDate === "") {

     setCountryAlert(false);
      setPurchaseAlert(false);
      setQtyAlert(false);
      setSellingAlert(false);
      setSellingQtyAlert(false);
      setStartDateAlert(true);
      setendDateAlert(false);
    } else if (endDate === "") {
      setCountryAlert(false);
      setPurchaseAlert(false);
      setQtyAlert(false);
      setSellingAlert(false);
      setSellingQtyAlert(false);
      setStartDateAlert(false);
      setendDateAlert(true);
    } else {
      setCountryAlert(false);
      setPurchaseAlert(false);
      setQtyAlert(false);
      setSellingAlert(false);
      setSellingQtyAlert(false);
      setStartDateAlert(false);
      setendDateAlert(false);
    }
  };

  //SmspackageCancelbtn...

  const  SmspackageCancelbtn=()=>{

    setsmsPackageCountry("");
    setPurchaseRate("");
    setPurchaseQty("");
    setSellingRate("");
    setSellingQty("");
    setStartDate("");
    setEndDate("");

  }



  return (
    <>
      <TopContainer label="SMS > SMS Package" />
      <div className="global-container device-settings-container">
        <p>Add SMS Package</p>
        <hr />
        <div className="device-settings-form">
          <div className="global-single-input device-settings-input">
            <p>Country</p>
            <Select
              onChange={(e) => setsmsPackageCountry(e.target.value)}
              value={smsPackageCountry}
            >
              <MenuItem value={""}>None</MenuItem>
              <MenuItem value={"india"}>india</MenuItem>
              <MenuItem value={"Germen"}>Germen</MenuItem>
              <MenuItem value={"Portugal"}>Portugal</MenuItem>
              <MenuItem value={"USA"}>USA</MenuItem>
              <MenuItem value={"Egypt"}>Egypt</MenuItem>
              <MenuItem value={"pakistan"}>pakistan</MenuItem>
              <MenuItem value={"England"}>England</MenuItem>
            </Select>
            {CountryAlert && (
              <p className="Country-validation-alert">Select a Country</p>
            )}
          </div>
          <div className="global-single-input device-settings-input ">
            <p>Purchase Rate</p>
            <input
              type="text"
              onChange={(e) => setPurchaseRate(e.target.value)}
              value={purchaseRate}
              placeholder="Enter purchase Rate..."
            />
            {purchaseAlert && (
              <p className="Country-validation-alert">Enter Purchase Rate</p>
            )}
          </div>
          <div className="global-single-input device-settings-input ">
            <p>Purchase Qty</p>
            <input
              type="text"
              onChange={(e) => setPurchaseQty(e.target.value)}
              value={PurchaseQty}
              placeholder="Enter Qty.."
            />
            {qtyAlert && <p className="Country-validation-alert">Enter Qty</p>}
          </div>
          <div className="global-single-input device-settings-input ">
            <p>Selling Rate</p>
            <input
              type="text"
              onChange={(e) => setSellingRate(e.target.value)}
              value={sellingRate}
              placeholder="Enter Selling Rate.."
            />
            {sellingAlert && (
              <p className="Country-validation-alert">Enter Selling Rate</p>
            )}
          </div>
          <div className="global-single-input device-settings-input ">
            <p>Selling Qty</p>
            <input
              type="text"
              onChange={(e) => setSellingQty(e.target.value)}
              value={sellingQty}
              placeholder="Enter Selling Qty"
            />
            {sellingQtyAlert && (
              <p className="Country-validation-alert">Enter Selling Qty</p>
            )}
          </div>
          <div className="global-single-input device-settings-input ">
            <p>Start Date</p>
            <input
              type="date"
              onChange={(e) => setStartDate(e.target.value)}
              value={startDate}
            />
            {startDateAlert && (
              <p className="Country-validation-alert">Enter Start Date</p>
            )}
          </div>
          <div className="global-single-input device-settings-input ">
            <p>End Date</p>
            <input
              type="date"
              onChange={(e) => setEndDate(e.target.value)}
              value={endDate}
            />
            {endDateAlert && (
              <p className="Country-validation-alert">Enter End Date</p>
            )}
          </div>
        </div>
        <div className="device-settings-cancel-submit-btn-container">
          <button className="global-button-contained-black-outlined" onClick={SmspackageCancelbtn}>
            Cancel
          </button>
          <button className="global-button-contained-blue" onClick={submitbtn}>
            Submit
          </button>
        </div>
      </div>
      <div className="provider-list-container">
        <p>SMS Provider List</p>
        <div className="global-container-table">
          <table>
            <thead>
              <tr>
                <th>No.</th>
                <th>Country</th>
                <th>Purchase Rate</th>
                <th>Purchase Qty</th>
                <th>Selling Rate</th>
                <th>Selling Qty</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
             <tbody>
              {
                smsPackage?.map((r,i)=>(
                  <tr>
                  <td>{i+1}</td>
                  <td>{r?.country}</td>
                  <td>{r?.purchaseRate}</td>
                  <td>{r?.purchaseQty}</td>
                  <td>{r?.sellingRate}</td>
                  <td>{r?.sellingQty}</td>
                  <td>{r?.STARTDATE}</td>
                  <td>{r?.ENDDATE}</td>
                </tr>
                ))
              }
             
             </tbody>
           

           
            
          
          </table>
        </div>
      </div>
      <SuccessSnackbar
        open={successSnackOpen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      />
    </>
  );
};
