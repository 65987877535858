import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import bankName from '../../../../../Assets/JSON/bankNames.json'
import { bankAndCompanyInfoAPICall } from '../../../../../API/Staff/HRM/addEmployee'

export const BankDetails = () => {
  //  get company info data
  const companyInfoData=useSelector((state)=>state.manageEmployeeSlice.value2)

  const bankDetailsInitialState={
    accountHolder:'',
    accountNumber:'',
    bankName:null,
    ifscCode:'',
    branchLocation:'',
    pan:''
  }
  const [bankDetails,setBankDetails]=useState(bankDetailsInitialState)
  const [finalEmployeeData,setFinalEmployeeData]=useState({})

  // ONCHANGE FUNCTION
  const getBankDetails=(key)=>(e,newValue)=>{
    const {value}=e.target
    if(key==="accountHolder"){
      setBankDetails({...bankDetails,accountHolder:value})
    }
    if(key==="accountNumber"){
      setBankDetails({...bankDetails,accountNumber:value})
    }
    if (key==="bankName") {
      setBankDetails({...bankDetails,bankName:newValue})
    }
    if(key==="ifscCode"){
      setBankDetails({...bankDetails,ifscCode:value})
    }
    if(key === "branchLocation"){
      setBankDetails({...bankDetails,branchLocation:value})
    }
    if(key ==="pan"){
      setBankDetails({...bankDetails,pan:value})
    }
  }

  const createFilnalBody=()=>{
    if(companyInfoData !== undefined){
      const {
        employeeId,
        department,
        designation,
        joiningDate,
        leavingDate,
        workHours,
        type,
        monthlySalary,
        from,
        to,
        status,
        qrCode
      } =  companyInfoData
      const {accountHolder,accountNumber,bankName,ifscCode,branchLocation,pan}=bankDetails;
      setFinalEmployeeData({
        id:employeeId?._id,
        department:department?._id,
        designation:designation?._id,
        date_of_join:joiningDate,
        workHour:workHours,
        salaryType:type?.type,
        monthlySalary:monthlySalary,
        contractPeriodFrm:from,
        contractPeriodTo:to,
        status:status,
        qrcode:qrCode,
        ac_holder:accountHolder,
        ac_no:accountNumber,
        bank:bankName?.name,
        bank_code:ifscCode,
        bankLocation:branchLocation,
        pan:pan
      })
    }
  }

  useEffect(()=>{
    createFilnalBody()
  },[companyInfoData,bankDetails])

  // console.log(finalEmployeeData);
  /// bank details submit button function
  const bankDetailsSubmitBtnFn=()=>{
    bankAndCompanyInfoAPICall(finalEmployeeData)
  }


  return (
    <div className='add-employee-profile-info-container'>
      <div className='profile-info-sub-container'>
        <div className='global-single-input add-employee-input'>
            <p>Account Holder</p>
            <input 
              type="text" 
              placeholder='Enter Account Holder' 
              value={bankDetails?.accountHolder} 
              onChange={getBankDetails("accountHolder")}
              />
        </div>
        <div className='global-single-input add-employee-input'>
            <p>Account Number</p>
            <input 
               type="text" 
               placeholder='Enter Account Number'
               value={bankDetails?.accountNumber} 
               onChange={getBankDetails("accountNumber")}
               />
        </div>
        <div className='global-single-input add-employee-input auto-complete'>
            <p>Bank Name</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={bankName || ['']}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params}/>}
              value={bankDetails?.bankName}
              onChange={getBankDetails("bankName")}
            />
        </div>
        <div className='global-single-input add-employee-input'>
            <p>IFSC / IBAN</p>
            <input 
              type="text" 
              placeholder='Enter IFSC/IBAN'
              value={bankDetails?.ifscCode}
              onChange={getBankDetails("ifscCode")}
              />
        </div>
        <div className='global-single-input add-employee-input'>
            <p>Branch Location</p>
            <input 
               type="text" 
               placeholder='Enter Branch'
               value={bankDetails?.branchLocation}
              onChange={getBankDetails("branchLocation")}
               />
        </div>
        <div className='global-single-input add-employee-input'>
            <p>PAN / Income Tax Code</p>
            <input 
              type="text" 
              placeholder='Enter PAN/Income Tax Code'
              value={bankDetails?.pan}
              onChange={getBankDetails("pan")}
              />
        </div>
      </div>
      <div className='profile-info-button-container'>
         <button className='global-button-contained-black-outlined'>Back</button>
         <button onClick={bankDetailsSubmitBtnFn} className='global-button-contained-blue'>Submit</button>
      </div>
    </div>
  )
}
