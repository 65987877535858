// getTaxListApiCall

import axios from "axios"
import { HEADERS } from "../urlAndPath"
import { store } from "../../Redux/Store"
import { get_All_Tax, get_single_tax } from "../../Redux/Tax/TaxSlice"

 export const getTaxListApiCall=(body)=>{
    axios.post('tax/getAllTax',body,HEADERS)
    .then((res)=>{
       
        if(res.status === 200){
            console.log(res.data)
            store.dispatch(get_All_Tax({TaxList:res.data}))
        }
    }).catch((err)=>{
        console.log("Error ")
    })
}

export const addTaxApiCall=(
    body,
    setState,
    state,
     setCreateTax
    )=>{
    axios.post('tax/addTax',body,HEADERS)
    .then((res)=>{
       
        if(res.status === 200){
            console.log(res.data)
            console.log("added"); setState({...state,message:"Created Success fully !!",success:true})
            setCreateTax(false);
            getTaxListApiCall()
        }
    }).catch((err)=>{
        console.log("Error ")
        if(err.response.status === 500){
            setState({...state,message:"Internal Server error !",error:true})
          }
          else{
            setState({...state,message:err?.response?.data,error:true})
          }
    })
}

export const getSingleApiCall=(body)=>{
    axios.get('tax/getSingleTax',body,HEADERS)
    .then((res)=>{
       
        if(res.status === 200){
            console.log(res.data)
            store.dispatch(get_single_tax({singleTaxData:res.data}))
            console.log("added");
        }
    }).catch((err)=>{
        console.log("Error ")
    })
}