/** @format */

import React from "react";
import "../../../Css/HomeDashboard/sales.css";
import { PurchaseRevenueCard } from "./PurchaseRevenueCard";
import { RecentPurchaseView } from "./RecentPurchaseView";
import { TopClients } from "./TopClients";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useState } from "react";
import { useEffect } from "react";
import { getTotalRevenueGraphApICall } from "../../../API/HomeDashboard/homeDashboardAPI";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
// const data = {
//   labels,
//   Legend: false,

//   datasets: [
//     {
//       label: "Sales",

//       data: [0, 40, 32, 0, 30, 65, 25, 49, 59, 44, 48, 30],
//       // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: "#0032f8",
//       backgroundColor: "rgba(53, 162, 235, 0.5)",
//       pointBackgroundColor: "#0032f8",
//     },
//   ],
// };

export const Sales = () => {
  
  const totalRevenueGraph = useSelector((state)=>{
    return state.totalRevenueGraph.value
  })


  const [status, setStatus] = useState("today");

  
  // Today Labels

  const todayLabels =[
    "1hrs",
    "2hrs",
    "3hrs",
    "4hrs",
    "5hrs",
    "6hrs",
    "7hrs",
    "8hrs",
    "9hrs",
    "10hrs",
    "11hrs",
    "12hrs",
    "13hrs",
    "14hrs",
    "15hrs",
    "16hrs",
    "17hrs",
    "18hrs",
    "19hrs",
    "20hrs",
    "21hrs",
    "22hrs",
    "23hrs",
    "24hrs",
  ];

  // Month Label
  const weekLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // Month Label

  const monthLabels =  [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ];


  // Graph Data

  const data = {
    labels:
    // Legend: false,
    status === 'today' ? todayLabels : 
    status === 'week' ? weekLabels : 
    status === 'month' ? monthLabels : "undefined",
  
    datasets: [
      {
        label: "Sales",
  
        data: totalRevenueGraph,
        // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        borderColor: "#0032f8",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
        pointBackgroundColor: "#0032f8",
      },
    ],
  };







  const radioBtn = (e) => {
    console.log(e.target.value);
    setStatus(e.target.value)

  };



  const navigate = useNavigate();
  const clickRecentPurchase = () => {
    navigate("/dashboard/recentpurchaseview");
  };
  const clickTotalRevenue = () => {
    navigate("/dashboard/totalrevenue");
  };
  const clickTopClients = () => {
    navigate("/dashboard/topclients");
  };
  const clickTotalRenewal = () => {
    navigate("/dashboard/totalrenewal");
  };

  useEffect(()=>{
    getTotalRevenueGraphApICall({timeFilter:status});
  },[status])






  return (
    <>
      <div className="bottom-left-container">
        <div className="bottom-left-first-container">
          <p>Sales</p>
          <div className="sales-radio-btn-grp">
            <input
              type="radio"
              id="fstBtn"
              name="sales"
              value="today"
              onClick={radioBtn}
            />
            <label htmlFor="fstBtn">Today</label>
            <hr />
            <input
              type="radio"
              id="secondBtn"
              name="sales"
              value="week"
              onClick={radioBtn}
            />
            <label htmlFor="secondBtn">Week</label>
            <hr />
            <input
              type="radio"
              id="thirdBtn"
              name="sales"
              value="month"
              onClick={radioBtn}
            />
            <label htmlFor="thirdBtn">Month</label>
          </div>
        </div>
        <div className="bottom-left-second-container">
          <Line data={data} options={options} />
        </div>
      </div>
      <div className="bottom-right-container">
        <PurchaseRevenueCard
          backgroundColor="#d9ceee"
          marginBottom="8px"
          borderLeft="8px solid #793bf6"
          heading="Recent Purchase View"
          color="#793bf6"
          onClick={clickRecentPurchase}
        />
        <PurchaseRevenueCard
          backgroundColor="#f5bac0"
          marginBottom="8px"
          borderLeft="8px solid #c70014"
          heading="Top Clients"
          color="#c70014"
          onClick={clickTopClients}
        />
        <PurchaseRevenueCard
          backgroundColor="#c8e8eb"
          marginBottom="8px"
          borderLeft="8px solid #008c9a"
          heading="Total Revenue"
          color="#008c9a"
          onClick={clickTotalRevenue}
        />
        <PurchaseRevenueCard
          backgroundColor="rgb(185 235 196)"
          marginBottom="8px"
          borderLeft="8px solid rgb(0 151 32)"
          heading="Total Renewal"
          color="rgb(0 151 32)"
          onClick={clickTotalRenewal}
        />
      </div>
    </>
  );
};
