import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Dialog, Icon, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import uploadImage from '../../../Assets/Images/upload-image.png'
import { useSelector } from 'react-redux';
import { LoadingForm } from '../../Custom Components/LoadingForm';
import { addBrancdAPICall, addCatalogParts, addManufYearAPICall, addModelAPICall, exportPartsAPICall, importPartsAPICall, viewBrandAPICall, viewCatalogPartsAPICall, viewManufYearAPICall, viewModelAPICall } from './catalogCategoryAPI';
import downloadImg from "../../../Assets/Icons/Icon awesome-file-export (1).svg"
import upload from "../../../Assets/Icons/Icon awesome-file-export.svg"
import SuccessSnackbar from '../../SingleCompoents/SnackBars/SuccessSnackbar';
import ErrorSnackbar from '../../SingleCompoents/SnackBars/ErrorSnackbar';
export const Parts = () => {
    const navigateTo=useNavigate()
    const catId=localStorage.getItem("catId")
    const catname=localStorage.getItem("catName")
    const subCatId=localStorage.getItem("subCatId")
    const subCatname=localStorage.getItem("subCatName")
     const partsList=useSelector((state)=>state.catalogCategorySlice.parts)
     const brandList=useSelector((state)=>state.catalogCategorySlice.brand)
     const modelList=useSelector((state)=>state.catalogCategorySlice.model)
     const yearList = useSelector((state)=>state.catalogCategorySlice.year)
     const [createParts,setcreateParts]=useState(false)
     const [addBrand,setAddBrand]=useState(false)
     const [addModel,setAddModel]=useState(false)
     const [addYear,setAddYear]=useState(false)
     const [uploadedImage,setUploadedImage]=useState()
     const [imageUrl,setImageUrl]=useState()
     const [loadingForm,setLoadingForm]=useState(false)
     const [isModify,setIsModify]=useState(false)
     const [filtererdModellist,setFilteredModelList]=useState([])
     const [fileToExport, setFileToExport] = useState([])
     const [validationAlert, setValidationAlert] = useState({});
     const formValuesInitialState={
         partName:"",
         partNo:"",
         brand:null,
         model:null,
         year:null
     }
     const [formValues,setFormValues]=useState(formValuesInitialState)
     const [brandName,setBrandName]=useState("")
     const addModelFormInitial={
        brandId:null,
        modelName:""
     }
     const [addModelForm,setAddModelForm]=useState(addModelFormInitial)
     const [yearname,setYearName]=useState('')
     const [searchInput,setSearchInput]=useState('')
     const [partIds,setPartsIds]=useState([])
     const [importPopup,setImportPopup]=useState(false)
     const [snackBarStates, setSnackBarStates] = useState({
      success: false,
      error: false,
      message: "",
      alert: false
  });

  const closeSnackbar = () => {
    setSnackBarStates({
        ...snackBarStates,
        success: false,
        error: false,
        alert: false,
        message: "",
    });
};

     const updateListner=()=>{
        setIsModify(!isModify)
     }
    const backToSubcat=()=>{
        localStorage.removeItem("subCatId")
        localStorage.removeItem("subCatName")
        navigateTo("/dashboard/catalog/subCategory")
    }
    const backToCat=()=>{
        localStorage.removeItem("catId")
        localStorage.removeItem("catName")
        localStorage.removeItem("subCatId")
        localStorage.removeItem("subCatName")
        navigateTo("/dashboard/catalog")
    }

    const imageUploadFn=(e)=>{
        setUploadedImage(e.target.files[0])
        setImageUrl(URL.createObjectURL(e.target.files[0]));

      }
      const removeImage=()=>{
        setImageUrl(undefined)
        setUploadedImage(undefined)

      }
    // useEffect(()=>{
    //     let image = undefined;
    //     if(typeof uploadedImage ==="string"){
    //       image = uploadedImage
    //     }
    //     else if(uploadedImage !== undefined && uploadedImage !== null){
    //       image=URL.createObjectURL(uploadedImage)
    //     }
    //     setImageUrl(image)
    // },[uploadedImage])

    const getFormValues=(key)=>(e,newValue)=>{
        const { value } = e.target;
        
        setFormValues({ ...formValues, [key]: value !== 0 ? value : newValue });
    }

    const saveBrandFunction=()=>{ 
        addBrancdAPICall({name:brandName},updateListner)

    }
    const saveModelFunction=()=>{
        addModelAPICall({
            name:addModelForm?.modelName,
            brandId:addModelForm?.brandId?._id
        },updateListner)
    }
    const saveYearFunction=()=>{
       addManufYearAPICall({name:yearname},updateListner)

    }

    const clearState=()=>{
      setFormValues(formValuesInitialState)
    }

    const formData=new FormData()
    formData.append("partName",formValues?.partName)
    formData.append("partNo",formValues?.partNo)
    formData.append("file",uploadedImage)
    formData.append("brandId",formValues?.brand?._id)
    formData.append("modelId",formValues?.model?._id)
    formData.append("manufacYearId",formValues?.year?._id)
    formData.append("categoryId",catId)
    formData.append("subCategoryId",subCatId)

    const saveFunction=()=>{
        addCatalogParts(formData,updateListner,clearState)
    }

    useEffect(()=>{
        setFilteredModelList(modelList?.filter((obj)=>{
            if(obj?.brandId===formValues?.brand?._id){
                return obj
            }
        }))
    },[formValues?.brand])

    useEffect(()=>{
        viewCatalogPartsAPICall({
            categoryId:catId,
            subCategoryId:subCatId
        })
        viewBrandAPICall()
        viewModelAPICall()
        viewManufYearAPICall()
    },[isModify])

    const searchFunction=()=>{
      viewCatalogPartsAPICall({
        categoryId:catId,
        subCategoryId:subCatId,
        search:searchInput
    })
    }

    useEffect(()=>{
      searchFunction()
    },[searchInput])
    
    
    const checkParts=(e)=>{
      const rowId=e.target.value
      if(partIds.includes(rowId)){
        setPartsIds((pre)=>pre.filter((id)=>id !==rowId))
      }
      else{
        setPartsIds((prev)=>[...prev,rowId])
      }
    }
    const checkAllFunction=(e)=>{
        const isChecked=e.target.checked;
        if(isChecked){
          setPartsIds(partsList?.map((r)=>r?._id))
        }
        else{
          setPartsIds([])
        }
    }
   

    const exportPartsFunction=()=>{
      exportPartsAPICall({catalogIds:partIds})
    }
    const importPartsFunction=()=>{
       setImportPopup(true)
    }

    const changeImage = (e) => {
      setFileToExport([...fileToExport, e.target.files[0]]);
      setValidationAlert({})
  };
  console.log(fileToExport);

    const submitImportClick=()=>{
      let newFormData = new FormData()

      // newFormData.append("_id",singleViewById)
      newFormData.append("file", fileToExport[0])

      if (fileToExport.length === 0) {
          setValidationAlert({ ...validationAlert, fileExport: "select a file !!" })
      }
      else {
        importPartsAPICall(newFormData,setImportPopup,setSnackBarStates, snackBarStates,setFileToExport)
      }
    }

  return (
    <div>
        <div className='catelog-header-container'>
            <p onClick={backToCat} style={{cursor:"pointer"}}>Catalog</p>
            <p><i class="bi bi-chevron-right"></i></p>
            <p onClick={backToSubcat} style={{cursor:"pointer"}}>{catname}</p>
            <p><i class="bi bi-chevron-right"></i></p>
            <p >{subCatname}</p>
        </div>
        <div className='catelog-heading-button'>
            <p> Parts </p>
             <div style={{width:"65%"}} className='catelog-buttom-container'>
           
            <TextField 
            value={searchInput}
            onChange={(e)=>setSearchInput(e.target.value)}
            label="Search" 
            color="primary" 
            focused 
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                       <IconButton onClick={searchFunction}>
                     <SearchIcon
      className="billing-search-icon"
      style={{ color: "#736ff3" }}
     
    /> 
    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

                    <button  onClick={exportPartsFunction} className='catalog-button export'>
                    <img src={upload} alt="" />
                  
                  Download
                  </button>
               <button onClick={importPartsFunction} className='catalog-button import'>
             
                <img src={downloadImg} alt="Button Image" />
                Upload</button>
                <button onClick={()=>setcreateParts(true)} className='catalog-button create'>Create</button>
            </div>
        </div>
        <div className='catalog-table-container'>
            <table>
                <thead>
                    <tr>
                        <th>Image</th>
                        <th>Parts No</th>
                        <th>Name</th>
                        <th>Brand</th>
                        <th>Model</th>
                        <th>Year</th>
                        <th>
                            <input 
                              type="checkbox"
                              onChange={checkAllFunction}
                              checked={partsList?.length !==0 && partIds?.length===(partsList?.map((row) => row._id))?.length}
                              />
                        </th>
                       
                    </tr>
                </thead>
                <tbody>
                    {
                     partsList?.map((r,i)=>{
                     
                      const imageData = [r?.imageUrl[0]?.data];
                      const base64String = imageData;
                      return  <tr>
                        <td>
                              <img src={`data:name;base64,${base64String}`} alt="" style={{width:"50px",height:"50px",borderRadius:"50%",border:"1px solid #000",}}/>

                        </td>
                        <td>{r?.partNo}</td>
                        <td>{r?.partName}</td>
                        <td>{r?.brandName}</td>
                        <td>{r?.modelName}</td>
                        <td>{r?.manufactureYear}</td>
                        <td>
                          <input 
                            type="checkbox"
                            value={r?._id}
                            onChange={checkParts}
                            checked={partIds.includes(r?._id)}
                            />
                        </td>
                    
                    </tr>
             })   
                    }
                    
                </tbody>
            </table>
        </div>
       {/* create parts */}
       <Dialog open={createParts} maxWidth="lg">
          <div className='create-category-container' style={{width:"60vw"}}>
             <div style={{padding:"4%"}}>
               <div className='create-category-icon-container'>
            
             <IconButton onClick={()=>setcreateParts(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                   <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                   <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>
             </IconButton>
              <IconButton onClick={saveFunction}>
               <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="blue" class="bi bi-floppy" viewBox="0 0 16 16">
                      <path d="M11 2H9v3h2z"/>
                      <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
              </svg>
              </IconButton>
               </div>
                <hr />
               <p className='create-category-name'>Create Parts</p>
                <div className='create-category-input-field'>
                  <div className='new-global-single-input' style={{width:"30%"}}>
                   <TextField
                      label="Part Name"
                      focused
                      value={formValues?.partName}
                      onChange={getFormValues('partName')}
                     
                   />
                  </div>
                  <div className='new-global-single-input' style={{width:"30%"}}>
                   <TextField
                      label="Part No"
                      focused
                      value={formValues?.partNo}
                      onChange={getFormValues('partNo')}
                   />
                  </div>
                <div className='global-single-input '>
           
            {
                    imageUrl === undefined &&
                  <div className='image-upload-file-conatiner'>
                  <input type="file" id="upload-image" onChange={imageUploadFn} />
                  <label htmlFor="upload-image">
                    <img src={uploadImage} alt="" />
                  </label>
                  </div>
                  }
                  {
                    imageUrl !== undefined &&
                    <div className='image-preview'>
                        <IconButton className='image-close-icon' onClick={removeImage}>
                           <i class="bi bi-x"></i>
                        </IconButton>
                        <img src={imageUrl} alt="" />
                    </div>
                    
                  }
                   <p>Upload Image</p>
                </div>
                <div className='new-global-single-input' style={{width:"30%"}}>
                <Autocomplete
              sx={{width:"100%"}}
              options={brandList||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} focused label="Select Brand"/>}
              value={formValues?.brand}
              onChange={getFormValues('brand')}
            />
            <p onClick={()=>setAddBrand(true)} className='add-model'>Add Brand</p>
                  </div>
                  <div className='new-global-single-input' style={{width:"30%"}}>
                <Autocomplete
              sx={{width:"100%"}}
              options={filtererdModellist||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} focused label="Select Model"/>}
              value={formValues?.model}
              onChange={getFormValues('model')}
            />
              <p onClick={()=>setAddModel(true)} className='add-model'>Add Model</p>
                  </div>
                  <div className='new-global-single-input' style={{width:"30%"}}>
                <Autocomplete
              sx={{width:"100%"}}
              options={yearList||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} focused label="Select Year"/>}
              value={formValues?.year}
              onChange={getFormValues('year')}
            />
              <p onClick={()=>setAddYear(true)} className='add-model'>Add Year</p>

                  </div>
              </div>
             </div>
           </div>
       </Dialog>
       {/* add brand */}
       <Dialog open={addBrand} maxWidth="lg">
       <div className='create-category-container' style={{width:"25vw"}}>
             <div style={{padding:"4%"}}>
               <div className='create-category-icon-container'>
            
             <IconButton onClick={()=>setAddBrand(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                   <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                   <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>
             </IconButton>
              <IconButton onClick={saveBrandFunction}>
               <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="blue" class="bi bi-floppy" viewBox="0 0 16 16">
                      <path d="M11 2H9v3h2z"/>
                      <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
              </svg>
              </IconButton>
               </div>
                <hr />
               <p className='create-category-name'>Create Brand</p>
                <div className='create-category-input-field'>
                  <div className='new-global-single-input' style={{width:"95%"}}>
                   <TextField
                      label="Brand Name"
                      focused
                      value={brandName}
                      onChange={(e)=>setBrandName(e.target.value)}
                     
                   />
                  </div>
               
             
                
              </div>
              <div style={{margin:"2% 4%"}}>
                {
                    brandList?.map((r,i)=>(
                        <div style={{display:"flex",justifyContent:"space-between",margin:"2% 1%"}}>
                        <p style={{fontSize:"0.8rem",fontWeight:600}}>{r?.name}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="red" class="bi bi-trash3" viewBox="0 0 16 16">
                             <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                        </svg>
                      </div>
                    ))
                }
           
              </div>
            
             </div>
           </div>
       </Dialog>

        {/* add model */}
        <Dialog open={addModel} maxWidth="lg">
       <div className='create-category-container' style={{width:"25vw"}}>
             <div style={{padding:"4%"}}>
               <div className='create-category-icon-container'>
            
             <IconButton onClick={()=>setAddModel(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                   <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                   <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>
             </IconButton>
              <IconButton onClick={saveModelFunction}>
               <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="blue" class="bi bi-floppy" viewBox="0 0 16 16">
                      <path d="M11 2H9v3h2z"/>
                      <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
              </svg>
              </IconButton>
               </div>
                <hr />
               <p className='create-category-name'>Create Model</p>
                <div className='create-category-input-field'>
                <div className='new-global-single-input' style={{width:"95%"}}>
                <Autocomplete
              sx={{width:"100%"}}
              options={brandList||[""]}
              getOptionLabel={(option)=>option?.name}
              renderInput={(params)=><TextField {...params} focused label="Select Brand"/>}
              value={addModelForm?.brandId}
              onChange={(e,newValue)=>setAddModelForm({...addModelForm,brandId:newValue})}
            />
                  </div>
                  <div className='new-global-single-input' style={{width:"95%"}}>
                   <TextField
                      label="Model Name"
                      focused
                      value={addModelForm?.modelName}
                      onChange={(e)=>setAddModelForm({...addModelForm,modelName:e.target.value})}
                     
                   />
                  </div>
                 
             
                
              </div>
              <div style={{margin:"2% 4%"}}>
                {
                    modelList?.map((r,i)=>(
                        <div style={{display:"flex",justifyContent:"space-between",margin:"4% 2% "}}>
                        <p style={{fontSize:"0.8rem",fontWeight:600}}>{r?.name}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="red" class="bi bi-trash3" viewBox="0 0 16 16">
                             <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                        </svg>
                      </div>
                    ))
                }
             
              </div>
            
             </div>
           </div>
       </Dialog>

        {/* add brand */}
        <Dialog open={addYear} maxWidth="lg">
       <div className='create-category-container' style={{width:"25vw"}}>
             <div style={{padding:"4%"}}>
               <div className='create-category-icon-container'>
            
             <IconButton onClick={()=>setAddYear(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="red" class="bi bi-x-circle" viewBox="0 0 16 16">
                   <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                   <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                </svg>
             </IconButton>
              <IconButton onClick={saveYearFunction}>
               <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="blue" class="bi bi-floppy" viewBox="0 0 16 16">
                      <path d="M11 2H9v3h2z"/>
                      <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
              </svg>
              </IconButton>
               </div>
                <hr />
               <p className='create-category-name'>Create Year</p>
                <div className='create-category-input-field'>
                  <div className='new-global-single-input' style={{width:"95%"}}>
                   <TextField
                      label="Year"
                      focused
                      value={yearname}
                      onChange={(e)=>setYearName(e.target.value)}
                     
                   />
                  </div>
               
             
                
              </div>
              <div style={{margin:"2% 4%"}}>
                {
                    yearList?.map((r,i)=>(
                        <div style={{display:"flex",justifyContent:"space-between",margin:"2% 1%"}}>
                        <p style={{fontSize:"0.8rem",fontWeight:600}}>{r?.name}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="red" class="bi bi-trash3" viewBox="0 0 16 16">
                             <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                        </svg>
                      </div>
                    ))
                }
           
              </div>
            
             </div>
           </div>
       </Dialog>

       <Dialog
                open={importPopup}
                maxWidth="lg"
                onClose={()=>setImportPopup(false)}
            >
                <div style={{width:"20vw"}}>
                <div className='actions-select-dropdown-dialog'>
                    <h4 style={{ margin: "0" }}>Upload</h4>
                    <hr className="global-hr" />
                    <div className="file-picker-new" style={{ position: "relative", margin: "10px auto" }}>
                        <input
                            // disabled={formValues?.isEdit} 
                            type="file"
                            id="fileUpload"
                            accept=".xlsx"
                            onChange={changeImage}
                            disabled={fileToExport?.length !== 0}
                        />
                        <label htmlFor="fileUpload"
                            style={{ cursor: fileToExport?.length !== 0 && "not-allowed" }}
                        >
                          
                            <i class="bi bi-upload"></i>
                            Upload File
                        </label>

                        <p style={{ top: "70%" }} className="doc-validation-alert">{validationAlert?.fileExport} </p>
                    </div>

                    {fileToExport.length !== 0 &&
                        <div style={{ width: "90%" }} className="global-single-input">
                            <p>{fileToExport[0]?.name}
                                <sup
                                    onClick={() => setFileToExport([])}
                                    style={{ fontSize: "1rem", color: "red", fontWeight: "600", cursor: "pointer" }}>X</sup>
                            </p>
                        </div>}
                    <div className='justify-center'>
                        <button
                            onClick={submitImportClick}
                            className="global-button-contained-blue-royal">Submit</button>
                        <button className="global-button-contained-black-outlined"
                            onClick={()=>setImportPopup(false)}
                        >Cancel</button>
                    </div>
                  
                </div>
                </div>
              
      </Dialog>
      <SuccessSnackbar
      open={snackBarStates.success}
      handleClose={closeSnackbar}
      message={snackBarStates.message}
     />
     <ErrorSnackbar
        open={snackBarStates.error}
        handleClose={closeSnackbar}
        message={snackBarStates.message}
     />
    </div>
  )
}
