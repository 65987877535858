import React, { useState } from "react";
import totalSalaryIcon from "../../../../../Assets/Icons/staffDashboardIcons/TOTAL SALARY PAID.svg";
import totalAttendance from "../../../../../Assets/Icons/staffDashboardIcons/TOTAL ATTENDENCE.svg";
import { ChevronRightOutlined } from "@mui/icons-material";
import Calendar from "react-calendar";
import totalLeave from "../../../../../Assets/Icons/staffDashboardIcons/TOTAL LEAVE.svg";

import { Bar } from "react-chartjs-2";
import "../../../../../Css/Staff/HRM/Manageemployee/EmployeeDashboard.css";

export const EmployeeDashboard = () => {


    const [value, setValue] = useState(new Date());
    const labels = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    

  const data = {
    labels,
    datasets: [
      {
        label: "Salary",
        data: [
          "13",
          "70",
          "30",
          "12",
          "77",
          "12",
          "64",
          "48",
          "67",
          "103",
          "15",
          "97",
        ],
        backgroundColor: "#14a23a",
        barThickness: 8,
      },
      {
        label: "Attendance",
        data: [
          "10",
          "40",
          "50",
          "12",
          "77",
          "12",
          "64",
          "48",
          "67",
          "103",
          "37",
          "90",
        ],
        backgroundColor: "#acb9fe",
        barThickness: 8,
      },
      {
        label: "Leave",
        data: [
          "24",
          "35",
          "45",
          "12",
          "11",
          "97",
          "28",
          "20",
          "77",
          "30",
          "14",
          "33",
        ],
        backgroundColor: "#e75757",
        barThickness: 8,
      },
    ],
  };



  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Salary, Attendance & Leave Chart",
      },
    },
  };

  //ClickTotalCommission

  const ClickTotalCommission = () => {};

  return (
    <>
      <div className="employee-dashborad-top-container">
        <div className="employee-dashboard-date-time-picker">
          <div className="employee-dashboard-date-picker">
            {/* <CalendarMonthOutlined className="calendar-icon" /> */}
            {/* <hr className='date-picker-hr'/> */}
            <input type="date" />
          </div>
          <div className="employee-dashboard-time-picker">
            {/* <AccessTimeIcon/> */}
            <input type="time" />
          </div>
        </div>
      </div>
      {/* card */}
      <div className="employee-dashboard-card-container1">

        <div className="employee-dashboard-card1">
          <div className="employee-dashboard-card-icon-container">
            <img src={totalSalaryIcon} alt="total salary" />
          </div>
          <div className="employee-dashboard-card-text-container">
            <h5>Total Salary Paid</h5>
            <p>Rs 1,50,000.00</p>
          </div>
          <div className="employee-dashboard-card-arrow-icon-container">
            <ChevronRightOutlined sx={{ color: "#fff" }} />
          </div>
        </div>

        <div className="employee-dashboard-card1">
          <div
            className="employee-dashboard-card-icon-container"
            style={{ backgroundColor: "#4da214" }}
          >
            <img src={totalAttendance} alt="total attendance" />
          </div>
          <div className="employee-dashboard-card-text-container">
            <h5>Total Attendance</h5>
            <p>256</p>
          </div>
          <div
            className="employee-dashboard-card-arrow-icon-container"
            style={{ backgroundColor: "#4da214" }}
          >
            <ChevronRightOutlined sx={{ color: "#fff" }} />
          </div>
        </div>



        <div className="employee-dashboard-card1">
          <div
            className="employee-dashboard-card-icon-container"
            style={{ backgroundColor: "#f24949" }}
          >
            <img src={totalLeave} alt="total leave" />
          </div>
          <div className="employee-dashboard-card-text-container">
            <h5>Total Leave</h5>
            <p>13</p>
          </div>
          <div
            className="employee-dashboard-card-arrow-icon-container"
            style={{ backgroundColor: "#f24949" }}
          >
            <ChevronRightOutlined sx={{ color: "#fff" }} />
          </div>
        </div>


        <div className="employee-dashboard-card1">
          <div
            className="employee-dashboard-card-icon-container"
            style={{ backgroundColor: "#1498a2" }}
          >
            <img src={totalAttendance} alt="total attendance" />
          </div>
          <div className="employee-dashboard-card-text-container">
            <h5>Total Transfer</h5>
            <p>05</p>
          </div>
          <div
            className="employee-dashboard-card-arrow-icon-container"
            style={{ backgroundColor: "#1498a2" }}
          >
            <ChevronRightOutlined sx={{ color: "#fff" }} />
          </div>
        </div>

        <div className="employee-dashboard-card1">
          <div
            className="employee-dashboard-card-icon-container"
            style={{ backgroundColor: "rgb(255 25 79)" }}
          >
            <div className="employee-dashboard-card-icon-container-circle">
              %
            </div>
          </div>
          <div className="employee-dashboard-card-text-container">
            <h5>Total Commission</h5>
            <p>200</p>
          </div>
          <div
            className="employee-dashboard-card-arrow-icon-container"
            style={{ backgroundColor: "rgb(255 25 79)" }}
            onClick={ClickTotalCommission}
          >
            <ChevronRightOutlined sx={{ color: "#fff" }} />
          </div>
        </div>


      </div>

      {/* grpah and calender */}
      <div className="employee-dashboard-graph-calender-container">
        <div className="employee-dashboard-graph-container">
          <Bar options={options} data={data} />
        </div>
        <div className="employee-dashboard-calender-container">
          <div className="employee-dashboard-calender-top-container">
            <h4>Calendar</h4>
          </div>
          <div className="employee-dashboard-calender-second-container">
            <Calendar
              onChange={setValue}
              value={value}
              className="custom-calendar"
            />
          </div>
        </div>
      </div>

      {/* leave and holidays container */}
      <div className="leave-holidays-container">
        <div className="leave-details-container">
          <div className="leave-details-top-container">
            <h3>Leave Details</h3>
            <div className="leave-details-top-right-container">
              <div className="leave-details-top-right-child-container">
                <h5>Paid Leave</h5>
                <p>14&nbsp;/&nbsp;365</p>
              </div>
              <div className="leave-details-top-right-child-container">
                <h5 style={{ color: "#ff0000" }}>Sick Leave</h5>
                <p style={{ color: "#ff0000" }}>5&nbsp;/&nbsp;10</p>
              </div>
              <div className="leave-details-top-right-child-container">
                <h5 style={{ color: "#0019ff" }}>Unpaid Leave</h5>
                <p style={{ color: "#0019ff" }}>5&nbsp;/&nbsp;365</p>
              </div>
            </div>
          </div>
          <div className="leave-details-table-container">
            <table>
              <thead>
                <tr>
                  <th>Leave Type</th>
                  <th>Used</th>
                  <th>Balanced</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="left-head">Paid Leave</td>
                  <td>15</td>
                  <td className="leave-table-balance">05</td>
                </tr>
                <tr>
                  <td className="left-head">Sick Leave</td>
                  <td>05</td>
                  <td className="leave-table-balance">01</td>
                </tr>
                <tr>
                  <td className="left-head">Unpaid Leave</td>
                  <td>05</td>
                  <td className="leave-table-balance">360</td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <button className='btn btn-primary dashboard-apply-leave-btn btn-font-size-less'>Apply For Leave</button> */}
        </div>
        <div className="holiday-container">
          <h3>Upcoming Holidays</h3>
          <div className="holiday-content-container">
            <div className="holiday-content-container-single-container">
              <div className="holiday-single-container-box">
                <p className="date">15</p>
                <p className="month">Mar</p>
              </div>
              <div className="holiday-single-container-middle-text">
                <h4>Public Holiday</h4>
                <p>Public Holiday</p>
              </div>
              <div className="holiday-single-container-last-text">
                <p>13 Days Left</p>
              </div>
            </div>
            <div className="holiday-content-container-single-container">
              <div
                style={{ backgroundColor: "#efe0ff", color: "#bd36ff" }}
                className="holiday-single-container-box"
              >
                <p className="date">15</p>
                <p className="month">Mar</p>
              </div>
              <div className="holiday-single-container-middle-text">
                <h4>Public Holiday</h4>
                <p>Public Holiday</p>
              </div>
              <div className="holiday-single-container-last-text">
                <p>13 Days Left</p>
              </div>
            </div>
            <div className="holiday-content-container-single-container">
              <div
                style={{ backgroundColor: "#e0f0ff", color: "#0088ff" }}
                className="holiday-single-container-box"
              >
                <p className="date">15</p>
                <p className="month">Mar</p>
              </div>
              <div className="holiday-single-container-middle-text">
                <h4>Public Holiday</h4>
                <p>Public Holiday</p>
              </div>
              <div className="holiday-single-container-last-text">
                <p>13 Days Left</p>
              </div>
            </div>
            <div className="holiday-content-container-single-container">
              <div
                style={{ backgroundColor: "#fffce0", color: "#ffee00" }}
                className="holiday-single-container-box"
              >
                <p className="date">15</p>
                <p className="month">Mar</p>
              </div>
              <div className="holiday-single-container-middle-text">
                <h4>Public Holiday</h4>
                <p>Public Holiday</p>
              </div>
              <div className="holiday-single-container-last-text">
                <p>13 Days Left</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
