import { Autocomplete, Switch, TextField } from '@mui/material'
import React from 'react'

export const EmpCompanyInfo = () => {
  return (
    <>
    <div className='emp-bank-detail-container'>
      <div className="emp-bank-detail-input-section">
        <div className="global-single-input">
          <p>Employee Id</p>
          <input type="text" />
        </div>
        <div className="global-single-input auto-complete">
          <p>Department</p>
          <Autocomplete 
            options={["SALES","MANAGER"]}
            getOptionLabel={(option)=>option}
            renderInput={(params)=>(<TextField {...params}/>)}
          />
        </div>
        <div className="global-single-input auto-complete">
          <p>Designation</p>
          <Autocomplete
            options={["SALES","MANAGER","SHAKIR"]}
            getOptionLabel={(option)=>option}
            renderInput={(params)=>(
              <TextField {...params}/>
            )}
          />
        </div>
        <div className="global-single-input">
          <p>Date of Joining</p>
          <input type="date" />
        </div>
        <div className="global-single-input">
          <p>Date of Leaving</p>
          <input type="date" />
        </div>
        <div className="global-single-input">
          <p>Work Hours</p>
          <input type="text" />
        </div>
        <div className="global-single-input auto-complete">
          <p>Outlet Location</p>
          <Autocomplete
            options={["Kannur","kozhikode","s"]}
            getOptionLabel={(option)=>option}
            renderInput={(params)=>(<TextField {...params}/>)}
          />
        </div>
      </div>
      <button className='global-button-contained-blue' style={{marginTop:"2%"}}>
        Update
      </button>
    </div>
    <hr style={{margin:"0 1%"}}/>
    <div className="emp-company-info-section">
      <h4>Salary</h4>
      <div className="emp-company-info-section-body">
        <div className="global-single-input auto-complete">
          <p>Type</p>
          <Autocomplete options={["Full Time","Part Time","Other"]}
            getOptionLabel={(option)=>option}
            renderInput={(params)=>(<TextField {...params}/>)}
          />
        </div>
        <div className="global-single-input">
          <p>Monthly Salary</p>
          <input type="text" />
        </div>
      </div>
    </div>
    <div className="emp-company-info-section">
      <h4>Contract Period</h4>
      <div className="emp-company-info-section-body">
        <div className="global-single-input auto-complete">
          <p>From</p>
          <input type="date" />
        </div>
        <div className="global-single-input">
          <p>To</p>
          <input type="date" />
        </div>
      </div>
    </div>
    <hr style={{margin:"0 1%"}}/>
    <div className="emp-company-info-section" style={{paddingBottom:"2%"}}>

        <div className="emp-company-info-section-bottom">
          <h5>Status:</h5>
          <div className="emp-company-info-status-switch-container">
            <Switch
              label="active"
              defaultChecked
              // checked={empCompanyInfoForm.status}
              // onChange={getUpdatedEmpCompanyInfo("status")}
            />
            <p>Inactive&nbsp;/&nbsp;Active</p>
          </div>
        </div>
    </div>
    </>
  )
}
