/** @format */

import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";
import { useEffect } from "react";
// css from sales.css

export const PurchaseRevenueCard = (props) => {
  const { backgroundColor, onClick, marginBottom, borderLeft, heading, color } =
    props;



  return (
    <div
      className="purchase-revenue-card-container"
      style={{
        backgroundColor: backgroundColor,
        marginBottom: marginBottom,
        borderLeft: borderLeft,
        height: "10vh",
      }}
    >
      <div className="purchase-revenue-card-content">
        <p style={{ color: color }}>{heading}</p>
        <div className="purchase-revenue-card-icon-container">
          <IconButton onClick={onClick}>
            <KeyboardArrowRightIcon sx={{ color: color, cursor: "pointer" }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
