import { Dialog } from "@mui/material";
import React from "react";
import "../../../Css/Custom css/topClientsView.css";


const TopClientsView=(props)=>{
    const { open, onClose } = props;

    return(
        <>
        <Dialog maxWidth="lg"  open={open} >
                    <div className="client-view-parent">
                        <div className="client-view-head">
                            <h2>View Details</h2>
                        </div>
                        <div className="client-view-body">
                                <div className="client-view-body-section-three">
                                <div className="global-single-input package-plans-input" style={{width:'40%'}}>
                                    <p>Customer ID</p>
                                    <input  type="text" />
                                </div>
                                <div className="global-single-input package-plans-input" style={{width:'40%'}}>
                                    <p>Customer Name</p>
                                    <input  type="text" />
                                </div>
                                <div className="global-single-input package-plans-input" style={{width:'40%'}}>
                                    <p>Contact</p>
                                    <input  type="text" />
                                </div>
                                </div>

                                <div className="client-view-body-section-three">
                                <div className="global-single-input package-plans-input" style={{width:'40%'}}>
                                    <p>Email ID</p>
                                    <input  type="text" />
                                </div>
                                <div className="global-single-input package-plans-input" style={{width:'40%'}}>
                                    <p>Package Type</p>
                                    <input  type="text" />
                                </div>
                                <div className="global-single-input package-plans-input" style={{width:'40%'}}>
                                    <p>Package Amount</p>
                                    <input  type="text" />
                                </div>
                                </div>


                                <div className="client-view-body-section-two">
                                <div className="global-single-input package-plans-input" style={{width:'31%'}}>
                                    <p>Start Date</p>
                                    <input  type="text" />
                                </div>
                                <div className="global-single-input package-plans-input" style={{width:'31%'}}>
                                    <p>End Date</p>
                                    <input  type="text" />
                                </div>
                                </div>
                        </div>
                        <div className="client-view-button">                      
                            <div className="client-view-button-ctn">
                                <button onClick={onClose} className="cancel-btn">Cancel</button>
                            </div>
                            <div className="client-view-button-ctn">
                            <button className="submit-btn">Submit</button>
                            </div>
                        </div>
                    </div>



        </Dialog>
        </>
    );
};

export default TopClientsView;