import React from 'react'

export const EmpDocumentDetails = () => {
  return (
    <div className="global-container">
      
      <div className="global-container-table">
        <div className="global-top-container-head-section">
          <h3>Employee Salary</h3>
        </div>
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Documents</th>
              <th>Document Id</th>
              <th>Expiry Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Adhar</td>
              <td>7042-2343-1265-9999</td>
              <td>15-06-2023</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
