import axios from "axios";
import { store } from "../../Redux/Store";
import { HEADERS } from "../urlAndPath"
import { get_demo_request } from "../../Redux/Marketing/demoRequestSlice";

export const getCustomerDemo =(body)=>{
    axios.post("customer/getDemos",body,HEADERS)
    .then((res)=>{
        if(res.status===200){
          store.dispatch(get_demo_request({demoRequestData:res.data}))
        }
    })
    .catch((err)=>{
      console.log(err.response);
      store.dispatch(get_demo_request({demoRequestData:undefined}))
    })
}