import { createSlice } from "@reduxjs/toolkit";

const offersListSlice = createSlice({
    name:"offerListSlice",
    initialState:{
        value:undefined
    },
    reducers:{ // list of Actions
        get_All_offers:(state,action)=>{
            state.value = action.payload.getAlloffersList
        }

    }
});

export const {get_All_offers} = offersListSlice.actions;
export default offersListSlice.reducer;