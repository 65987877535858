import React, { useState } from "react";
import "../../../Css/HomeDashboard/totalRevenue.css";
import TopContainer from "../CustomComponent/TopContainer";
import { useNavigate } from "react-router-dom";
 export const TotalRevenue = () => {
  
  return (
    <>
    <div>
      <TopContainer label='Total Revenue'/>
      <div className="global-container reg-user-container">
        <div className="global-container-table">
        <table>
            <thead>
                <tr>
                <th>Particular</th>
                <th>Selling Price</th>
                <th>Quantity</th>
                <th>Revenue</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>ERP Restaurant</td>
                    <td>100.00</td>
                    <td>50</td>
                    <td>5000.00</td>
                </tr>

                <tr>
                    <td>ERP Restaurant</td>
                    <td>100.00</td>
                    <td>50</td>
                    <td>5000.00</td>
                </tr>
                <tr>
                    <td>ERP Restaurant</td>
                    <td>100.00</td>
                    <td>50</td>
                    <td>5000.00</td>
                </tr>
                <tr>
                    <td>ERP Restaurant</td>
                    <td>100.00</td>
                    <td>50</td>
                    <td>5000.00</td>
                </tr>
                <tr>
                    <td>ERP Restaurant</td>
                    <td>100.00</td>
                    <td>50</td>
                    <td>5000.00</td>
                </tr>
                <tr>
                    <td>ERP Restaurant</td>
                    <td>100.00</td>
                    <td>50</td>
                    <td>5000.00</td>
                </tr>
                <tr>
                    <td>ERP Restaurant</td>
                    <td>100.00</td>
                    <td>50</td>
                    <td>5000.00</td>
                </tr>
                <tr>
                    <td>ERP Restaurant</td>
                    <td>100.00</td>
                    <td>50</td>
                    <td>5000.00</td>
                </tr>
            </tbody>
        </table>

        
        </div>

        <div className="total-revenue-footer">
            <div className="total-revenue-footer-ctn">
             <div className="total-revenue-footer-ctn-top">
             <div className="total-revenue-footer-ctn-top-left">
                    <p>Net Revenue</p>
                    <p>Refund</p>
                    <p>Discount & Allowance</p>
                    

              </div>
              <div className="total-revenue-footer-ctn-top-right">
                    <input type />
                    <input type />
                    <input type />

              </div>

              </div>
              <div className="total-revenue-footer-ctn-bottom">
              <div className="total-revenue-footer-ctn-bottom-left">
                <p>Total Revenue</p>
                </div>
                <div className="total-revenue-footer-ctn-bottom-right">
                  <input type />
                </div>
                </div> 
            </div>
        </div>
        </div>
        </div>
    </>
  );
};

export default TotalRevenue;

