import { createSlice } from "@reduxjs/toolkit";

const totalRevenueSlice = createSlice({
    name:'totalRevenueGraph',
    initialState:{
        value:undefined
    },
    reducers:{
        get_total_revenue_graph:(state,action) =>{
            state.value=action.payload.totalGraphData
        }
    }
});

export const {get_total_revenue_graph} = totalRevenueSlice.actions;
export default totalRevenueSlice.reducer;
