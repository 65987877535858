import React, { useState } from 'react'
import TopContainer from '../../CustomComponent/TopContainer'
import { Autocomplete, Dialog, IconButton, Select, TextField } from '@mui/material'
import '../../../../Css/Staff/leave.css'

export const LeaveApplication = () => {
   const [leaveView,setLeaveView]=useState(false)
   const openSingleView=()=>{
    setLeaveView(true)
   }
  
  return (
    <>
      <TopContainer label="Leave > Leave Application"/>
      <div className='global-container'>
         <div className='global-heading-containe'>
                <h3>Leave Application</h3>
                <div style={{width:"50%",display:"flex"}}>
                  <div className='global-search-container' style={{width:"50%",height:"43px",margin:"1%"}}>
                     <input type="text" placeholder='Search...' style={{height:"37px"}}/>
                     <i class="bi bi-search search-icon global-search-icon"></i>
                  </div>
                  <div className="global-single-input auto-complete leave-application-autocomplete" style={{width:"40%"}}>
                   <Autocomplete
                     sx={{width:"100%",}}
                     options={['']}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=><TextField {...params} style={{backgroundColor:'red'}}/>}
                   />
                </div>
                </div>
          </div>
          <div className='global-container-table'>
             <table>
                <thead>
                  <tr>
                    <th>Employee ID</th>
                    <th>Employee Name</th>
                    <th>Image</th>
                    <th>Leave Type</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Days</th>
                    <th>Applied On</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td >
                      <span className='leave-status-approved'>Approved</span>
                    </td>
                    <td>
                       <IconButton onClick={openSingleView}>
                          <i class="bi bi-eye visibility-icon"></i>
                        </IconButton>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td >
                      <span className='leave-status-rejected'>Rejected</span>
                    </td>
                    <td>
                       <IconButton>
                          <i class="bi bi-eye visibility-icon"></i>
                        </IconButton>
                    </td>
                  </tr>
                </tbody>
             </table>
          </div>
      </div>

      {/* leave application single view */}
      <Dialog open={leaveView} maxWidth="lg" onClose={()=>setLeaveView(false)}>
        <div className='add-leave-type-popup-container' style={{width:"40vw"}}>
          <div className='add-leave-type-popup-sub-container'>
             <h3 style={{margin:"2% 0"}}>view</h3>
             <hr className='global-br' style={{margin:"2% 0"}}/>
             <div className='add-leave-content-view'>
               <p>hello</p>
             </div>
             <div className='add-leave-view-button'>
              <p><span className='leave-status-rejected'>Rejected</span></p>
               
             </div>
          </div>
        </div>
      </Dialog>
    </>
  )
}
