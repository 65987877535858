import { Dialog, IconButton } from '@mui/material'
import React, { useState } from 'react'
import { PaySlip, PayslipView } from '../../../CustomComponent/PayslipView'

export const EmpPayslip = () => {
  const [paySlipView,setPayslipView]=useState(false)
  return (
    <>
    <div className="global-container">
      <div className="global-container-table">
        <div className="global-top-container-head-section">
          <h3>Payslip Summary</h3>
          <div className='dashboard-employee-leave-details-search-container'>
             <input type="text" placeholder='Search...'/>
             <button>Search</button>
         </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>No</th>
              <th>Transaction Id</th>
              <th>Month</th>
              <th>Year</th>
              <th>Net Salary</th>
              <th>Date</th>
              <th>View</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>PKLS43</td>
              <td>February</td>
              <td>2023</td>
              <td>75000</td>
              <td>03-02-2022</td>
              <td>
                <IconButton onClick={()=>setPayslipView(true)}>
                  <i class="bi bi-eye visibility-icon"></i>
                </IconButton>
              </td>
              <td>
                <IconButton>
                    <i class="bi bi-trash3 delete-icon"></i>
                </IconButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Dialog maxWidth="lg" open={paySlipView} onClose={()=>setPayslipView(false)}>
        <>
            <PayslipView exitDialog={()=>setPayslipView(false)}/>
        </>
    </Dialog>
    </>
  )
}
