import { Dialog, IconButton, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import totalSalaryIcon from "../../../Assets/Icons/staffDashboardIcons/TOTAL SALARY PAID.svg";
import totalLeave from "../../../Assets/Icons/staffDashboardIcons/TOTAL LEAVE.svg";
import totalAttendance from "../../../Assets/Icons/staffDashboardIcons/TOTAL ATTENDENCE.svg";
import {
  createEmpCountDoughnutData,
  monthLabels,
  todayLabels,
  weekLabels,
} from "./staffDashboardChartDatas";
import "../../../Css/Staff/staffDashboard/staffDashboard.css";
import { CustomRadioGroup } from "../../SingleCompoents/SnackBars/CustomRadioGroup";

export const StaffDashboad = () => {

    //Custom Radio data
    const customRadio = [
      {
        id: "fstRadio",
        label: "Today",
        name: "customRadio",
        value: "day",
        
      },
      {
        id: "secondRadio",
        label: "Week",
        name: "customRadio",
        value: "week",
        checked: true
      },
      {
        id: "thirdRadio",
        label: "Month",
        name: "customRadio",
        value: "month",
      },
    ];

  const noticeboardData = [
    {
      fromeDate: "2023-04-15",
      note: "Celebrate vishu utsav",
      time: "04:19:00",
      title: "Vishu Celebration",
      toDate: "2023-04-16",
      id: "63ff4709835465d8210b890d",
    },
    {
      fromeDate: "2023-04-15",
      note: "Shojappan day",
      time: "04:19:00",
      title: "Vishu Celebration",
      toDate: "2023-04-16",
      id: "63ff4709835465d8210b890d",
    },
    {
      fromeDate: "2023-04-15",
      note: "Pedakkna mean",
      time: "04:19:00",
      title: "SHAKIR",
      toDate: "2023-04-16",
      id: "63ff4709835465d8210b890d",
    },
  ];
  const empCountGraph = "";

  const convertMonthToWord = (date = "0000-00-00") => {
    let monthInWord = null;
    let [year, month, day] = date.split("-");

    switch (month) {
      case "01":
        monthInWord = "Jan";
        break;
      case "02":
        monthInWord = "Feb";
        break;
      case "03":
        monthInWord = "Mar";
        break;
      case "04":
        monthInWord = "Apr";
        break;
      case "05":
        monthInWord = "May";
        break;
      case "06":
        monthInWord = "Jun";
        break;
      case "07":
        monthInWord = "Jul";
        break;
      case "08":
        monthInWord = "Aug";
        break;
      case "09":
        monthInWord = "Sep";
        break;
      case "10":
        monthInWord = "Oct";
        break;
      case "11":
        monthInWord = "Nov";
        break;
      case "12":
        monthInWord = "Dec";
        break;

      default:
        monthInWord = "##";
        break;
    }
    return monthInWord;
  };

  // states
  //Add Notice board Form
  const addNoticeboardInitialState = {
    title: "",
    note: "",
    fromDate: "today",
    toDate: "dateAfterFiveDays",
    timeHour: 1,
    timeMinute: 0,
    meridiem: "AM",
  };
  const [addNoticeBoardForm, setAddNoticeBoardForm] = useState(
    addNoticeboardInitialState
  );

  const [noticeAddBoard, setNoticeAddBoard] = useState(false);
  const [expenseType, setExpenseType] = useState("week");
  const getCurrentPcTime = () => {
    let now = new Date();
    return `${now.getHours().toString().padStart(2, "0")}:${now
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  };
  const [currentPcTime, setCurrentPcTime] = useState(getCurrentPcTime());

  //submitNotice
  const submitNotice = () => {};

  //closeAddNoticeBoardDialog
  const closeAddNoticeBoardDialog = () => {};

  //getAddBoardData
  const getAddBoardData = () => {};

  //doughnutData
  const doughnutData = {
    labels: ["Female", "Male"],
    datasets: [
      {
        data: createEmpCountDoughnutData(empCountGraph),
        backgroundColor: ["rgb(17 0 255)", "rgb(255 36 0)"],
        radius: 100,
      },
    ],
  };

  //Line Chart Data
  const data = {
    labels:
      expenseType === "day"
        ? todayLabels
        : expenseType === "week"
        ? weekLabels
        : expenseType === "month"
        ? monthLabels
        : undefined,

    datasets: [
      {
        label: "Revenue",
        data: "expenseGraphData" || null,
        backgroundColor: "#3a00ff",
        borderColor: "#3a00ff",
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Revenue Graph",
      },
    },
  };

  //deleteNoticeBoardClick
  const deleteNoticeBoardClick = () => {};

  //ClickNoticeAddBoard
  const ClickNoticeAddBoard = () => {

    
  };

  //totalExpenseGraphChange
  const totalExpenseGraphChange = () => {};

  return (
    <>
      <div className="staff-dashboard-container">
        <p className="breadcrumb">Staff &gt; Dashboard</p>

        <div
          className="employee-dashboard-date-time-picker"
          style={{ alignSelf: "flex-end" }}
        >
          <div className="employee-dashboard-date-picker">
            <input type="date" value={"today"} />
          </div>
          <div className="employee-dashboard-time-picker">
            <input type="time" value={currentPcTime} disabled />
          </div>
        </div>

        <div className="employee-dashboard-card-container">
          <div className="employee-dashboard-card">
            <div
              className="employee-dashboard-card-icon-container"
              style={{ backgroundColor: "#f26149" }}
            >
              <img src={totalAttendance} alt="total salary" />
            </div>
            <div className="employee-dashboard-card-text-container">
              <h5>Total Employees</h5>
              <p>{empCountGraph?.Employeetotal || "##"}</p>
            </div>
          </div>
          <div className="employee-dashboard-card">
            <div
              className="employee-dashboard-card-icon-container"
              style={{ backgroundColor: "rgb(79 73 242)" }}
            >
              <img src={totalLeave} alt="total attendance" />
            </div>
            <div className="employee-dashboard-card-text-container">
              <h5>Total Departments</h5>
              <p>{empCountGraph?.departmentTotal || "##"}</p>
            </div>
          </div>
          <div className="employee-dashboard-card">
            <div
              className="employee-dashboard-card-icon-container"
              style={{ backgroundColor: "rgb(0 133 85)" }}
            >
              <img src={totalSalaryIcon} alt="total leave" />
            </div>
            <div className="employee-dashboard-card-text-container">
              <h5>Total Expense</h5>
              <p>{empCountGraph?.expenseTotal || "##"}</p>
            </div>
          </div>
        </div>

        <div className="staff-dashboard-body-container">
          <div className="staff-dashboard-total-expense-container">
            <div className="top-bar">
              <p>Total Expense</p>
              <CustomRadioGroup
              customRadioData={customRadio}
              value={expenseType}
              onChange={totalExpenseGraphChange}
            />
            </div>

            <div className="chart-container">
              <Line options={options} data={data} />
            </div>
          </div>

          <div className="staff-dashboard-notice-board-container">
            <div className="staff-dashboard-notice-board-container-top">
              <h3>Notice Board</h3>
              <button
                className="btn btn-notice-board"
                onClick={ClickNoticeAddBoard}
              >
                Add Board
              </button>
            </div>
            <div className="staff-dashboard-notice-board-container-body">
              {noticeboardData
                ?.slice()
                ?.reverse()
                ?.map((notice) => (
                  <div className="staff-dashboard-notice-board-container-section">
                    <div className="staff-dashboard-notice-board-container-section-left">
                      <p>{convertMonthToWord(notice?.fromeDate)}</p>
                      <p>{notice?.fromeDate?.split("-")[2]}</p>
                    </div>
                    <div className="staff-dashboard-notice-board-container-section-right">
                      <h4>{notice?.title}</h4>
                      <p>{notice?.note}</p>
                    </div>
                    {/* <IconButton
                      onClick={deleteNoticeBoardClick(notice?._id)}
                      className="staff-dashboard-notice-board-container-section-delete-btn"
                    >
                      <i class="bi bi-trash3 delete-icon"></i>
                    </IconButton> */}
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="staff-dashboard-body-container-two">
          <div className="staff-dashboard-body-container-two-left">
            <h3>Employee</h3>
            <div className="chart-container staff-dashboard-doughnut">
              {/* <div className="chart-container-total-count">
                              <h4>Total</h4>
                              <h1>500</h1>
                          </div> */}
              <Doughnut
                data={doughnutData}
                options={{
                  responsive: true,
                  cutout: "70%",
                  plugins: {
                    legend: {
                      display: true,
                      position: "bottom",
                      labels: {
                        boxWidth: 18,
                        boxHeight: 18,
                      },
                    },
                  },
                }}
              ></Doughnut>
            </div>
          </div>

          <div className="staff-dashboard-body-container-two-right">
            <div className="top-bar">
              <h3>Notifications</h3>
              <CustomRadioGroup
              customRadioData={customRadio}
              // value={""}
              // onChange={totalExpenseGraphChange}
              backgroundColor="#f60000"
            />
            </div>
            <div className="staff-dashboard-body-container-two-right-notification">
              <div className="staff-dashboard-body-container-two-right-notification-left"></div>
              <div className="staff-dashboard-body-container-two-right-notification-content">
                <h3>Job Application Mail</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div className="staff-dashboard-body-container-two-right-notification-right">
                <p>10 min ago</p>
              </div>
            </div>
            <div className="staff-dashboard-body-container-two-right-notification">
              <div
                className="staff-dashboard-body-container-two-right-notification-left"
                style={{ backgroundColor: "#efeb7c" }}
              ></div>
              <div className="staff-dashboard-body-container-two-right-notification-content">
                <h3>Job Application Mail</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div className="staff-dashboard-body-container-two-right-notification-right">
                <p>10 min ago</p>
              </div>
            </div>
            <div className="staff-dashboard-body-container-two-right-notification">
              <div
                className="staff-dashboard-body-container-two-right-notification-left"
                style={{ backgroundColor: "#7cbdef" }}
              ></div>
              <div className="staff-dashboard-body-container-two-right-notification-content">
                <h3>Job Application Mail</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div className="staff-dashboard-body-container-two-right-notification-right">
                <p>10 min ago</p>
              </div>
            </div>
            <div className="staff-dashboard-body-container-two-right-notification">
              <div
                className="staff-dashboard-body-container-two-right-notification-left"
                style={{ backgroundColor: "#efeb7c" }}
              ></div>
              <div className="staff-dashboard-body-container-two-right-notification-content">
                <h3>Job Application Mail</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
              <div className="staff-dashboard-body-container-two-right-notification-right">
                <p>10 min ago</p>
              </div>
            </div>
          </div>
        </div>
        <div className="staff-dashboard-body-container-bottom">
          <div className="top-bar">
            <h3>Attendance</h3>
            <CustomRadioGroup
            customRadioData={customRadio}
            // value={""}
            backgroundColor="#f60000"
          />
          </div>
          <div className="global-container-table">
            <table className="global-table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Employee ID</th>
                  <th>Name</th>
                  <th>In</th>
                  <th>Out</th>
                  <th>Total Work Hours</th>
                  <th>Break In</th>
                  <th>Break Out</th>
                  <th>Total Break Hours</th>
                  <th>Date</th>
                  <th>Leave</th>
                  <th>View</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>01</td>
                  <td>EMP-W2200</td>
                  <td>Niyhad</td>
                  <td>9:00 AM</td>
                  <td>6:00 PM</td>
                  <td>9 hours</td>
                  <td>12:00 PM</td>
                  <td>3:00 PM</td>
                  <td>4 Hours</td>
                  <td>09-12-2022</td>
                  <td>20</td>
                  <td>
                    <IconButton>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <button className="btn btn-green-outlined">Active</button>
                  </td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>EMP-W2200</td>
                  <td>Niyhad</td>
                  <td>9:00 AM</td>
                  <td>6:00 PM</td>
                  <td>9 hours</td>
                  <td>12:00 PM</td>
                  <td>3:00 PM</td>
                  <td>4 Hours</td>
                  <td>09-12-2022</td>
                  <td>20</td>
                  <td>
                    <IconButton>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <button className="btn btn-green-outlined">Active</button>
                  </td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>EMP-W2200</td>
                  <td>Niyhad</td>
                  <td>9:00 AM</td>
                  <td>6:00 PM</td>
                  <td>9 hours</td>
                  <td>12:00 PM</td>
                  <td>3:00 PM</td>
                  <td>4 Hours</td>
                  <td>09-12-2022</td>
                  <td>20</td>
                  <td>
                    <IconButton>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <button className="btn btn-green-outlined">Active</button>
                  </td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>EMP-W2200</td>
                  <td>Niyhad</td>
                  <td>9:00 AM</td>
                  <td>6:00 PM</td>
                  <td>9 hours</td>
                  <td>12:00 PM</td>
                  <td>3:00 PM</td>
                  <td>4 Hours</td>
                  <td>09-12-2022</td>
                  <td>20</td>
                  <td>
                    <IconButton>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <button className="btn btn-green-outlined">Active</button>
                  </td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>EMP-W2200</td>
                  <td>Niyhad</td>
                  <td>9:00 AM</td>
                  <td>6:00 PM</td>
                  <td>9 hours</td>
                  <td>12:00 PM</td>
                  <td>3:00 PM</td>
                  <td>4 Hours</td>
                  <td>09-12-2022</td>
                  <td>20</td>
                  <td>
                    <IconButton>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <button className="btn btn-green-outlined">Active</button>
                  </td>
                </tr>
                <tr>
                  <td>01</td>
                  <td>EMP-W2200</td>
                  <td>Niyhad</td>
                  <td>9:00 AM</td>
                  <td>6:00 PM</td>
                  <td>9 hours</td>
                  <td>12:00 PM</td>
                  <td>3:00 PM</td>
                  <td>4 Hours</td>
                  <td>09-12-2022</td>
                  <td>20</td>
                  <td>
                    <IconButton>
                      <i class="bi bi-eye visibility-icon"></i>
                    </IconButton>
                  </td>
                  <td>
                    <button className="btn btn-green-outlined">Active</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* <Dialog
      open={noticeAddBoard}
      maxWidth="lg"
      onKeyDown={(e) => e.key === "Escape" && setNoticeAddBoard(false)}
    >
      <div className="notice-add-board-container">
        <h3>Add Board</h3>
        <div className="global-single-input notice-add-board-input">
          <p>Title</p>
          <input
            type="text"
            value={addNoticeBoardForm.title}
            onChange={getAddBoardData("title")}
            placeholder="Enter title.."
          />
        </div>
        <div className="global-single-input notice-add-board-input">
          <p>Note</p>
          <textarea
            value={addNoticeBoardForm.note}
            onChange={getAddBoardData("note")}
            placeholder="Enter notes..."
          ></textarea>
        </div>
        <div className="notice-add-board-container-section">
          <div className="global-single-input notice-add-board-input">
            <p>From date</p>
            <input
              type="date"
              value={addNoticeBoardForm.fromDate}
              onChange={getAddBoardData("fromDate")}
            />
          </div>
          <div className="global-single-input notice-add-board-input">
            <p>To Date</p>
            <input
              type="date"
              value={addNoticeBoardForm.toDate}
              onChange={getAddBoardData("toDate")}
            />
          </div>
        </div>
        <div className="notice-add-board-container-section-two">
          <div className="global-single-input notice-add-board-input">
            <p>Time</p>
            <Select
              style={{ backgroundColor: "#eef2f9" }}
              value={addNoticeBoardForm.timeHour}
              onChange={getAddBoardData("timeHour")}
            >
              {Hours.map((hour) => (
                <MenuItem value={hour.hour}>{hour.hour}</MenuItem>
              ))}
            </Select>
          </div>
          <div className="global-single-input notice-add-board-input">
            <Select
              style={{ backgroundColor: "#eef2f9" }}
              value={addNoticeBoardForm.timeMinute}
              onChange={getAddBoardData("timeMinute")}
            >
              {Minutes.map((minute) => (
                <MenuItem value={minute.minute}>{minute.minute}</MenuItem>
              ))}
            </Select>
          </div>
          <div className="global-single-input notice-add-board-input">
            <Select
              style={{ backgroundColor: "#eef2f9" }}
              value={addNoticeBoardForm.meridiem}
              onChange={getAddBoardData("meridiem")}
            >
              <MenuItem value="AM">AM</MenuItem>
              <MenuItem value="PM">PM</MenuItem>
            </Select>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            className="btn btn-secondary-outlined"
            style={{ alignSelf: "center", margin: "3%" }}
            onClick={closeAddNoticeBoardDialog}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            style={{ alignSelf: "center", margin: "3%" }}
            onClick={submitNotice}
          >
            Submit
          </button>
        </div>
      </div>
    </Dialog> */}

      {/* <SuccessSnackbar open={openSuccessSnack} handleClose={closeSuccessSnackbar} message={snackMsg}/>
            <ErrorSnackbar open={openErrorSnack} handleClose={closeErrorSnackbar} message={snackMsg}/> */}
    </>
  );
};
