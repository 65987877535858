import React, { useState } from "react";
import TopContainer from "../../CustomComponent/TopContainer";
import { Autocomplete, Dialog, IconButton, TextField } from "@mui/material";
import uploadImage from "../../../../Assets/Images/upload-image.png";
import "../../../../Css/Staff/HRM/addDocument.css"

export const AddDocument = () => {
  const [addDocument, setAddDocument] = useState(false);
  const [uploadedImage, setUploadedImage] = useState();
  // Document View state
  const [documentsView, setDocumentsView] = useState(false);

  const openAddDocument = () => {
    setAddDocument(true);
  };

  const imageUploadFn = (e) => {
    setUploadedImage(e.target.files[0]);
  };
  const removeImage = () => {
    setUploadedImage(undefined);
  };
  return (
    <>
      <TopContainer label="Staff > HRM > Add Document" />
      <div className="global-container">
        <div className="global-heading-containe">
          <h3 style={{ margin: "2% 0 0 2%" }}>Document</h3>
          <div
            style={{
              width: "42%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              className="global-search-container"
              style={{ width: "58%", height: "43px", margin: "1%" }}
            >
              <input
                type="text"
                placeholder="Search..."
                style={{ height: "37px" }}
              />
              <i class="bi bi-search search-icon global-search-icon"></i>
            </div>
            <button
              onClick={openAddDocument}
              style={{ width: "35%" }}
              className="global-button-contained-blue"
            >
              Add Document
            </button>
          </div>
        </div>
        <div className="global-container-table">
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Employee</th>
                <th>Employee ID</th>
                <th>Documents</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <IconButton onClick={() => setDocumentsView(true)}>
                    <i class="bi bi-eye visibility-icon"></i>
                  </IconButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* add Doccument */}
      <Dialog open={addDocument} maxWidth="lg">
        <div
          className="add-leave-type-popup-container"
          style={{ width: "40vw" }}
        >
          <div className="add-leave-type-popup-sub-container">
            <h3 style={{ margin: "2% 0" }}>Add Document</h3>
            <hr className="global-br" style={{ margin: "2% 0" }} />
            <div className="add-leave-type-popup-input-container">
              <div
                className="global-single-input auto-complete"
                style={{ width: "48%" }}
              >
                <p>Employee Name</p>
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={[""]}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <div className="global-single-input" style={{ width: "48%" }}>
                <p>Employee ID</p>
                <input type="text" />
              </div>
              <div
                className="global-single-input auto-complete"
                style={{ width: "48%" }}
              >
                <p>Document Type</p>
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={[""]}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <div className="global-single-input" style={{ width: "48%" }}>
                <p>Document Number</p>
                <input type="text" />
              </div>
              <div className="global-single-input" style={{ width: "48%" }}>
                <p>Expiry Date</p>
                <input type="date" />
              </div>
              <div className="global-single-input">
                <p>Upload Image</p>
                {uploadedImage === undefined && (
                  <div className="image-upload-file-conatiner">
                    <input
                      type="file"
                      id="upload-image"
                      onChange={imageUploadFn}
                    />
                    <label htmlFor="upload-image">
                      <img src={uploadImage} alt="" />
                    </label>
                  </div>
                )}
                {uploadedImage !== undefined && (
                  <div className="image-preview">
                    <IconButton
                      className="image-close-icon"
                      onClick={removeImage}
                    >
                      <i class="bi bi-x"></i>
                    </IconButton>
                    <img src={uploadedImage} alt="" />
                  </div>
                )}
              </div>

              <div className="button-container">
                <button
                  onClick={() => setAddDocument(false)}
                  className="global-button-contained-black-outlined"
                  style={{ width: "20%", height: "35px" }}
                >
                  Cancel
                </button>
                <button
                  className="global-button-contained-blue"
                  style={{ width: "20%", height: "35px" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      
      <Dialog
        open={documentsView}
        maxWidth="lg"
        onKeyDown={(e) => e.key === "Escape" && setDocumentsView(false)}
      >
        <div className="document-view-container">
          <h3>Documents</h3>
          <hr className="global-hr" style={{ width: "100%" }} />
          <div className="document-view-container-body">
            <h4>DocumentType</h4>
          </div>
          <div className="document-view-img-container">
            <img src="" alt="Document Proof" />

          </div>
        </div>
      </Dialog>
    </>
  );
};
