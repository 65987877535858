import { createSlice } from "@reduxjs/toolkit";
const transactionIDSlice =createSlice({
    name:'transactionIdSlice',
    initialState:{
        value:{
            prefix:"#",
            transId:"##"
          }
    },
    reducers:{
        update_TrasctiionID:(state,action)=>{
            state.value = action.payload.transactionID
        }
    }
});

export const {update_TrasctiionID} = transactionIDSlice.actions
export default transactionIDSlice.reducer;