import React from 'react'
import TopContainer from '../CustomComponent/TopContainer'
import '../../../Css/RolesAndPermission/rolesAndPermission.css'
import { Accordion, AccordionDetails, AccordionSummary, IconButton } from '@mui/material'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const RolesAndPermission = () => {
  return (
    <>
       <TopContainer label="Roles And Permission" />
       <div className='roles-and-permission-container'>
         <div className='roles-and-permission-single-container'>
           <h3>Employee</h3>
           <div className='roles-and-permission-search-container'>
            <input type="text" placeholder='Search Employee'/>
            <IconButton className='search-icon-container-roles-and-permission'>
              <i class="bi bi-search search-icon"></i>
            </IconButton>
           </div>
           <div className='employee-list-container'>
             <div className='employee-radio-button'>
               <input type="radio" id="employee-radio" name="employeeRadio"/>
               <label htmlFor="employee-radio">Deepak</label>
             </div>
             <div className='employee-radio-button'>
               <input type="radio" id="employee-radio-1" name="employeeRadio"/>
               <label htmlFor="employee-radio-1">Deepak</label>
             </div>
           </div>
         </div>
         <div className='roles-and-permission-single-container'>
           <div className='roles-permissiom-right-top-container'>
             <h3>Module Access</h3>
             <button className='module-access-update-button'>Update</button>
           </div>
           
           <hr />
             <div className='roles-and-permission-toggle-btn-container'>
             <div className='single-toggle-button'>
                <h4>Registered User</h4>
                <input
                className='toggle-checkbox' 
                type="checkbox"
                
                />
                </div>
                <div className='single-toggle-button'>
                <h4>Package & Plans</h4>
                <input
                className='toggle-checkbox' 
                type="checkbox"
                
                />
                </div>
                <div className='single-toggle-button'>
                <h4>Floor Plan Approval</h4>
                <input
                className='toggle-checkbox' 
                type="checkbox"
                
                />
                </div>
                <div>
                  <Accordion>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h4>Offers</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='single-toggle-button'>
                        <p>All</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                      <div className='single-toggle-button'>
                        <p>Add Offer</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                       <div className='single-toggle-button'>
                        <p>Offer Listed</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div>
                  <Accordion>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h4>Marketing</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='single-toggle-button'>
                        <p>All</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                      <div className='single-toggle-button'>
                        <p>Customer List</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                       <div className='single-toggle-button'>
                        <p>Add Customer</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className='single-toggle-button'>
                <h4>Tax</h4>
                <input
                className='toggle-checkbox' 
                type="checkbox"
                
                />
                </div>
                <div>
                  <Accordion>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h4>Reports</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='single-toggle-button'>
                        <p>All</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                      <div className='single-toggle-button'>
                        <p>Daily Report</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                       <div className='single-toggle-button'>
                        <p>Refund Report</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className='single-toggle-button'>
                <h4>Report Forward</h4>
                <input
                className='toggle-checkbox' 
                type="checkbox"
                
                />
                </div>
                <div>
                  <Accordion>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h4>SMS</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='single-toggle-button'>
                        <p>All</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                      <div className='single-toggle-button'>
                        <p>SMS Provider</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                       <div className='single-toggle-button'>
                        <p>SMS Package</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                       <div className='single-toggle-button'>
                        <p>User List</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className='single-toggle-button'>
                <h4>Refund</h4>
                <input
                className='toggle-checkbox' 
                type="checkbox"
                
                />
                </div>
                <div>
                  <Accordion>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h4>Staff</h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='single-toggle-button'>
                        <p>All</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                      <div className='single-toggle-button'>
                        <p>Dashboard</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                       <div>
                          <Accordion>
                             <AccordionSummary
                               expandIcon={<ExpandMoreIcon/>}
                               aria-controls="panel1a-content"
                               id="panel1a-header"
                             >
                                <h4>HRM</h4>
                             </AccordionSummary>
                             <AccordionDetails>
                               <div className='single-toggle-button'>
                                 <p>Department</p>
                                 <input
                                    className='toggle-checkbox' 
                                    type="checkbox"
                                 />
                               </div>
                               <div className='single-toggle-button'>
                                 <p>Designation</p>
                                 <input
                                    className='toggle-checkbox' 
                                    type="checkbox"
                                 />
                               </div>
                               <div className='single-toggle-button'>
                                 <p>Manage Employee</p>
                                 <input
                                    className='toggle-checkbox' 
                                    type="checkbox"
                                 />
                               </div>
                               <div className='single-toggle-button'>
                                 <p>Add Employee</p>
                                 <input
                                    className='toggle-checkbox' 
                                    type="checkbox"
                                 />
                               </div>
                               <div className='single-toggle-button'>
                                 <p>Add Document</p>
                                 <input
                                    className='toggle-checkbox' 
                                    type="checkbox"
                                 />
                               </div>
                             </AccordionDetails>
                          </Accordion>
                       </div>
                       <div className='single-toggle-button'>
                        <p>Attendance</p>
                        <input
                           className='toggle-checkbox' 
                           type="checkbox"
                        />
                       </div>
                       <div>
                          <Accordion>
                             <AccordionSummary
                               expandIcon={<ExpandMoreIcon/>}
                               aria-controls="panel1a-content"
                               id="panel1a-header"
                             >
                                <h4>Leave</h4>
                             </AccordionSummary>
                             <AccordionDetails>
                               <div className='single-toggle-button'>
                                 <p>Leave Application</p>
                                 <input
                                    className='toggle-checkbox' 
                                    type="checkbox"
                                 />
                               </div>
                               <div className='single-toggle-button'>
                                 <p>Holiday</p>
                                 <input
                                    className='toggle-checkbox' 
                                    type="checkbox"
                                 />
                               </div>
                               <div className='single-toggle-button'>
                                 <p>Add Leave Type</p>
                                 <input
                                    className='toggle-checkbox' 
                                    type="checkbox"
                                 />
                               </div>
                             </AccordionDetails>
                          </Accordion>
                       </div>
                       <div>
                          <Accordion>
                             <AccordionSummary
                               expandIcon={<ExpandMoreIcon/>}
                               aria-controls="panel1a-content"
                               id="panel1a-header"
                             >
                                <h4>Payroll</h4>
                             </AccordionSummary>
                             <AccordionDetails>
                               <div className='single-toggle-button'>
                                 <p>Employee Salary</p>
                                 <input
                                    className='toggle-checkbox' 
                                    type="checkbox"
                                 />
                               </div>
                               <div className='single-toggle-button'>
                                 <p>Add Payroll</p>
                                 <input
                                    className='toggle-checkbox' 
                                    type="checkbox"
                                 />
                               </div>
                               <div className='single-toggle-button'>
                                 <p>Payroll Item</p>
                                 <input
                                    className='toggle-checkbox' 
                                    type="checkbox"
                                 />
                               </div>
                               <div className='single-toggle-button'>
                                 <p>Payslip</p>
                                 <input
                                    className='toggle-checkbox' 
                                    type="checkbox"
                                 />
                               </div>
                             </AccordionDetails>
                          </Accordion>
                       </div>
                       <div>
                          <Accordion>
                             <AccordionSummary
                               expandIcon={<ExpandMoreIcon/>}
                               aria-controls="panel1a-content"
                               id="panel1a-header"
                             >
                                <h4>Loan</h4>
                             </AccordionSummary>
                             <AccordionDetails>
                               <div className='single-toggle-button'>
                                 <p>Add New Loan</p>
                                 <input
                                    className='toggle-checkbox' 
                                    type="checkbox"
                                 />
                               </div>
                               <div className='single-toggle-button'>
                                 <p>Loan Report</p>
                                 <input
                                    className='toggle-checkbox' 
                                    type="checkbox"
                                 />
                               </div>
                               <div className='single-toggle-button'>
                                 <p>Loan Approval</p>
                                 <input
                                    className='toggle-checkbox' 
                                    type="checkbox"
                                 />
                               </div>
                              
                             </AccordionDetails>
                          </Accordion>
                       </div>

                       
                    </AccordionDetails>
                  </Accordion>
                </div>




              <div className='single-toggle-button'>
                <h4>Customer Support</h4>
                <input
                className='toggle-checkbox' 
                type="checkbox"
                
                />
              </div>
            
             </div>
         </div>
       </div>
    </>
  )
}
