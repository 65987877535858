import {  Snackbar } from '@mui/material'
import React from 'react'
import MuiAlert from '@mui/material/Alert';



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const SuccessSnackbar = ({open,handleClose,message}) => {
  return (
    <>
      <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success"  sx={{width:"100%"}}>
                {message}
          </Alert>
      </Snackbar>   
    </>
  )
}

export default SuccessSnackbar
