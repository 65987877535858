import { Autocomplete, Dialog, Switch, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  addlocationApicall,
  generateQrCodeAPICall,
  viewAllInactiveEmployeeAPICall,
} from "../../../../../API/Staff/HRM/addEmployee";
import { getDepartmentAPICall } from "../../../../../API/Staff/HRM/departmentAPI";
import { viewDesigantionAPI } from "../../../../../API/Staff/HRM/designationAPI";
import { store } from "../../../../../Redux/Store";
import { save_company_info } from "../../../../../Redux/Staff/manageEmployeeSlice";

import CountryList from "../../../../../Assets/JSON/countryState.json";
import { orange } from "@mui/material/colors";
import SuccessSnackbar from "../../../../SingleCompoents/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../../../SingleCompoents/SnackBars/ErrorSnackbar";

export const CompanyInfo = (props) => {
  const { moveToBankDetails } = props;
  //  useSelector
  // view al inactive employee list
  const inActiveEmployeeList = useSelector(
    (state) => state.manageEmployeeSlice.value
  );
  // view all department list
  const allDepartmentList = useSelector((state) => state.departmentSlice.value);
  // all designation list
  const allDesignationList = useSelector(
    (state) => state.designationSlice.value
  );
  //employee qr code
  const empQRCode = useSelector((state) => state.manageEmployeeSlice.value1);
  // all branch list
  const allBranchList = useSelector(
    (state) => state.manageEmployeeSlice.value3
  );
  // const all location list
  const allLocationList = useSelector(
    (state) => state.manageEmployeeSlice.value4
  );

  //  state
  const companyInfoInitialState = {
    employeeId: null,
    department: null,
    designation: null,
    joiningDate: "",
    leavingDate: "",
    workHours: "",
    type: null,
    monthlySalary: "",
    from: "",
    to: "",
    status: true,
    qrCode: null,
  };

  const PersonalInformation = {
    country: null,
    NewLocation: "",
  };

  const [companyinfoForm, setCompanyInfoForm] = useState(
    companyInfoInitialState
  );
  const [filteredDesignationList, setFilteredDesignationList] = useState([]);
  const [addBranch, setAddBranch] = useState(false);
  const [addLocation, setAddLocation] = useState(false);
  const [addlocation, setAddlocation] = useState(PersonalInformation);

  //Alert

  const [countryAlert, setCountryAlert] = useState(false);
  const [NewLocationAlert, setNewLocationAlert] = useState(false);

  // snackBar
  const [successSnackbaropen, setSuccessSnackbaropen] = useState(true);

  const [snackMsg, setSnackMsg] = useState("Oops!!");

  console.log(addlocation);
  useEffect(() => {
    viewAllInactiveEmployeeAPICall();
    getDepartmentAPICall();
    viewDesigantionAPI();
  }, []);

  // onChange function
  const getCompanyInfoForm = (key) => (e, newValue) => {
    const { value, checked } = e.target;
    if (key === "employeeId") {
      setCompanyInfoForm({ ...companyinfoForm, employeeId: newValue });
    }
    if (key === "department") {
      setCompanyInfoForm({ ...companyinfoForm, department: newValue });
    }
    if (key === "designation") {
      setCompanyInfoForm({ ...companyinfoForm, designation: newValue });
    }
    if (key === "dateOfJoining") {
      setCompanyInfoForm({ ...companyinfoForm, joiningDate: value });
    }
    if (key === "dateOfLeaving") {
      setCompanyInfoForm({ ...companyinfoForm, leavingDate: value });
    }
    if (key === "workHours") {
      setCompanyInfoForm({ ...companyinfoForm, workHours: value });
    }
    if (key === "type") {
      setCompanyInfoForm({ ...companyinfoForm, type: newValue });
    }
    if (key === "monthlySalary") {
      setCompanyInfoForm({ ...companyinfoForm, monthlySalary: value });
    }
    if (key === "from") {
      setCompanyInfoForm({ ...companyinfoForm, from: value });
    }
    if (key === "to") {
      setCompanyInfoForm({ ...companyinfoForm, to: value });
    }
    if (key === "status") {
      setCompanyInfoForm({ ...companyinfoForm, status: checked });
    }
  };
  // FILTER designation list
  useEffect(() => {
    let designation = [];
    designation = allDesignationList?.filter((obj) => {
      if (obj.departmentId === companyinfoForm?.department?._id) {
        return designation;
      }
    });
    setFilteredDesignationList(designation);
  }, [companyinfoForm?.department]);

  const generateQrBody = {
    empId: companyinfoForm?.employeeId?._id,
    // name:companyinfoForm?.employeeId?.staff_name
  };

  // generate qr code function
  const generateQrCodeFn = () => {
    if (companyinfoForm?.employeeId === null) {
      alert("Select Employee");
    } else {
      generateQrCodeAPICall(generateQrBody);
    }
  };
  // assign qr code data to state
  useEffect(() => {
    if (empQRCode !== undefined) {
      setCompanyInfoForm({ ...companyinfoForm, qrCode: empQRCode });
    }
  }, [empQRCode]);

  // company info next button function
  const companyInfoNextBtnFn = () => {
    store.dispatch(save_company_info({ companyInfoData: companyinfoForm }));
    moveToBankDetails();
  };

  //CountryFun
  const CountryFun = (e, newValue, key) => {
    const value = newValue || e.target.value || "";
    console.log(value);

    if (key === "Country") {
      console.log("country");
      setAddlocation({ ...addlocation, country: newValue });
      setCountryAlert(false);
      if (value === "") {
        setCountryAlert(true);
      }
    }

    if (key === "NewLocation") {
      setAddlocation({ ...addlocation, NewLocation: value });
      setNewLocationAlert(false);
      if (value === "") {
        setNewLocationAlert(true);
      }
    }
  };

  // locationAddBtn
  const locationAddBtn = () => {
    const { country,NewLocation } = addlocation; // destructuring

    if (country === null) {
      setCountryAlert(true);
    }
    else if(NewLocation === ""){
      setNewLocationAlert(true)
    }
    else{
      addlocationApicall(
        {
          country:addlocation?.country.country,
          locationName:addlocation?.NewLocation
      }
      )
    }

  };

  const closeSuccessSnackbar =()=>{
    setSuccessSnackbaropen(false);
  }

  return (
    <>
      <div className="add-employee-profile-info-container">
        <div className="profile-info-sub-container">
          <div className="global-single-input add-employee-input auto-complete">
            <p>Employee ID</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={inActiveEmployeeList || [""]}
              getOptionLabel={(option) =>
                `${option?.EMPID}-${option?.staff_name}`
              }
              renderInput={(params) => <TextField {...params} />}
              onChange={getCompanyInfoForm("employeeId")}
            />
          </div>
          <div className="global-single-input add-employee-input auto-complete">
            <p>Department</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={allDepartmentList || [""]}
              getOptionLabel={(option) => option?.departmentName}
              renderInput={(params) => <TextField {...params} />}
              onChange={getCompanyInfoForm("department")}
            />
          </div>
          <div className="global-single-input add-employee-input auto-complete">
            <p>Designation</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={filteredDesignationList || [""]}
              getOptionLabel={(option) => option?.position}
              renderInput={(params) => <TextField {...params} />}
              onChange={getCompanyInfoForm("designation")}
            />
          </div>
          <div className="global-single-input add-employee-input">
            <p>Date Of Joining</p>
            <input type="date" onChange={getCompanyInfoForm("dateOfJoining")} />
          </div>
          <div className="global-single-input add-employee-input">
            <p>Date Of Leaving</p>
            <input type="date" onChange={getCompanyInfoForm("dateOfLeaving")} />
          </div>
          <div className="global-single-input add-employee-input">
            <p>Work Hours</p>
            <input
              type="text"
              value={companyinfoForm?.workHours}
              onChange={getCompanyInfoForm("workHours")}
            />
          </div>
          <div className="global-single-input add-employee-input auto-complete">
            <p>Branch</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={[""]}
              getOptionLabel={(option) => option}
              renderInput={(params) => <TextField {...params} />}
            />
            <div className="package-module-add-btn">
              <button onClick={() => setAddBranch(true)}>Add Branch</button>
            </div>
          </div>
        </div>
        <hr className="global-hr" style={{ margin: "2%" }} />
        <h4 style={{ textAlign: "left", margin: "2%" }}>Salary</h4>
        <div className="profile-info-sub-container">
          <div className="global-single-input add-employee-input auto-complete">
            <p>Type</p>
            <Autocomplete
              sx={{ width: "100%" }}
              options={[{ type: "Full Time" }, { type: "Part Time" }] || [""]}
              getOptionLabel={(option) => option?.type}
              renderInput={(params) => <TextField {...params} />}
              onChange={getCompanyInfoForm("type")}
            />
          </div>
          <div className="global-single-input add-employee-input">
            <p>Monthly Salary</p>
            <input
              type="text"
              placeholder="Enter Monthly Salary"
              value={companyinfoForm?.monthlySalary}
              onChange={getCompanyInfoForm("monthlySalary")}
            />
          </div>
        </div>
        <h4 style={{ textAlign: "left", margin: "2%" }}>Salary</h4>
        <div className="profile-info-sub-container">
          <div className="global-single-input add-employee-input">
            <p>From</p>
            <input
              type="date"
              value={companyinfoForm?.from}
              onChange={getCompanyInfoForm("from")}
            />
          </div>
          <div className="global-single-input add-employee-input">
            <p>To</p>
            <input
              type="date"
              value={companyinfoForm?.to}
              onChange={getCompanyInfoForm("to")}
            />
          </div>
        </div>
        <hr className="global-hr" />
        <div className="profile-info-sub-container">
          <div className="toggle-status-container">
            <p style={{ fontSize: "0.9rem", fontWeight: 600 }}>Status</p>
            <Switch
              label="active"
              defaultChecked
              value={companyinfoForm?.status}
              onChange={getCompanyInfoForm("status")}
            />
            <p>Inactive&nbsp;/&nbsp;Active</p>
          </div>
          <div
            className="global-single-input add-employee-input"
            style={{ flexDirection: "row", alignItems: "center" }}
          >
            <button
              className="company-info-generate-qr-barcode"
              onClick={generateQrCodeFn}
            >
              Generate QR Code
            </button>
            <div>
              <img src={companyinfoForm?.qrCode} alt="" />
            </div>
          </div>
        </div>
        <div className="profile-info-button-container">
          <button className="global-button-contained-black-outlined">
            Back
          </button>
          <button
            onClick={companyInfoNextBtnFn}
            className="global-button-contained-blue"
          >
            Next
          </button>
        </div>
      </div>

      {/* Add branch dialog */}
      <Dialog open={addBranch} maxWidth="lg">
        <div className="add-department-dialog-container">
          <div className="add-department-dialog-sub-container">
            <h3>Add Branch</h3>
            <hr className="global-hr" />
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <div
                className="global-single-input auto-complete"
                style={{ width: "48%" }}
              >
                <p>Country</p>
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={[""]}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <div
                className="global-single-input auto-complete"
                style={{ width: "48%", margin: "1% 1% 2% 1%" }}
              >
                <p>Location</p>
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={[""]}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => <TextField {...params} />}
                />
                <div className="package-module-add-btn">
                  <button onClick={() => setAddLocation(true)}>
                    Add Location
                  </button>
                </div>
              </div>
              <div className="global-single-input" style={{ width: "48%" }}>
                <p>Branch</p>
                <input
                  style={{ width: "93%" }}
                  type="text"
                  placeholder="Enter new Branch..."
                />
              </div>
              <div className="global-single-input" style={{ width: "48%" }}>
                <p>Contact Name</p>
                <input
                  style={{ width: "93%" }}
                  type="text"
                  placeholder="Enter Contact Name..."
                />
              </div>
              <div className="global-single-input" style={{ width: "48%" }}>
                <p>Contact Number</p>
                <input
                  style={{ width: "93%" }}
                  type="text"
                  placeholder="Enter Contact Number..."
                />
              </div>
              <div className="global-single-input" style={{ width: "48%" }}>
                <p>Address</p>
                <textarea
                  style={{ width: "93%" }}
                  type="text"
                  placeholder="Enter Address..."
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "4% 0",
              }}
            >
              <button
                onClick={() => setAddBranch(false)}
                style={{ width: "28%" }}
                className="btn global-button-contained-black-outlined"
              >
                Cancel
              </button>
              <button
                style={{ width: "28%" }}
                className="btn global-button-contained-black"
              >
                Add
              </button>
            </div>
            <div className="global-container-table">
              <table className="global-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Country</th>
                    <th>Location</th>
                    <th>Branch</th>
                    <th>Contact Name</th>
                    <th>Contact Number</th>
                    <th>Address</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Dialog>


      {/* add location dialog */}

      <Dialog open={addLocation} maxWidth="lg">
        <div
          className="add-department-dialog-container"
          style={{ width: "35vw" }}
        >
          <div className="add-department-dialog-sub-container">
            <h3>Add Location</h3>
            <hr className="global-hr" />
            <div className="add-loaction-dialog-container">
              <div
                className="global-single-input auto-complete"
                style={{ width: "100%", margin: "2% 0" }}
              >
                <p>Country</p>
                <Autocomplete
                  sx={{ width: "100%" }}
                  options={CountryList || [""]}
                  getOptionLabel={(option) => `${option.country}`}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select Country" />
                  )}
                  onChange={(e, newValue) => CountryFun(e, newValue, "Country")}
                />
                {countryAlert && (
                  <p className="validation-alert">Select a Country</p>
                )}
              </div>

              <div className="add-loaction-dialog-top-container">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "85%",
                  }}
                >
                  <input
                    type="text"
                    placeholder="Enter New Location"
                    onChange={(e, newValue) =>
                      CountryFun(e, newValue, "NewLocation")
                    }
                    style={{ width: "95%" }}
                  />

                  {NewLocationAlert && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "0.7rem",
                        fontWeight: 600,
                        alignSelf: "end",
                      }}
                    >
                      Enter Location
                    </p>
                  )}
                </div>
                <button
                  style={{
                    position: "relative",
                    top: "-3px",
                  }}
                  onClick={locationAddBtn}
                >
                  +
                </button>
              </div>

              <div className="add-location-dialog-top-main-container">
                <div className="add-loaction-dialog-top-container">
                  <input type="text" placeholder="Search Location" />
                  <button>
                    <i class="bi bi-search search-icon"></i>
                  </button>
                </div>
                <div className="add-location-list">

                  <p>aaa</p>
                  <p>bbb</p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "4% 0",
              }}
            >
              <button
                className="btn global-button-contained-black"
                style={{ width: "35%" }}
                onClick={() => setAddLocation(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Dialog>

      {/* <SuccessSnackbar
        open={successSnackbaropen}
        handleClose={closeSuccessSnackbar}
        message={snackMsg}
      /> */}

      {/* <ErrorSnackbar

      /> */}
    </>
  );
};
