/** @format */

import logo from "./logo.svg";
import "./App.css";
import Login from "./Components/Login/Login";
import LeftMenu from "./Components/Dashboard/LeftMenu/LeftMenu";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Dashboard from "./Components/Dashboard/Dashboard";
import { ProtectedRoutes } from "./ProtectedRoutes";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="dashboard/*" element={<Dashboard />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
