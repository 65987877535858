import { createSlice } from "@reduxjs/toolkit";

const homeRegisterSlice = createSlice({
    name:'homeRegisterSlice',
    initialState:{
        value:undefined,
        singleUserData:undefined,
        customerInvoice:undefined,
        singleData:undefined
    },

    reducers:{
        get_All_customers:(state,action)=>{
            state.value = action.payload.registerdUserData
            console.log("successs fullyyyy")
        },
        get_Single_Customers:(state,action)=>{
            // console.log(action.payload.singleUserData)
            state.singleUserData = action.payload.singleUserData;
        },
        get_customer_invoice:(state,action)=>{
            state.customerInvoice = action.payload.customerInvoiceData;
        },
        get_my_plans_singleView:(state,action)=>{
            state.singleData=action.payload.singleViewData
        }
    }
});


export const {get_All_customers,get_Single_Customers,get_customer_invoice,get_my_plans_singleView} =homeRegisterSlice.actions;
export default homeRegisterSlice.reducer