import {createSlice} from "@reduxjs/toolkit";
const customerRegistrationSlice = createSlice({
    name:'customerRegistrationSlice',
    initialState:{
        initialForm:undefined,
        filterPackage:undefined

    },
    reducers:{
        customer_register_initial_form:(state,action)=>{
            state.initialForm = action.payload.initialFormData;
            
        },
        filter_package_list:(state,action)=>{
            state.filterPackage = action.payload.filterPackageData;
            
        }
    }
});

export const {customer_register_initial_form,filter_package_list} = customerRegistrationSlice.actions;
export default customerRegistrationSlice.reducer;