import React from "react";
import TopContainer from "../CustomComponent/TopContainer";
import "../../../Css/Foor Plan Approval/floorPlanApproval.css";
import Image from "../../../Assets/Images/man.jpg";
import DownloadIcon from '../../../Assets/Images/downloadIcon.png'
import { IconButton } from "@mui/material";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
const FloorPlanApproval = () => {
  return (
    <>
      <TopContainer label="Floor Plan Approval" />
      <div className="global-container floor-plan-approval-container">
        <h3>Floor Plan Approval</h3>
        <div className="global-container-table">
          <table>
            <thead>
              <tr>
                <th>No</th>
                <th>Company Name</th>
                <th>Contact</th>
                <th>Mail</th>
                <th>Image</th>
                <th>Action</th>
                <th>Status</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>01</td>
                <td>Lorum Ipsum...</td>
                <td>0123456789</td>
                <td>lorum@gail.com</td>
                <td>
                  <div className="table-img-container">
                    <img src={Image} alt="Downloadable Image" />
                    <img src={DownloadIcon} alt="Download Icon" className="download-icon" />
                  </div>
                </td>
                <td>
                    <div className="table-upload-image">
                      <input type="file" id="tableImageUpload"/>
                      <label htmlFor="tableImageUpload">
                        <p>Upload</p>
                        <KeyboardArrowDownOutlinedIcon sx={{color:'#fff',fontSize:'0.9rem'}}/>
                      </label>
                    </div>
                </td>
                <td>
                  <p className="active">Active</p>
                </td>
                <td><IconButton><DeleteOutlineOutlined sx={{color:'#ff2400'}}/></IconButton></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default FloorPlanApproval;
