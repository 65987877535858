import axios from "axios"
import { useNavigate } from "react-router-dom"
import { BASE_URL, HEADERS, HEADERS1 } from "../urlAndPath"




export const loginApi =async(userCredential,setisSuccess,setErrorSnack,setErrorMsg)=>{

    try{

        // throw "undefined"

        const res = await axios.post("admin/login",userCredential,HEADERS)
        if(res.status === 200){
            console.log("tokenno",res.data.token)
            localStorage.setItem('userToken',res.data.token)
            localStorage.setItem('isAuth',true)
            localStorage.setItem("ErpAdminpanelCredential",JSON.stringify(res.data))
            axios.defaults.headers.common[
                "Authorization"
            ]=`Bearer ${res.data.token}`;
            setisSuccess(true);
        }
    }catch(err){
        console.log("error...",err)

        if(err == undefined){
            console.log("undefined error...",err)
            setErrorMsg('Network Error!')
        }
        if(err.response.status === 500){
            setErrorMsg("Server error..!")

        }
        if(err.response.status === 401){
            setErrorMsg("Invalid Password..!")
        }

        setErrorSnack(true);
        console.log(err.response)
    }
}