import { Dialog, IconButton } from '@mui/material'
import React, { useState } from 'react'
import "../../../../../Css/Staff/HRM/empLoanDetails.css"
export const EmpLoanDetails = () => {

  const [applyLoan,setApplyLoan]=useState(false)
  const [viewSingleLoan,setViewSingleLoan]=useState(false)
  const clickApplyForLoan=()=>{
    setApplyLoan(true)
    setViewSingleLoan(false)
  }
  const clickLoanSingleView=()=>{
    setViewSingleLoan(true)
    setApplyLoan(true)
  }
  const clickLoanSingleViewClose=()=>{
    setApplyLoan(false)
  }
  return (
    <>
    <div className="global-container">
      <div className="global-container-table">
        <div className="global-top-container-head-section">
          <h3>Loan Details</h3>
          <button 
          onClick={clickApplyForLoan}
          className="global-button-contained-blue emp-leave-apply-button" style={{backgroundColor:"#25ae29"}}>Apply For Loan</button>
        </div>
        <table>
          <thead>
            <tr>
              <th>Loan Id</th>
              <th>Loan Amount</th>
              <th>Interest Rate</th>
              <th>Tenure</th>
              <th>Status</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>LN2</td>
              <td>450000</td>
              <td>35</td>
              <td>4</td>
              <td><span className='status-span pending'>Pending</span></td>
              <td>
                <IconButton onClick={clickLoanSingleView}>
                  <i class="bi bi-eye visibility-icon"></i>
                </IconButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Dialog open={applyLoan} maxWidth="lg">
      <div className="add-loan-dialog-box">
        <div className="add-loan-dialog-container">
          <div className="add-loan-dialog-section">
            <h3>Employee Details</h3>
            <div className="add-loan-dialog-input-section">
              <div className="global-single-input">
                <p>Employee Name</p>
                <input type="text" />
              </div>
              <div className="global-single-input">
                <p>Employee ID</p>
                <input type="text" />
              </div>
              <div className="global-single-input">
                <p>Contact</p>
                <input type="text" />
              </div>
              <div className="global-single-input">
                <p>Department</p>
                <input type="text" />
              </div>
              <div className="global-single-input">
                <p>Designation</p>
                <input type="text" />
              </div>
              <div className="global-single-input">
                <p>Branch</p>
                <input type="text" />
              </div>
            </div>
          </div>
          <div className="add-loan-dialog-section">
            <h3>Loan Details</h3>
            <div className="add-loan-dialog-input-section">
              <div className="global-single-input">
                <p>Actual Loan Amount</p>
                <input type="text" />
              </div>
              <div className="global-single-input">
                <p>Balance Amount</p>
                <input type="text" />
              </div>
            </div>
          </div>
          <div className="add-loan-dialog-section">
            <h3>Account Information</h3>
            <div className="add-loan-dialog-input-section">
              <div className="global-single-input">
                <p>Bank Name</p>
                <input type="text" />
              </div>
              <div className="global-single-input">
                <p>Account Holder Name</p>
                <input type="text" />
              </div>
              <div className="global-single-input">
                <p>Account No</p>
                <input type="text" />
              </div>
              <div className="global-single-input">
                <p>IFSC</p>
                <input type="text" />
              </div>
              <div className="global-single-input">
                <p>Branch</p>
                <input type="text" />
              </div>
            </div>
          </div>
          <div className="add-loan-dialog-section">
            <h3>Dates</h3>
            <div className="add-loan-dialog-input-section">
              <div className="global-single-input">
                <p>Loan Requested Date</p>
                <input type="text" />
              </div>
            </div>
          </div>
          <div className="apply-leave-btn-ctn">
              <button 
              onClick={clickLoanSingleViewClose}
              className="global-button-contained-black-outlined">Cancel</button>
              {!viewSingleLoan &&
              <button className="global-button-contained-blue">Submit</button>
              }
            </div>
        </div>
      </div>
    </Dialog>
    </>
  )
}
