import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { IconButton, MenuItem, Select } from "@mui/material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import "../../../Css/Custom css/packageduration.css";
import { addPackageDuration, deletePackageDurationAPICall } from "../../../API/AddPackage/AddPackageAPI";
import SuccessSnackbar from "../../SingleCompoents/SnackBars/SuccessSnackbar";
import ErrorSnackbar from "../../SingleCompoents/SnackBars/ErrorSnackbar";
import { deleteAlertMessage } from "../../../Js/generalFunnctionnns";
const PackageDuration = (props) => {
  const { open, onClose, packageDuration,update} = props; 
  
  const [duration,setDuration]=useState('')
  const [successSnackbar,setSuccessSnackbar]=useState(false)
  const [errorSnackbar,setErrorSnackbar]=useState(false)
  const [snackMsg,setSnackMsg]=useState("Oops!!!")
  
  const getDuration=(e)=>{
      setDuration(e.target.value)
  }
  const addDurationFn=()=>{
    addPackageDuration(
      {duration:duration},
      update,
      setDuration,
      setSuccessSnackbar,
      setErrorSnackbar,
      setSnackMsg
      )
  }

  const deletePackageDuration=(id)=>{
    deletePackageDurationAPICall(
      {_id:id},
      update,
      setSuccessSnackbar,
      setErrorSnackbar,
      setSnackMsg
      )
  }

  return (
    <>
      <Dialog maxWidth="lg"  open={open} >
        <div className="package-parent">

          <div className="package-head-container">
            <h2>Add Duration</h2>
          </div>
          <div className="package-body-container">
            <div className="package-body-container-left" >
                 <div className="global-single-input package-plans-input" style={{width:'100%'}}>
                    <p>Duration</p>
                    <input type="text" value={duration} onChange={getDuration} />    
                 </div>
             
            </div>
            <div className="package-body-container-right">
                    <button onClick={addDurationFn}>Add</button>
            </div>
           </div>
           <div className="package-container-table">
           <table>
                  <thead>
                      <tr>
                          <th>Duration</th>
                          <th>Action</th>
                          </tr>
                  </thead>
                  {/* <tbody>
                    {
                      packageDuration?.map((r,i)=>(
                        <tr>
                        <td>{r?.duration}</td>
                        <td>
                          <IconButton 
                         onClick={() => {
                          deletePackageDuration(r?._id);
                        }}
                          >
                             <DeleteOutlineOutlinedIcon sx={{ color: "#fd0301" }}/>
                          </IconButton></td>
                       </tr>
                      ))
                    }
                    
                     
                  </tbody> */}
              </table>
           </div>
           <div className="package-container-button">
                    <div className="package-container-button-ctn">
                        <button onClick={onClose} className="cancel-btn">Cancel</button>
                    </div>
                    <div className="package-container-button-ctn">
                    <button className="submit-btn">Submit</button>
                    </div>
           </div>
           </div>
      </Dialog>

      <SuccessSnackbar
       open={successSnackbar}
       handleClose={()=>setSuccessSnackbar(false)}
       message={snackMsg}
     />
     <ErrorSnackbar
       open={errorSnackbar}
       handleClose={()=>setErrorSnackbar(false)}
       message={snackMsg}
     />
    </>
  );
};

export default PackageDuration;
