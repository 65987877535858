import axios from 'axios'
import { get_homeDashboard } from '../../Redux/HomeDashboard/homeDashboardSlice'
import { get_total_revenue_graph } from '../../Redux/HomeDashboard/totalRevenueSlice'
import { store } from '../../Redux/Store'
import { BASE_URL, HEADERS, HEADERS1 } from '../urlAndPath'
import { getRecentPurchase } from '../../Redux/HomeDashboard/RecentPurchaseSlice'
import { get_quickAccess } from '../../Redux/HomeDashboard/QuickAccessSlice'



// getdashboard...


export const getdashboard=(setisLoading)=>{
    axios.get("dashboard/",HEADERS)
    .then((res)=>{
        // console.log("Response data--->",res)
        if(res.status === 200){
          store.dispatch(get_homeDashboard({homeDashboardData:res.data}))
          setisLoading(false);
        }
    })
    .catch((err)=>{
        console.log("Something Wrong....")
    })
};




// GetRecentPurchases

export const getRecentPurchases=(setisLoading)=>{
    console.log("88888")
    axios.get("dashboard/getRecentPurchases",HEADERS)
    .then((res)=>{
        console.log("response",res)
        if(res.status === 200){
            store.dispatch(getRecentPurchase({RecentPurchaseData:res.data}))
            setisLoading(false);

        }
    }).catch((err)=>{
        console.log("getrecentPurchase error occured.....")
    })


}




//getTotalRevenueGraphAPICall

export const getTotalRevenueGraphApICall = (body)=>{
    
    // console.log("gettotalRevenueGraph api call",body)
    axios.post("dashboard/getSaleGraph",body,HEADERS)
    .then((res)=>{
        // console.log(res)
        if(res.status === 200){
            // console.log("success fullyyyy......")
            store.dispatch(get_total_revenue_graph({totalGraphData:res.data}))
        }


    }).catch((err)=>{
        console.log("wrong....")

    })

};

//viewAllQuickAccess.
export const viewAllQuickAccess=()=>{
    axios.post("admin/viewQuickAccess",{},HEADERS)
    .then((res)=>{
        if(res.status === 200){
            console.log("viewAll quick access--->",res.data)
            store.dispatch(get_quickAccess({quickAccessData:res.data}))
        }

    }).catch((err)=>{
        console.log("WROOOONGGGG>>>>>")
    })

}




//addQuickAccessAPICall 

export const addQuickAccessAPICall=(body,updateListener)=>{
    console.log("addQuickaccessapi call....")
    console.log(body)
    axios.post("admin/addQuickAccess",body,HEADERS)
    .then((res)=>{
        if(res.status === 200){
            console.log("success fullyyyy....")
            updateListener()
        }
    })
    .catch((err)=>{
        console.log("Error Occured...")
    })

}