import React from 'react'
import TopContainer from '../../CustomComponent/TopContainer'

export const LoanApproval = () => {
  return (
    <>
        <TopContainer label="Loan > Loan Approval"/>
        <div className='global-container'>
            <div className='global-heading-containe'>
                <h3>Loan Approval</h3>
                <div className='global-search-container'>
                  <input type="text" placeholder='Search...' />
                  <i class="bi bi-search search-icon global-search-icon"></i>
                </div>
            </div>
            <div className='global-container-table'>
               <table>
                  <thead>
                    <tr>
                        <th>Loan ID</th>
                        <th>Employee ID</th>
                        <th>Name</th>
                        <th>Image</th>
                        <th>Mobile</th>
                        <th>Loan Amount</th>
                        <th>Actual Amount</th>
                        <th>Applied Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                  </tbody>
               </table>
            </div>
        </div>
    </>
  )
}
