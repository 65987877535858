import { Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'

export const AddSubModule = (props) => {
    const { open, onClose,onDataUpdate,dataArray} = props;
    const data=[
      {name:"basic",isChecked:false},
      {name:"accounts",isChecked:false},
      {name:"payroll",isChecked:false},
      {name:"mobileService",isChecked:false}
    ]
    const [dataToParent,setDataToParent]=useState(data)
    const checkboxChange = (index) => () => {
      const newData = [...dataToParent]; // Create a copy of the array
      newData[index] = { ...newData[index], isChecked: !newData[index].isChecked }; // Update the isChecked property
      setDataToParent(newData); // Update the state
      onDataUpdate(newData); // Call the function to pass data to the parent
  };

  useEffect(()=>{
    dataArray?.length !==0 ? setDataToParent(dataArray):setDataToParent(data)

  },[dataArray])

  
  return (
    <>
        <Dialog open={open} maxWidth="lg" onClose={onClose}>
            <div className="package-parent">
               <div className="package-head-container">
                   <h2>Add Sub module</h2>
               </div>
               <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start",width:"80%",margin:"4%"}}>
                  {dataToParent?.map((r,i)=>(
                    <div style={{display:"flex",margin:"4px 0"}}>
                        <input 
                           type="checkbox"  
                           style={{width:"18px",height:"18px",accentColor:"black",cursor:"pointer"}}
                           checked={r?.isChecked}
                           onChange={checkboxChange(i)}
                           />
                        <label htmlFor="" style={{marginLeft:"8px",fontSize:"0.9rem",fontWeight:600}}>{r?.name}</label>
                    </div>
                  ))}
                
               
               </div>
               <div className="package-container-button">
                    <div className="package-container-button-ctn">
                        <button onClick={onClose} className="cancel-btn">Cancel</button>
                    </div>
                    <div className="package-container-button-ctn">
                    <button onClick={onClose} className="submit-btn">Submit</button>
                    </div>
           </div>
            </div>
        </Dialog>
    </>
  )
}
