import React, { useState } from 'react'
import TopContainer from '../../CustomComponent/TopContainer'
import '../../../../Css/Staff/addNewLoan.css'
import { Autocomplete, IconButton, TextField } from '@mui/material'
import uploadImage from '../../../../Assets/Images/upload-image.png'
import uploadVideoImg from '../../../../Assets/Images/video thumb.png'

export const AddNewLoan = () => {
  const [uploadedImage,setUploadedImage]=useState()
  const imageUploadFn=(e)=>{
    setUploadedImage(e.target.files[0])
  }
  console.log(uploadedImage);
  const removeImage=()=>{
    setUploadedImage(undefined)
  }
  return (
    <>
       <TopContainer label="Loan > Add New Loan"/>
       <div className='global-container'>
           <div className='global-heading-containe'>
                <h3 style={{margin:"4px 0 0 8px"}}>Add New Loan</h3>
            </div>
            {/* employee details */}
            <div className='add-new-loan-single-container'>
              <h4>Employee Details</h4>
              <div className='add-new-loan-single-input-container'>
                <div className="global-single-input auto-complete">
                   <p>Select Branch</p>
                   <Autocomplete
                     sx={{width:"100%"}}
                     options={['']}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=><TextField {...params}/>}
                   />
                </div>
                <div className="global-single-input auto-complete">
                   <p>Employee Name</p>
                   <Autocomplete
                     sx={{width:"100%"}}
                     options={['']}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=><TextField {...params}/>}
                   />
                </div>
                <div className='global-single-input'>
                  <p>Employee ID</p>
                  <input type="text" />
                </div>
                <div className='global-single-input'>
                  <p>Department</p>
                  <input type="text" />
                </div>
                <div className='global-single-input'>
                  <p>Designation</p>
                  <input type="text" />
                </div>
                <div className='global-single-input'>
                  <p>Contact</p>
                  <input type="text" />
                </div>
              </div>
            </div>

            {/* loan details */}
            <div className='add-new-loan-single-container'>
              <h4>Loan Details</h4>
              <div className='add-new-loan-single-input-container'>
                <div className="global-single-input auto-complete">
                   <p>Payment Method</p>
                   <Autocomplete
                     sx={{width:"100%"}}
                     options={['']}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=><TextField {...params}/>}
                   />
                </div>
                <div className="global-single-input auto-complete">
                   <p>Loan Type</p>
                   <Autocomplete
                     sx={{width:"100%"}}
                     options={['']}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=><TextField {...params}/>}
                   />
                </div>
                <div className='global-single-input'>
                  <p>Loan Amount</p>
                  <input type="text" />
                </div>
                <div className='global-single-input'>
                  <p>Start Date</p>
                  <input type="date" />
                </div>
                <div className='global-single-input'>
                  <p>Interest Rate</p>
                  <input type="text" />
                </div>
                <div className='global-single-input'>
                  <p>Tenure</p>
                  <input type="text" />
                </div>
                <div className='global-single-input'>
                  <p>Interest Amount</p>
                  <input type="text" />
                </div>
                <div className='global-single-input'>
                  <p>End Date</p>
                  <input type="date" />
                </div>
                <div className='global-single-input'>
                  <p>Extra Interest Amount</p>
                  <input type="text" />
                </div>
                <div className="global-single-input auto-complete">
                   <p>Interest Type</p>
                   <Autocomplete
                     sx={{width:"100%"}}
                     options={['']}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=><TextField {...params}/>}
                   />
                </div>
                <div className='global-single-input'>
                  <p>Remaining Amount</p>
                  <input type="text" />
                </div>

              </div>
            </div>

             {/* bank details */}
             <div className='add-new-loan-single-container'>
              <h4>Employee Details</h4>
              <div className='add-new-loan-single-input-container'>
                <div className='global-single-input'>
                  <p>Bank Name</p>
                  <input type="text" />
                </div>
                <div className='global-single-input'>
                  <p>Account Holder Name</p>
                  <input type="text" />
                </div>
                <div className='global-single-input'>
                  <p>Account No</p>
                  <input type="text" />
                </div>
                <div className='global-single-input'>
                  <p>IFSC Code</p>
                  <input type="text" />
                </div>
                <div className="global-single-input auto-complete">
                   <p>Branch</p>
                   <Autocomplete
                     sx={{width:"100%"}}
                     options={['']}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=><TextField {...params}/>}
                   />
                </div>
              </div>
            </div>

            {/* add document */}
            <div className='add-new-loan-single-container'>
              <h4>Employee Details</h4>
              <div className='add-new-loan-single-input-container'>
               
                <div className="global-single-input auto-complete">
                   <p>Select Document Type</p>
                   <Autocomplete
                     sx={{width:"100%"}}
                     options={['']}
                     getOptionLabel={(option)=>option}
                     renderInput={(params)=><TextField {...params}/>}
                   />
                </div>
                <div className='global-single-input'>
                  <p>Document No</p>
                  <input type="text" />
                </div>
                <div className='global-single-input'>
                  <p>Upload Image</p>
                  {
                    uploadedImage === undefined &&
                  <div className='image-upload-file-conatiner'>
                  <input type="file" id="upload-image" onChange={imageUploadFn} />
                  <label htmlFor="upload-image">
                    <img src={uploadImage} alt="" />
                  </label>
                  </div>
                  }
                  {
                    uploadedImage !== undefined &&
                    <div className='image-preview'>
                        <IconButton className='image-close-icon' onClick={removeImage}>
                           <i class="bi bi-x"></i>
                        </IconButton>
                        <img src={uploadedImage} alt="" />
                    </div>
                    
                  }
                  
                  
                </div>
              </div>
            </div>
       </div>
    </>
  )
}
