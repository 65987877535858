import {createSlice} from "@reduxjs/toolkit";
const smsProviderSlice = createSlice({
    name:'smsProviderSlice',
    initialState:{
        value:undefined
    },
    reducers:{
        add_sms_Provider:(state,action)=>{
            state.value = action.payload.addsmsProvider;
            console.log(state.value)
        }
    }
});

export const {add_sms_Provider} = smsProviderSlice.actions;
export default smsProviderSlice.reducer;