import axios from "axios"
import { get_All_offers } from "../../Redux/Offers/OffersList"
import { store } from "../../Redux/Store"
import { HEADERS, HEADERS_FORMDATA } from "../urlAndPath"
import { get_Single_offer_List } from "../../Redux/Offers/SingleOfferApi";
import { update_TrasctiionID } from "../../Redux/Offers/transactionID";


export const getAllOffers =(setisLoading)=>{
    axios.get("offer/getAllOffers",HEADERS)
    .then((res)=>{
        console.log("GetAll OffersList-->",res.data)
        if(res.status === 200){
            store.dispatch(get_All_offers({getAlloffersList:res.data}))
            setisLoading(false);
        }

    }).catch((err)=>{
        console.log('ERROR')
    })
};


    // getSingleOfferApiCall

export const getSingleOfferApiCall=(body)=>{
    // console.log(body)
    axios.post("offer/getSingleOffer",body,HEADERS)
    .then((res)=>{
        console.log("reponsedData===>",res)
        if(res.status === 200){
            store.dispatch(get_Single_offer_List({singleOfferView:res.data}));
        }

    }).catch((err)=>{
        console.log("ERRORR>>>>>")
        store.dispatch(get_Single_offer_List({singleOfferView:undefined}))
        
    })
}


//generateTransctionID_Api_Call

export const generateTransactionIDApiCall =()=>{
    axios.get("offer/generateOfferId",HEADERS)
    .then((res)=>{
        if(res.status === 200){
            store.dispatch(update_TrasctiionID({transactionID:res.data}))
        }

    }).catch((err)=>{
        console.log("ERRORR OCCURED>>>>>")

    })

}   


// addofferApiCall
export const addOfferApiCall =(formdata,setSuccessSnackbaropen,setSnackMsg,clearState,setErrorSnackbar,setErrorsnackMsg)=>{
    console.log(formdata)
    axios.post("offer/addOffer",formdata,HEADERS_FORMDATA).then((res)=>{
    
        if(res.status === 200){
            // throw "manually error created...."
            console.log(res.data)
            setSuccessSnackbaropen(true)
            setSnackMsg("Successfully... 👍");
            clearState()
        }

    }).catch((err)=>{
        console.log(err)
        setErrorSnackbar(true)
        setErrorsnackMsg("Something is Wrong...")
    })
}