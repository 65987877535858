import React, { useState } from 'react'
import '../../../../Css/Staff/HRM/addEmployee.css'
import TopContainer from '../../CustomComponent/TopContainer'
import { EmployeeSalary } from '../Payroll/EmployeeSalary'
import { PersonalInfo } from './AddEmployeePages/PersonalInfo'
import { CompanyInfo } from './AddEmployeePages/CompanyInfo'
import { BankDetails } from './AddEmployeePages/BankDetails'

export const AddEmployee = () => {
   const [activeTab,setActiveTab]=useState("employeeProfileInfo")

   const profileInfoClick=()=>{
    document.getElementById("employeeProfileInfo").classList.add("tab-active")
    document.getElementById("employeeCompanyInfo").classList.remove("tab-active")
    document.getElementById("employeebankDetails").classList.remove("tab-active")
    setActiveTab("employeeProfileInfo")
   }

   const companyInfoClick=()=>{
    document.getElementById("employeeProfileInfo").classList.remove("tab-active")
    document.getElementById("employeeCompanyInfo").classList.add("tab-active")
    document.getElementById("employeebankDetails").classList.remove("tab-active")
    setActiveTab("employeeCompanyInfo")
   }
   const bankDetailsClick=()=>{
    document.getElementById("employeeProfileInfo").classList.remove("tab-active")
    document.getElementById("employeeCompanyInfo").classList.remove("tab-active")
    document.getElementById("employeebankDetails").classList.add("tab-active")
    setActiveTab("employeebankDetails")
   }

   console.log(activeTab);
  return (
    <>
       <TopContainer label="Staff > HRM > Add Employee"/>
        <div className='add-employee-container'>
          <div className='add-employee-top-container'>
            <div className='add-employee-top-sub-container'>
              <div 
                 className='add-employee-single-tab tab-active'
                 id="employeeProfileInfo"
                 onClick={profileInfoClick}

                 >
                <p>Personal Info</p>
              </div>
              <div 
                className='add-employee-single-tab'
                id="employeeCompanyInfo"
                onClick={companyInfoClick}
              >
                <p>Company Info</p>
              </div>
              <div 
                 className='add-employee-single-tab'
                 id="employeebankDetails"
                 onClick={bankDetailsClick}
                 >
                <p>Bank Details</p>
              </div>
              <div 
                className='add-employee-single-tab disable-menu'
                id="employeeleaveDetails"
                // onClick={leaveDetailsClick}
                >
                <p>Leave Details</p>
              </div>
              <div 
                 className='add-employee-single-tab disable-menu'
                 id="employeeloanDetails"
                //  onClick={loanDetailsClick}
                 
                 >
                <p>Loan Details</p>
              </div>
              <div 
                className='add-employee-single-tab disable-menu'
                id="employeePayslip"
                // onClick={payslipClick}
                >
                <p>Payslip</p>
              </div>
              <div 
                className='add-employee-single-tab disable-menu'
                id="employeeDocumentDetails"
                // onClick={documentDetailsClick}
                >
                <p>Document Details</p>
              </div>
            </div>
          </div>
          <div className='add-employee-bottom-container'>
             {
              activeTab === "employeeProfileInfo"?
              <PersonalInfo navigateToNextBtn={companyInfoClick}/>
              :activeTab ==="employeeCompanyInfo"?
              <CompanyInfo moveToBankDetails={bankDetailsClick}/>
              :activeTab === "employeebankDetails"?
              <BankDetails/>
              :undefined
             }
          </div>
        </div>
    </>
  )
}
