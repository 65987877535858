//viewCustomersList

import axios from "axios";
import { getDataInfo } from "../../Redux/Marketing/MarketingSlice";
import { store } from "../../Redux/Store";
import { HEADERS, HEADER_QUERY } from "../urlAndPath";


// viewCustomersList
export const viewCustomersList = () => {
  store.dispatch(getDataInfo());
};

//delete CustomersAPiCall
export const deleteCustomersApiCall = (
  id,
  updateListner,
  setSuccessSnackbaropen,
  setSnackMsg,
  setErrorSnackOpen
) => {
  axios
    .delete("customer/deleteSalesCustomer", {
      headers: HEADER_QUERY,
      data: {
        _id: id,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        // throw " manuall error create..."
        console.log(res);
        updateListner();
        setSuccessSnackbaropen(true);
        setSnackMsg("Deleted...");
      }
    })
    .catch((err) => {
      console.log("errorr");
      setErrorSnackOpen(true);
      if (err.response === undefined) {
        setSnackMsg("Network Connection error!!");
      }
      if (err.response.status === 409) {
        setSnackMsg("Category Already Exist");
      }

      if (err.response.status === 401) {
        setSnackMsg("Unauthorized");
      }
      if (err.response.status === 403) {
        setSnackMsg("Forbidden");
      }
      if (err.response.status === 422) {
        setSnackMsg(err.response.data?.msg);
      }
    });
};

//add Customer

export const addCustomer=(body,setSuccessSnackbaropen,setSnackMsg,clearState)=>{
  // console.log(body)
  axios.post("customer/addSalesCustomer",body,HEADERS).then((res)=>{

    if(res.status === 200){
      setSuccessSnackbaropen(true)
      console.log(res.data)
      setSnackMsg("Successfully... 👍")

      clearState()
     
    }

    
  }).catch((err)=>{
    console.error("Error !!!")

  })
}
