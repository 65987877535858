import React, { useEffect, useState } from 'react'
import "./customerRegistration.css"
import { Autocomplete, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { viewAllPackageList } from '../../../API/AddPackage/AddPackageAPI'
import { useSelector } from 'react-redux'
import { filterErpPackage } from './CustomerRegistrationAPICall'
import { get_my_plans_singleView } from '../../../Redux/RegisterdList/registerdUserSlice'
import { store } from '../../../Redux/Store'

export const CustomerRegistration = () => {
    const navigateTo=useNavigate()
    const packageList=useSelector((state)=>state.generatePackageIdSlice?.value1)
    const [selectedOption, setSelectedOption] = useState('month');

    const handleOptionClick = (option) => {
        setSelectedOption(option === selectedOption ? selectedOption : option);
      };
    const clickNextBtn=()=>{
        navigateTo("/dashboard/customerRegistration/cart")
    }
    console.log(selectedOption);

     useEffect(()=>{
        viewAllPackageList()
       
      },[])

      useEffect(()=>{
        filterErpPackage()
      },[])

  return (
    <div className='global-container'>
        {/* <div className='register-customer-container-first'>
         <div className="global-single-input auto-complete">
            <p>ERP</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={[""]}
              getOptionLabel={(option)=>option}
              renderInput={(params)=><TextField {...params}/>}
            />
         </div>
        </div> */}
        <div className='register-customer-container-second'>
           <div className="global-single-input auto-complete">
            <p>ERP</p>
            <Autocomplete
              sx={{width:"100%"}}
              options={packageList||[""]}
              getOptionLabel={(option)=>option?.packageName}
              renderInput={(params)=><TextField {...params}/>}
            />
            </div>
            <div className='register-customer-container-second-radio'>
               <div 
                  className={`register-customer-container-second-radio-single ${
                    selectedOption === 'month' ? 'select' : ''
                  }`}
                  onClick={() => handleOptionClick('month')}
                  >
                <p>Month</p>
               </div>
               <div 
                 className={`register-customer-container-second-radio-single ${
                    selectedOption === 'year' ? 'select' : ''
                  }`}
                 onClick={() => handleOptionClick('year')}
                 >
               <p>Year</p>
               </div>
            </div>
          
        </div>
        <div className='register-customer-container-third'>
            <div className='register-customer-container-third-single'>
                <div className='register-customer-container-third-single-top'>
                     <p className='register-customer-card-std'>STANDARD</p>
                     <p className='register-customer-card-std-price'>AED 150/month</p>
                </div>
                <div className='register-customer-container-third-single-bottom'>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <button onClick={clickNextBtn}>PURCHASE</button>
                </div>
            </div>
            <div className='register-customer-container-third-single platinum-card'>
                <div className='register-customer-container-third-single-top platinum-card-top'>
                     <p className='register-customer-card-std'>PLATINUM</p>
                     <p className='register-customer-card-std-price'>AED 350/month</p>
                </div>
                <div className='register-customer-container-third-single-bottom platinum-card-bottom'>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <div className='register-customer-card-icon-label'>
                        <div className='register-customer-card-icon'>
                           <i class="bi bi-check2" style={{color:"blue"}}></i>
                        </div>
                        <p>Among design professionals</p>
                    </div>
                    <button onClick={clickNextBtn}>PURCHASE</button>
                </div>
            </div>
        </div>
    </div>
  )
}
