import React from 'react'
import TopContainer from '../CustomComponent/TopContainer'
import { Select } from '@mui/material'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { IconButton } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import RefundTransaction from "../CustomComponent/RefundTransaction"
import { useState } from 'react';
// css from deviceSettings.css
export const Refund = () => {

   const[openDialog,setOpenDialog]=useState(false)
   const clickOpenDialog =()=>{
      setOpenDialog(true)
   }
   const clickCloseDialog =()=>{
      setOpenDialog(false)
   }

  return (
    <>
    <TopContainer label='Refund'/>
    {/* <div className="global-container device-settings-container">
    <p>Refund</p>
    <hr />
    </div> */}

    <div className="refund-list-container">
        <div className="global-container-table">
          <table>
            <thead>
              <tr>
                <th>Customer ID</th>
                <th>Customer Name</th>
                <th>Contact</th>
                <th>Email</th>
                <th>Refund Date</th>
                <th>Package</th>
                <th>Amount</th>
                <th>Refund Status</th>
                <th>Refund</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PKJS4892299</td>
                <td>Rajeshhh</td>
                <td>9003992311</td>
                <td>akiki@gmail.com</td>
                <td>12/12/22</td>
                <td> ERP </td>
                <td>500.00</td>
                <td><div className="refund-status-pending-ctn">Pending</div></td>
                <td><button onClick={clickOpenDialog} className='refund-pending-btn'>Pay</button></td>
              </tr>
              <tr>
                <td>PKJS4892299</td>
                <td>Rajeshhh</td>
                <td>9003992311</td>
                <td>akiki@gmail.com</td>
                <td>12/12/22</td>
                <td> ERP </td>
                <td>500.00</td>
                <td><div className="refund-status-cancel-ctn">Cancelled</div></td>
                <td><button className='refund-cancel-btn' disabled>Pay</button></td>
              </tr>
              <tr>
                <td>PKJS4892299</td>
                <td>Rajeshhh</td>
                <td>9003992311</td>
                <td>akiki@gmail.com</td>
                <td>12/12/22</td>
                <td> ERP </td>
                <td>500.00</td>
                <td><div className="refund-status-cancel-ctn">Cancelled</div></td>
                <td><button className='refund-cancel-btn' disabled>Pay</button></td>
              </tr>
              <tr>
                <td>PKJS4892299</td>
                <td>Rajeshhh</td>
                <td>9003992311</td>
                <td>akiki@gmail.com</td>
                <td>12/12/22</td>
                <td> ERP </td>
                <td>500.00</td>
                <td><div className="refund-status-cancel-ctn">Cancelled</div></td>
                <td><button className='refund-cancel-btn' disabled>Pay</button></td>
              </tr>
              <tr>
                <td>PKJS4892299</td>
                <td>Rajeshhh</td>
                <td>9003992311</td>
                <td>akiki@gmail.com</td>
                <td>12/12/22</td>
                <td> ERP </td>
                <td>500.00</td>
                <td><div className="refund-status-cancel-ctn">Cancelled</div></td>
                <td><button className='refund-cancel-btn' disabled>Pay</button></td>
              </tr>
              <tr>
                <td>PKJS4892299</td>
                <td>Rajeshhh</td>
                <td>9003992311</td>
                <td>akiki@gmail.com</td>
                <td>12/12/22</td>
                <td> ERP </td>
                <td>500.00</td>
                <td><div className="refund-status-cancel-ctn">Cancelled</div></td>
                <td><button className='refund-cancel-btn' disabled>Pay</button></td>
              </tr>
              <tr>
                <td>PKJS4892299</td>
                <td>Rajeshhh</td>
                <td>9003992311</td>
                <td>akiki@gmail.com</td>
                <td>12/12/22</td>
                <td> ERP </td>
                <td>500.00</td>
                <td><div className="refund-status-cancel-ctn">Cancelled</div></td>
                <td><button className='refund-cancel-btn' disabled>Pay</button></td>
              </tr>
              

            </tbody>
            
          </table>
        </div>
        </div>
        <RefundTransaction open={openDialog} onClose={clickCloseDialog}/>
    </>
  )
}
