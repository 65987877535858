/** @format */

//add device settings Api

import axios from "axios";
import { HEADERS } from "../urlAndPath";
import { store } from "../../Redux/Store";
import { add_device_Settings } from "../../Redux/DeviceSettings/deviceSettingsSlice";

export const addDeviceSettingsApi = (
  body,
  setSuccessSnackbaropen,
  setSnackMsg,
  setCountry,
  setDeviceType,
  setBrand,
  setApiPath,
  updateListner
) => {
  console.log(body);

  axios
    .post(
      "admin/addDeviceSettings",
      body,
      HEADERS
    )
    .then((res) => {
      if (res.status === 200) {
        store.dispatch(add_device_Settings({ addDeviceSettings: res.data }));
        updateListner()
        setSuccessSnackbaropen(true);
        setSnackMsg("Successfully... 👍");
        setCountry(null);
        setDeviceType(null);
        setBrand(null);
        setApiPath("");

      }
    })
    .catch((err) => {
      console.log("Something is Wrong......");
    });
};

// view all device list
export const viewAllDeviceList=(setAllDeviceList)=>{
  axios.get("admin/getDeviceSettings",HEADERS)
  .then((res)=>{
    if(res.status ===200){
      console.log('get all');
      setAllDeviceList(res.data)
    }
  })
  .catch((err)=>{
    console.log(err.response);
  })
}
