import { configureStore } from '@reduxjs/toolkit'
import reportForwardMenuStateReducer from '../Redux/Global States/ReportForwardMenuStateSlice'
import homeDashboardReducer from '../Redux/HomeDashboard/homeDashboardSlice'
import totalRevenueGraphReducer from './HomeDashboard/totalRevenueSlice'
import registerdUsersReducer from './RegisterdList/registerdUserSlice'
import smsProviderSlice from './addsmsProvider/addsmsProvider';
import  getAllsmsProviderSlice  from './addsmsProvider/getsmsProvider'
import deviceSettingsSlice from './DeviceSettings/deviceSettingsSlice'
import RecentPurchaseSlice from './HomeDashboard/RecentPurchaseSlice'
import quickAccessReducer from './HomeDashboard/QuickAccessSlice'
import offersListReducer from './Offers/OffersList'
import singleOfferReducer from './Offers/SingleOfferApi'
import transactionIDReducer from './Offers/transactionID'
import generatePackageIdReducer from './AddPackage/generatePackageIdSlice'
import departmentReducer from './Staff/departmentSlice'
import desigantionReducer from './Staff/designationSlice'
import manageEmployeeReducer from './Staff/manageEmployeeSlice'
import TaxlistReducer from './Tax/TaxSlice';
import MarketingReducer from './Marketing/MarketingSlice'
import smsPackageReducer from './smsPackage/getSmsPackageSlice'
import catalogCategorySlice from "../Components/Pages/Catelog/catalogCategorySlice"
import customerRegistrationSlice from "../Components/Pages/Registered Users/customerRegistrationSlice"
import taxSettingsSlice from "../Components/Pages/Tax/taxSettingsSlice";
import TaxListSlice from "../Redux/Tax/TaxSlice";
import demoRequestSlice from "../Redux/Marketing/demoRequestSlice"

export const store = configureStore({
  reducer: {
    reportForwardMenuState:reportForwardMenuStateReducer,
    homeDashboardSlice:homeDashboardReducer,
    totalRevenueGraph:totalRevenueGraphReducer,
    registerdUsers:registerdUsersReducer,
    addsmsProvider:smsProviderSlice,
    getAllsmsProvider:getAllsmsProviderSlice,
    addDeviceSettings:deviceSettingsSlice,
    RecentPurchaselist:RecentPurchaseSlice,
    quickAccessSlice:quickAccessReducer,
    offersListSlice:offersListReducer,
    SingleOfferSlice:singleOfferReducer,
    transactionIdSlice:transactionIDReducer,
    generatePackageIdSlice:generatePackageIdReducer,
    departmentSlice:departmentReducer,
    designationSlice:desigantionReducer,
    manageEmployeeSlice:manageEmployeeReducer,
    // TaxlistSlice:TaxlistReducer,
    MarketinSlice:MarketingReducer,
    getAllsmsPackageSlice:smsPackageReducer,
    catalogCategorySlice,
    customerRegistrationSlice,
    taxSettingsSlice,
    TaxListSlice,
    demoRequestSlice

  },
})