
import "../../../Css/SingleComponents/customRadioGroup.css"


export const CustomRadioGroup = (props) => {
    const { customRadioData,width,onClick,onChange,margin,backgroundColor,className,singleContainerWidth} = props;
    console.log("className---->",className)
    const containerStyle={
      width,
      backgroundColor,
      margin
    }
  
    return (
      <div className="custom-radio-group-container" style={containerStyle}>
        {customRadioData?.map((radio, i) =>
          i !== customRadioData.length - 1 ? (
            <div className="custom-radio-group" style={{width:singleContainerWidth}}>
              <input className={className} type="radio" id={radio?.id} name={radio?.name} value={radio?.value} onClick={onClick} onChange={onChange} defaultChecked={radio.checked}/>
              <label  htmlFor={radio?.id}>{radio?.label}</label>
              &nbsp;&nbsp;<hr />
            </div>
          ) : (
            <div className="custom-radio-group"  style={{width:singleContainerWidth}}>
              <input className={className} type="radio" id={radio?.id} name={radio?.name} value={radio?.value} onClick={onClick} onChange={onChange} defaultChecked={radio.checked}/>
              <label style={{margin:margin}} htmlFor={radio?.id}>{radio.label}</label>
            </div>
          )
        )}
      </div>
    );
  };