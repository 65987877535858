import React, { useState } from "react";
import "../../../Css/HomeDashboard/homeDashboard.css";
import { DatePicker } from "../CustomComponent/DatePicker.";
import { TimePicker } from "../CustomComponent/TimePicker";
import { Card } from "./Card";
import RegisteredUserIcon from "../../../Assets/Icons/Active_Customer_Count.svg";
import RenewalIcon from "../../../Assets/Icons/renewable1.svg";
import TopContainer from "../CustomComponent/TopContainer";
import { Purchase } from "./Purchase";
import { Sales} from "./Sales"
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getdashboard } from "../../../API/HomeDashboard/homeDashboardAPI";
export const HomeDashboard = () => {


  const dashBoardData=useSelector((state)=>state.homeDashboardSlice.value);

  const [isLoading,setisLoading] = useState(true)
  
  
  useEffect(()=>{
    getdashboard(setisLoading)
  },[])




  return (
    <div className="home-dashboard-main-container">
      <TopContainer label="Dashboard" />
      <div className="home-dashboard-second-container">
        <Card
          url={RegisteredUserIcon}
          heading={"Total Customer Count"}
          price={dashBoardData?.totalCustomerCount}
          color="#8373f1"
          
          
        />
        <Card
          backgroundColor="#27bc86"
          url={RegisteredUserIcon}
          heading={"Active Customer Count"}
          price={1500}
          color="#27bc86"
        />
        <Card
          backgroundColor="#ffa200"
          url={RenewalIcon}
          heading={"Renewal Count"}
          price={1500}
          color="#ffa200"
        />
      </div>
      <div className="home-dashboard-middle-container">
        <Purchase dashBoardData={dashBoardData} isLoading={isLoading}/>
      </div>

      <div className="home-dashboard-bottom-container">
        <Sales/>
      </div>
    </div>
  );
};
